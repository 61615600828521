import { useEffect, useState } from 'react';
import Cropper from 'react-cropper';
import { Button, Col, Modal, ModalBody, ModalFooter, Row } from 'reactstrap';
import '@assets/scss/cropper.css';

const CropperModal = (props) => {
	const [image, setImage] = useState(props.photoUrl);
	const [cropper, setCropper] = useState();

	useEffect(() => {
		async function getData() {
			let response = await fetch(props.photoUrl);
			const reader = new FileReader();
			reader.onload = () => {
				setImage(reader.result);
			};
			reader.readAsDataURL(await response.blob());
		}
		if (props.photoUrl) getData();
	});
	///////////////////////////////////////////////////////////////////////

	const getCropData = () => {
		if (typeof cropper !== 'undefined') {
			props.onCrop(cropper.getCroppedCanvas().toDataURL());
		}
	};

	const rotateImage = () => {
		cropper.rotate(90);
	};

	return (
		<div>
			<Modal isOpen={props.showing}>
				<ModalBody>
					<div style={{ width: '100%' }}>
						<Cropper
							style={{ height: '100%', width: '100%' }}
							zoomTo={0}
							initialAspectRatio={1}
							preview='.img-preview'
							src={image}
							viewMode={1}
							minCropBoxHeight={10}
							minCropBoxWidth={10}
							background={false}
							responsive={true}
							autoCropArea={1}
							checkOrientation={false}
							onInitialized={(instance) => {
								setCropper(instance);
							}}
							guides={true}
						/>
					</div>
				</ModalBody>
				<ModalFooter>
					<Row>
						<Col>
							<Button
								color='light'
								onClick={() => props.cancel()}
								className='modal-btn'
							>
								Cancel
							</Button>
							<Button onClick={rotateImage} className='modal-btn'>
								Rotate
							</Button>
							<Button
								color='dark'
								onClick={getCropData}
								className='modal-btn'
							>
								Crop
							</Button>
						</Col>
					</Row>
				</ModalFooter>
			</Modal>
		</div>
	);
	///////////////////////////////////////////////////////////////////////
};
export { CropperModal };
