// import { useParams } from 'react-router-dom';
import { useThemeMode } from 'hooks/themeMode';
import styles from './BotChatMessage.module.css';

const InitialMessageOptions = ({
	actionProvider,
	state: { showInitialOptions },
}) => {
	const { thememode } = useThemeMode();

	// const assetQuestions = [
	// 	'How is my Asset Health determined?',
	// 	'Why is this asset in this condition?',
	// 	'How do I decommission an asset?',
	// ];

	// const orgQuestions = [
	// 	'generate user account',
	// 	'provide leave behind report',
	// ];

	// const params = useParams();

	// const initialQs = params['*'].includes('asset')
	// 	? assetQuestions
	// 	: orgQuestions;
	const initialQs = [];

	const handleOption = async (e) => {
		e.preventDefault();
		actionProvider.handleOptionSelection(e.target.innerText);
	};

	const messageContainerStyleClasses =
		thememode !== 'dark'
			? styles.messageContainer
			: styles.messageContainer_dark;
	// const messageTextStyleClasses =
	// 	thememode !== 'dark' ? styles.messageText : styles.messageText_dark;

	return (
		showInitialOptions && (
			<div className='d-flex flex-column align-items-end'>
				{initialQs.map((question, id) => (
					<div
						key={id}
						onClick={handleOption}
						className={`border ${messageContainerStyleClasses}`}
						style={{
							marginBottom: '0.5em',
							cursor: 'pointer',
							// borderRadius: '20px',
						}}
					>
						{question}
					</div>
				))}
			</div>
		)
	);
};

export default InitialMessageOptions;
