import { Routes, Route, useParams, useNavigate } from 'react-router-dom';
import { Container, Row, Col } from 'reactstrap';
import { Dashboard } from '@views/SignedIn/Dashboard/Dashboard';
import { Profile } from '@views/SignedIn/Profile/Profile';
import { Sidebar } from '@SignedIn/Sidebar/Sidebar';
import { Locations } from '@views/SignedIn/Locations/Locations';
import { Navbar } from '@SignedIn/Navbar/Navbar';
import { SingleLocation } from '@views/SignedIn/SingleLocation/SingleLocation';
import { Forms } from '@views/SignedIn/Forms/Forms';
import { SingleForm } from '@views/SignedIn/SingleForm/SingleForm';
import { Plans } from '@views/SignedIn/Plans/Plans';
import { Admin } from '@views/SignedIn/Admin/Admin';
import { useEffect, useState } from 'react';
import { Releases } from '@views/SignedIn/Releases/Releases';
import { deleteField } from 'firebase/firestore';
import { auth } from '@assets/services/auth-service';
import { Toast } from '@SignedIn/Toast/Toast';
import { setUserRole, getUserRole } from '@assets/services/user-role-service';
import { CWDashboard } from '@views/SignedIn/CWDashboard/CWDashboard';
import { AssetProfile } from '@views/SignedIn/AssetProfile/AssetProfile';
import { getUser, updateUser } from '@services/user-service';
import { getOrganizations } from '@services/organization-service';
import { useContext } from 'react';
import AuthContext from '@assets/services/Auth';
import { PEChatbot } from '../../components/layouts/SignedIn/Chatbot/PEChatbot';
import { Copyright } from '@components/layouts/SignedIn/Copyright/Copyright';
import { DashboardProvider } from '@components/layouts/SignedIn/views/Dashboard/dashboardContext';
import { GraphProvider } from '@components/layouts/SignedIn/views/Dashboard/graphContext';

const SignedIn = (props) => {
	const { authInfo, setAuthInfo } = useContext(AuthContext);

	const [isSidebarOpen, setIsSidebarOpen] = useState(false);
	const [userOrganization, setUserOrganization] = useState('');
	const [organizations, setOrganizations] = useState([]);
	const [isToastShowing, setIsToastShowing] = useState(false);
	const [userRole, setUserRoleState] = useState('');
	const [userPaid, setUserPaid] = useState(null);
	const [dashboard, setDashboard] = useState();
	const [toggleChatbot, setToggleChatbot] = useState(false);

	const navigate = useNavigate();
	const routeObj = useParams();
	const currentURL = routeObj['*'];

	const getData = async () => {
		const user = await getUser(auth.currentUser.uid);
		const docSnap = await getOrganizations();
		let orgs = [];
		let userOrg;
		docSnap.forEach((org) => {
			orgs.push({ id: org.id, data: org.data() });
			if (org.id == user.organization) {
				userOrg = org.data().name;
			}
		});

		if (userOrg === 'Cushman & Wakefield') {
			setDashboard(<CWDashboard />);
		} else {
			setDashboard(<Dashboard />);
		}

		setUserPaid(user.paid || false);
		setUserRole(user.role);
		setUserRoleState(getUserRole());
		setUserOrganization(userOrg);
		setOrganizations(orgs);
	};

	const updateUserOrg = async (orgId, orgName) => {
		if (userOrganization === orgName) return;
		await updateUser(auth.currentUser.uid, {
			organization: orgId,
			savedFilters: deleteField(),
		});
		const newAuthInfo = await JSON.parse(JSON.stringify(authInfo));
		newAuthInfo.org = orgId;
		setAuthInfo(newAuthInfo);
		setIsToastShowing({
			showing: true,
			title: 'Success',
			message: 'Organization has been changed',
			type: 'success',
			position: 'bottom',
		});
		setTimeout(() => {
			setIsToastShowing({
				showing: false,
			});
		}, 2500);
		setUserOrganization(orgName);

		// redirect user to locations table if routed to a single location
		if (currentURL.includes('locations')) {
			setTimeout(() => {
				navigate('/locations');
				window.location.reload();
			}, 1500);
		} else {
			setTimeout(() => {
				window.location.reload();
			}, 1500);
		}
	};

	useEffect(() => {
		getData();
	}, [userOrganization]);

	return (
		<div className='page m-0 vw-100'>
			<Row className='m-0 vw-100'>
				<Container fluid className='p-0 m-0 vw-100'>
					<div className='d-flex flex-column flex-md-row'>
						<Sidebar
							isSidebarOpen={isSidebarOpen}
							className='sidebar-menu'
							name={props.authInfo.displayName}
							orgOptions={organizations}
							org={userOrganization}
							updateUserOrg={updateUserOrg}
							userRole={userRole}
							userPaid={userPaid}
						/>
						<PEChatbot
							toggleChatbot={toggleChatbot}
							setToggleChatbot={setToggleChatbot}
						/>
						<Col
							className={
								isSidebarOpen
									? 'px-4 pt-1 main-page-container min-vh-100'
									: 'px-4 pt-1 main-page-container min-vh-100 main-page-close'
							}
						>
							<Navbar
								setIsToastShowing={setIsToastShowing}
								toggleChatbot={toggleChatbot}
								setToggleChatbot={setToggleChatbot}
								isSidebarOpen={isSidebarOpen}
								setIsSidebarOpen={setIsSidebarOpen}
							/>
							<DashboardProvider>
								<GraphProvider>
									<Routes>
										<Route path='/' element={dashboard} />
										<Route
											path='/profile'
											element={<Profile />}
										/>
										<Route
											path='/admin'
											element={
												<Admin
													setToast={setIsToastShowing}
													userRole={userRole}
												/>
											}
										/>
										<Route
											path='/admin/assets/:assetid'
											element={<AssetProfile />}
										/>
										<Route
											path='/plans'
											element={<Plans />}
										/>
										<Route
											path='/locations'
											element={
												<Locations
													setIsToastShowing={
														setIsToastShowing
													}
												/>
											}
										/>
										<Route
											path={'/locations/:id/'}
											element={
												<SingleLocation
													userRole={userRole}
													setToast={setIsToastShowing}
												/>
											}
										/>
										<Route
											path={
												'/locations/:id/assets/:assetid/'
											}
											element={<AssetProfile />}
										/>
										<Route
											path='/forms'
											element={<Forms />}
										/>
										<Route
											path='/forms/:id/:org'
											element={<SingleForm />}
										/>
										<Route
											path='/forms/:id/'
											element={<SingleForm />}
										/>
										<Route
											path='/releases'
											element={<Releases />}
										/>
									</Routes>
								</GraphProvider>
							</DashboardProvider>

							<div className='copyright d-flex mt-5'>
								<Copyright
									setIsToastShowing={setIsToastShowing}
								/>
							</div>
						</Col>
					</div>
					<Toast
						isShowing={isToastShowing.showing}
						title={isToastShowing.title}
						subtitle={isToastShowing.message}
						position='bottom'
						type={isToastShowing.type}
					></Toast>
				</Container>
			</Row>
		</div>
	);
};

export { SignedIn };
