import { forwardRef } from 'react';

const NameInput = forwardRef((props, ref) => {
	return (
		<input
			id='name-input'
			ref={ref}
			onChange={props.onChange}
			value={props.value}
		/>
	);
});

NameInput.displayName = 'NameInput';

export { NameInput };
