import { useEffect, useState } from 'react';
import { Card, CardHeader, Table, Form, CardBody, Input } from 'reactstrap';
// import { searchDrafts } from './children/Search';
import { MdDownload } from 'react-icons/md';
import { AiOutlineClose } from 'react-icons/ai';
import { BsThreeDotsVertical } from 'react-icons/bs';
import { IoMdTrash } from 'react-icons/io';
import { Filter } from '@SignedIn/views/Locations/children/Filter';
import { canDelete } from '@assets/services/user-role-service';
import { ConfirmDialog } from '@SignedIn/views/Locations/children/ConfirmDialog';
import { Loader } from '@SignedIn/Loader/Loader';
import { useNavigate } from 'react-router-dom';
import { DB_ORG } from '@constants/db';
import {
	deleteWithRef,
	getDocFromPath,
	getOrganizations,
} from '@services/organization-service';
import { getForms } from '@services/form-service';
import {
	getDrafts,
	getDraftsCount,
	getDraftsWithPagenation,
} from '@services/draft-service';

const DraftsTable = (props) => {
	const {
		organization,
		browserLocationId,
		setViewingDrafts,
		isFocused,
		extraProps,
	} = props;
	const { formsArr, changeForm, formIndex } = extraProps;
	let displayAddress;
	if (browserLocationId) displayAddress = false;
	else displayAddress = true;

	const navigate = useNavigate();

	const [collectionCount, setCollectionCount] = useState(0);
	const [firstIndex, setFirstIndex] = useState();
	const [lastIndex, setLastIndex] = useState();
	const [prevFirstIndexes, setPrevFirstIndexes] = useState([]);
	const limitRows = 10;

	// Loader state
	const [isLoading, setIsLoading] = useState(true);

	// Search value state
	const [searchVal, setSearchVal] = useState('');

	// Tracks whether all checkboxes are selected or not
	const [allChecked, setAllChecked] = useState(false);

	// Checked submissions state
	const [checked, setChecked] = useState([]);

	// Show data state
	const [drafts, setDrafts] = useState();

	// Pagination tracking number state
	const [pageNum, setPageNum] = useState(1);

	// Sort by (which column) state
	const [sortBy, setSortBy] = useState('submittedDate');

	// Sort ascending (vs descending) state
	const [sortAsc, setSortAsc] = useState(false);

	// Helper state to trigger re-rendering when drafts changes
	const [draftsChange, setDraftsChange] = useState(false);

	// Drafts table component state
	const [draftsTable, setDraftsTable] = useState();

	// Determines whether the filter menu is shown
	const [showFilter, setShowFilter] = useState(false);

	// Determines which columns are shown
	const [columnsShown, setColumnsShown] = useState(() => {
		const object = {};
		object.formName = true;
		object.submittingUser = true;
		object.submittedDate = true;
		if (displayAddress) object.location = true;
		return object;
	});

	// Tracks whether columnsShown state changes (for useEffect's sake)
	const [columnsShownChanges, setColumnsShownChanges] = useState(false);

	// Determines whether the delete confirmation is shown
	const [showDeleteModal, setShowDeleteModal] = useState(false);

	// Toggles showFilter state
	const toggleFilter = () => setShowFilter(!showFilter);

	// Update checked attribute for given location
	const updateCheck = async (index, check) => {
		let newChecked = [...checked];
		const newDrafts = drafts;
		const newVal = newDrafts[index].ref;
		if (check) newChecked.push(newVal);
		else newChecked = newChecked.filter((item) => item.id != newVal.id);
		await setAllChecked(newChecked.length == collectionCount);
		await setChecked(newChecked);
		setDraftsChange(true);
	};

	// Deletes checked off drafts from front-end and database
	const deleteDrafts = async () => {
		for (const draftRef of checked) {
			await deleteWithRef(draftRef);
		}
		toggleDeleteModal();
		await setAllChecked(false);
		await setChecked([]);
		updateDraftData();
	};

	// Handles search & updates drafts with results
	const handleSearch = async (e = null) => {
		if (e != null) {
			e.preventDefault();
		}
		if (searchVal.length >= 3) {
			let results;
			// const results = await searchDrafts(
			// 	searchVal,
			// 	drafts,
			// 	displayAddress,
			// 	organization
			// );
			if (results.length == 0 && searchVal == '') {
				setDrafts(drafts);
			} else if (results.length == 0) {
				setDrafts([]);
			} else {
				let docs = [];
				for (let i = 0; i < results.length; i++) {
					if (
						organization !== DB_ORG.DATAFLEET &&
						results[i].path.includes(organization)
					) {
						let docSnap = await getDocFromPath(results[i].path);
						docs.push(docSnap);
					} else {
						let docSnap = await getDocFromPath(results[i].path);
						docs.push(docSnap);
					}
				}
				let formDrafts = [];
				for (const draft of docs) {
					let id = draft.id;
					let data = draft.data();
					data.submissionId = id;
					//data.formId = form.id;
					//data.logo = formLogo;
					data.organization = organization;
					data.address = `${
						data.location.address || data.location.address1
					}, ${data.location.city}, ${data.location.state} ${
						data.location.zip
					}`;
					if (organization == DB_ORG.CUSHMANWAKEFIELD) {
						data.siteName = data.location.name;
					}
					data.submittedDate = formatDate(data.submittedDate);
					const newDraft = { id: id, data: data };
					formDrafts = [...formDrafts, newDraft];
				}
				setDrafts(formDrafts);
			}
		} else {
			setDrafts(drafts);
		}

		setPageNum(1);
		setDraftsChange(true);
	};

	// Toggles showDeleteModal state
	const toggleDeleteModal = () => setShowDeleteModal(!showDeleteModal);

	// Checks or unchecks boxes for all locations
	const checkAll = async () => {
		if (!isLoading) setIsLoading(true);
		const newChecked = [];
		if (!browserLocationId && !allChecked) {
			const formDocs = [];
			if (organization === DB_ORG.DATAFLEET) {
				// Our org, so get all forms
				const orgsSnapshot = await getOrganizations();
				for (const org of orgsSnapshot.docs) {
					if (org.id == DB_ORG.URBANPLATES) continue;
					const formsSnap = await getForms(org.id);
					for (const form of formsSnap.docs) formDocs.push(form);
				}
			} else {
				const formsSnap = await getForms(organization);
				for (const form of formsSnap.docs) formDocs.push(form);
			}
			for (const form of formDocs) {
				const formOrg = form.ref.parent.parent.id;
				const draftsSnap = await getDrafts(formOrg, form.id);
				for (const draft of draftsSnap.docs) {
					newChecked.push(draft.ref);
				}
			}
		} else if (!allChecked) {
			const formsSnap = await getForms(organization);
			for (const form of formsSnap.docs) {
				const formOrg = form.ref.parent.parent.id;
				const draftsSnap = await getDrafts(
					formOrg,
					form.id,
					browserLocationId
				);
				for (const draft of draftsSnap.docs) {
					newChecked.push(draft.ref);
				}
			}
		}
		await setChecked(newChecked);
		await setAllChecked(!allChecked);
		setDraftsChange(true);
	};

	// Handles when pagination buttons are clicked
	const pageBackward = () => {
		if (pageNum > 1) {
			let sortDir;
			if (sortAsc) sortDir = 'asc';
			else sortDir = 'desc';
			const newPageNum = pageNum - 1;
			setPageNum(newPageNum);
			getAllDrafts(false, undefined, sortBy, sortDir);
		}
	};
	const pageForward = () => {
		if (pageNum < Math.ceil(collectionCount / 10)) {
			let sortDir;
			if (sortAsc) sortDir = 'asc';
			else sortDir = 'desc';
			const newPageNum = pageNum + 1;
			setPageNum(newPageNum);
			getAllDrafts(true, undefined, sortBy, sortDir);
		}
	};

	// Sort drafts upon column click
	const sortDrafts = async (column) => {
		let isAscending = true;
		// Change direction if same key
		if (column == sortBy) isAscending = !sortAsc;
		let sortDir;
		if (isAscending) sortDir = 'asc';
		else sortDir = 'desc';
		await setSortBy(column);
		await setSortAsc(isAscending);
		await setPageNum(1);
		getAllDrafts(undefined, true, column, sortDir);
	};

	const getCollectionCount = async () => {
		if (organization === DB_ORG.DATAFLEET) {
			// Need to revisit when we have a solution
			return;
		}
		const count = await getDraftsCount(organization, browserLocationId);
		setCollectionCount(count);
	};

	// Retrieves form submissions of all locations from the database, within a certain range
	const getAllDrafts = async (forward, sorting, column, sortDir) => {
		setDraftsChange(false);
		if (!isLoading) setIsLoading(true);
		if (organization === DB_ORG.DATAFLEET && !browserLocationId) {
			// Need to revisit when we have a solution
			return;
		}
		let draftsSnap;
		if (drafts && (forward != undefined || sorting != undefined)) {
			if (forward) {
				const newIndexes = prevFirstIndexes;
				newIndexes.push(firstIndex);
				setPrevFirstIndexes(newIndexes);
				draftsSnap = await getDraftsWithPagenation(
					organization,
					browserLocationId,
					column,
					sortDir,
					limitRows,
					lastIndex
				);
			} else if (forward == false) {
				draftsSnap = await getDraftsWithPagenation(
					organization,
					browserLocationId,
					column,
					sortDir,
					limitRows,
					null,
					prevFirstIndexes.at(-1),
					firstIndex
				);
				const newIndexes = prevFirstIndexes;
				newIndexes.pop();
				setPrevFirstIndexes(newIndexes);
			} else if (sorting) {
				draftsSnap = await getDraftsWithPagenation(
					organization,
					browserLocationId,
					column,
					sortDir,
					limitRows
				);
				setPrevFirstIndexes([]);
			}
		} else {
			draftsSnap = await getDraftsWithPagenation(
				organization,
				browserLocationId,
				column,
				sortDir,
				limitRows
			);
		}
		setFirstIndex(draftsSnap.docs[0]);
		setLastIndex(draftsSnap.docs[draftsSnap.docs.length - 1]);
		const newDrafts = [];
		for (const draft of draftsSnap.docs) {
			const ref = draft.ref;
			const id = draft.id;
			const data = draft.data();
			if (data.savedLocation) {
				data.address = `${
					data.savedLocation.e.data.address ||
					data.savedLocation.e.data.address1
				}, ${data.savedLocation.e.data.city}, ${
					data.savedLocation.e.data.state
				} ${data.savedLocation.e.data.zip}`;
				data.sitename = data.savedLocation.e.data.name;
			}
			data.submittedDate = formatDate(data.submittedDate);
			const newDraft = { id: id, data: data, ref: ref };
			newDrafts.push(newDraft);
		}
		await setDrafts(newDrafts);
		setDraftsChange(true);
	};

	// Formats submission date to "YYYY-MM-DD HH:MM:SS" format
	const formatDate = (oldDate) => {
		let date = new Date(oldDate);

		let month = String(date.getMonth() + 1).padStart(2, '0');
		let day = String(date.getDate()).padStart(2, '0');
		let hours = String(date.getHours()).padStart(2, '0');
		let minutes = String(date.getMinutes()).padStart(2, '0');
		let seconds = String(date.getSeconds()).padStart(2, '0');
		let year = String(date.getFullYear());

		return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
	};
	useEffect(() => {
		if (isFocused) {
			let sortDir;
			if (sortAsc) sortDir = 'asc';
			else sortDir = 'desc';
			getAllDrafts(undefined, undefined, sortBy, sortDir);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [collectionCount]);

	useEffect(() => {
		if (isFocused) {
			getCollectionCount();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isFocused]);

	// Run search function when searchVal changes
	useEffect(() => {
		if (drafts != undefined) {
			handleSearch();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [searchVal]);

	// Update draftsTable state when draftsChage & pageNum states change
	useEffect(() => {
		if ((draftsChange || columnsShownChanges) && drafts != undefined) {
			let newDraftsTable;
			if (drafts.length > 0) {
				const draftRows = [];
				for (let i = 0; i < drafts.length; i++) {
					const draft = drafts[i];
					if (draft) {
						const isChecked = Boolean(
							checked.find((item) => item.id == draft.id)
						);
						const newRow = (
							<tr
								key={draft.id}
								onClick={(e) => {
									if (
										!e.target.className.includes(
											'submission-checkbox'
										)
									)
										navigate(
											'/forms/' + draft.data.formId,
											{
												state: {
													responseData:
														draft.data.responseData,
													draftId: draft.data.draftId,
													savedLocation:
														draft.data
															.savedLocation,
												},
											}
										);
								}}
							>
								<td>
									<input
										className='submission-checkbox'
										type='checkbox'
										checked={isChecked}
										onChange={() => {
											updateCheck(i, !isChecked);
										}}
									/>
								</td>
								{(() =>
									columnsShown.formName ? (
										<td>{draft.data.formName}</td>
									) : null)()}
								{(() =>
									columnsShown.location &&
									displayAddress &&
									organization != DB_ORG.CUSHMANWAKEFIELD ? (
										<td>{draft.data.address}</td>
									) : columnsShown.location &&
									  displayAddress &&
									  organization ==
											DB_ORG.CUSHMANWAKEFIELD ? (
										<td>{draft.data.sitename}</td>
									) : null)()}
								{(() =>
									columnsShown.assetType &&
									organization != DB_ORG.CUSHMANWAKEFIELD ? (
										<td>{draft.data.assetType}</td>
									) : null)()}
								{(() =>
									columnsShown.submittingUser ? (
										<td>
											{draft.data.submittingUser.name ||
												null}
										</td>
									) : null)()}
								{(() =>
									columnsShown.submittedDate ? (
										<td>{draft.data.submittedDate}</td>
									) : null)()}
							</tr>
						);
						draftRows.push(newRow);
					}
				}
				newDraftsTable = (
					<CardBody>
						<Table responsive hover>
							<thead>
								<tr>
									<th>
										<input
											type='checkbox'
											onClick={checkAll}
											checked={allChecked}
										/>
									</th>
									{(() =>
										columnsShown.formName ? (
											<th
												onClick={() =>
													sortDrafts('formName')
												}
												className={`is-bold-${
													sortBy == 'formName'
												}`}
											>
												Form Name
												{(() => {
													if (
														sortBy == 'formName' &&
														sortAsc
													) {
														return '↑';
													} else if (
														sortBy == 'formName'
													) {
														return '↓';
													}
												})()}
											</th>
										) : null)()}
									{(() =>
										columnsShown.location &&
										displayAddress &&
										organization !=
											DB_ORG.CUSHMANWAKEFIELD ? (
											<th
												onClick={() =>
													sortDrafts(
														'savedLocation.e.data.address1'
													)
												}
												className={`is-bold-${
													sortBy ==
													'savedLocation.e.data.address1'
												}`}
											>
												Location
												{(() => {
													if (
														sortBy ==
															'savedLocation.e.data.address1' &&
														sortAsc
													) {
														return '↑';
													} else if (
														sortBy ==
														'savedLocation.e.data.address1'
													) {
														return '↓';
													}
												})()}
											</th>
										) : columnsShown.location &&
										  displayAddress &&
										  organization ==
												DB_ORG.CUSHMANWAKEFIELD ? (
											<th
												onClick={() =>
													sortDrafts(
														'savedLocation.e.data.name'
													)
												}
												className={`is-bold-${
													sortBy ==
													'savedLocation.e.data.name'
												}`}
											>
												Site Name
												{(() => {
													if (
														sortBy ==
															'savedLocation.e.data.name' &&
														sortAsc
													) {
														return '↑';
													} else if (
														sortBy ==
														'savedLocation.e.data.name'
													) {
														return '↓';
													}
												})()}
											</th>
										) : null)()}
									{(() =>
										columnsShown.submittingUser ? (
											<th
												onClick={() =>
													sortDrafts(
														'submittingUser.name'
													)
												}
												className={`is-bold-${
													sortBy ==
													'submittingUser.name'
												}`}
											>
												Submitting User
												{(() => {
													if (
														sortBy ==
															'submittingUser.name' &&
														sortAsc
													) {
														return '↑';
													} else if (
														sortBy ==
														'submittingUser.name'
													) {
														return '↓';
													}
												})()}
											</th>
										) : null)()}
									{(() =>
										columnsShown.submittedDate ? (
											<th
												onClick={() =>
													sortDrafts('submittedDate')
												}
												className={`is-bold-${
													sortBy == 'submittedDate'
												}`}
											>
												Submission Date
												{(() => {
													if (
														sortBy ==
															'submittedDate' &&
														sortAsc
													) {
														return '↑';
													} else if (
														sortBy ==
														'submittedDate'
													) {
														return '↓';
													}
												})()}
											</th>
										) : null)()}
								</tr>
							</thead>
							<tbody>{draftRows}</tbody>
						</Table>
						<div className='activities-page-buttons d-flex flex-row justify-content-end mt-2'>
							<button
								className='px-2 border rounded-start fs-5'
								onClick={pageBackward}
							>
								<i className='bi bi-arrow-left-short' />
							</button>
							<div
								className='border-top border-bottom p-2 h-100'
								style={{ fontSize: '12px' }}
							>{`${pageNum} of ${Math.ceil(
								collectionCount / 10
							)}`}</div>
							<button
								className='px-2 border rounded-end fs-5'
								onClick={pageForward}
							>
								<i className='bi bi-arrow-right-short' />
							</button>
						</div>
					</CardBody>
				);
			} else if (searchVal.length > 0) {
				newDraftsTable = (
					<CardBody>
						<div
							className='d-flex flex-column justify-content-center align-items-center w-100'
							style={{ minHeight: '200px' }}
						>
							<div>No search results. Try again.</div>
						</div>
					</CardBody>
				);
			} else {
				newDraftsTable = (
					<CardBody>
						<div
							className='d-flex flex-column justify-content-center align-items-center w-100'
							style={{ minHeight: '200px' }}
						>
							<div>No drafts to display.</div>
						</div>
					</CardBody>
				);
			}
			setDraftsTable(newDraftsTable);
			if (newDraftsTable) setIsLoading(false);
		}
		if (draftsChange) setDraftsChange(false);
		if (columnsShownChanges) setColumnsShownChanges(false);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [draftsChange, columnsShownChanges]);

	const updateDraftData = () => {
		let sortDir;
		if (sortAsc) sortDir = 'asc';
		else sortDir = 'desc';
		getAllDrafts(undefined, undefined, sortBy, sortDir);
	};

	return (
		<div>
			<Card className='mt-4 submissions-table'>
				<CardHeader className='p-3 d-flex flex-column flex-md-row justify-content-between'>
					<Form onSubmit={handleSearch} className=''>
						<input
							id='search'
							className='searchbar rounded-pill'
							type='text'
							placeholder='Search'
							value={searchVal}
							onChange={(e) => {
								setSearchVal(e.target.value);
							}}
						/>
					</Form>
					<Input
						className='my-3 my-md-0 w-auto'
						type='select'
						onChange={(e) => {
							changeForm(Number(e.target.value));
							if (e.target.value != formsArr.length - 1) {
								setViewingDrafts(false);
							}
						}}
					>
						{formsArr.map((form, i) => {
							return (
								<option
									value={i}
									selected={i == formIndex}
									key={form.id}
								>
									{form.data().formName}
								</option>
							);
						})}
					</Input>
					<div className='d-flex flex-row-reverse flex-md-row justify-content-between align-end'>
						<button className='filter-btn table-btn rounded-circle no-border'>
							<AiOutlineClose
								className={`filter-close ${
									showFilter && 'filter-close-expand'
								}`}
								onClick={toggleFilter}
							/>

							{!showFilter && (
								<BsThreeDotsVertical
									className='filter-dots'
									onClick={toggleFilter}
								/>
							)}

							<Filter
								visible={showFilter}
								columns={columnsShown}
								setColumns={setColumnsShown}
								colsChange={columnsShownChanges}
								setColsChange={setColumnsShownChanges}
								org={organization}
							/>
						</button>
						<button
							onClick={toggleDeleteModal}
							color='primary'
							className='rounded-circle no-border table-btn'
							hidden={!canDelete()}
						>
							<IoMdTrash className='larger-font-icon larger-font' />
						</button>
						<button
							color='primary'
							className='rounded-circle no-border table-btn'
							// onClick={toggleExportModal}
						>
							<MdDownload className='larger-font-icon larger-font' />
						</button>
						{/* <button
							color='primary'
							className='rounded-circle no-border table-btn'
							hidden={!canAdd()}
							onClick={() => setViewingDrafts(false)}
						>
							<MdDrafts className='larger-font-icon larger-font' />
						</button> */}
						{/* <button
							color='primary'
							className='rounded-circle no-border table-btn'
						>
							<MdRefresh className='larger-font-icon larger-font' />
						</button> */}
					</div>
				</CardHeader>
				{isLoading ? <Loader /> : draftsTable}
			</Card>
			<ConfirmDialog
				showConfirm={showDeleteModal}
				toggle={toggleDeleteModal}
				title='Confirm Deletion'
				body={`Are you sure you want to delete ${checked.length} of ${collectionCount} drafts?`}
				functionality={deleteDrafts}
			/>
		</div>
	);
};
export { DraftsTable };
