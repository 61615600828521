import { DB_ORG } from '@constants/db';
import { getFormAssetTypes } from '@services/asset-service';
import { getForms } from '@services/form-service';
import { getSubmissions } from '@services/submission-service';
const exportCsv = async (organization, locations, rows, headers) => {
	// Retrieves form snapshot docs
	const getFormDocs = async (organization) => {
		let formsSnapshot;
		if (organization === DB_ORG.DATAFLEET) {
			// Our org, so get all forms
			// Update later on when we decide how to handle multiple-form CSV exports
			return null;
		} else {
			formsSnapshot = await getForms(organization);
			return formsSnapshot.docs;
		}
	};

	// Retrieves form(s) and its inputs from the database
	const getFormsData = async (formDocs) => {
		let formsData = [];
		if (formDocs.length > 1) {
			// Update later on when we decide how to handle multiple-form CSV exports
		} else {
			const formSnap = formDocs[0];
			// Iterate & modify the data in a different way before returning it
			let linearInputs = formSnap.data().inputs.pages[0].Details;
			for (let x = 0; x < linearInputs.length; x++) {
				let input = JSON.parse(linearInputs[x]);
				if (input.length == undefined) {
					if (input.type != 'label') formsData.push(input);
				} else {
					for (let i = 0; i < input.length; i++) {
						if (i == 0) {
							formsData.push(input[0]);
						} else {
							input[
								i
							].label = `${input[0].label} ${input[i].label}`;
							formsData.push(input[i]);
						}
					}
				}
			}
			const condInputs = await getConditionalInputs(
				formSnap.ref.parent.parent.id,
				formSnap.id
			);
			let condIndex;
			for (let i = 0; i < formsData.length; i++) {
				const input = formsData[i];
				if (input.length == undefined) {
					if (input.type == 'image') {
						condIndex = i;
						break;
					}
				}
			}
			formsData = [
				...formsData.slice(0, condIndex),
				...condInputs,
				...formsData.slice(condIndex),
			];

			return formsData;
		}
	};

	// Retrieves conditional inputs from form's asset types
	const getConditionalInputs = async (organization, formId) => {
		const condInputs = [];
		// Get all assetType objects
		const assetTypesSnap = await getFormAssetTypes(organization, formId);
		const assetTypes = assetTypesSnap.docs;
		// Iterate over each assetType
		for (const assetType of assetTypes) {
			// Iterate over each input of the assetType
			// Skip to next assetType if no inputs
			if (assetType.data().inputs == undefined) continue;
			for (const inputJson of assetType.data().inputs) {
				const input = JSON.parse(inputJson);
				// Add the input to condInputs if not already in it
				if (input.length != undefined) {
					for (let i = 0; i < input.length; i++) {
						let addInput = true;
						for (const condInput of condInputs) {
							if (input[i].responseTag == condInput.responseTag) {
								addInput = false;
								break;
							}
						}
						if (addInput) {
							if (i == 0) {
								condInputs.push(input[0]);
							} else {
								input[
									i
								].label = `${input[0].label} ${input[i].label}`;
								condInputs.push(input[i]);
							}
						}
					}
				} else {
					let addInput = true;
					for (const condInput of condInputs) {
						if (input.responseTag == condInput.responseTag) {
							addInput = false;
							break;
						}
					}
					if (addInput) condInputs.push(input);
				}
			}
		}
		return condInputs;
	};

	// Returns all form(s) submissions from database
	const getSubmissionsData = async (
		formsData,
		formDocs,
		header,
		responseHeader
	) => {
		if (formDocs.length > 1) {
			// Update later on when we decide how to handle multiple-form CSV exports
		}
		// If formDocs only contain 1 form, then return one array of submissions
		else {
			const formId = formDocs[0].id;
			const submissionDocs = [];
			const end = locations.length;
			let i = 1;
			await locations.forEach(async (location) => {
				const submissionsSnap = await getSubmissions(
					organization,
					formId,
					location.id
				);
				if (!submissionsSnap.empty) {
					submissionDocs.push(...submissionsSnap.docs);
				}
				if (i == end) {
					await formatSubmissionsData(
						formsData,
						formDocs,
						header,
						responseHeader,
						submissionDocs
					);
				}
				i++;
			});
		}
	};
	const formatSubmissionsData = async (
		formsData,
		formDocs,
		header,
		responseHeader,
		submissionDocs
	) => {
		const submissions = [];
		for (const item of submissionDocs) {
			const submission = item.data();
			// (AMC specific code)
			// Check if submission contains 'images' key and assign new keys
			if (submission.images != undefined) {
				if (submission.images.length == 2) {
					submission['equipmentAssetImage'] = submission.images[0];
					submission['manufacturersPlateAssetImage'] =
						submission.images[1];
				} else {
					submission['equipmentAssetImage'] = submission.images[0];
				}
			}
			// Update submitted date field
			submission.submittedDate = formatDate(
				submission.submittedDate || submission.dateOfVisit
			);
			// add firebase doc id
			submission.id = item.id;
			submissions.push(submission);
		}
		await getRows(submissions, formsData, formDocs, header, responseHeader);
	};

	const getResponseTagHeaders = async (formsData, formDocs) => {
		if (formDocs.length > 1) {
			// Update later on when we decide how to handle multiple-form CSV exports
		}
		// If formDocs only contain 1 form, then return one array for the CSV header
		else {
			let headers = [];
			// Iterate through formsData and add input labels to header variable
			for (let input of formsData) headers.push(input.responseTag);
			// Add date of visit
			headers = [
				'Submission ID',
				'submittedDate',
				'submittingUser',
				...headers,
			];
			return headers;
		}
	};

	// Returns CSV header array
	const getHeader = async (formsData, formDocs) => {
		if (formDocs.length > 1) {
			// Update later on when we decide how to handle multiple-form CSV exports
		}
		// If formDocs only contain 1 form, then return one array for the CSV header
		else {
			let headers = [];
			// Iterate through formsData and add input labels to header variable
			for (let input of formsData) headers.push(input.label);
			// Add date of visit
			headers = [
				'Submission ID',
				'Date of Visit',
				'Submitting User',
				...headers,
			];
			return headers;
		}
	};

	// Returns CSV rows array
	const getRows = async (
		submissionsData,
		formsData,
		formDocs,
		header,
		responseHeader
	) => {
		if (formDocs.length > 1) {
			// Update later on when we decide how to handle multiple-form CSV exports
		}
		// If formDocs only contain 1 form, then following code will return an array of rows for the CSV rows
		else {
			const rows = [];
			for (const submission of submissionsData) {
				let row = [];
				for (let input of formsData) {
					const responseTag = input.responseTag;
					let response;
					if (submission[responseTag] != undefined) {
						if (responseTag == 'location' && submission.location) {
							let location = '';
							if (organization == DB_ORG.CUSHMANWAKEFIELD) {
								if (
									submission.location.tririgaBuildingSystemId
								) {
									location =
										submission.location
											.tririgaBuildingSystemId;
								} else {
									location = 'n/a';
								}
							} else {
								if (submission.location.address) {
									location = submission.location.address;
								}
								if (submission.location.address1) {
									location =
										location +
										' ' +
										submission.location.address1;
								}
								if (submission.location.address2) {
									location =
										location +
										' ' +
										submission.location.address2;
								}
								location =
									location +
									', ' +
									submission.location.city +
									', ' +
									submission.location.state +
									' ' +
									submission.location.zip;
							}
							submission[responseTag] = location;
						}
						response = submission[responseTag];
					} else {
						response = 'n/a';
					}
					row = [...row, response];
				}

				// Add date of visit and submitting user
				const date = submission.submittedDate;
				const user = submission.submittingUser;
				row = [submission.id, date, user, ...row];

				// Add row to rows arr
				rows.push(row);
			}
			getCsvString([responseHeader, header, ...rows]);
		}
	};

	// Formats submission date to "YYYY-MM-DD HH:MM:SS" format
	const formatDate = (oldDate) => {
		let date = new Date(oldDate);

		let month = String(date.getMonth() + 1).padStart(2, '0');
		let day = String(date.getDate()).padStart(2, '0');
		let hours = String(date.getHours()).padStart(2, '0');
		let minutes = String(date.getMinutes()).padStart(2, '0');
		let seconds = String(date.getSeconds()).padStart(2, '0');
		let year = String(date.getFullYear());

		return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
	};

	// Converts a 2D array into a CSV string
	const getCsvString = (data) => {
		const csvString = data
			.map(
				(row) =>
					row
						.map(String) // convert every value to String
						.map((v) => v.replaceAll('"', '""')) // escape double colons
						.map((v) => `"${v}"`) // quote it
						.join(',') // comma-separated
			)
			.join('\r\n'); // rows starting on new lines
		const filename = convertDateToISOString(new Date());
		const contentType = 'text/csv;charset=utf-8;';
		return downloadFile(csvString, filename, contentType);
	};

	// Downloads CSV string as a CSV file
	const downloadFile = (csvString, filename, contentType) => {
		// Create a blob
		var blob = new Blob([csvString], { type: contentType });
		var url = URL.createObjectURL(blob);

		// Create a link to download it
		var pom = document.createElement('a');
		pom.href = url;
		pom.setAttribute('download', filename);
		pom.click();
	};

	// Converts any date to ISO string
	const convertDateToISOString = (date) => {
		if (date) {
			const tempDate = new Date(date).toISOString().split('T');
			return tempDate[0];
		}
	};

	// If we didn't pass locations in, we want a straight data -> csv experience
	if (!locations) {
		getCsvString([headers, ...rows]);
	} else {
		const formDocs = await getFormDocs(organization);
		if (formDocs != null) {
			const formsData = await getFormsData(formDocs);
			const responseTagHeaders = await getResponseTagHeaders(
				formsData,
				formDocs
			);
			const header = await getHeader(formsData, formDocs);
			await getSubmissionsData(
				formsData,
				formDocs,
				header,
				responseTagHeaders
			);
		}
	}
};

export { exportCsv };
