import { useEffect, useState } from 'react';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { ProgressProvider } from './ProgressProvider';
import { useNavigate } from 'react-router-dom';

const HealthScore = (props) => {
	const navigate = useNavigate();
	const [health, setHealth] = useState(0);
	const [valueEnd, setValueEnd] = useState(0);

	// Component state for location's health score
	const [healthScore, setHealthScore] = useState();

	useEffect(() => {
		setValueEnd(props.healthScore);
		setHealth(props.healthScore);
	}, [props.healthScore]);

	// Update healthScore component state when health state changes
	useEffect(() => {
		// let rygColor;
		// if (health >= 4) {
		// 	rygColor = '#00cd10';
		// } else if (health >= 2) {
		// 	rygColor = '#e7c012';
		// } else {
		// 	rygColor = '#eb0f11';
		// }

		// dynamically get health score colors
		const getHealthColors = (valueEnd) => {
			const greenColor = 'rgba(39,174,95,255)';
			const yellowColor = '#fbc658';
			const redColor = '#df3053';

			if (valueEnd > 75) {
				return greenColor;
			} else if (valueEnd > 50 && valueEnd <= 75) {
				return yellowColor;
			} else {
				return redColor;
			}
		};
		const getHealthTextColors = (valueEnd) => {
			let healthTextColor;

			if (valueEnd > 75) {
				healthTextColor = 'green';
			} else if (valueEnd > 50 && valueEnd <= 75) {
				healthTextColor = 'yellow';
			} else {
				healthTextColor = 'red';
			}
			return healthTextColor;
		};
		const getOuterGlow = (valueEnd) => {
			const yellow = 'outer-glow-yellow';
			const red = 'outer-glow-red';

			if (valueEnd > 50 && valueEnd <= 75) {
				return yellow;
			} else if (!valueEnd || valueEnd < 50) {
				return red;
			} else {
				return;
			}
		};

		setHealthScore(
			<div
				className={`health-circle-wrapper ${getOuterGlow(
					valueEnd
				)} p-0`}
				onClick={() => {
					navigate('/admin', { state: { tab: 'Assets' } });
				}}
			>
				{valueEnd > 75 && <div className='health-outer-glow' />}
				{valueEnd >= 75 && <div className='health-score-green' />}
				{valueEnd < 75 && valueEnd >= 25 && (
					<div className='health-score-yellow' />
				)}
				{valueEnd < 25 && <div className='health-score-red' />}
				<p
					className={`my-0 ms-0 me-2 health-title ${getHealthTextColors(
						valueEnd
					)}`}
				>
					{props.label}
				</p>
				<ProgressProvider valueStart={0} valueEnd={valueEnd}>
					{(value) => (
						<CircularProgressbar
							value={value}
							text={`${value ? value : 0}%`}
							strokeWidth={10}
							styles={buildStyles({
								// Text size
								textSize: '160px',
								textColor: getHealthColors(valueEnd),
								pathColor: getHealthColors(valueEnd),
								trailColor: '#d4d4d4',
								pathTransitionDuration: 2.0,
							})}
						/>
					)}
				</ProgressProvider>
			</div>
		);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [health]);

	return healthScore;
};

export { HealthScore };
