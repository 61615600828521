import { EmailAuthProvider, reauthenticateWithCredential } from 'firebase/auth';
import { useState } from 'react';
import { Button, Modal, ModalHeader, ModalBody } from 'reactstrap';
import { auth } from '@assets/services/auth-service';
import { IoEyeOffOutline, IoEyeOutline } from 'react-icons/io5';

const ReAuthenticateModal = ({ showing, toggle, toggleNextModal }) => {
	const [emailInput, setEmailInput] = useState('');
	const [passwordInput, setPasswordInput] = useState('');

	const [passwordShown, setPasswordShown] = useState(false);

	const handleChange = (e, setValue) => setValue(e.target.value);

	const handleSubmit = async (e) => {
		e.preventDefault();

		const credential = EmailAuthProvider.credential(
			emailInput,
			passwordInput
		);
		const user = auth.currentUser;

		reauthenticateWithCredential(user, credential)
			.then(() => {
				alert('Authenticated.');
				toggle();
				setEmailInput('');
				setPasswordInput('');
				toggleNextModal();
			})
			.catch((error) => {
				setPasswordInput('');
				alert(error);
			});
	};

	return (
		<div>
			<Modal
				id='email-modal'
				centered='true'
				isOpen={showing}
				toggle={toggle}
			>
				<ModalHeader toggle={toggle}>Please sign in again.</ModalHeader>
				<ModalBody>
					<div className='d-flex flex-column'>
						<div className='position-relative'>
							<form onSubmit={handleSubmit}>
								<label>Email Address</label>
								<input
									onChange={(e) =>
										handleChange(e, setEmailInput)
									}
									value={emailInput}
									type='email'
									placeholder='Enter email address'
									className='mb-2'
								/>
								<label>Password</label>
								<div className='position-relative'>
									<input
										onChange={(e) =>
											handleChange(e, setPasswordInput)
										}
										value={passwordInput}
										type={`${
											passwordShown ? 'text' : 'password'
										}`}
										placeholder='Enter password'
									/>
									<div
										onClick={() =>
											setPasswordShown(!passwordShown)
										}
										className='eye-icon'
									>
										{passwordShown ? (
											<IoEyeOutline />
										) : (
											<IoEyeOffOutline />
										)}
									</div>
								</div>
								<Button
									disabled={`${
										!emailInput.length ? 'disabled' : ''
									}`}
									className='bg-green border-none mt-3'
									type='submit'
								>
									Sign In
								</Button>
							</form>
						</div>
					</div>
				</ModalBody>
			</Modal>
		</div>
	);
};

export { ReAuthenticateModal };
