import { useEffect, useState } from 'react';
import Loader from './Loader';

const FAILED_REQUEST_MESSAGE =
	'Sorry, it seems I am having some technical difficulties at the moment. Please try your question again later.';

const BotChatMessage = ({ message }) => {
	const [messageText, setMessageText] = useState(null);

	const scrollIntoView = () => {
		setTimeout(() => {
			const container = document.querySelector(
				'.react-chatbot-kit-chat-message-container'
			);

			container.scrollTo(0, container.scrollHeight);
		}, 100);
	};

	useEffect(() => {
		if (typeof message === 'string') {
			setMessageText(message);
			return;
		}

		message
			.then((body) =>
				body.json().then((text) => setMessageText(text.response))
			)
			.finally(() => {
				scrollIntoView();
			})
			.catch(() => setMessageText(FAILED_REQUEST_MESSAGE));
	}, [message]);

	return (
		<div
			className={'react-chatbot-kit-chat-bot-message'}
			style={{ backgroundColor: '#e9ecef' }}
		>
			{messageText ? (
				<span>{messageText}</span>
			) : (
				<Loader dashboard={false} />
			)}
			<div
				className='react-chatbot-kit-chat-bot-message-arrow'
				style={{ borderRightColor: '#e9ecef' }}
			></div>
		</div>
	);
};

export default BotChatMessage;
