import { Button, Col, Modal, ModalBody, ModalFooter, Row } from 'reactstrap';
import { Html5QrcodePlugin } from './Html5QrcodeScannerPlugin';

const BarcodeModal = (props) => {
	const { showing, cancel, gotData } = props;

	return (
		<div>
			<Modal isOpen={showing}>
				<ModalBody>
					<div style={{ width: '100%' }}>
						<Html5QrcodePlugin
							fps={10}
							qrbox={250}
							disableFlip={false}
							qrCodeSuccessCallback={(text, result) =>
								gotData(text, result)
							}
						/>
					</div>
				</ModalBody>
				<ModalFooter>
					<Row>
						<Col>
							<Button
								onClick={() => cancel()}
								className='modal-btn'
							>
								Cancel
							</Button>
						</Col>
					</Row>
				</ModalFooter>
			</Modal>
		</div>
	);
};

export { BarcodeModal };
