import { canViewVendors } from '@assets/services/user-role-service';
import { useState, useEffect } from 'react';
import { Loader } from '@SignedIn/Loader/Loader';
import { FormsTable } from '@SignedIn/views/Forms/FormsTable';
import { DB_FORM, DB_ORG } from '@constants/db';
import { getForms } from '@services/form-service';
import { getOrganizations } from '@services/organization-service';
import AuthContext from '@assets/services/Auth';
import { useContext } from 'react';

const Forms = () => {
	const [isLoading, setIsLoading] = useState(true);
	const [allForms, setAllForms] = useState([]);
	const [allFormsLoaded, setAllFormsLoaded] = useState(null);
	const { authInfo } = useContext(AuthContext);
	const organization = authInfo.org;
	/**
	 * Values are stored in the firebase db like so:
	 * type, label, collection, responseTag
	 *
	 * type = the type of input element
	 *  - text: simple text input
	 *  - select: dropdown menu, options are filled via the collection value
	 *  - checkbox: simple checkbox value
	 *
	 * label = what to display above the input
	 * collection = currently only used to populate the checkobox dropdowns
	 * responseTag = when building the response on submit, the data will use this value for the response
	 *  - responseTag="make". Store the make input field data on the response object like so { make: "" }
	 *
	 * Even though we are making these forms dynamic in the backend, there is a standard.
	 * That is what will help us on the front end. Example, QR code scanning. Locations dropdown. etc.
	 *
	 * We eventually will make a WYSIWYG in the front end for our users to build their own forms.
	 * But for now we need to make them ourselves manually or write a script that can do it.
	 *
	 * TODO:
	 * add a required value. That way the form knows if it should be required or not, obviously.
	 */

	const updateAllForms = async () => {
		let formsList = [];
		if (organization === DB_ORG.DATAFLEET) {
			// Our org, so get all forms
			const orgSnap = await getOrganizations();
			let promises = [];
			orgSnap.forEach((d) => {
				let orgId = d.id;
				promises.push(
					new Promise((resolve) => {
						getForms(orgId).then((docSnap) => {
							docSnap.forEach((doc) => {
								formsList.push({
									data: doc.data(),
									id: doc.id,
									org: orgId,
								});
							});
							resolve();
						});
					})
				);
			});
			Promise.all(promises).then(() => {
				setAllForms(formsList);
			});
		} else {
			let formsSnap;
			// If org is cushman, differentiate between admins & users
			if (organization == DB_ORG.CUSHMANWAKEFIELD) {
				const canViewAllForms = await canViewVendors();
				// If admin, get all forms, like any other org
				if (canViewAllForms) {
					formsSnap = await getForms(organization);
				}
				// If user, only get Handyman Checklist
				else {
					formsSnap = await getForms(
						DB_ORG.CUSHMANWAKEFIELD,
						DB_FORM.HANDYMAN
					);
				}
			}
			// All other orgs
			else {
				formsSnap = await getForms(organization);
			}
			formsSnap.forEach((doc) => {
				formsList.push({
					data: doc.data(),
					id: doc.id,
				});
			});
			setAllForms(formsList);
			setAllFormsLoaded(true);
		}
	};

	// Update allForms state upon initial render
	useEffect(() => {
		updateAllForms();
	}, []);

	// Update isLoading when allFormsLoaded changes
	useEffect(() => {
		setIsLoading(false);
	}, [allFormsLoaded]);

	return isLoading ? (
		<Loader />
	) : (
		<div>
			<FormsTable allForms={allForms} />
		</div>
	);
};

export { Forms };
