import { ProgressBar } from 'react-bootstrap';

const PredSpendAssetRow = (props) => {
	const { asset, type } = props;

	const shouldShowAsset = () => {
		if (type === 'critical') {
			if (asset.data.replVal) return true;
			else return false;
		} else {
			return true;
		}
	};

	let healthColor;
	if (asset.data.healthScore >= 75) {
		healthColor = 'greenHealth';
	} else if (asset.data.healthScore >= 25) {
		healthColor = 'yellowHealth';
	} else {
		healthColor = 'redHealth';
	}

	return shouldShowAsset() ? (
		<tr className='text-center'>
			<td className='d-flex justify-content-center align-items-center'>
				<div className='px-2'>
					<h6 className='asset-title m-0'>
						{asset.data.make}: {asset.data.model}
					</h6>
				</div>
			</td>
			<td hidden={type !== 'all'}>
				<span>{asset.data.assetType}</span>
			</td>
			<td hidden={type === 'critical'}>
				<ProgressBar
					now={asset.data.healthScore}
					max={100}
					min={0}
					variant={healthColor}
					className={'assetHealth'}
				/>
			</td>
			<td
				hidden={type !== 'all' || type === 'critical'}
				className={
					Number(asset.data.usefulLifeRemaining) >= 1
						? 'text-pe-green'
						: 'text-pe-red'
				}
			>
				{asset.data.usefulLifeRemaining} years
			</td>
			<td hidden={type !== 'all' || type === 'critical'}>
				{asset.data.replacementYear}
			</td>
			<td hidden={type !== 'unhealthy'}>{asset.data.avgReplCostUSD}</td>
		</tr>
	) : (
		<></>
	);
};

export { PredSpendAssetRow };
