const searchResponses = async (
	val,
	org,
	form,
	pageNum,
	pageSize,
	sort,
	location
) => {
	let firebaseConfig = JSON.parse(process.env.REACT_APP_FIREBASE_CONFIG);
	const response = await fetch(
		firebaseConfig.projectId.includes('staging')
			? 'https://us-central1-property-echo-staging.cloudfunctions.net/search'
			: 'https://us-central1-property-echo-4d747.cloudfunctions.net/search',
		// ? 'http://localhost:5001/property-echo-4d747/us-central1/search'
		// : 'http://localhost:5001/property-echo-4d747/us-central1/search',
		{
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				type: 'submission',
				query: val,
				org,
				form,
				isProd: !firebaseConfig.projectId.includes('staging'),
				pageNum,
				pageSize,
				sort,
				location,
			}),
		}
	);
	const responsejson = await response.json();
	if (responsejson.hits)
		return { results: responsejson.hits, count: responsejson.nbHits };
};

export { searchResponses };
