import { Collapse } from 'reactstrap';
import { NavLink } from 'react-router-dom';
import { AiOutlineUser, AiOutlineDashboard } from 'react-icons/ai';
import { TbMap2, TbReport } from 'react-icons/tb';
import { RiAdminLine } from 'react-icons/ri';
import { BiLogOut } from 'react-icons/bi';
import { IoMdArrowDropup } from 'react-icons/io';
import { useState, useRef } from 'react';
import { AuthProviders } from '@assets/services/auth-service';
import { Avatar } from '@SignedIn/Avatar/Avatar';
import { OrgDropdown } from './OrgDropdown';

const Sidebar = (props) => {
	const { userPaid } = props;

	const [openAvatar, setOpenAvater] = useState(false);
	const wrapper = useRef(null);

	return (
		<nav
			className={`sidebar ${
				props.isSidebarOpen && 'sidebar-expanded'
			} d-md-flex flex-md-column collapse position-fixed`}
			ref={wrapper}
		>
			<div className='user'>
				<div className='photo'>
					<Avatar alt='Avatar' className='avatar' />
				</div>
				<div className='info'>
					<span
						style={{ color: 'white' }}
						data-toggle='collapse'
						aria-expanded={openAvatar}
						onClick={() => setOpenAvater(!openAvatar)}
					>
						<div className='sidebar-username-wrapper'>
							<div
								className={`sidebar-username ${
									!props.isSidebarOpen && 'hidden'
								}`}
							>
								{props.name}
							</div>
							<div
								className={
									openAvatar
										? 'arrow-icon-up-wrapper'
										: 'arrow-icon-down-wrapper'
								}
							>
								<IoMdArrowDropup className='arrow-icon' />
							</div>
						</div>
					</span>
					<Collapse isOpen={openAvatar}>
						<ul className='nav'>
							<li
								className={`sidebar-normal ${
									!props.isSidebarOpen &&
									'sidebar-normal-collapsed'
								}`}
							>
								<NavLink
									to='/profile'
									className={(isActive) =>
										isActive.isActive
											? 'active'
											: 'inactive'
									}
								>
									<div className='sidebar-item-wrapper'>
										<div
											className='sidebar-mini-icon'
											style={{
												transform: 'translate(1px, 4px)',
											}}
										>
											<AiOutlineUser />
										</div>

										<div
											className={`sidebar-profile-item ${
												!props.isSidebarOpen && 'hidden'
											}`}
										>
											My Profile
										</div>
									</div>
									<div
										className={`blend-effect ${
											props.isSidebarOpen &&
											'blend-effect-expanded'
										}`}
									/>
									<div className='blend-effect-top' />
								</NavLink>
							</li>
							<li
								className={`sidebar-normal log-out ${
									!props.isSidebarOpen &&
									'sidebar-normal-collapsed'
								}`}
							>
								<div
									className='inactive'
									onClick={AuthProviders.logout}
								>
									<div className='sidebar-mini-icon log-out-icon'>
										<BiLogOut />
									</div>
									<div
										className={`sidebar-profile-item log-out-text ${
											!props.isSidebarOpen && 'hidden'
										}`}
									>
										Log Out
									</div>
								</div>
							</li>
						</ul>
					</Collapse>
				</div>
			</div>
			<hr className='text-white'></hr>
			<ul className='nav nav-topics'>
				<li
					className={`sidebar-normal ${
						!props.isSidebarOpen && 'sidebar-normal-collapsed'
					}`}
				>
					<NavLink
						to='/'
						className={(isActive) =>
							isActive.isActive ? 'active' : 'inactive'
						}
						onClick={() => setOpenAvater(false)}
					>
						<div className='sidebar-mini-icon'>
							<AiOutlineDashboard />
						</div>
						<div
							className={`sidebar-topic-item ${
								!props.isSidebarOpen && 'hidden'
							}`}
						>
							Dashboard
						</div>
						<div
							className={`blend-effect ${
								props.isSidebarOpen && 'blend-effect-expanded'
							}`}
						/>
					</NavLink>
				</li>
				<li
					className={`sidebar-normal ${
						!props.isSidebarOpen && 'sidebar-normal-collapsed'
					}`}
				>
					<NavLink
						to='/locations'
						className={(isActive) =>
							isActive.isActive ? 'active' : 'inactive'
						}
						onClick={() => setOpenAvater(false)}
					>
						<div className='sidebar-mini-icon'>
							<TbMap2 />
						</div>
						<div
							className={`sidebar-topic-item ${
								!props.isSidebarOpen && 'hidden'
							}`}
						>
							Locations
						</div>
						<div
							className={`blend-effect ${
								props.isSidebarOpen && 'blend-effect-expanded'
							}`}
						/>
					</NavLink>
				</li>
				<li
					className={`sidebar-normal ${
						!props.isSidebarOpen && 'sidebar-normal-collapsed'
					}`}
				>
					<NavLink
						to='/forms'
						className={(isActive) =>
							isActive.isActive ? 'active' : 'inactive'
						}
						onClick={() => setOpenAvater(false)}
					>
						<div className='sidebar-mini-icon'>
							<TbReport />
						</div>
						<div
							className={`sidebar-topic-item ${
								!props.isSidebarOpen && 'hidden'
							}`}
						>
							Property Reports
						</div>
						<div
							className={`blend-effect ${
								props.isSidebarOpen && 'blend-effect-expanded'
							}`}
						/>
					</NavLink>
				</li>
				<li
					className={`sidebar-normal ${
						!props.isSidebarOpen && 'sidebar-normal-collapsed'
					}`}
				>
					<NavLink
						to={`${userPaid ? '/admin' : '/plans'}`}
						className={(isActive) =>
							isActive.isActive ? 'active' : 'inactive'
						}
						onClick={() => setOpenAvater(false)}
					>
						<div className='sidebar-mini-icon'>
							<RiAdminLine />
						</div>
						<div
							className={`sidebar-topic-item ${
								!props.isSidebarOpen && 'hidden'
							}`}
						>
							Administration
						</div>
						<div
							className={`blend-effect ${
								props.isSidebarOpen && 'blend-effect-expanded'
							}`}
						/>
					</NavLink>
				</li>
			</ul>
			{props.isSidebarOpen && (
				<OrgDropdown
					org={props.org}
					updateUserOrg={props.updateUserOrg}
					options={props.orgOptions}
					userRole={props.userRole}
				/>
			)}
		</nav>
	);
};

export { Sidebar };
