import { useEffect, useState, useRef, useContext } from 'react';
import { deleteField } from 'firebase/firestore';
import { updateProfile } from 'firebase/auth';
import { auth } from '@assets/services/auth-service';
import {
	Card,
	CardBody,
	CardHeader,
	Col,
	Input,
	Row,
	Form,
	FormGroup,
} from 'reactstrap';
import { Loader } from '@SignedIn/Loader/Loader';
import { AvatarUpload } from '@SignedIn/Avatar/AvatarUpload';
import { PasswordModal } from '@SignedIn/views/Profile/PasswordModal';
import { EmailModal } from '@SignedIn/views/Profile/EmailModal';
import { BiCheck } from 'react-icons/bi';
import { MdClose } from 'react-icons/md';
import { NameInput } from '@SignedIn/views/Profile/NameInput';
import { Toast } from '@SignedIn/Toast/Toast';
import { setUserRole, getUserRole } from '@assets/services/user-role-service';
import { getUser, updateUser } from '@services/user-service';
import {
	getOrganization,
	getOrganizations,
	getOrgIdFromName,
} from '@services/organization-service';
import AuthContext from '@assets/services/Auth';

const Profile = () => {
	const { authInfo, setAuthInfo } = useContext(AuthContext);

	const [isLoading, setIsLoading] = useState(true);
	const [userRole, setUserRoleState] = useState('');
	const [userOrganization, setUserOrganization] = useState('');
	const [organizations, setOrganizations] = useState([]);

	const [nameInputValue, setNameInputValue] = useState(
		auth?.currentUser?.displayName
	);
	const [nameInputButtonsShowing, setNameInputButtonsShowing] =
		useState(false);
	const nameInputRef = useRef(null);

	const [isToastShowing, setIsToastShowing] = useState(false);

	const [options, setOptions] = useState();

	const getData = async () => {
		let user = await getUser(auth.currentUser.uid);
		const docSnap = await getOrganizations();
		let orgs = [];
		docSnap.forEach((or) => {
			orgs.push(or.id);
		});
		setUserRole(user.role);
		setUserRoleState(getUserRole());
		setUserOrganization(user.organization);
		setOrganizations(orgs);
	};

	const getOrgName = async (orgId) => {
		const orgDoc = await getOrganization(orgId);
		return orgDoc.data().name;
	};

	const getOptions = async () => {
		const newOptions = [];
		for (const org of organizations) {
			const orgName = await getOrgName(org);
			newOptions.push(
				<option selected={userOrganization === org}>{orgName}</option>
			);
		}
		setOptions(newOptions);
	};

	const updateUserOrganiztion = async (e) => {
		let name = e.target.value;
		let id = await getOrgIdFromName(name);
		if (userOrganization === name) return;

		await updateUser(auth.currentUser.uid, {
			organization: id,
			savedFilters: deleteField(),
		});
		const newAuthInfo = await JSON.parse(JSON.stringify(authInfo));
		newAuthInfo.org = id;
		setAuthInfo(newAuthInfo);

		setIsToastShowing({
			showing: true,
			title: 'Success',
			subtitle: 'Organization has been changed',
			type: 'success',
			position: 'align-profile',
		});
		setTimeout(() => {
			setIsToastShowing({
				showing: false,
			});
		}, 2500);
		setTimeout(() => {
			window.location.reload();
		}, 1500);
		setUserOrganization(name);
	};

	useEffect(() => {
		getData();
	}, []);

	useEffect(() => {
		getOptions();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [organizations]);

	useEffect(() => {
		setIsLoading(false);
	}, [options]);

	const onInputChange = (e) => {
		e.preventDefault();
		setNameInputValue(e.target.value);
	};

	function useOutsideAlert(ref) {
		useEffect(() => {
			function handleClick(e) {
				if (ref.current && !ref.current.contains(e.target))
					setNameInputButtonsShowing(false);
				else if (ref.current.contains(e.target))
					setNameInputButtonsShowing(true);
			}

			document.addEventListener('click', handleClick);

			return () => {
				document.removeEventListener('click', handleClick);
			};
		}, [ref]);
	}
	useOutsideAlert(nameInputRef);

	const updateUsername = async () => {
		if (nameInputValue === auth.currentUser.displayName) return;
		try {
			setNameInputButtonsShowing(false);
			await updateUser(auth.currentUser.uid, {
				name: nameInputValue,
			});
			updateProfile(auth.currentUser, {
				displayName: nameInputValue,
			});
			setIsToastShowing({
				showing: true,
				title: 'Success',
				subtitle: 'Username successfully updated.',
				position: 'align-profile',
				type: 'success',
			});
			setTimeout(() => {
				setIsToastShowing({
					showing: false,
				});
			}, 2500);
			setTimeout(() => {
				window.location.reload();
			}, 1000);
		} catch (error) {
			setIsToastShowing({
				showing: true,
				title: 'Error',
				subtitle: 'There was an error updating your username.',
				position: 'bottom',
				type: 'danger',
			});
			setTimeout(() => {
				setIsToastShowing({
					showing: false,
				});
			}, 2500);
		}
	};

	return (
		<div>
			{isLoading ? (
				<Loader />
			) : (
				<div className='profile-page'>
					<Row>
						<Col className='col-12 col-lg-4 mx-auto'>
							<h5 className='fw-bold'>Profile</h5>
							<h6 className='profile-category mb-4'>
								Manage your personal information and photo here.
							</h6>

							<Card className='profile-card'>
								<CardHeader className='p-0 d-flex flex-column align-items-center'>
									<div className='profile-picture-wrapper my-3'>
										<AvatarUpload />
									</div>
									<div className='mb-2 text-center'>
										<h5 className='fw-bold m-0'>
											{auth.currentUser.displayName}
										</h5>
									</div>
								</CardHeader>
								<CardBody className='px-4 py-3'>
									<div className='d-flex flex-column mb-4 position-relative'>
										<h6 className='profile-category text-nowrap'>
											Preferred Name
										</h6>
										<NameInput
											value={nameInputValue}
											onChange={onInputChange}
											ref={nameInputRef}
										/>

										{nameInputButtonsShowing && (
											<div className='profile-input-btns-wrapper'>
												<button
													onClick={updateUsername}
													className='profile-input-btn'
												>
													<BiCheck />
												</button>
												<button
													onClick={() =>
														setNameInputButtonsShowing(
															false
														)
													}
													className='profile-input-btn'
												>
													<MdClose />
												</button>
											</div>
										)}
									</div>

									<div className='d-flex flex-column mb-4'>
										<div className='d-flex align-items-center'>
											<h6 className='profile-category m-0'>
												Email
											</h6>{' '}
											<EmailModal
												setIsToastShowing={
													setIsToastShowing
												}
												userEmail={
													auth?.currentUser?.email
												}
											/>
										</div>
										<div className='d-flex flex-column flex-md-row my-2'>
											<input
												disabled
												value={auth?.currentUser?.email}
											/>
										</div>
									</div>

									<div className='d-flex flex-column mb-4'>
										<div className='d-flex align-items-center mb-2'>
											<span className='profile-category'>
												Password
											</span>
											<PasswordModal
												setIsToastShowing={
													setIsToastShowing
												}
											/>
										</div>
										<input
											value={'********'}
											type='password'
											disabled
										/>
									</div>

									<div className='d-flex flex-column mb-4'>
										<h6 className='profile-category'>
											Organization
										</h6>
										<Form>
											<FormGroup>
												<Input
													type='select'
													disabled={
														userRole !== 'superuser'
															? true
															: false
													}
													onChange={(e) =>
														updateUserOrganiztion(e)
													}
												>
													{options}
												</Input>
											</FormGroup>
										</Form>
									</div>

									<div className='d-flex flex-column mb-4'>
										<h6 className='profile-category'>
											Role
										</h6>
										<input value={userRole} disabled />
									</div>
								</CardBody>
							</Card>
						</Col>
					</Row>
					<Toast
						isShowing={isToastShowing.showing}
						title={isToastShowing.title}
						subtitle={isToastShowing.subtitle}
						position={isToastShowing.position}
						type={isToastShowing.type}
					></Toast>
				</div>
			)}
		</div>
	);
};

export { Profile };
