import {
	collection,
	deleteDoc,
	doc,
	endBefore,
	getCountFromServer,
	getDocs,
	limit,
	orderBy,
	query,
	setDoc,
	startAfter,
	startAt,
	where,
} from 'firebase/firestore';
import { db } from '@assets/services/auth-service';
import { DB_FILTER, DB_PATH } from '@constants/db';

export const getDrafts = async (organization, form, locationId) => {
	const colRef = collection(
		db,
		DB_PATH.ORGANIZATIONS,
		organization,
		DB_PATH.FORMS,
		form,
		DB_PATH.DRAFTS
	);
	const snap = locationId
		? await getDocs(
				query(colRef, where(DB_FILTER.LOCATION_ID, '==', locationId))
		  )
		: await getDocs(colRef);
	return snap;
};

export const getDraftsWithPagenation = async (
	organization,
	locId,
	sort,
	sortDir,
	limitRows,
	after,
	at,
	before
) => {
	const params = [
		collection(db, DB_PATH.ORGANIZATIONS, organization, DB_PATH.DRAFTS),
	];
	if (locId) {
		params.push(where(DB_FILTER.SAVED_LOCATION_E_ID, '==', locId));
	}
	if (sort && sortDir) {
		params.push(orderBy(sort, sortDir));
	}
	if (after) {
		params.push(startAfter(after));
	}
	if (at && before) {
		params.push(startAt(at));
		params.push(endBefore(before));
	}
	if (limitRows) {
		params.push(limit(limitRows));
	}
	const snap = await getDocs(query(...params));
	return snap;
};

export const setDraft = async (organization, id, data) => {
	const ref = setDoc(
		doc(db, DB_PATH.ORGANIZATIONS, organization, DB_PATH.DRAFTS, id),
		data
	);
	return ref;
};

export const deleteDraft = async (organization, id) => {
	await deleteDoc(
		doc(db, DB_PATH.ORGANIZATIONS, organization, DB_PATH.DRAFTS, id)
	);
};

export const getDraftsCount = async (organization, locId) => {
	const params = [];
	if (locId) {
		params.push(where(DB_FILTER.LOCATION_ID, '==', locId));
	}
	const snap = await getCountFromServer(
		query(
			collection(db, DB_PATH.ORGANIZATIONS, organization, DB_PATH.DRAFTS),
			...params
		)
	);
	return snap.data().count;
};
