import { sendMessage } from '@services/alfred-service';
import { createClientMessage } from 'react-chatbot-kit';
import React from 'react';

const ActionProvider = ({ createChatBotMessage, setState, children }) => {
	const handleInput = async (message) => {
		const response = sendMessage(message);
		const msg = createChatBotMessage(response);

		setState((prev) => ({
			...prev,
			messages: [...prev.messages, msg],
			showInitialOptions: false,
		}));
	};

	const handleOptionSelection = async (message) => {
		const cli = createClientMessage(message);
		const response = sendMessage(message);
		const msg = createChatBotMessage(response);

		setState((prev) => ({
			...prev,
			messages: [...prev.messages, cli, msg],
			showInitialOptions: false,
		}));
	};

	return (
		<div>
			{React.Children.map(children, (child) => {
				return React.cloneElement(child, {
					actions: {
						handleInput,
						handleOptionSelection,
					},
				});
			})}
		</div>
	);
};

export { ActionProvider };
