const toRow = (asset, schema) => {
	return schema.headers.map((column) => getPropertyValue(column, asset));
};

// Handle nested properties from schema (e.g. asset.other.tag)
const getPropertyValue = (column, asset) => {
	return (
		column.key.split('.').reduce((obj, property) => obj[property], asset) ||
		''
	);
};

const getSchema = (example, subtype) => {
	return example.find((schema) => schema.subtype === subtype);
};

const getHeaders = (schema) => {
	return schema.headers.map((column) => column.header);
};

export { getHeaders, toRow, getSchema };
