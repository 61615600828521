import { useState, useEffect } from 'react';

const DownloadCsv = (props) => {
	const { form } = props;

	// Header row arr state
	const [header, setHeader] = useState();
	// Data row arr state
	const [data, setData] = useState();
	// Filename state
	const [filename, setFilename] = useState('');
	// CSV state
	const [csv, setCsv] = useState();

	// Update header row, data row, and filename states on render / when form prop changes
	useEffect(() => {
		if (form != null) {
			let keys = Object.keys(form);
			let newHeader;
			let newData;
			let address = '';

			if (keys.length > 0) {
				newHeader = ['Form Name', 'Address'];
				if (form.location.address) {
					address = form.location.address;
				}
				if (form.location.address1) {
					address = address + ' ' + form.location.address1;
				}
				if (form.location.address2) {
					address = address + ' ' + form.location.address2;
				}
				if (form.location.city) {
					address = address + ', ' + form.location.city;
				}
				if (form.location.state) {
					address = address + ', ' + form.location.state;
				}
				if (form.location.zip) {
					address = address + ' ' + form.location.zip;
				}
				newData = [form.formName, address];
			}

			keys.forEach((key) => {
				if (
					key !== 'location' &&
					key !== 'formName' &&
					key !== 'formId' &&
					key !== 'submissionId' &&
					key !== 'logo'
				) {
					let result = key.replace(/([A-Z])/g, ' $1');
					let finalResult =
						result.charAt(0).toUpperCase() + result.slice(1);

					if (form[key] === 'on') {
						form[key] = 'Yes';
					} else if (form[key] === 'off') {
						form[key] = 'No';
					}

					newHeader = [...newHeader, finalResult];
					newData = [...newData, form[key]];
				}
			});

			setHeader(newHeader);
			setData(newData);
			setFilename(`${form.formName} - ${address}`);
		}
	}, [form]);

	// Convert a 2D array into a CSV string
	const arrayToCsv = (data) => {
		return data
			.map(
				(row) =>
					row
						.map(String) // convert every value to String
						.map((v) => v.replaceAll('"', '""')) // escape double colons
						.map((v) => `"${v}"`) // quote it
						.join(',') // comma-separated
			)
			.join('\r\n'); // rows starting on new lines
	};

	// Update csv state when header & data arr states change
	useEffect(() => {
		if (header != null && data != null) {
			const newCsv = arrayToCsv([header, data]);
			setCsv(newCsv);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	// Download contents as a file
	const downloadFile = (content, name, contentType) => {
		// Create a blob
		var blob = new Blob([content], { type: contentType });
		var url = URL.createObjectURL(blob);

		// Create a link to download it
		var pom = document.createElement('a');
		pom.href = url;
		pom.setAttribute('download', name);
		pom.click();
	};

	return (
		<div
			className='activities-page-header-buttons d-flex p-1 width-fit-content '
			onClick={() =>
				downloadFile(csv, filename, 'text/csv;charset=utf-8;')
			}
		>
			<div className='d-flex justify-content-center align-items-center'>
				CSV{` `}
				<i className='bi bi-cloud-download mx-2'></i>
			</div>
		</div>
	);
};

export { DownloadCsv };
