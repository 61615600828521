import { useState } from 'react';
import { Modal, ModalHeader, ModalBody, Button } from 'reactstrap';
import { getFunctions, httpsCallable } from 'firebase/functions';

const SendEmail = ({ setIsToastShowing }) => {
	const [modal, setModal] = useState(false);
	const [messageInput, setMessageInput] = useState();
	const toggle = () => setModal(!modal);

	const handleSubmit = async (e) => {
		e.preventDefault();
		const functions = getFunctions();
		const sendSupportEmail = httpsCallable(functions, 'sendSupportEmail');
		setMessageInput('');
		setModal(false);
		setIsToastShowing({
			showing: true,
			title: 'Success',
			message: 'Message has been sent.',
			type: 'success',
			position: 'bottom',
		});
		setTimeout(() => {
			setIsToastShowing({
				showing: false,
			});
		}, 2500);
		await sendSupportEmail({ text: messageInput });
	};

	return (
		<div>
			<span onClick={toggle} className='pe-footer-item'>
				Contact Us
			</span>
			<Modal isOpen={modal} toggle={toggle}>
				<ModalHeader toggle={toggle}>Report An Error</ModalHeader>
				<ModalBody>
					<form
						method='POST'
						className='d-flex flex-column'
						onSubmit={handleSubmit}
					>
						<textarea
							onChange={(e) => setMessageInput(e.target.value)}
							value={messageInput}
							minLength={1}
							rows={4}
							required={true}
							className='mb-4 send-email-text-input'
						/>
						<Button type='submit'>Send</Button>
					</form>
				</ModalBody>
			</Modal>
		</div>
	);
};

export { SendEmail };
