export const formalDate = (isoString) => {
	const date = new Date(isoString);
	const options = { day: 'numeric', month: 'long', year: 'numeric' };
	const result = date.toLocaleDateString('en-US', options);
	const results = result.split(' ');

	const day = date.getUTCDate();
	const lastDigit = day % 10;
	let ordinal;

	switch (lastDigit) {
		case 1:
			ordinal = 'st';
			break;
		case 2:
			ordinal = 'nd';
			break;
		case 3:
			ordinal = 'rd';
			break;
		default:
			ordinal = 'th';
	}

	results[1] = day + ordinal + ',';
	const formalDate = results.join(' ');

	return formalDate;
};
