import {
	collection,
	deleteDoc,
	doc,
	getDoc,
	getDocs,
	query,
	updateDoc,
	where,
	orderBy,
	addDoc,
} from 'firebase/firestore';
import { db } from '@assets/services/auth-service';
import { DB_FILTER, DB_ORDER, DB_PATH } from '@constants/db';

export const getDocFromRef = async (ref) => {
	const docSnap = await getDoc(ref);
	return docSnap;
};

export const getDocFromPath = async (path) => {
	const docSnap = await getDoc(doc(db, path));
	return docSnap;
};

export const getDocsFromPath = async (path, order) => {
	let docQuery = query(collection(db, path));
	if (order) {
		docQuery = query(docQuery, orderBy(order, DB_ORDER.ASC));
	}
	const docsSnap = await getDocs(docQuery);
	return docsSnap;
};

export const getOrganizations = async () => {
	const orgsSnap = await getDocs(collection(db, DB_PATH.ORGANIZATIONS));
	return orgsSnap;
};

export const getOrganization = async (id) => {
	const orgSnap = await getDoc(doc(db, DB_PATH.ORGANIZATIONS, id));
	return orgSnap;
};

export const getOrgIdFromName = async (name) => {
	const orgsSnap = await getDocs(
		query(
			collection(db, DB_PATH.ORGANIZATIONS),
			where(DB_FILTER.NAME, '==', name)
		)
	);
	let orgId;
	orgsSnap.forEach((org) => {
		orgId = org.id;
	});
	return orgId;
};

export const getManufacturers = async (name) => {
	const colRef = collection(db, DB_PATH.MANUFACTURERS);
	const snap = name
		? await getDocs(query(colRef, where(DB_FILTER.NAME, '==', name)))
		: await getDocs(colRef);
	return snap;
};

export const getReleases = async () => {
	const snap = await getDocs(
		query(
			collection(db, DB_PATH.RELEASES),
			orderBy(DB_ORDER.DATE, DB_ORDER.DESC)
		)
	);
	return snap;
};

export const updateOrgnaization = async (id, data) => {
	await updateDoc(doc(db, DB_PATH.ORGANIZATIONS, id), data);
};

export const addManufacturer = async (name) => {
	await addDoc(collection(db, DB_PATH.MANUFACTURERS), {
		name: name,
	});
};

export const deleteWithRef = async (ref) => {
	await deleteDoc(ref);
};

export const updateWithRef = async (ref, data) => {
	await updateDoc(ref, data);
};
