import { useState, useEffect } from 'react';
import {
	AccordionBody,
	AccordionHeader,
	AccordionItem,
	Badge,
	UncontrolledAccordion,
	List,
} from 'reactstrap';
import { formalDate } from '@utils/formalDate';
import { Loader } from '@SignedIn/Loader/Loader';
import { getReleases } from '@services/organization-service';

const Releases = () => {
	const [releaseArr, setReleaseArr] = useState([]);
	const [releases, setReleases] = useState();
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		updateReleaseArr();
	}, []);

	useEffect(() => {
		if (releaseArr.length > 0) {
			updateReleases();
		} else {
			setLoading(false);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [releaseArr]);

	useEffect(() => {
		if (releases != null) setLoading(false);
	}, [releases]);

	const updateReleaseArr = async () => {
		const releasesSnap = await getReleases();
		if (releasesSnap.empty) return;
		const newReleaseArr = [];
		releasesSnap.forEach((doc) => newReleaseArr.push(doc.data()));
		setReleaseArr(newReleaseArr);
	};

	const updateReleases = async () => {
		setReleases(
			<UncontrolledAccordion defaultOpen={'1'}>
				{releaseArr.map((release, i) => {
					return (
						<AccordionItem key={release.date}>
							<AccordionHeader targetId={String(i + 1)}>
								{formalDate(release.date)}
							</AccordionHeader>
							<AccordionBody accordionId={String(i + 1)}>
								{release.updates.length > 0 ? (
									<div>
										<h2>
											<Badge
												color='success'
												className='m-3 ms-0'
											>
												Updates & Improvements
											</Badge>
										</h2>
										{release.updates.map((update) => {
											return (
												<div key={update.title}>
													<h5>{update.title}</h5>
													<List>
														{update.details.map(
															(detail) => {
																return (
																	<li
																		className='ms-3'
																		key={
																			update.title
																		}
																	>
																		{detail}
																	</li>
																);
															}
														)}
													</List>
												</div>
											);
										})}
									</div>
								) : null}
								{release.bugs.length > 0 ? (
									<div>
										<h2>
											<Badge
												color='warning'
												className='m-3 ms-0'
											>
												Bugs & Fixes
											</Badge>
										</h2>
										{release.bugs.map((bug) => {
											return (
												<div key={bug.title}>
													<h5>{bug.title}</h5>
													<List>
														{bug.details.map(
															(detail) => {
																return (
																	<li
																		className='ms-3'
																		key={
																			bug.title
																		}
																	>
																		{detail}
																	</li>
																);
															}
														)}
													</List>
												</div>
											);
										})}
									</div>
								) : null}
							</AccordionBody>
						</AccordionItem>
					);
				})}
			</UncontrolledAccordion>
		);
	};

	return loading ? <Loader /> : releases;
};

export { Releases };
