// Array response means the submission form includes multiple images.
// Otherwise, the response is a single image url string (old submission pre multi-image).
export const getResponseImages = (response, imageInput) => {
	return Array.isArray(response.image)
		? response.image
		: [
				{
					url: response.image?.url || response.image,
					responseTag: imageInput.responseTag,
				},
		  ];
};

// Check if current image subinput is not the first image input
const imageDisplayed = (inputs, subInput) => {
	return (
		inputs
			.filter((input) => input.type == 'image')
			.findIndex(
				(imageInput) => imageInput.responseTag === subInput.responseTag
			) !== 0
	);
};

// Check if response is a multi-image or single image form; return image url
export const getImageDetail = (response, inputs, subInput) => {
	const singleImageResponse = response && !Array.isArray(response);

	// Prevent an image from a single image form response from being repeated
	if (singleImageResponse && imageDisplayed(inputs, subInput)) {
		return null;
	}

	if (singleImageResponse) {
		subInput.label = 'Asset Image';
		return { index: 0, url: response.url || response };
	}

	const index = inputs
		.filter((input) => input.type === 'image')
		.findIndex((input) => input.responseTag === subInput.responseTag);

	const url = response
		? response.find((_response) =>
				subInput.responseTag.includes(_response.responseTag)
		  )?.url
		: '';

	return { index, url };
};
