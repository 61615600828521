import {
	Page,
	Text,
	View,
	Document,
	Image,
	StyleSheet,
} from '@react-pdf/renderer';
import { useState, useEffect } from 'react';
import { auth, db } from '@assets/services/auth-service';
import { getForms, getForm } from '@services/form-service';
import { getAssets as getOrgAssets } from '@services/asset-service';
import { DB_ORDER } from '@constants/db';
import { collection, getDocs, query, where } from 'firebase/firestore';
import { formatDate } from '@utils/formalDate2';

const CampingWorldReport = (props) => {
	const { address1, address2, org, locationId, setToast } = props;
	const [isLoading, setIsLoading] = useState(false);
	const [pdf, setPdf] = useState();
	const [orgLogo, setOrgLogo] = useState();
	const [assets, setAssets] = useState();
	const [nonTaggedAssets, setNonTaggedAssets] = useState();
	const [visitDate, setVisitDate] = useState();

	const getFormId = async () => {
		let formId;
		const snap = await getForms(org);
		snap.forEach((form) => {
			formId = form.id;
		});
		return formId;
	};

	const getLogo = async () => {
		const formId = await getFormId();
		const formSnap = await getForm(org, formId);
		setOrgLogo(formSnap.data().logo);
	};

	const getAssets = async () => {
		let assetRows = [];
		let nonTaggedAssetRows = [];
		let visit;
		const assetsSnap = await getOrgAssets(
			org,
			locationId,
			null,
			null,
			DB_ORDER.ASSET_TYPES
		);
		const assetTypesRef = collection(
			db,
			'organizations',
			'campingworld.com',
			'asset-types'
		);
		assetsSnap.forEach(async (asset) => {
			const assetType = asset.data().assetType;
			const q = query(assetTypesRef, where('name', '==', assetType));
			const assetTypeSnap = await getDocs(q);
			const condInputs = [];
			let inputs;
			assetTypeSnap.forEach(
				(assetType) => (inputs = assetType.data().inputs)
			);
			if (inputs) {
				for (const input of inputs) {
					const parsedInput = JSON.parse(input);
					if (parsedInput.label === 'Scan QR Code') {
						continue;
					}
					condInputs.push(parsedInput);
				}
			}
			const newAsset = {
				id: asset.data().other.assetTag,
				type: assetType,
				make:
					asset.data().make === '(other)'
						? asset.data().other.ifOtherManufacturer
						: asset.data().make,
				model: asset.data().model,
				serial: asset.data().serial,
				img: asset.data().other.equipmentAssetImage,
				manufacturersPlateAssetImage:
					asset.data().other.manufacturersPlateAssetImage,
				location: asset.data().other.assetLocation,
				storeSite: asset.data().other.location,
				bayLocation: asset.data().other.bayLocation,
				bayCount: asset.data().other.bayQuantity,
				condition: asset.data().other.assetCondition,
				manufacturerDate: asset.data().other.manufacturerDate,
				idTagAssetImage: asset.data().other.idTagAssetImage,
				submittedDate: asset.data().other.submittedDate,
				quantity: asset.data().other.quantity,
				bayQuantity: asset.data().other.bayQuantity,
				height: asset.data().other.height,
				condInputs: condInputs,
				other: asset.data().other,
			};
			if (newAsset.id) {
				assetRows.push(newAsset);
			} else {
				nonTaggedAssetRows.push(newAsset);
			}
			if (!visit) {
				const newVisitDate = formatDate(
					asset.data().other.submittedDate
				);
				setVisitDate(newVisitDate);
			}
		});
		setAssets(assetRows);
		setNonTaggedAssets(nonTaggedAssetRows);
	};

	const styles = StyleSheet.create({
		page: {
			flexDirection: 'row',
			fontFamily: 'Helvetica',
		},
		titleSection: {
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
			flexGrow: 1,
		},
		section: {
			flexGrow: 1,
		},
		title: {
			fontSize: 24,
			flexDirection: 'column',
			textAlign: 'center',
			marginTop: 2,
			marginBottom: 12,
			fontStyle: 'bolder',
		},
		address1: {
			textAlign: 'center',
			margin: '8px 0 0 0',
		},
		address2: {
			textAlign: 'center',
			margin: '0 0 8px 0',
		},
		date: {
			marginTop: '10px',
		},
		logo: {
			position: 'absolute',
			left: 10,
			bottom: 10,
			objectFit: 'contain',
			width: 160,
		},
		header: {
			height: 100,
			backgroundColor: '#000',
			position: 'relative',
			top: 0,
			left: 0,
			right: 0,
			display: 'flex',
			alignItems: 'center',
		},
		headerText: {
			color: '#fff',
			textAlign: 'center',
			fontSize: 40,
			marginTop: 25,
		},
		break: {
			height: 60,
			position: 'relative',
			width: '100%',
			bottom: 0,
			backgroundColor: 'transparent',
		},
		img: {
			objectFit: 'cover',
			width: '160px',
			height: '160px',
		},
		inputRow: {
			display: 'flex',
			flexDirection: 'row',
			marginBottom: 14,
		},
		inputLabel: {
			fontWeight: 'bolder',
			fontSize: 14,
			width: '280px',
			marginRight: 50,
			wordWrap: 'break-word',
		},
		inputValue: {
			paddingLeft: 12,
			paddingRight: 12,
			paddingTop: 4,
			paddingBottom: 4,
			borderRadius: '4px',
			backgroundColor: '#ADD8E6',
			fontSize: 10,
			height: 22,
		},
		coverPhoto: {
			width: '100%',
			objectFit: 'cover',
			position: 'absolute',
			bottom: '0',
			zIndex: -5,
		},
		pageNumber: {
			position: 'absolute',
			bottom: 10,
			right: 10,
			color: '#000',
			fontSize: '14px',
		},
	});

	const getPdf = async () => {
		if (assets && nonTaggedAssets && orgLogo) {
			await setPdf(
				<Document>
					<Page size='A4' style={styles.page}>
						<View style={styles.titleSection}>
							<View
								style={{
									objectFit: 'contain',
									width: '180px',
								}}
							>
								<Image src={orgLogo}></Image>
							</View>
							<View
								style={{
									border: '2px solid #228B22',
									padding: '20px',
									display: 'flex',
									alignItems: 'center',
									justifyContent: 'center',
									marginTop: '10px',
								}}
							>
								<Text style={styles.title}>
									Site Inspection Report
								</Text>
								<Text style={styles.address1}>{address1}</Text>
								<Text style={styles.address2}>{address2}</Text>
								<Text
									style={styles.date}
								>{`Visit Date: ${visitDate}`}</Text>
							</View>
							<View style={styles.coverPhoto}>
								<Image
									src={
										'https://firebasestorage.googleapis.com/v0/b/property-echo-4d747.appspot.com/o/iStock-1409408119.jpg?alt=media&token=1659d894-be6c-429b-b8a2-4eff1db3ba08'
									}
								/>
							</View>
							<View style={styles.logo}>
								<Image
									src={
										'https://firebasestorage.googleapis.com/v0/b/property-echo-4d747.appspot.com/o/Logos%2Fpe-logo.png?alt=media&token=06d779c3-03e4-4981-8b10-009679be3e9e'
									}
								></Image>
							</View>
						</View>
					</Page>

					{assets.map((asset, index) => {
						return (
							<Page
								size='A4'
								style={{
									...styles.page,
									paddingTop: 6,
								}}
								wrap
								key={asset.id}
							>
								<View style={styles.section}>
									<View
										style={{
											display: 'flex',
											flexDirection: 'column',
											margin: 12,
										}}
										wrap={false}
									>
										<View style={styles.inputRow}>
											<Text style={styles.inputLabel}>
												ASSET TAG ID
											</Text>
											<Text style={styles.inputValue}>
												{asset.id || 'N/A'}
											</Text>
										</View>
										<View style={styles.inputRow}>
											<Text style={styles.inputLabel}>
												ASSET TYPE
											</Text>
											<Text style={styles.inputValue}>
												{asset.type || 'N/A'}
											</Text>
										</View>
										<View style={styles.inputRow}>
											<Text style={styles.inputLabel}>
												ASSET AREA
											</Text>
											<Text style={styles.inputValue}>
												{asset.location || 'N/A'}
											</Text>
										</View>
										<View style={styles.inputRow}>
											<Text style={styles.inputLabel}>
												MAKE
											</Text>
											<Text style={styles.inputValue}>
												{asset.make || 'N/A'}
											</Text>
										</View>
										<View style={styles.inputRow}>
											<Text style={styles.inputLabel}>
												MODEL
											</Text>
											<Text style={styles.inputValue}>
												{asset.model || 'N/A'}
											</Text>
										</View>
										<View style={styles.inputRow}>
											<Text style={styles.inputLabel}>
												SERIAL
											</Text>
											<Text style={styles.inputValue}>
												{asset.serial || 'N/A'}
											</Text>
										</View>
										<View style={styles.inputRow}>
											<Text style={styles.inputLabel}>
												MANUFACTURER DATE
											</Text>
											<Text style={styles.inputValue}>
												{asset.manufacturerDate ||
													'N/A'}
											</Text>
										</View>
										{asset.bayLocation && (
											<View style={styles.inputRow}>
												<Text style={styles.inputLabel}>
													FALL ARREST SYSTEM BAY
													LOCATION
												</Text>
												<Text style={styles.inputValue}>
													{asset.bayLocation || 'N/A'}
												</Text>
											</View>
										)}
										<View style={styles.inputRow}>
											<Text style={styles.inputLabel}>
												CONDITION
											</Text>
											<Text style={styles.inputValue}>
												{asset.condition || 'N/A'}
											</Text>
										</View>
										<View
											style={{
												...styles.inputRow,
											}}
										>
											<Text style={styles.inputLabel}>
												EQUIPMENT OR AREA PICTURE
											</Text>
											<View style={styles.img}>
												<Image src={asset.img} />
											</View>
										</View>
										<View
											style={{
												...styles.inputRow,
											}}
										>
											<Text style={styles.inputLabel}>
												MANUFACTURER PLATE
											</Text>
											<View style={styles.img}>
												<Image
													src={
														asset.manufacturersPlateAssetImage
													}
												/>
											</View>
										</View>
									</View>
									<View style={styles.logo} fixed>
										<Image
											src={
												'https://firebasestorage.googleapis.com/v0/b/property-echo-4d747.appspot.com/o/Logos%2Fpe-logo.png?alt=media&token=06d779c3-03e4-4981-8b10-009679be3e9e'
											}
										></Image>
									</View>
									<View style={styles.coverPhoto} fixed>
										<Image
											src={
												'https://firebasestorage.googleapis.com/v0/b/property-echo-4d747.appspot.com/o/cover2.jpg?alt=media&token=c9d04f70-9f92-49e9-8c82-e12f9d89434b'
											}
										/>
									</View>
									<View fixed style={styles.break}></View>
									<View style={styles.pageNumber}>
										<Text>
											Page {index + 2} of{' '}
											{assets.length +
												1 +
												nonTaggedAssets.length}
										</Text>
									</View>
								</View>
							</Page>
						);
					})}

					{nonTaggedAssets.map((asset, index) => (
						<Page
							size='A4'
							style={{
								...styles.page,
								paddingTop: 6,
							}}
							key={asset.id}
							wrap
						>
							<View style={styles.section}>
								<View style={{ margin: 12 }}>
									<Text>ADDITIONAL DATA CAPTURE</Text>
								</View>
								<View
									style={{
										display: 'flex',
										flexDirection: 'column',
										margin: 12,
									}}
									wrap={false}
								>
									<View style={styles.inputRow}>
										<Text style={styles.inputLabel}>
											ASSET TYPE
										</Text>
										<Text style={styles.inputValue}>
											{asset.type || 'N/A'}
										</Text>
									</View>
									<View style={styles.inputRow}>
										<Text style={styles.inputLabel}>
											ASSET AREA
										</Text>
										<Text style={styles.inputValue}>
											{asset.location || 'N/A'}
										</Text>
									</View>
									<View style={styles.inputRow}>
										<Text style={styles.inputLabel}>
											MAKE
										</Text>
										<Text style={styles.inputValue}>
											{asset.make || 'N/A'}
										</Text>
									</View>
									<View style={styles.inputRow}>
										<Text style={styles.inputLabel}>
											CONDITION
										</Text>
										<Text style={styles.inputValue}>
											{asset.condition || 'N/A'}
										</Text>
									</View>
									{asset.condInputs.map((input) => (
										<View
											style={styles.inputRow}
											key={input.label}
										>
											<Text style={styles.inputLabel}>
												{input.label}
											</Text>
											<Text style={styles.inputValue}>
												{asset.other[
													input.responseTag
												] || 'N/A'}
											</Text>
										</View>
									))}
									<View
										style={{
											...styles.inputRow,
										}}
									>
										<Text style={styles.inputLabel}>
											EQUIPMENT OR AREA PICTURE
										</Text>
										<View style={styles.img}>
											<Image src={asset.img} />
										</View>
									</View>
									<View
										style={{
											...styles.inputRow,
										}}
									>
										<Text style={styles.inputLabel}>
											MANUFACTURER PLATE
										</Text>
										<View style={styles.img}>
											<Image
												src={
													asset.manufacturersPlateAssetImage
												}
											/>
										</View>
									</View>
								</View>
								<View style={styles.logo} fixed>
									<Image
										src={
											'https://firebasestorage.googleapis.com/v0/b/property-echo-4d747.appspot.com/o/Logos%2Fpe-logo.png?alt=media&token=06d779c3-03e4-4981-8b10-009679be3e9e'
										}
									></Image>
								</View>
								<View style={styles.coverPhoto} fixed>
									<Image
										src={
											'https://firebasestorage.googleapis.com/v0/b/property-echo-4d747.appspot.com/o/cover2.jpg?alt=media&token=c9d04f70-9f92-49e9-8c82-e12f9d89434b'
										}
									/>
								</View>
								<View fixed style={styles.break}></View>
								<View style={styles.pageNumber}>
									<Text>
										Page {assets.length + index + 2} of{' '}
										{assets.length +
											1 +
											nonTaggedAssets.length}
									</Text>
								</View>
							</View>
						</Page>
					))}
				</Document>
			);
		}
	};

	const downloadPdf = async () => {
		const asString = JSON.stringify(pdf, (k, v) =>
			typeof v === 'symbol' ? `$$Symbol:${Symbol.keyFor(v)}` : v
		);

		// On localhost, open the following link and request temp access to the demo server
		// to get around the CORS issue: https://cors-anywhere.herokuapp.com/corsdemo ...
		// Before deploying to prod, switch the URLs passed to fetch, as CORS is not an issue
		// when using the app on propertyecho.com or staging.propertyecho.com
		const controller = new AbortController();
		const signal = controller.signal;
		const date = new Date().toISOString().split('T')[0];
		fetch(
			// 'https://micro-backend-kn7dm7lqsa-ue.a.run.app/leaveBehindExport',
			'https://us-central1-property-echo-4d747.cloudfunctions.net/downloadLeaveBehind',
			{
				method: 'POST',
				signal: signal,
				headers: {
					'Content-Type': 'application/json',
				},
				body: JSON.stringify({
					data: asString,
					email: auth?.currentUser?.email,
					location: `${address1}, ${address2}`,
					date: date,
					org: org,
				}),
			}
		);
		setToast({
			showing: true,
			title: 'Generating PDF',
			message:
				'You will receive an email when your download link is ready.',
			type: 'success',
			position: 'bottom',
		});
		setTimeout(() => {
			setToast({
				showing: false,
			});
			setIsLoading(false);
		}, 4500);
		setTimeout(() => {
			controller.abort();
		}, 5000);
	};

	useEffect(() => {
		if (isLoading) {
			getAssets();
			getLogo();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isLoading]);

	useEffect(() => {
		getPdf();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [assets, nonTaggedAssets, orgLogo]);

	useEffect(() => {
		if (pdf) {
			downloadPdf();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [pdf]);

	return !isLoading ? (
		<button
			style={{ float: 'right' }}
			className='bg-green text-white px-2 mx-4 py-2 mt-2 border-none rounded'
			onClick={() => setIsLoading(true)}
		>
			Generate Report
		</button>
	) : (
		<button
			style={{ float: 'right' }}
			disabled
			className='bg-green text-white mx-4 px-2 py-2 mt-2 border-none rounded'
		>
			Generating Report...
		</button>
	);
};

export { CampingWorldReport };
