import { MutatingDots } from 'react-loader-spinner';

const Loader = (props) => {
	return (
		<div
			className={
				props.small ? 'loading-spinner-small' : 'loading-spinner'
			}
		>
			<MutatingDots
				width={120}
				height={120}
				color='#6bd098'
				secondaryColor='#6bd098'
			/>
		</div>
	);
};
export { Loader };
