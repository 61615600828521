import { Configuration, OpenAIApi } from 'openai';

const configuration = new Configuration({
	organization: 'org-73j2UNq2MWSjoEOWS2CNubuM',
	apiKey: process.env.OPENAI_API_KEY,
});

delete configuration.baseOptions.headers['User-Agent'];

const openai = new OpenAIApi(configuration);

export const generateAssetDescription = async (prompt) =>
	openai.createChatCompletion({
		model: 'gpt-3.5-turbo',
		messages: [{ role: 'user', content: prompt }],
		max_tokens: 200,
		temperature: 0.2,
		frequency_penalty: 1.5,
		presence_penalty: 1.5,
	});
