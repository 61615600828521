import { Modal, ModalBody, ModalHeader, Button } from 'reactstrap';
import { useEffect, useState } from 'react';
import Select from 'react-select';
import { FormGroup, Label } from 'reactstrap';
import { exportCsv } from './exportCsv';
import { Loader } from '../../../../Loader/Loader.js';
import { getOrganizationUsers } from '../../../../../../../services/user-service';
import { getOrganization } from '../../../../../../../services/organization-service';
import { DateRangeFilter } from './DateRangeFilter';
import { exportCsvCushman } from '../../../Locations/children/exportCsvCushmanNew';
import { DB_ORG } from '@constants/db';

const ExportModal = (props) => {
	const { organization, showExport, browserLocationId, locations } = props;

	// Loading state
	const [loading, setLoading] = useState(false);

	// Modal state (ie, first page, second page, third page, fourth page)
	const [modal, setModal] = useState();

	const toggle = () => {
		setLoading(false);
		setField();
		setFieldOptions([]);
		setOption('submittedDate');
		setDirection('desc');
		setShowApproved(null);
		props.toggle();
	};

	// Filter field options
	let filterFields = [
		{
			label: '(no filter applied)',
			value: '',
		},
		{
			label: 'Submitting User',
			value: 'submittingUserId',
		},
		{
			label: 'Submission Date',
			value: 'submittedDate',
		},
		{
			label: 'Address',
			value: 'location.address1',
		},
	];

	// Selected field state
	const [field, setField] = useState();

	// Selected field options state
	const [fieldOptions, setFieldOptions] = useState([]);
	const [fieldOptionsChange, setFieldOptionsChange] = useState(false);

	// Selected field option state
	const [option, setOption] = useState();
	const [optionChange, setOptionChange] = useState(false);

	// Selected sorting option state
	const [sortOption, setSortOption] = useState('submittedDate');

	// Sort direction state (ie, ascending vs descending);
	const [direction, setDirection] = useState('desc');

	// Extra query paramter state for Cushman (ie, approved, not, or both)
	const [showApproved, setShowApproved] = useState(null);

	// Updates field options from Firebase
	const getFieldOptions = async () => {
		const options = [];
		if (field == 'submittingUserId') {
			const usersSnap = await getOrganizationUsers(organization);
			usersSnap.forEach((user) => {
				const userData = user.data();
				options.push({
					label: userData.name,
					value: userData.uid,
				});
			});
			options.sort((a, b) => {
				if (a.label > b.label) return 1;
				else if (a.label < b.label) return -1;
				return 0;
			});
		} else if (field == 'reviewed') {
			options.push(
				{ label: 'True', value: true },
				{ label: 'False', value: false }
			);
		} else if (field && field != '') {
			const orgDoc = await getOrganization(organization);
			let key;
			const subStr = 'location.';
			if (field.includes(subStr)) {
				const index = field.indexOf(subStr) + subStr.length;
				key = field.slice(index);
			} else {
				key = field;
			}
			for (const item of orgDoc.data().exportOptions[key]) {
				options.push({ label: item, value: item });
			}
		}
		await setFieldOptions(options);
		await setOption();
		setFieldOptionsChange(true);
		setOptionChange(true);
	};

	// Export function
	const triggerExport = (params) => {
		if (organization === DB_ORG.CUSHMANWAKEFIELD) {
			exportCsvCushman(...params);
		} else {
			exportCsv(...params);
		}
	};

	// Updates modal state
	const updateModal = async () => {
		const val = { value: field };
		for (const item of filterFields) {
			if (item.value == field) {
				val.label = item.label;
				break;
			}
		}
		const lineOne = (
			<FormGroup key={`modal-filter-1`}>
				<Label>Select a Field to Filter Your Results With</Label>
				<Select
					options={filterFields}
					value={val}
					onChange={(e) => {
						setField(e.value);
					}}
					menuShouldScrollIntoView={false}
				/>
			</FormGroup>
		);
		let lineTwo = null;
		if (field === 'submittedDate') {
			lineTwo = (
				<DateRangeFilter
					setOption={setOption}
					setOptionChange={setOptionChange}
				/>
			);
		} else if (field && field.value != '') {
			let val;
			if (option) val = { label: option.label, value: option.value };
			lineTwo = (
				<FormGroup className='mt-4' key={`modal-filter-2`}>
					<Label>Select an Option for Your Filtering Field</Label>
					<Select
						options={fieldOptions}
						value={val}
						onChange={(e) => {
							setOption({ label: e.label, value: e.value });
							setOptionChange(true);
						}}
						menuShouldScrollIntoView={false}
					/>
				</FormGroup>
			);
		}
		let lineThree = null;
		let lineFour = null;
		if (field == '' || (field && option)) {
			let labelThree;
			const lineThreeOptions = [
				{ label: 'Submission Date', value: 'submittedDate' },
			];
			for (const item of filterFields) {
				if (
					item.value != '' &&
					item.value != 'submittedDate' &&
					item.value != 'location.address1'
				)
					lineThreeOptions.push(item);
			}
			for (const item of lineThreeOptions) {
				if (item.value == sortOption) {
					labelThree = item.label;
					break;
				}
			}
			lineThree = (
				<FormGroup className='mt-4' key={`modal-sort-3`}>
					<Label>{`Select Which Field to Sort By`}</Label>
					<Select
						options={lineThreeOptions}
						value={{ label: labelThree, value: sortOption }}
						onChange={(e) => setSortOption(e.value)}
						menuShouldScrollIntoView={false}
					/>
				</FormGroup>
			);

			let labelFour;
			if (direction == 'asc') labelFour = 'Ascending';
			else labelFour = 'Descending';
			lineFour = (
				<FormGroup className='mt-4' key={`modal-sort-4`}>
					<Label>{`Select Which Direction to Sort`}</Label>
					<Select
						options={[
							{ label: 'Ascending', value: 'asc' },
							{ label: 'Descending', value: 'desc' },
						]}
						value={{ label: labelFour, value: direction }}
						onChange={(e) => setDirection(e.value)}
						menuShouldScrollIntoView={false}
					/>
				</FormGroup>
			);
		}
		let lineFive = null;
		if (organization == DB_ORG.CUSHMANWAKEFIELD) {
			let labelFive;
			if (showApproved == 'true') {
				labelFive = 'Approved';
			} else if (showApproved == 'false') {
				labelFive = 'Unapproved';
			} else if (showApproved === null) {
				labelFive = 'Both';
			}
			lineFive = (
				<FormGroup className='mt-4' key={`modal-sort-5`}>
					<Label>{`Include Approved, Unapproved, or Both?`}</Label>
					<Select
						options={[
							{ label: 'Approved', value: 'true' },
							{ label: 'Unapproved', value: 'false' },
							{ label: 'Both', value: null },
						]}
						value={{ label: labelFive, value: showApproved }}
						onChange={(e) => setShowApproved(e.value)}
						menuShouldScrollIntoView={false}
					/>
				</FormGroup>
			);
		}
		const params = [];

		// A field + option are selected OR "no field" is explicitly selected
		if (field && field != '' && option && !locations) {
			params.push(
				organization,
				field,
				option,
				sortOption,
				direction,
				setLoading,
				browserLocationId,
				null,
				toggle
			);
		} else if (field == '' && !locations) {
			params.push(
				organization,
				undefined,
				undefined,
				sortOption,
				direction,
				setLoading,
				browserLocationId,
				null,
				toggle
			);
		} else if (locations) {
			params.push(
				organization,
				field,
				option,
				sortOption,
				direction,
				setLoading,
				null,
				locations,
				toggle
			);
		}
		if (organization == DB_ORG.CUSHMANWAKEFIELD) {
			params.push(showApproved);
		}

		setModal(
			<ModalBody>
				<div className='mb-4 fs-5'>Filter Your Submissions</div>
				<div>
					{lineOne}
					{lineTwo}
					{lineThree}
					{lineFour}
					{lineFive}
				</div>
				<div className='mt-4 d-flex justify-content-between'>
					<Button onClick={toggle}>Go Back</Button>
					<Button onClick={() => triggerExport(params)}>
						Download
					</Button>
				</div>
			</ModalBody>
		);
	};

	// Update fieldOptions state when field changes
	useEffect(() => {
		getFieldOptions();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [field]);

	// Update modal state when option or direction states change
	useEffect(() => {
		updateModal();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [field, sortOption, direction, fieldOptions, showApproved]);

	// Update modal state when field or fieldOptions states change
	useEffect(() => {
		if (fieldOptionsChange || optionChange) {
			updateModal();
			if (fieldOptionsChange) setFieldOptionsChange(false);
			if (optionChange) setOptionChange(false);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [fieldOptionsChange, optionChange]);

	return (
		<Modal isOpen={showExport} className='export-modal' centered>
			<ModalHeader toggle={toggle}>
				Export CSV - Custom Options
			</ModalHeader>
			{(() => {
				if (!loading) return modal;
				else return <Loader />;
			})()}
		</Modal>
	);
};

export { ExportModal };
