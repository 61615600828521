import { useEffect, useState } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import { Plans } from '@views/SignedIn/Plans/Plans';
import { MdOutlineHealthAndSafety, MdOutlineHistory } from 'react-icons/md';
import { IoTicketOutline } from 'react-icons/io5';
import { FiSettings, FiUsers } from 'react-icons/fi';
import { BsClipboardCheck, BsBuilding } from 'react-icons/bs';
import { MdRateReview } from 'react-icons/md';
import { GiProgression } from 'react-icons/gi';
import { RiKey2Line } from 'react-icons/ri';
import {
	Button,
	Card,
	CardBody,
	CardHeader,
	FormGroup,
	Input,
	Label,
	Nav,
	NavItem,
	NavLink,
	TabContent,
	TabPane,
} from 'reactstrap';
import { useLocation } from 'react-router-dom';
import { isUndefined } from 'util';
import { DB_ORG } from '@constants/db';
import { PredictiveSpend } from '@components/layouts/SignedIn/views/Admin/PredictiveSpend/PredictiveSpend';
import {
	getOrganization,
	updateOrgnaization,
} from '@services/organization-service';
import { QATab } from '@SignedIn/views/SingleLocation/QATab/QATab';
import { UsersTable } from '@SignedIn/views/Admin/UsersTable/UsersTable';
import { ActivitiesTab } from '@SignedIn/views/SingleLocation/ActivitiesTab/ActivitiesTab';
import { CushmanDataUpdateTab } from '@SignedIn/views/Admin/CushmanDataUpdateTab/CushmanDataUpdateTab';
import { AssetsTable } from '@SignedIn/views/Admin/AssetsTable/AssetsTable';
import { Loader } from '@SignedIn/Loader/Loader';
import { MonthlyPMTab } from '@SignedIn/views/Admin/MonthlyPMTab/MonthlyPMTab';
import { CurrentMonthTab } from '@SignedIn/views/Admin/CurrentMonthTab/CurrentMonthTab';
import AuthContext from '@assets/services/Auth';
import { useContext } from 'react';
import { ThemeModeToggle } from '@components/layouts/SignedIn/ThemeMode/ThemeModeToggle';

const Admin = (props) => {
	const { setToast } = props;
	const role = props.userRole;

	const { authInfo } = useContext(AuthContext);
	const organization = authInfo.org;

	const [autoSaveDrafts, setAutoSaveDrafts] = useState(false);
	// Init loading state
	const [isLoading, setIsLoading] = useState(true);
	const location = useLocation();
	const navigate = useNavigate();

	let tabNames;

	if (organization === DB_ORG.CUSHMANWAKEFIELD) {
		tabNames = [
			'Users',
			'Submissions',
			'Data Update',
			'Previous Month Audit',
			'Current Month Progress',
		];
	} else {
		tabNames = ['Users', 'Submissions', 'QA Submissions', 'Assets'];
	}
	if (role == 'superuser' && organization != 'cushmanwakefield.com') {
		// tabNames = [...tabNames, 'Master Asset Types', 'Org Asset Types'];
		tabNames = [...tabNames, 'Predictive Spend'];
	}
	tabNames.push('Settings');

	// tab state
	const [selectedTab, setSelectedTab] = useState(() => {
		if (location.state) {
			return Number(tabNames.indexOf(location.state.tab)) + 1;
		} else return 1;
	});

	const [tabWidthArr, setTabWidthArr] = useState([]);

	const [areTabsRendered, setAreTabsRendered] = useState(false);

	const toggle = (tab) => {
		if (selectedTab !== tab) setSelectedTab(tab);
	};

	// State that switches between the actual page and redirecting to the Plans page
	const [page, setPage] = useState();

	// Function that runs on init render and redirects the user to Plans if not an admin
	const checkRole = async () => {
		const canView = role == 'admin' || role == 'superuser';
		if (!canView) {
			setPage(<Navigate to='/plans' element={<Plans />} replace />);
		} else {
			updatePage();
		}
	};

	const getOrgInfo = async () => {
		const docSnap = await getOrganization(organization);
		setAutoSaveDrafts(
			isUndefined(docSnap.data().autoSaveDrafts)
				? true
				: docSnap.data().autoSaveDrafts
		);
	};

	const updateOrgAutoSave = async (val) => {
		setAutoSaveDrafts(val.target.checked);

		updateOrgnaization(organization, {
			autoSaveDrafts: autoSaveDrafts,
		});
	};

	// function to retrieve value where tab indicator should be placed
	const sumWidth = (currentTab) => {
		return tabWidthArr.slice(0, currentTab).reduce((a, c) => {
			return a + c;
		}, 0);
	};

	const getTabWidthArr = () => {
		// array of tab widths
		let tabs = [];
		// get width of each tab
		const tabElements = document.querySelectorAll('.nav-link');
		tabElements.forEach((tab) => {
			tabs.push(tab.getBoundingClientRect().width);
		});
		setTabWidthArr(tabs);
		setAreTabsRendered(true);
	};

	// updates page whenever a tab is clicked
	useEffect(() => {
		updatePage();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedTab, areTabsRendered]);

	useEffect(() => {
		if (page) {
			getTabWidthArr();
		}
	}, [page]);

	// Redirects non-paid user to Plans on init render or starts cascade of page rendering, starting with allLocations state
	useEffect(() => {
		checkRole();
		getOrgInfo();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	// Updates page component with child components
	const updatePage = async () => {
		setPage(
			<div>
				<Nav className='navbar-animated mb-2'>
					<div className='position-relative d-flex'>
						{tabNames.map((tab, i) => {
							return (
								<NavItem
									className='navbar-animated-item'
									key={tabNames[i]}
								>
									<NavLink
										className={
											selectedTab == i + 1
												? 'active-tab'
												: 'inactive-tab'
										}
										onClick={() => {
											toggle(i + 1);
										}}
									>
										<span className='tab-title'>{tab}</span>
										{tab == 'Users' && (
											<FiUsers className='tab-icon' />
										)}
										{tab == 'Submissions' && (
											<BsClipboardCheck className='tab-icon' />
										)}
										{tab == 'QA Submissions' && (
											<MdRateReview className='tab-icon' />
										)}
										{tab == 'Assets' && (
											<MdOutlineHealthAndSafety className='tab-icon' />
										)}
										{tab == 'Settings' && (
											<FiSettings className='tab-icon' />
										)}
										{tab == 'Data Update' && (
											<IoTicketOutline className='tab-icon' />
										)}
										{tab == 'Previous Month Audit' && (
											<MdOutlineHistory className='tab-icon' />
										)}
										{tab == 'Current Month Progress' && (
											<GiProgression className='tab-icon' />
										)}
										{tab == 'Master Asset Types' && (
											<RiKey2Line className='tab-icon' />
										)}
										{tab == 'Org Asset Types' && (
											<BsBuilding className='tab-icon' />
										)}
									</NavLink>
								</NavItem>
							);
						})}
						<div
							className='tab-indicator'
							style={{
								left: `${sumWidth(selectedTab - 1)}px`,
								width: `${tabWidthArr[selectedTab - 1]}px`,
							}}
						></div>
					</div>
				</Nav>
				<TabContent activeTab={selectedTab} className='fullSize'>
					<TabPane
						tabId={tabNames.indexOf('Users') + 1}
						className='fullSize'
					>
						<UsersTable
							isFocused={
								selectedTab == tabNames.indexOf('Users') + 1
							}
						/>
					</TabPane>
					<TabPane
						tabId={tabNames.indexOf('Submissions') + 1}
						className='fullSize'
					>
						<ActivitiesTab
							isFocused={
								selectedTab ==
								tabNames.indexOf('Submissions') + 1
							}
							organization={organization}
						/>
					</TabPane>
					{organization !== DB_ORG.CUSHMANWAKEFIELD && (
						<TabPane
							tabId={tabNames.indexOf('QA Submissions') + 1}
							className='fullSize'
						>
							<QATab
								isFocused={
									selectedTab ==
									tabNames.indexOf('QA Submissions') + 1
								}
								organization={organization}
								browserLocationId={null}
							/>
						</TabPane>
					)}
					{organization != DB_ORG.CUSHMANWAKEFIELD ? (
						<TabPane
							tabId={tabNames.indexOf('Assets') + 1}
							className='fullSize'
						>
							<AssetsTable
								isFocused={
									selectedTab ==
									tabNames.indexOf('Assets') + 1
								}
								organization={organization}
								browserLocationId={null}
								leaveBehindReportInfo={null}
								setToast={null}
							/>
						</TabPane>
					) : (
						<TabPane
							tabId={tabNames.indexOf('Data Update') + 1}
							className='fullSize'
						>
							<CushmanDataUpdateTab
								isFocused={
									selectedTab ==
									tabNames.indexOf('Data Update') + 1
								}
								setToast={setToast}
							/>
						</TabPane>
					)}
					{organization === DB_ORG.CUSHMANWAKEFIELD && (
						<TabPane
							tabId={tabNames.indexOf('Previous Month Audit') + 1}
							className='fullSize'
						>
							<MonthlyPMTab
								isFocused={
									selectedTab ==
									tabNames.indexOf('Previous Month Audit') + 1
								}
								org={organization}
							/>
						</TabPane>
					)}
					{organization === DB_ORG.CUSHMANWAKEFIELD && (
						<TabPane
							tabId={
								tabNames.indexOf('Current Month Progress') + 1
							}
							className='fullSize'
						>
							<CurrentMonthTab
								isFocused={
									selectedTab ==
									tabNames.indexOf('Current Month Progress') +
										1
								}
								org={organization}
								setToast={setToast}
							/>
						</TabPane>
					)}
					<TabPane
						tabId={tabNames.indexOf('Settings') + 1}
						className='fullSize'
					>
						<Card className='mb-3'>
							<CardHeader>Property Report Settings</CardHeader>
							<CardBody>
								<FormGroup switch>
									<Label check>Auto Save Drafts?</Label>
									<Input
										type='switch'
										role='switch'
										className='toggle-label-admin'
										checked={autoSaveDrafts}
										onChange={(val) =>
											updateOrgAutoSave(val)
										}
									/>
								</FormGroup>
							</CardBody>
						</Card>
						<Card style={{ marginBottom: '19px' }}>
							<CardBody>
								<ThemeModeToggle />
							</CardBody>
						</Card>
						<Button
							color='success'
							onClick={() => navigate('/releases')}
						>
							View Releases
						</Button>
					</TabPane>
					{role === 'superuser' && (
						<TabPane
							tabId={tabNames.indexOf('Predictive Spend') + 1}
							className='fullSize'
						>
							{/* <div className='d-flex justify-content-center'> */}
							<div className=''>
								<PredictiveSpend
									isFocused={
										selectedTab ==
										tabNames.indexOf('Predictive Spend') + 1
									}
									org={organization}
								/>
							</div>
						</TabPane>
					)}
				</TabContent>
			</div>
		);
		setIsLoading(false);
	};

	return isLoading ? <Loader /> : page;
};

export { Admin };
