///////////////////////////////////////////////////////////////////////
import { Modal, ModalBody } from 'reactstrap';
import { Component } from 'react';
import { Loader } from '@SignedIn/Loader/Loader';

///////////////////////////////////////////////////////////////////////

class LoadingModal extends Component {
	toggle() {
		this.setState((prevState) => ({
			dropdownOpen: !prevState.dropdownOpen,
		}));
	}

	toggleMenu() {
		this.setState({ sidemenuOpen: !this.state.sidemenuOpen });
	}

	///////////////////////////////////////////////////////////////////////
	render() {
		return (
			<Modal isOpen={this.props.showing} className='loading-modal'>
				<ModalBody>
					<Loader />
				</ModalBody>
			</Modal>
		);
	}
	///////////////////////////////////////////////////////////////////////
}
export { LoadingModal };
