import { auth } from '@assets/services/auth-service';

export const sendMessage = async (message) =>
	fetch(process.env.ALFRED_SERVICE_URL, {
		method: 'POST',
		body: JSON.stringify({ query: message }),
		headers: {
			'Content-Type': 'application/json',
			Authorization: `Bearer ${await auth.currentUser.getIdToken()}`,
		},
	});
