import { Button } from 'reactstrap';

const SettingsTab = ({ userRole, resetVirtual }) => {
	return (
		<div className='settings-tab'>
			{(userRole === 'admin' || userRole === 'superuser') && (
				<Button
					color='success'
					className='virtual-reset'
					onClick={resetVirtual}
				>
					Reset Virtual ID
				</Button>
			)}
		</div>
	);
};

export { SettingsTab };
