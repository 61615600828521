import { Row, Col } from 'reactstrap';
import { MediumCard } from '@SignedIn/views/Dashboard/MediumCard/MediumCard';
import { ChatbotMainCard } from '@components/layouts/SignedIn/views/Dashboard/ChatbotMainCard/ChatbotMainCard';
import { useEffect, useState } from 'react';
import { getOrganization } from '@services/organization-service';
import { useContext } from 'react';
import AuthContext from '@assets/services/Auth';
import { AlfredsNotes } from '@components/layouts/SignedIn/AlfredsNotes/AlfredsNotes';

const Dashboard = () => {
	const [orgData, setOrgData] = useState({});
	const { authInfo } = useContext(AuthContext);
	const org = authInfo.org;

	const getOrgData = async () => {
		const orgSnapshot = await getOrganization(org);
		return orgSnapshot.data();
	};

	// Update locationHealth state upon render
	useEffect(() => {
		getOrgData().then((d) => {
			setOrgData(d);
		});
	}, [org]);

	return (
		<div className='dashboard mb-3 mb-md-0'>
			<Row className='light-border-b row-1 mb-2 mb-md-0'>
				<Col className='col-12 col-sm-12 col-xl-5 mb-5 mb-lg-0'>
					<ChatbotMainCard />
				</Col>
				<Col className='col-12 col-xl-7 mb-2 mb-md-0 d-flex justify-content-center align-items-center'>
					<AlfredsNotes
						healthScore={orgData.healthscore}
						organization={org}
					/>
				</Col>
			</Row>
			<Row className='justify-content-center'>
				{[...Array(3)].map((_, index) => (
					<Col key={index} className='col-12 col-xl-4'>
						<MediumCard index={index} />
					</Col>
				))}
			</Row>
		</div>
	);
};

export { Dashboard };
