import { Html5QrcodeScanner } from 'html5-qrcode';
import { useEffect } from 'react';

// const Html5QrcodePlugin = (props) => {
// 	useEffect(() => {
// 		const html5QrCode = new Html5Qrcode('reader');
// 		const { qrCodeSuccessCallback } = props;
// 		const config = { fps: 10, qrbox: { width: 250, height: 250 } };
// 		html5QrCode.start(
// 			{ facingMode: 'environment' },
// 			config,
// 			(text, res) => {
// 				qrCodeSuccessCallback(text, res);
// 				html5QrCode.stop();
// 			},
// 			(e) => console.log(e)
// 		);
// 	}, []);

// 	return <div id='reader' style={{ fontSize: '14px' }}></div>;
// };

const qrcodeRegionId = 'html5qr-code-full-region';

// Creates the configuration object for Html5QrcodeScanner.
const createConfig = (props) => {
	let config = {};
	if (props.fps) {
		config.fps = props.fps;
	}
	if (props.qrbox) {
		config.qrbox = props.qrbox;
	}
	if (props.aspectRatio) {
		config.aspectRatio = props.aspectRatio;
	}
	if (props.disableFlip !== undefined) {
		config.disableFlip = props.disableFlip;
	}
	return config;
};

const Html5QrcodePlugin = (props) => {
	var html5QrcodeScanner;
	useEffect(() => {
		// when component mounts
		const config = createConfig(props);
		const verbose = props.verbose === true;
		// Suceess callback is required.
		if (!props.qrCodeSuccessCallback) {
			console.error('qrCodeSuccessCallback is required callback.');
		}

		// eslint-disable-next-line
		html5QrcodeScanner = new Html5QrcodeScanner(
			qrcodeRegionId,
			config,
			verbose
		);
		html5QrcodeScanner.render(props.qrCodeSuccessCallback);
		// eslint-disable-next-line
		// cleanup function when component will unmount
		return () => {
			html5QrcodeScanner.clear().catch((error) => {
				console.error('Failed to clear html5QrcodeScanner. ', error);
			});
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	return <div id={qrcodeRegionId} />;
};

export { Html5QrcodePlugin };
