import { useEffect, useState, useCallback, useRef } from 'react';
import { Card, CardHeader, Table, Form, CardBody, Input } from 'reactstrap';
import { searchAssets } from './children/Search';
import { MdAdd } from 'react-icons/md';
import { AiOutlineClose } from 'react-icons/ai';
import { BsThreeDotsVertical } from 'react-icons/bs';
import { IoMdTrash } from 'react-icons/io';
import { Filter } from '@SignedIn/views/Locations/children/Filter';
import { canDelete, canAdd } from '@assets/services/user-role-service';
import {
	getHeaders,
	getSchema,
	toRow,
} from '@SignedIn/views/Locations/children/scExport';
import { auth } from '@assets/services/auth-service';
import { ConfirmDialog } from '@SignedIn/views/Locations/children/ConfirmDialog';
import { Loader } from '@SignedIn/Loader/Loader';
import { ProgressBar } from 'react-bootstrap';
import { SCExportDialog } from './children/scExportDialog';
import { exportCsv } from '@SignedIn/views/Locations/children/exportCsv';
import { WarrantyIcon } from './children/WarrantyIcon';
import { useLocation, useNavigate } from 'react-router-dom';
import {
	deleteAsset,
	getAssets,
	// getAssetsCount,
	getAssetsWithPagination,
	getAssetTypes,
} from '@services/asset-service';
import { getUser, updateUserFilter } from '@services/user-service';
import {
	getDocFromPath,
	getOrganization,
	getOrganizations,
} from '@services/organization-service';
import DatePicker from 'react-datepicker';
import { DB_ORG } from '@constants/db';
import { DB_FILTER } from '@constants/db';
import styles from './AssetTable.module.css';
import { formatDate } from '@utils/ISOdateToMMDDYYYY';
import { LeaveBehindReportPdf } from '../../SingleLocation/ActivitiesTab/children/LeaveBehindReportPdf';

const ASCENDING = 'asc';
const DESCENDING = 'desc';

const AssetsTable = (props) => {
	const {
		organization,
		browserLocationId,
		isFocused,
		leaveBehindReportInfo,
		setToast,
	} = props;

	const location = useLocation();
	let displayAddress = true;
	if (browserLocationId) displayAddress = false;
	const userId = auth.currentUser.uid;

	// Keep track of total assets of these states
	const activeCollectionCount = useRef(0);
	const decommissionedCollectionCount = useRef(0);
	const allCollectionCount = useRef(0);

	const [firstIndex, setFirstIndex] = useState();
	const [lastIndex, setLastIndex] = useState();
	const [prevFirstIndexes, setPrevFirstIndexes] = useState([]);
	const [limitRows] = useState(10);

	// Asset type categories state for SC export
	// Note - it will be an object, not an array
	const [assetCategories, setAssetCategories] = useState();
	const [scExportOptions, setSCExportOptions] = useState([]);

	// Loading state
	const [isLoading, setIsLoading] = useState(false);

	// Search value state
	const [searchVal, setSearchVal] = useState(
		location?.state?.searchVal ? location?.state?.searchVal : ''
	);
	// const [prevSearchVal, setPrevSearchVal] = useState('');

	// Search input value
	const [searchText, setSearchText] = useState(
		location?.state?.searchVal ? location?.state?.searchVal : ''
	);

	const [dateRangeFilter, setDateRangeFilter] = useState([null, null]);

	// Tracks whether all checkboxes are selected or not
	const [allChecked, setAllChecked] = useState(false);

	// Checked assets state
	const [checked, setChecked] = useState([]);

	// Shown assets state
	const [shownAssets, setShownAssets] = useState();
	const [activeAssetsFirstPage, setActiveAssetsFirstPage] = useState();
	const [decommissionedAssetsFirstPage, setDecommissionedAssetsFirstPage] =
		useState();
	const [allAssetsFirstPage, setAllAssetsFirstPage] = useState();

	// Pagination tracking number state
	const [pageNum, setPageNum] = useState(
		location?.state?.pageNum ? location?.state?.pageNum : 1
	);

	// Sort by (which column) state
	const [sortBy, setSortBy] = useState(
		location?.state?.sortBy
			? location?.state?.sortBy
			: DB_FILTER.ASSET_SUBMITTED_DATE
	);

	// Sort ascending (vs descending) state
	// const [sortAsc, setSortAsc] = useState(false);
	const [sortOrder, setSortOrder] = useState(
		location?.state?.sortOrder ? location?.state?.sortOrder : DESCENDING
	);

	// Helper state to trigger re-rendering when assets changes
	const [assetsChange, setAssetsChange] = useState(false);

	// Assets table component state
	const [assetsTable, setAssetsTable] = useState();

	// Filter component state
	const [filter, setFilter] = useState();

	// Determines whether the filter menu is shown
	const [showFilter, setShowFilter] = useState(false);

	// Determines which columns are shown
	const [columnsShown, setColumnsShown] = useState();

	// Tracks whether columnsShown state changes (for useEffect's sake)
	const [columnsShownChanges, setColumnsShownChanges] = useState(false);

	// Determines whether the delete confirmation is shown
	const [showDeleteModal, setShowDeleteModal] = useState(false);

	const [showSCExportModal, setShowSCExportModal] = useState(false);

	// eslint-disable-next-line no-unused-vars
	// const [totalAssetsCount, setTotalAssetsCount] = useState(0);

	const [isDesktop, setDesktop] = useState(window.innerWidth > 768);

	const [active, setActive] = useState('active');
	const [activeChanges, setActiveChanges] = useState(false);

	const [flags, setFlags] = useState();

	const getOrgFlags = async () => {
		const orgSnap = await getOrganization(leaveBehindReportInfo.org);
		setFlags(orgSnap.data());
	};

	useEffect(() => {
		getOrgFlags();
	}, []);

	const updateMedia = () => {
		setDesktop(window.innerWidth > 768);
	};

	useEffect(() => {
		window.addEventListener('resize', updateMedia);
		return () => window.removeEventListener('resize', updateMedia);
	});

	const navigate = useNavigate();

	const navigateToAssetProfile = (id, locationId, location) => {
		const tableOptions = {
			searchVal,
			sortBy,
			sortOrder,
			pageNum,
		};
		if (locationId) {
			return navigate(`/locations/${locationId}/assets/${id}`, {
				state: { data: location, ...tableOptions },
			});
		}
		navigate(`/admin/assets/${id}`, {
			state: { ...tableOptions },
		});
	};

	const debounce = (func) => {
		let timer;
		return function (...args) {
			const context = this;
			if (timer) clearTimeout(timer);
			timer = setTimeout(() => {
				timer = null;
				func.apply(context, args);
			}, 1000);
		};
	};

	const displaySortDirection = (column) => {
		if (sortBy !== column) return;
		return sortOrder === DESCENDING ? '↓' : '↑';
	};

	// Toggles showFilter state
	const toggleFilter = () => setShowFilter(!showFilter);

	const getHealthColor = (data) => {
		if (data >= 75) {
			return 'greenHealth';
		} else if (data >= 25 && data < 75) {
			return 'yellowHealth';
		} else {
			return 'redHealth';
		}
	};

	// Update checked attribute for given asset
	const updateCheck = async (index, check) => {
		let newChecked = [...checked];
		const newAssets = shownAssets;
		const newVal = newAssets[index].ref;
		if (check) newChecked.push(newVal);
		else newChecked = newChecked.filter((item) => item.id != newVal.id);
		await setAllChecked(
			newChecked.length ==
				(active == 'active'
					? activeCollectionCount.current
					: active == 'decommissioned'
					? decommissionedCollectionCount.current
					: allCollectionCount.current)
		);
		await setChecked(newChecked);
		setAssetsChange(true);
	};

	// Deletes checked off assets from front-end and database
	const deleteAssets = async () => {
		for (const assetRef of checked) {
			await deleteAsset(assetRef);
		}
		toggleDeleteModal();
		await setAllChecked(false);
		await setChecked([]);
		if (browserLocationId)
			getAllAssetsLocation(undefined, undefined, sortBy, sortOrder);
		else getAllAssets(undefined, undefined, sortBy, sortOrder);
	};

	// eslint-disable-next-line
	const optimizedFn = useCallback(debounce(setSearchVal), []);

	// Takes results from searchAssets and appends id, data, and ref
	const appendDataToResults = async (results) => {
		let docs = [];
		for (let i = 0; i < results.length; i++) {
			if (results[i].path.includes(organization)) {
				let docSnap = await getDocFromPath(results[i].path);
				docs.push(docSnap);
			}
		}
		let newAssets = [];
		for (const asset of docs) {
			const id = asset.id;
			const data = asset.data();
			const ref = asset.ref;
			data.assetId = data.other.assetTag;
			// data.other.submittingUser = await getUserByID(
			// 	data.other.submittingUserId
			// );
			data.address = `${
				data.other.location.address || data.other.location.address1
			}, ${data.other.location.city}, ${data.other.location.state} ${
				data.other.location.zip
			}`;
			data.other.submittedDate = formatDate(data.other.submittedDate);
			data.other.organization = organization;
			data.other.locationId = data.other.location.id;
			const newAsset = { id: id, data: data, ref: ref };
			newAssets.push(newAsset);
		}
		return newAssets;
	};

	const getFirstPageAssets = async (decommissioned) => {
		let startDate;
		let endDate;
		if (dateRangeFilter[0] && dateRangeFilter[1]) {
			startDate = dateRangeFilter[0].getTime() / 1000;
			endDate = dateRangeFilter[1].getTime() / 1000;
		}
		const { results, count } = await searchAssets(
			searchVal,
			null,
			displayAddress,
			organization,
			pageNum,
			limitRows,
			sortOrder ? `${sortOrder}(${sortBy})` : null,
			browserLocationId,
			startDate,
			endDate,
			decommissioned
		);
		let newAssets = [];

		// Logic if search or page load
		// If searching, checks for count > collection count isn't wanted behavior
		// If page load, checks for count > collection count help stabilize unpredictable db queries
		if (searchVal) {
			if (results.length == 0 && decommissioned == false) {
				setActiveAssetsFirstPage([]);
				activeCollectionCount.current = 0;
			} else if (results.length == 0 && decommissioned == true) {
				setDecommissionedAssetsFirstPage([]);
				decommissionedCollectionCount.current = 0;
			} else if (results.length == 0) {
				setAllAssetsFirstPage([]);
				allCollectionCount.current = 0;
			} else {
				newAssets = await appendDataToResults(results);
				if (decommissioned == false) {
					activeCollectionCount.current = count;
					setActiveAssetsFirstPage(newAssets);
				} else if (decommissioned == true) {
					decommissionedCollectionCount.current = count;
					setDecommissionedAssetsFirstPage(newAssets);
				} else {
					allCollectionCount.current = count;
					setAllAssetsFirstPage(newAssets);
				}
			}
		} else {
			if (
				(results.length == 0 &&
					decommissioned == true &&
					count > decommissionedCollectionCount.current) ||
				(results.length == 0 &&
					decommissioned == true &&
					decommissionedCollectionCount.current == 0)
			) {
				setDecommissionedAssetsFirstPage([]);
			} else if (
				results.length == 0 &&
				count > allCollectionCount.current
			) {
				setAllAssetsFirstPage([]);
			} else {
				newAssets = await appendDataToResults(results);
				if (
					decommissioned == false &&
					count > activeCollectionCount.current
				) {
					activeCollectionCount.current = count;
					setActiveAssetsFirstPage(newAssets);
				}
				if (
					decommissioned == true &&
					count > decommissionedCollectionCount.current
				) {
					decommissionedCollectionCount.current = count;
					setDecommissionedAssetsFirstPage(newAssets);
				} else if (count > allCollectionCount.current) {
					allCollectionCount.current = count;
					setAllAssetsFirstPage(newAssets);
				}
			}
		}
	};

	// Handles search & updates assets with results
	const handleSearch = async (e = null) => {
		if (isLoading) return;
		try {
			if (e != null) {
				e.preventDefault();
			}
			const promises = [];
			if (active == 'active') {
				promises.push(
					getFirstPageAssets(true),
					getFirstPageAssets(null)
				);
			} else if (active == 'decommissioned') {
				promises.push(
					getFirstPageAssets(false),
					getFirstPageAssets(null)
				);
			} else {
				promises.push(
					getFirstPageAssets(false),
					getFirstPageAssets(true)
				);
			}
			setIsLoading(true);
			// get Unix timestamps for algolia date filter
			let startDate;
			let endDate;
			if (dateRangeFilter[0] && dateRangeFilter[1]) {
				startDate = dateRangeFilter[0].getTime();
				endDate = dateRangeFilter[1].getTime();
			}
			const searchedDecommissionStatus =
				active == 'active'
					? false
					: active == 'decommissioned'
					? true
					: null;
			const { results, count } = await searchAssets(
				searchVal,
				shownAssets,
				displayAddress,
				organization,
				pageNum,
				limitRows,
				sortOrder ? `${sortOrder}(${sortBy})` : null,
				browserLocationId,
				startDate,
				endDate,
				searchedDecommissionStatus
			);
			let newAssets = [];
			if (results.length == 0) {
				setShownAssets([]);
			} else {
				newAssets = await appendDataToResults(results);
			}

			// Logic if search or page load
			// If searching, checks for count > collection count isn't wanted behavior
			// If page load, checks for count > collection count help stabilize unpredictable db queries
			if (searchVal) {
				if (active == 'active') {
					activeCollectionCount.current = count;
					setActiveAssetsFirstPage(newAssets);
				} else if (active == 'decommissioned') {
					decommissionedCollectionCount.current = count;
					setDecommissionedAssetsFirstPage(newAssets);
				} else {
					allCollectionCount.current = count;
					setAllAssetsFirstPage(newAssets);
				}
			} else {
				if (
					active == 'active' &&
					count > activeCollectionCount.current
				) {
					activeCollectionCount.current = count;
					setActiveAssetsFirstPage(newAssets);
				} else if (
					active == 'decommissioned' &&
					count > decommissionedCollectionCount.current
				) {
					decommissionedCollectionCount.current = count;
					setDecommissionedAssetsFirstPage(newAssets);
				} else if (
					active == 'showAll' &&
					count > allCollectionCount.current
				) {
					allCollectionCount.current = count;
					setAllAssetsFirstPage(newAssets);
				}
			}
			setShownAssets(newAssets);
			await Promise.all(promises);
		} catch (error) {
			console.error(error);
		} finally {
			setIsLoading(false);
			setAssetsChange(true);
		}
	};

	// Toggles showDeleteModal state
	const toggleDeleteModal = () => setShowDeleteModal(!showDeleteModal);

	const toggleSCExportModal = () => {
		if (!assetCategories) return startExport();
		setShowSCExportModal(!showSCExportModal);
	};

	// Checks or unchecks boxes for all locations
	const checkAll = async () => {
		setAssetsChange(false);
		setIsLoading(true);
		const newChecked = [];
		if (!allChecked) {
			const orgs = [];
			if (organization === DB_ORG.DATAFLEET) {
				const orgsSnap = await getOrganizations();
				orgsSnap.forEach((org) => orgs.push(org.id));
			} else orgs.push(organization);
			for (let i = 0; i < orgs.length; i++) {
				const assetsSnap = await getAssets(orgs[i], browserLocationId);
				for (const asset of assetsSnap.docs) newChecked.push(asset.ref);
			}
		}
		await setChecked(newChecked);
		await setAllChecked(!allChecked);
		setAssetsChange(true);
	};

	// Handles when pagination buttons are clicked
	const pageBackward = () => {
		if (pageNum > 1) {
			const newPageNum = pageNum - 1;
			setPageNum(newPageNum);
		}
	};
	const pageForward = () => {
		if (
			pageNum <
			Math.ceil(
				active == 'active'
					? activeCollectionCount.current / 10
					: active == 'decommissioned'
					? decommissionedCollectionCount.current / 10
					: allCollectionCount.current / 10
			)
		) {
			const newPageNum = pageNum + 1;
			setPageNum(newPageNum);
		}
	};

	// Sort assets upon column clickdisplaySortDirection
	const sortAssets = async (column) => {
		// Change direction if same key
		let tempSortOrder = DESCENDING;
		if (column == sortBy) {
			tempSortOrder = sortOrder === DESCENDING ? ASCENDING : DESCENDING;
		}
		setSortOrder(tempSortOrder);
		await setSortBy(column);
		await setPageNum(1);
	};

	// Retrieves all assets from all locations inside the database
	const getAllAssets = async (forward, sorting, column, sortDir) => {
		const orgs =
			organization === DB_ORG.DATAFLEET
				? (await getOrganizations()).docs.map((org) => org.id)
				: [organization];
		const newAssets = [];
		setIsLoading(true);
		setAssetsChange(false);
		for (const [index, organization] of orgs.entries()) {
			let assetsSnap;
			let queryOptions = {
				organization,
				location: null,
				datesFilter: !dateRangeFilter.includes(null) && dateRangeFilter,
				orderByFields: [column, sortDir],
				pageSize: limitRows,
			};
			if (shownAssets && (forward != undefined || sorting != undefined)) {
				if (forward) {
					const newIndexes = prevFirstIndexes;
					newIndexes.push(firstIndex);
					setPrevFirstIndexes(newIndexes);
					assetsSnap = await getAssetsWithPagination({
						...queryOptions,
						after: lastIndex,
					});
				} else if (forward == false) {
					assetsSnap = await getAssetsWithPagination({
						...queryOptions,
						after: null,
						at: prevFirstIndexes.at(-1),
						before: firstIndex,
					});
					const newIndexes = prevFirstIndexes;
					newIndexes.pop();
					setPrevFirstIndexes(newIndexes);
				} else if (sorting) {
					assetsSnap = await getAssetsWithPagination({
						...queryOptions,
					});
					setPrevFirstIndexes([]);
				}
			} else {
				assetsSnap = await getAssetsWithPagination({
					...queryOptions,
				});
			}

			if (index == 0) setFirstIndex(assetsSnap.docs[0]);
			setLastIndex(assetsSnap.docs[assetsSnap.docs.length - 1]);

			if (!assetsSnap.empty) {
				for (const asset of assetsSnap.docs) {
					const id = asset.id;
					const data = asset.data();
					const ref = asset.ref;
					data.assetId = data.other.assetTag;
					data.address = `${
						data.other.location.address ||
						data.other.location.address1
					}, ${data.other.location.city}, ${
						data.other.location.state
					} ${data.other.location.zip}`;
					data.other.organization = asset.ref.parent.id;
					const newAsset = { id: id, data: data, ref: ref };
					newAssets.push(newAsset);
				}
			}
		}
		setShownAssets(newAssets);
		setAssetsChange(true);
	};

	// Retrieves all assets for one location inside the database
	const getAllAssetsLocation = async (forward, sorting, column, sortDir) => {
		const newAssets = [];
		setAssetsChange(false);
		setIsLoading(true);
		let queryOptions = {
			organization: organization,
			locationId: browserLocationId,
			datesFilter: !dateRangeFilter.includes(null) && dateRangeFilter,
			orderByFields: [column, sortDir],
			pageSize: limitRows,
		};

		if (shownAssets && (forward != undefined || sorting != undefined)) {
			if (forward) {
				const newIndexes = prevFirstIndexes;
				newIndexes.push(firstIndex);
				setPrevFirstIndexes(newIndexes);
				queryOptions = {
					...queryOptions,
					pageSize: limitRows,
					after: lastIndex,
				};
			} else if (forward == false) {
				queryOptions = {
					...queryOptions,
					after: null,
					at: prevFirstIndexes.at(-1),
					before: firstIndex,
				};
				const newIndexes = prevFirstIndexes;
				newIndexes.pop();
				setPrevFirstIndexes(newIndexes);
			} else if (sorting) {
				setPrevFirstIndexes([]);
			}
		}

		const assetsSnap = await getAssetsWithPagination(queryOptions);

		setFirstIndex(assetsSnap.docs[0]);
		setLastIndex(assetsSnap.docs[assetsSnap.docs.length - 1]);

		if (!assetsSnap.empty) {
			for (const asset of assetsSnap.docs) {
				const id = asset.id;
				const data = asset.data();
				const ref = asset.ref;
				data.assetId = data.other.assetTag;
				data.other.organization = asset.ref.parent.id;
				const newAsset = { id: id, data: data, ref: ref };
				newAssets.push(newAsset);
			}
		}

		setShownAssets(newAssets);
		setAssetsChange(true);
	};

	const getColsShown = async () => {
		const data = await getUser(userId);
		let dataExists = true;
		if (!data.savedFilters) dataExists = false;
		else if (!data.savedFilters.assetsTable) dataExists = false;
		if (dataExists) setColumnsShown(data.savedFilters.assetsTable);
		else
			setColumnsShown({
				address: true,
				assetType: true,
				assetId: true,
				make: true,
				model: true,
				serial: true,
				dateOfBirth: true,
				healthscore: true,
				submittedDate: true,
				warranty: true,
			});
		setColumnsShownChanges(true);
	};

	const updateFilters = async () => {
		const data = await getUser(userId);
		let savedFilters = data.savedFilters;
		if (!savedFilters) savedFilters = {};
		savedFilters.assetsTable = columnsShown;
		await updateUserFilter(userId, savedFilters);
	};

	const startExport = async (subtype = null) => {
		setShowSCExportModal(false);
		setIsLoading(true);

		const assetTypes = subtype ? assetCategories[subtype] : [null];
		const schema = getSchema(scExportOptions, subtype);
		const rows = [];
		for (const type of assetTypes) {
			const assetsSnap = await getAssets(
				organization,
				browserLocationId,
				type
			);
			const rowData = assetsSnap.docs.map((doc) =>
				toRow(doc.data(), schema)
			);
			rows.push(...rowData);
		}

		setIsLoading(false);

		await exportCsv(organization, null, rows, getHeaders(schema));
	};

	const updateAssetCategories = async () => {
		const [typesSnap, orgSnap] = await Promise.all([
			getAssetTypes(organization),
			getOrganization(organization),
		]);
		setSCExportOptions(orgSnap?.data().scExportOptions || []);

		if (!typesSnap.empty) {
			const newObj = {};
			typesSnap.forEach((type) => {
				if (!newObj[type.data().subType])
					newObj[type.data().subType] = [];
				newObj[type.data().subType].push(type.data().name);
			});
			setAssetCategories(newObj);
		}
	};

	const subtypesWithExportOptions = () => {
		if (!assetCategories) return [];

		const subtypesWithSchema = scExportOptions.map((o) => o.subtype);
		return Object.keys(assetCategories).filter((subtype) =>
			subtypesWithSchema.includes(subtype)
		);
	};

	useEffect(() => {
		if (columnsShownChanges && columnsShown && isFocused) {
			updateFilters();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [columnsShownChanges]);

	useEffect(() => {
		// Don't refresh when one date is null
		if (dateRangeFilter.filter((f) => f).length === 1) return;
		if (isFocused) {
			handleSearch();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dateRangeFilter, isFocused]);

	useEffect(() => {
		if (columnsShown && isFocused) {
			setFilter(
				<Filter
					visible={showFilter}
					columns={columnsShown}
					setColumns={setColumnsShown}
					colsChange={columnsShownChanges}
					setColsChange={setColumnsShownChanges}
					org={organization}
					displayAddress={displayAddress}
				/>
			);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [showFilter, columnsShownChanges]);

	// useEffect(() => {
	// 	if (isFocused && browserLocationId != undefined)
	// 		getAllAssetsLocation(undefined, undefined, sortBy, sortOrder);
	// 	else if (isFocused && browserLocationId == undefined)
	// 		getAllAssets(undefined, undefined, sortBy, sortOrder);
	// 	// eslint-disable-next-line react-hooks/exhaustive-deps
	// }, [collectionCount]);

	useEffect(() => {
		if (isFocused) {
			handleSearch();
			getColsShown();
			updateAssetCategories();
		} else {
			setFilter();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isFocused, searchVal, pageNum, sortOrder, sortBy]);

	// // Run search function when searchVal changes (but NOT on init render)
	// const firstUpdate = useRef(true);
	// useEffect(() => {
	// 	if (firstUpdate.current) {
	// 		firstUpdate.current = false;
	// 	} else if (assets != undefined) {
	// 		handleSearch();
	// 	}
	// 	// eslint-disable-next-line react-hooks/exhaustive-deps
	// }, [searchVal]);

	useEffect(() => {
		optimizedFn(searchText);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [searchText]);

	const updateTable = async () => {
		let newAssetsTable;
		if (shownAssets.length > 0) {
			const resRows = [];
			for (let i = 0; i < shownAssets.length; i++) {
				const asset = shownAssets[i];
				const isChecked = Boolean(
					checked.find((item) => item.id == asset.id)
				);
				const newRow = (
					<tr
						key={asset.id}
						// onClick={(e) => {
						// 	if (
						// 		!e.target.className.includes(
						// 			'asset-checkbox'
						// 		)
						// 	)
						// 		toggle(asset, i);
						// }}
						onClick={() =>
							navigateToAssetProfile(
								asset.id,
								browserLocationId,
								asset.data.other.location
							)
						}
					>
						<td>
							<input
								className='asset-checkbox'
								type='checkbox'
								checked={isChecked}
								onChange={() => {
									updateCheck(i, !isChecked);
								}}
							/>
						</td>
						{(() =>
							columnsShown.warranty ? (
								<td className='text-center'>
									<WarrantyIcon
										isWarrantyExpired={
											asset.data.isWarrantyExpired
										}
										percentWarrantyLeft={
											asset.data.percentWarrantyLeft
										}
									/>
								</td>
							) : null)()}
						{(() =>
							columnsShown.assetId ? (
								<td>{asset.data.assetId || null}</td>
							) : null)()}
						{(() =>
							columnsShown.address && displayAddress ? (
								<td>{asset.data.address}</td>
							) : null)()}
						{(() =>
							columnsShown.assetType ? (
								<td>{asset.data.assetType}</td>
							) : null)()}
						{(() =>
							columnsShown.make ? (
								<td>{asset.data.make}</td>
							) : null)()}
						{(() =>
							columnsShown.model ? (
								<td>{asset.data.model}</td>
							) : null)()}
						{(() =>
							columnsShown.serial ? (
								<td>{asset.data.serial}</td>
							) : null)()}
						{(() =>
							columnsShown.dateOfBirth ? (
								<td>{asset.data.dateOfBirth}</td>
							) : null)()}
						{(() =>
							columnsShown.submittedDate ? (
								<td>
									{formatDate(asset.data.other.submittedDate)}
								</td>
							) : null)()}
						{(() =>
							columnsShown.healthscore ? (
								<td>
									<ProgressBar
										now={asset.data.healthScore}
										max={100}
										min={0}
										variant={getHealthColor(
											asset.data.healthScore
										)}
										className={'locationHealth'}
									/>
								</td>
							) : null)()}
					</tr>
				);
				resRows.push(newRow);
			}
			newAssetsTable = (
				<CardBody>
					<Table responsive hover>
						<thead>
							<tr>
								<th>
									<input
										type='checkbox'
										onClick={checkAll}
										checked={allChecked}
									/>
								</th>
								{(() =>
									columnsShown.warranty ? (
										<th
											className={`is-bold-${
												sortBy == 'other.assetTag'
											} text-center`}
										>
											Warranty
										</th>
									) : null)()}
								{(() =>
									columnsShown.assetId ? (
										<th
											onClick={() =>
												sortAssets('other.assetTag')
											}
											className={`is-bold-${
												sortBy == 'other.assetTag'
											} text-nowrap`}
										>
											Asset ID
											{displaySortDirection(
												'other.assetTag'
											)}
										</th>
									) : null)()}
								{(() =>
									columnsShown.address && displayAddress ? (
										<th
											onClick={() =>
												sortAssets(
													'other.location.address1'
												)
											}
											className={`is-bold-${
												sortBy ==
												'other.location.address1'
											}`}
										>
											Address
											{displaySortDirection(
												'other.location.address1'
											)}
										</th>
									) : null)()}
								{(() =>
									columnsShown.assetType ? (
										<th
											onClick={() =>
												sortAssets('assetType')
											}
											className={`is-bold-${
												sortBy == 'assetType'
											}`}
										>
											Asset Type
											{displaySortDirection('assetType')}
										</th>
									) : null)()}
								{(() =>
									columnsShown.make ? (
										<th
											onClick={() =>
												sortAssets('other.manufacturer')
											}
											className={`is-bold-${
												sortBy == 'make'
											}`}
										>
											Make
											{displaySortDirection(
												'other.manufacturer'
											)}
										</th>
									) : null)()}
								{(() =>
									columnsShown.model ? (
										<th
											onClick={() =>
												sortAssets('other.modelNumber')
											}
											className={`is-bold-${
												sortBy == 'other.modelNumber'
											}`}
										>
											Model
											{displaySortDirection(
												'other.modelNumber'
											)}
										</th>
									) : null)()}
								{(() =>
									columnsShown.serial ? (
										<th
											onClick={() =>
												sortAssets('other.serialNumber')
											}
											className={`is-bold-${
												sortBy == 'other.serialNumber'
											}`}
										>
											Serial
											{displaySortDirection(
												'other.serialNumber'
											)}
										</th>
									) : null)()}
								{(() =>
									columnsShown.dateOfBirth ? (
										<th
											onClick={() =>
												sortAssets(
													'other.manufacturerDate'
												)
											}
											className={`is-bold-${
												sortBy ==
												'other.manufacturerDate'
											}`}
										>
											DOB
											{displaySortDirection(
												'other.manufacturerDate'
											)}
										</th>
									) : null)()}
								{(() =>
									columnsShown.submittedDate ? (
										<th
											onClick={() =>
												sortAssets(
													'other.submittedDate'
												)
											}
											className={`is-bold-${
												sortBy == 'submittedDate'
											}`}
										>
											Submitted
											{displaySortDirection(
												'other.submittedDate'
											)}
										</th>
									) : null)()}
								{(() =>
									columnsShown.healthscore ? (
										<th
											onClick={() =>
												sortAssets('healthScore')
											}
											className={`is-bold-${
												sortBy == 'healthScore'
											}`}
										>
											Health Score
											{displaySortDirection(
												'healthScore'
											)}
										</th>
									) : null)()}
							</tr>
						</thead>
						<tbody>{resRows}</tbody>
					</Table>
					<div className='activities-page-buttons d-flex flex-row justify-content-end mt-2'>
						<button
							className='px-2 border rounded-start fs-5'
							onClick={pageBackward}
						>
							<i className='bi bi-arrow-left-short' />
						</button>
						<div
							className='border-top border-bottom p-2 h-100'
							style={{ fontSize: '12px' }}
						>{`${pageNum} of ${Math.ceil(
							active == 'active'
								? activeCollectionCount.current / 10
								: active == 'decommissioned'
								? decommissionedCollectionCount.current / 10
								: allCollectionCount.current / 10
						)}`}</div>
						<button
							className='px-2 border rounded-end fs-5'
							onClick={pageForward}
						>
							<i className='bi bi-arrow-right-short' />
						</button>
					</div>
				</CardBody>
			);
		} else if (searchVal.length > 0) {
			// setTotalAssetsCount(0);
			newAssetsTable = (
				<CardBody>
					<div
						className='d-flex flex-column justify-content-center align-items-center w-100'
						style={{ minHeight: '200px' }}
					>
						<div>No search results. Try again.</div>
					</div>
				</CardBody>
			);
		} else {
			// setTotalAssetsCount(0);
			newAssetsTable = (
				<CardBody>
					<div
						className='d-flex flex-column justify-content-center align-items-center w-100'
						style={{ minHeight: '200px' }}
					>
						<div>No assets to display.</div>
					</div>
				</CardBody>
			);
		}
		setAssetsTable(newAssetsTable);
		// if (newAssetsTable) setIsLoading(false);
	};

	// Get first page of assets on load
	useEffect(() => {
		handleSearch();
	}, [location]);

	useEffect(() => {
		if (activeChanges) {
			let newAssets;
			if (active == 'active' && activeAssetsFirstPage != null) {
				newAssets = activeAssetsFirstPage;
			} else if (
				active == 'decommissioned' &&
				decommissionedAssetsFirstPage != null
			) {
				newAssets = decommissionedAssetsFirstPage;
			} else if (active == 'showAll' && allAssetsFirstPage != null) {
				newAssets = allAssetsFirstPage;
			} else {
				handleSearch();
			}
			setShownAssets(newAssets);
		}
		if (activeChanges) setActiveChanges(false);
	}, [activeChanges]);

	useEffect(() => {
		setAssetsChange(true);
	}, [shownAssets]);

	// Update assetsTable state when assetsChage & pageNum states change or toggled to see decommissioned assets
	useEffect(() => {
		if (
			(assetsChange || columnsShownChanges) &&
			shownAssets != undefined &&
			columnsShown != undefined &&
			isFocused
		) {
			updateTable();
		}
		if (assetsChange) setAssetsChange(false);
		if (columnsShownChanges) setColumnsShownChanges(false);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [assetsChange, columnsShownChanges]);

	return (
		<div>
			<Card className='mt-4 submissions-table'>
				<CardHeader className='p-3 d-flex flex-column flex-md-row justify-content-between'>
					<div className='d-flex flex-column flex-md-row justify-content-center align-items-center'>
						<Form onSubmit={handleSearch} className='m-2'>
							<input
								id='search'
								className='searchbar rounded-pill'
								type='text'
								placeholder='Search'
								value={searchText}
								disabled={isLoading}
								onChange={(e) => {
									setSearchText(e.target.value);
								}}
							/>
						</Form>
						<div className='m-2'>
							<DatePicker
								clearButtonClassName={
									styles['react-datepicker__close-icon']
								}
								className='searchbar rounded-pill'
								placeholderText='Time Period'
								selectsRange
								isClearable
								onChange={(update) => {
									if (update[1]) {
										update[1].setHours(23);
										update[1].setMinutes(59);
										update[1].setSeconds(59);
									}
									setDateRangeFilter(update);
								}}
								startDate={dateRangeFilter[0]}
								endDate={dateRangeFilter[1]}
							/>
						</div>
						{/* {isDesktop ? (
							<div className={'m-2 ' + styles.assetstablecounter}>
								{!isLoading && totalAssetsCount > 0 ? (
									<span
										className={
											styles.assetstablecounter_span
										}
									>
										{totalAssetsCount} Assets
									</span>
								) : null}
							</div>
						) : null} */}
						<Input
							className={styles.assetdropdown}
							type='select'
							value={active}
							onChange={(e) => {
								setActive(e.target.value);
								setActiveChanges(true);
							}}
						>
							<option value='active'>
								{activeCollectionCount.current} Active Asset
								{activeCollectionCount.current == 1 ? '' : 's'}
							</option>
							<option value='decommissioned'>
								{decommissionedCollectionCount.current}{' '}
								Decommissioned Asset
								{decommissionedCollectionCount.current == 1
									? ''
									: 's'}
							</option>
							<option value='showAll'>All Assets</option>
						</Input>
					</div>
					<div
						className='d-flex flex-row-reverse flex-md-row justify-content-between align-end align-items-center'
						style={{ marginTop: isDesktop ? '' : '1em' }}
					>
						{flags &&
							flags.showLeaveBehindReport &&
							leaveBehindReportInfo && (
								<LeaveBehindReportPdf
									address1={
										leaveBehindReportInfo.data.address1
									}
									address2={`${leaveBehindReportInfo.data.city}, ${leaveBehindReportInfo.data.state} ${leaveBehindReportInfo.data.zip}`}
									org={leaveBehindReportInfo.org}
									locationId={leaveBehindReportInfo.id}
									setToast={setToast}
								/>
							)}
						<button
							onClick={toggleSCExportModal}
							className='no-border table-btn'
							style={{ marginTop: '5px' }}
							hidden={!scExportOptions.length}
						>
							SC Export
						</button>
						<button className='filter-btn table-btn rounded-circle no-border'>
							<AiOutlineClose
								className={`filter-close ${
									showFilter && 'filter-close-expand'
								}`}
								onClick={toggleFilter}
							/>
							{!showFilter && (
								<BsThreeDotsVertical
									className='filter-dots'
									onClick={toggleFilter}
								/>
							)}
							{filter}
						</button>
						<button
							onClick={toggleDeleteModal}
							color='primary'
							className='rounded-circle no-border table-btn'
							hidden={!canDelete()}
						>
							<IoMdTrash className='larger-font-icon larger-font' />
						</button>
						{/* <Button
							color='primary'
							className='rounded-circle no-border '
							outline
							onClick={exportCsv}
						>
							<MdUpload className='larger-font-icon larger-font' />
						</Button> */}
						<button
							color='primary'
							className='rounded-circle no-border table-btn'
							hidden={!canAdd()}
						>
							<MdAdd className='larger-font-icon larger-font' />
						</button>
						{/* <Button
							color='primary'
							className='rounded-circle no-border table-btn'
							outline
						>
							<MdRefresh className='larger-font-icon larger-font' />
						</Button> */}
					</div>
				</CardHeader>
				{isLoading ? <Loader /> : assetsTable}
			</Card>
			<ConfirmDialog
				showConfirm={showDeleteModal}
				toggle={toggleDeleteModal}
				title='Confirm Deletion'
				body='Would you like to delete these assets?'
				functionality={deleteAssets}
			/>
			<SCExportDialog
				organization={organization}
				showConfirm={showSCExportModal}
				toggle={toggleSCExportModal}
				functionality={startExport}
				categories={subtypesWithExportOptions()}
			/>
		</div>
	);
};
export { AssetsTable };
