import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { auth } from './assets/services/auth-service';
import { ProtectedRoute } from './assets/services/protected-route';
import { useEffect, useState } from 'react';
import { Login } from './views/Login/Login';
import AuthContext from './assets/services/Auth';
import { Loader } from './components/layouts/SignedIn/Loader/Loader';
import { Landing } from './views/Landing/Landing';
import {
	getUserOrg,
	getUserRolePromise,
} from './assets/services/user-role-service';

const App = () => {
	useScript('https://static.matterport.com/showcase-sdk/latest.js');

	function useScript(src) {
		// Keep track of script status ("idle", "loading", "ready", "error")
		const [status, setStatus] = useState(src ? 'loading' : 'idle');
		useEffect(
			() => {
				// Allow falsy src value if waiting on other data needed for
				// constructing the script URL passed to this hook.
				if (!src) {
					setStatus('idle');
					return;
				}
				// Fetch existing script element by src
				// It may have been added by another intance of this hook
				let script = document.querySelector(`script[src="${src}"]`);
				if (!script) {
					// Create script
					script = document.createElement('script');
					script.src = src;
					script.async = true;
					script.setAttribute('data-status', 'loading');
					// Add script to document body
					document.body.appendChild(script);
					// Store status in attribute on script
					// This can be read by other instances of this hook
					const setAttributeFromEvent = (event) => {
						script.setAttribute(
							'data-status',
							event.type === 'load' ? 'ready' : 'error'
						);
					};
					script.addEventListener('load', setAttributeFromEvent);
					script.addEventListener('error', setAttributeFromEvent);
				} else {
					// Grab existing script status from attribute and set to state.
					setStatus(script.getAttribute('data-status'));
				}
				// Script event handler to update status in state
				// Note: Even if the script already exists we still need to add
				// event handlers to update the state for *this* hook instance.
				const setStateFromEvent = (event) => {
					setStatus(event.type === 'load' ? 'ready' : 'error');
				};
				// Add event listeners
				script.addEventListener('load', setStateFromEvent);
				script.addEventListener('error', setStateFromEvent);
				// Remove event listeners on cleanup
				return () => {
					if (script) {
						script.removeEventListener('load', setStateFromEvent);
						script.removeEventListener('error', setStateFromEvent);
					}
				};
			},
			[src] // Only re-run effect if script src changes
		);
		return status;
	}
	const [authInfo, setAuthInfo] = useState({ loggedIn: false });
	const [isLoading, setIsLoading] = useState(true);

	// Init browser routes state
	useEffect(() => {
		return auth.onAuthStateChanged(async (result) => {
			if (isLoading) {
				setIsLoading(false);
			}
			if (result) {
				// Set user role globally
				const org = await getUserOrg();
				const role = await getUserRolePromise();
				setAuthInfo({
					loggedIn: true,
					id: result.uid,
					displayName: result.displayName ?? '',
					emailVerified: result.emailVerified,
					org: org,
					role: role,
				});
			} else {
				setAuthInfo({ loggedIn: false });
			}
		});
	}, [isLoading, setIsLoading, setAuthInfo]);

	if (
		localStorage.getItem('loggedIn') &&
		window.localStorage.getItem('themestatemode') === 'dark'
	) {
		window.document.querySelector('html').classList.add('dark-mode');
	}
	if (authInfo.loggedIn) {
		if (!localStorage.getItem('loggedIn'))
			localStorage.setItem('loggedIn', true);
	} else {
		localStorage.removeItem('loggedIn');
	}

	return (
		<>
			<AuthContext.Provider value={{ authInfo, setAuthInfo }}>
				{isLoading ? (
					<Loader />
				) : (
					<BrowserRouter>
						<Routes>
							<Route path='*' element={<ProtectedRoute />} />
							<Route path='landing' element={<Landing />} />
							<Route path='login' element={<Login />} />
						</Routes>
					</BrowserRouter>
				)}
			</AuthContext.Provider>
		</>
	);
};

export { App };
