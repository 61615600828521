import { useEffect, useState } from 'react';
import {
	Button,
	Col,
	Form,
	FormGroup,
	Input,
	Label,
	Modal,
	ModalBody,
	ModalFooter,
	ModalHeader,
	Row,
} from 'reactstrap';
import { getUserRole } from '@assets/services/user-role-service';
const EditUserModal = (props) => {
	const [newUser, setNewUser] = useState({ role: 'user' });
	const [showing, setShowing] = useState(false);

	useEffect(() => {
		if (props.user) {
			setNewUser(props.user);
			setShowing(true);
		}
	}, [props.user]);

	const getRoleOptions = () => {
		let options = [
			{ label: 'Super User', value: 'superuser' },
			{ label: 'Admin', value: 'admin' },
			{ label: 'Standard', value: 'user' },
		];
		let optionRows = [];
		if (getUserRole() !== 'superuser') options.shift();
		for (let i of options) {
			optionRows.push(<option value={i.value}>{i.label}</option>);
		}
		return optionRows;
	};
	///////////////////////////////////////////////////////////////////////
	return (
		<div>
			<Modal isOpen={showing} className='add-user-modal'>
				<ModalHeader toggle={props.cancel}></ModalHeader>
				<ModalBody>
					<Form>
						<Row>
							<Col>
								<FormGroup floating>
									<Input
										id='username'
										defaultValue={newUser.name}
										onChange={(e) =>
											setNewUser({
												...newUser,
												name: e.target.value,
											})
										}
									/>
									<Label for='username'>Name</Label>
								</FormGroup>
							</Col>
						</Row>
						<Row>
							<Col>
								<FormGroup floating>
									<Input
										type='select'
										defaultValue={newUser.role}
										id='role'
										onChange={(e) =>
											setNewUser({
												...newUser,
												role: e.target.value,
											})
										}
									>
										{getRoleOptions()}
									</Input>
									<Label for='role'>Role</Label>
								</FormGroup>
							</Col>
						</Row>
					</Form>
				</ModalBody>
				<ModalFooter>
					<Row>
						<Col>
							<Button
								onClick={() => {
									props.cancel();
									setShowing(false);
								}}
							>
								Cancel
							</Button>
						</Col>
						<Col>
							<Button
								onClick={() => {
									props.submit(newUser);
									setShowing(false);
								}}
							>
								Submit
							</Button>
						</Col>
					</Row>
				</ModalFooter>
			</Modal>
		</div>
	);
	///////////////////////////////////////////////////////////////////////
};
export { EditUserModal };
