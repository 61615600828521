import { useState } from 'react';
import {
	Dropdown,
	DropdownToggle,
	DropdownMenu,
	DropdownItem,
} from 'reactstrap';

const OrgDropdown = ({ userRole, options, org, updateUserOrg }) => {
	const [dropdownOpen, setDropdownOpen] = useState(false);

	const toggle = () => setDropdownOpen((prevState) => !prevState);
	const sortedOptions = options.sort((a, b) =>
		a.data.name.toLowerCase() > b.data.name.toLowerCase() ? 1 : -1
	);

	return (
		<Dropdown
			disabled={userRole !== 'superuser' ? true : false}
			isOpen={dropdownOpen}
			toggle={toggle}
			direction='up'
		>
			<DropdownToggle>{org}</DropdownToggle>
			<DropdownMenu>
				{sortedOptions.map((org) => (
					<DropdownItem
						key={org.id}
						onClick={() => updateUserOrg(org.id, org.data.name)}
					>
						{org.data.name}
					</DropdownItem>
				))}
			</DropdownMenu>
		</Dropdown>
	);
};

export { OrgDropdown };
