import { useEffect, useState } from 'react';
import { Card, CardHeader, Table, CardBody } from 'reactstrap';
// import { MdRefresh } from 'react-icons/md';
import { AiOutlineClose } from 'react-icons/ai';
import { BsThreeDotsVertical } from 'react-icons/bs';
import { IoMdTrash } from 'react-icons/io';
import { Filter } from '@SignedIn/views/Locations/children/Filter';
import { canDelete, canEdit } from '@assets/services/user-role-service';
import { ConfirmDialog } from '@SignedIn/views/Locations/children/ConfirmDialog';
import { SubmissionQA } from './SubmissionQA';
import { Loader } from '@SignedIn/Loader/Loader';
import { DB_ORG } from '@constants/db';
import { getQACount, getQAs, getQAsWithPagenation } from '@services/qa-service';
import {
	deleteWithRef,
	getOrganizations,
} from '@services/organization-service';

const QATable = (props) => {
	const { organization, browserLocationId, isFocused } = props;

	let displayAddress = true;
	if (browserLocationId) displayAddress = false;

	const [collectionCount, setCollectionCount] = useState();
	const [firstIndex, setFirstIndex] = useState();
	const [lastIndex, setLastIndex] = useState();
	const [prevFirstIndexes, setPrevFirstIndexes] = useState([]);
	const [limitRows] = useState(10);

	// Loader state
	const [isLoading, setIsLoading] = useState(true);

	// Search value state
	const [searchVal] = useState('');

	// Tracks whether all checkboxes are selected or not
	const [allChecked, setAllChecked] = useState(false);

	// Checked submissions state
	const [checked, setChecked] = useState([]);

	// Show data state
	const [responses, setResponses] = useState([]);

	// Pagination tracking number state
	const [pageNum, setPageNum] = useState(1);

	// Sort by (which column) state
	const [sortBy, setSortBy] = useState('submittedDate');

	// Sort ascending (vs descending) state
	const [sortAsc, setSortAsc] = useState(false);

	// Helper state to trigger re-rendering when responses changes
	const [responsesChange, setResponsesChange] = useState(false);

	// Responses table component state
	const [responsesTable, setResponsesTable] = useState();

	// Bool state for displaying individual responses
	const [showForm, setShowForm] = useState(false);

	// Individual response state (to be displayed)
	const [chosenResponse, setChosenResponse] = useState({});
	const [chosenRef, setChosenRef] = useState();

	// Determines whether the filter menu is shown
	const [showFilter, setShowFilter] = useState(false);

	// Determines which columns are shown
	const [columnsShown, setColumnsShown] = useState(() => {
		const object = {};
		object.formName = true;
		object.submittingUser = true;
		object.submittedDate = true;
		if (displayAddress) object.location = true;
		return object;
	});

	// Tracks whether columnsShown state changes (for useEffect's sake)
	const [columnsShownChanges, setColumnsShownChanges] = useState(false);

	// Determines whether the delete confirmation is shown
	const [showDeleteModal, setShowDeleteModal] = useState(false);

	// Toggles showFilter state
	const toggleFilter = () => setShowFilter(!showFilter);

	const toggle = async (submission = null, searchIndex = null) => {
		if (submission != undefined && searchIndex != null) {
			setChosenRef(submission.ref);
			setChosenResponse(submission.data);
		}
		if (showForm == true) {
			await setShowForm(false);
			setResponsesChange(true);
		} else {
			setShowForm(true);
		}
	};

	// Update checked attribute for given location
	const updateCheck = async (index, check) => {
		let newChecked = [...checked];
		const newResponses = responses;
		const newVal = newResponses[index].ref;
		if (check) newChecked.push(newVal);
		else newChecked = newChecked.filter((item) => item.id != newVal.id);
		await setAllChecked(newChecked.length == collectionCount);
		await setChecked(newChecked);
		setResponsesChange(true);
	};

	// Deletes checked off QA submissions from front-end and database
	const deleteResponses = async () => {
		for (const responseRef of checked) {
			await deleteWithRef(responseRef);
		}
		toggleDeleteModal();
		await setAllChecked(false);
		await setChecked([]);
		updateResponsesData();
	};

	// Toggles showDeleteModal state
	const toggleDeleteModal = () => setShowDeleteModal(!showDeleteModal);

	// Checks or unchecks boxes for QA responses
	const checkAll = async () => {
		setIsLoading(true);
		const newChecked = [];
		if (!browserLocationId && !allChecked) {
			const orgs = [];
			if (organization === DB_ORG.DATAFLEET) {
				// Our org, so get all orgs' qa submissions
				const orgsSnapshot = await getOrganizations();
				for (const org of orgsSnapshot.docs) {
					if (org.id == DB_ORG.URBANPLATES) continue;
					else orgs.push(org.id);
				}
			} else {
				orgs.push(organization);
			}
			for (const org of organization) {
				const responsesSnap = await getQAs(org);
				for (const response of responsesSnap.docs) {
					newChecked.push(response.ref);
				}
			}
		} else if (!allChecked) {
			const responsesSnap = await getQAs(organization, browserLocationId);
			for (const response of responsesSnap.docs) {
				newChecked.push(response.ref);
			}
		}
		await setChecked(newChecked);
		await setAllChecked(!allChecked);
		setResponsesChange(true);
	};
	// Handles when pagination buttons are clicked
	const pageBackward = () => {
		if (pageNum > 1) {
			let sortDir;
			if (sortAsc) sortDir = 'asc';
			else sortDir = 'desc';
			const newPageNum = pageNum - 1;
			setPageNum(newPageNum);
			getAllResponses(false, undefined, sortBy, sortDir);
		}
	};
	const pageForward = () => {
		if (pageNum < Math.ceil(collectionCount / limitRows)) {
			let sortDir;
			if (sortAsc) sortDir = 'asc';
			else sortDir = 'desc';
			const newPageNum = pageNum + 1;
			setPageNum(newPageNum);
			getAllResponses(true, undefined, sortBy, sortDir);
		}
	};

	// Sort responses upon column click
	const sortResponses = async (column) => {
		let isAscending = true;
		// Change direction if same key
		if (column == sortBy) isAscending = !sortAsc;
		let sortDir;
		if (isAscending) sortDir = 'asc';
		else sortDir = 'desc';
		await setSortBy(column);
		await setSortAsc(isAscending);
		await setPageNum(1);
		getAllResponses(undefined, true, column, sortDir);
	};

	const getCollectionCount = async () => {
		if (organization === DB_ORG.DATAFLEET) {
			// Need to revisit when we have a solution
			return;
		}
		const count = await getQACount(organization, browserLocationId);
		setCollectionCount(count);
	};

	// Retrieves QA submissions, within a certain range
	const getAllResponses = async (forward, sorting, column, sortDir) => {
		setResponsesChange(false);
		setIsLoading(true);
		if (organization === DB_ORG.DATAFLEET && !browserLocationId) {
			// Need to revisit when we have a solution
			return;
		}
		let responsesSnap;
		if (responses && (forward != undefined || sorting != undefined)) {
			if (forward) {
				const newIndexes = prevFirstIndexes;
				newIndexes.push(firstIndex);
				setPrevFirstIndexes(newIndexes);
				responsesSnap = await getQAsWithPagenation(
					organization,
					browserLocationId,
					column,
					sortDir,
					limitRows,
					lastIndex
				);
			} else if (forward == false) {
				responsesSnap = await getQAsWithPagenation(
					organization,
					browserLocationId,
					column,
					sortDir,
					limitRows,
					null,
					prevFirstIndexes.at(-1),
					firstIndex
				);
				const newIndexes = prevFirstIndexes;
				newIndexes.pop();
				setPrevFirstIndexes(newIndexes);
			} else if (sorting) {
				responsesSnap = await getQAsWithPagenation(
					organization,
					browserLocationId,
					column,
					sortDir,
					limitRows
				);
				setPrevFirstIndexes([]);
			}
		} else {
			responsesSnap = await getQAsWithPagenation(
				organization,
				browserLocationId,
				column,
				sortDir,
				limitRows
			);
		}
		setFirstIndex(responsesSnap.docs[0]);
		setLastIndex(responsesSnap.docs[responsesSnap.docs.length - 1]);
		const newResponses = [];

		for (const response of responsesSnap.docs) {
			const ref = response.ref;
			const id = response.id;
			const data = response.data();
			if (data.location) {
				data.address = `${
					data.location.address || data.location.address1
				}, ${data.location.city}, ${data.location.state} ${
					data.location.zip
				}`;
			}
			data.submittedDate = formatDate(data.submittedDate);
			const newResponse = { id: id, data: data, ref: ref };
			newResponses.push(newResponse);
		}
		await setResponses(newResponses);
		setResponsesChange(true);
	};

	// Formats submission date to "YYYY-MM-DD HH:MM:SS" format
	const formatDate = (oldDate) => {
		let date = new Date(oldDate);

		let month = String(date.getMonth() + 1).padStart(2, '0');
		let day = String(date.getDate()).padStart(2, '0');
		let hours = String(date.getHours()).padStart(2, '0');
		let minutes = String(date.getMinutes()).padStart(2, '0');
		let seconds = String(date.getSeconds()).padStart(2, '0');
		let year = String(date.getFullYear());

		return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
	};

	useEffect(() => {
		if (isFocused) {
			getCollectionCount();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isFocused]);

	useEffect(() => {
		let sortDir;
		if (sortAsc) sortDir = 'asc';
		else sortDir = 'desc';
		if (isFocused) {
			getAllResponses(undefined, undefined, sortBy, sortDir);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [collectionCount]);

	useEffect(() => {
		if (isFocused) {
			getCollectionCount();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isFocused]);

	// Update responsesTable state when responsesChange & pageNum states change
	useEffect(() => {
		if (
			(responsesChange || columnsShownChanges) &&
			responses != undefined
		) {
			let newResponsesTable;
			if (responses.length > 0) {
				const responseRows = [];
				for (let i = 0; i < responses.length; i++) {
					const response = responses[i];
					if (response) {
						const isChecked = Boolean(
							checked.find((item) => item.id == response.id)
						);
						const newRow = (
							<tr
								key={response.id}
								onClick={(e) => {
									if (
										!e.target.className.includes(
											'submission-checkbox'
										) &&
										canEdit()
									)
										toggle(response, i);
								}}
							>
								<td>
									<input
										className='submission-checkbox'
										type='checkbox'
										checked={isChecked}
										onChange={() => {
											updateCheck(i, !isChecked);
										}}
									/>
								</td>
								{(() =>
									columnsShown.formName ? (
										<td>{response.data.formName}</td>
									) : null)()}
								{(() =>
									columnsShown.location && displayAddress ? (
										<td>{response.data.address}</td>
									) : null)()}
								{(() =>
									columnsShown.submittingUser ? (
										<td>
											{response.data.submittingUser ||
												null}
										</td>
									) : null)()}
								{(() =>
									columnsShown.submittedDate ? (
										<td>{response.data.submittedDate}</td>
									) : null)()}
							</tr>
						);
						responseRows.push(newRow);
					}
				}
				newResponsesTable = (
					<CardBody>
						<Table responsive hover>
							<thead>
								<tr>
									<th>
										<input
											type='checkbox'
											onClick={checkAll}
											checked={allChecked}
										/>
									</th>
									{(() =>
										columnsShown.formName ? (
											<th
												onClick={() =>
													sortResponses('formName')
												}
												className={`is-bold-${
													sortBy == 'formName'
												}`}
											>
												Form Name
												{(() => {
													if (
														sortBy == 'formName' &&
														sortAsc
													) {
														return '↑';
													} else if (
														sortBy == 'formName'
													) {
														return '↓';
													}
												})()}
											</th>
										) : null)()}
									{(() =>
										columnsShown.location &&
										displayAddress ? (
											<th
												onClick={() =>
													sortResponses(
														'location.address1'
													)
												}
												className={`is-bold-${
													sortBy ==
													'location.address1'
												}`}
											>
												Location
												{(() => {
													if (
														sortBy ==
															'location.address1' &&
														sortAsc
													) {
														return '↑';
													} else if (
														sortBy ==
														'location.address1'
													) {
														return '↓';
													}
												})()}
											</th>
										) : null)()}
									{(() =>
										columnsShown.submittingUser ? (
											<th
												onClick={() =>
													sortResponses(
														'submittingUser'
													)
												}
												className={`is-bold-${
													sortBy == 'submittingUser'
												}`}
											>
												Submitting User
												{(() => {
													if (
														sortBy ==
															'submittingUser' &&
														sortAsc
													) {
														return '↑';
													} else if (
														sortBy ==
														'submittingUser'
													) {
														return '↓';
													}
												})()}
											</th>
										) : null)()}
									{(() =>
										columnsShown.submittedDate ? (
											<th
												onClick={() =>
													sortResponses(
														'submittedDate'
													)
												}
												className={`is-bold-${
													sortBy == 'submittedDate'
												}`}
											>
												Submission Date
												{(() => {
													if (
														sortBy ==
															'submittedDate' &&
														sortAsc
													) {
														return '↑';
													} else if (
														sortBy ==
														'submittedDate'
													) {
														return '↓';
													}
												})()}
											</th>
										) : null)()}
								</tr>
							</thead>
							<tbody>{responseRows}</tbody>
						</Table>
						<div className='activities-page-buttons d-flex flex-row justify-content-end mt-2'>
							<button
								className='px-2 border rounded-start fs-5'
								onClick={pageBackward}
							>
								<i className='bi bi-arrow-left-short' />
							</button>
							<div
								className='border-top border-bottom p-2 h-100'
								style={{ fontSize: '12px' }}
							>{`${pageNum} of ${Math.ceil(
								collectionCount / limitRows
							)}`}</div>
							<button
								className='px-2 border rounded-end fs-5'
								onClick={pageForward}
							>
								<i className='bi bi-arrow-right-short' />
							</button>
						</div>
					</CardBody>
				);
			} else if (searchVal.length > 0) {
				newResponsesTable = (
					<CardBody>
						<div
							className='d-flex flex-column justify-content-center align-items-center w-100'
							style={{ minHeight: '200px' }}
						>
							<div>No search results. Try again.</div>
						</div>
					</CardBody>
				);
			} else {
				newResponsesTable = (
					<CardBody>
						<div
							className='d-flex flex-column justify-content-center align-items-center w-100'
							style={{ minHeight: '200px' }}
						>
							<div>No QA submissions to display.</div>
						</div>
					</CardBody>
				);
			}
			setResponsesTable(newResponsesTable);
			if (newResponsesTable) setIsLoading(false);
			if (responsesChange) setResponsesChange(false);
			if (columnsShownChanges) setColumnsShownChanges(false);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [responsesChange, columnsShownChanges]);

	const updateResponsesData = () => {
		let sortDir;
		if (sortAsc) sortDir = 'asc';
		else sortDir = 'desc';
		setChosenResponse({});
		setChosenRef();
		getAllResponses(undefined, undefined, sortBy, sortDir);
	};

	return showForm ? (
		<div>
			<Card className='mt-4 submissions-table no-border'>
				<SubmissionQA
					chosenResponse={chosenResponse}
					chosenRef={chosenRef}
					updateResData={updateResponsesData}
					toggleSubmission={toggle}
				/>
			</Card>
		</div>
	) : (
		<div>
			<Card className='mt-4 submissions-table'>
				<CardHeader className='p-3 d-flex flex-column flex-md-row justify-content-between'>
					<span className='m-0 title'>QA Submissions</span>
					<div className='d-flex flex-row-reverse flex-md-row justify-content-between align-end'>
						<button className='filter-btn table-btn rounded-circle no-border'>
							<AiOutlineClose
								className={`filter-close ${
									showFilter && 'filter-close-expand'
								}`}
								onClick={toggleFilter}
							/>

							{!showFilter && (
								<BsThreeDotsVertical
									className='filter-dots'
									onClick={toggleFilter}
								/>
							)}
							{isFocused ? (
								<Filter
									visible={showFilter}
									columns={columnsShown}
									setColumns={setColumnsShown}
									colsChange={columnsShownChanges}
									setColsChange={setColumnsShownChanges}
									org={organization}
								/>
							) : null}
						</button>
						<button
							onClick={toggleDeleteModal}
							color='primary'
							className='rounded-circle no-border table-btn'
							hidden={!canDelete()}
						>
							<IoMdTrash className='larger-font-icon larger-font' />
						</button>
						{/* <Button
							color='primary'
							className='rounded-circle no-border table-btn'
							outline
						>
							<MdRefresh className='larger-font-icon larger-font' />
						</Button> */}
					</div>
				</CardHeader>
				{isLoading ? <Loader /> : responsesTable}
			</Card>
			<ConfirmDialog
				showConfirm={showDeleteModal}
				toggle={toggleDeleteModal}
				title='Confirm Deletion'
				body={`Are you sure you want to delete ${checked.length} of ${collectionCount} QA'd submissions?`}
				functionality={deleteResponses}
			/>
		</div>
	);
};
export { QATable };
