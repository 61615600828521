import {
	Card,
	CardHeader,
	Input,
	Label,
	FormGroup,
	Row,
	Col,
	Button,
} from 'reactstrap';
import { useEffect, useState } from 'react';
import { doc } from 'firebase/firestore';
import { auth, db, storage } from '@assets/services/auth-service';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import ExifReader from 'exifreader';
import { canEdit } from '@assets/services/user-role-service';
import { v4 as uuid } from 'uuid';
import { ConfirmDialog } from '@SignedIn/views/Locations/children/ConfirmDialog';
import Select from 'react-select';
import { CropperModal } from '@SignedIn/views/SingleForm/CropperModal';
import { isValidUrl } from '@utils/validation';
import { EditLog } from './EditLog';
import DatePicker from 'react-datepicker';
import dayjs from 'dayjs';
import { DB_ORG, DB_PATH } from '@constants/db';
import { getForm, getFormDropdowns } from '@services/form-service';
import { getFormAssetTypes } from '@services/asset-service';
import {
	getManufacturers,
	updateWithRef,
} from '@services/organization-service';
import { getImageDetail, getResponseImages } from './submissionUtils';

const SubmissionEdit = (props) => {
	const {
		parsedResponse,
		setParsedResponse,
		setParsedResponseChanges,
		toggleEdit,
		toggleSubmission,
		updateResData,
	} = props;

	// Show update modal state
	const [showUpdate, setShowUpdate] = useState(false);
	const toggleUpdate = () => setShowUpdate(!showUpdate);

	// Show revert modal state
	const [showReset, setShowReset] = useState(false);
	const toggleReset = () => setShowReset(!showReset);

	// Updated response data state (for rendering & also updating the DB record)
	const [updatedResponse, setUpdatedResponse] = useState();

	// Helper state that tracks changes for updatedResponse
	const [updatedResponseChanges, setUpdatedResponseChanges] = useState(false);

	// Form inputs data state
	const [inputs, setInputs] = useState();

	// Helper state that tracks changes for inputs state
	const [inputsChange, setInputsChange] = useState();

	// Dropdown array of object
	const [dropdown, setDropdown] = useState();

	// Helper state that tracks changes for inputs state
	const [dropdownChange, setDropdownChange] = useState(false);

	// Bool state for determining if form inputs is nested or linear
	const [isNested, setisNested] = useState();

	// Bool state for determining if form has conditional inputs
	const [isConditional, setIsConditional] = useState();

	// Rendered data (ie, child component) state
	const [submission, setSubmission] = useState();

	// Parent component state for submission child component state
	const [submissionParent, setSubmissionParent] = useState();

	const [isCropShowing, setIsCropShowing] = useState(false);

	const [photoToBeCropped, setPhotoToBeCropped] = useState(null);

	const [selectedItem, setSelectedItem] = useState(null);

	// Converts date field between formats
	const convertDateToISOString = (date) => {
		if (date) {
			const tempDate = new Date(date).toISOString().split('T');
			return tempDate[0];
		}
	};

	// Takes nested data from form & further nests the inputs under the labels
	const nestAndFilterData = async (data) => {
		let newData = [];
		for (let page of data) {
			let key = Object.keys(page)[0];
			let newPage = {
				page: key,
				sections: [],
			};
			let pageInputs = page[key];
			// Parent object for form section
			let section;
			// Child array for section inputs
			let sectionInputs = [];
			for (let input of pageInputs) {
				// Assign first section
				if (section == null) {
					section = input;
				}
				// Add input values to section inputs IF same inputs exist in parsedResponse
				// Also check if data is old (before commit 69e0450) and parse differently
				else if (
					input.length != undefined &&
					parsedResponse.inputs == undefined
				) {
					input[1].responseTag =
						input[1].responseTag + input[0].responseTag;
					input[2].responseTag =
						input[0].responseTag + input[2].responseTag;
					// Also, check if any of the subinputs are images, then check if they need to be rotated
					for (let i = 0; i < input.length; i++) {
						let subResponse = parsedResponse[input[i].responseTag];
						if (
							input[i].type == 'image' &&
							subResponse != undefined
						) {
							const res = await fetch(subResponse);
							const blob = await res.blob();
							const arrBuff = await blob.arrayBuffer();
							const tags = ExifReader.load(arrBuff);
							input[i].exif = tags;
						}
					}
					sectionInputs = [...sectionInputs, input];
				} else if (
					input.length != undefined &&
					parsedResponse.inputs != undefined
				) {
					input[1].responseTag =
						input[1].responseTag + input[0].responseTag;
					// Add parent responseTag to each image responseTag
					input
						.filter((_input) => _input.type === 'image')
						.forEach(
							(imageInput) =>
								(imageInput.responseTag =
									input[0].responseTag +
									imageInput.responseTag)
						);

					let responseTag = input[0].responseTag;
					let response;
					if (parsedResponse.inputs)
						response = parsedResponse.inputs[responseTag];
					else response = parsedResponse[responseTag];
					// Also, check if any of the subinputs are images, then check if they need to be rotated
					if (response != undefined) {
						for (let i = 0; i < response.length; i++) {
							if (response[i].image === undefined) continue;

							const responseImages = getResponseImages(
								response[i],
								input[2]
							);

							for (const { url, responseTag } of responseImages) {
								if (!url || !responseTag) continue;

								const imageInput = input.find((_input) =>
									_input.responseTag.includes(responseTag)
								);

								const res = await fetch(url);
								const blob = await res.blob();
								const arrBuff = await blob.arrayBuffer();
								const tags = ExifReader.load(arrBuff);
								if (imageInput.exif == undefined) {
									imageInput.exif = { [i]: tags };
								} else {
									imageInput.exif[i] = tags;
								}
							}
						}
					}
					sectionInputs = [...sectionInputs, input];
				} else {
					// Add sectionInputs to section as property + add section to page.sections
					section.inputs = sectionInputs;
					newPage.sections = [...newPage.sections, section];
					// Reset section & sectionInputs for next section
					section = input;
					sectionInputs = [];
				}
			}
			// Add final section to page.sections
			section.inputs = sectionInputs;
			newPage.sections = [...newPage.sections, section];
			// Add update page to newData array
			newData = [...newData, newPage];
		}
		return newData;
	};

	// Takes linear data from form & updates as needed, plus adds conditional inputs
	const filterLinearData = async (oldInputs, organization, formId) => {
		// First, add in missing conditional inputs to end of array, before image inputs
		const condInputs = await getConditionalInputs(
			organization,
			formId,
			parsedResponse.assetType
		);
		let condIndex;
		for (let i = 0; i < oldInputs.length; i++) {
			const input = oldInputs[i];
			if (input.length == undefined) {
				if (input.type == 'image') {
					condIndex = i;
					break;
				}
			}
		}
		const oldInputsWithCond = [
			...oldInputs.slice(0, condIndex),
			...condInputs,
			...oldInputs.slice(condIndex),
		];
		let newInputs = [];
		const newDropdown = {};
		for (let input of oldInputsWithCond) {
			if (input.length == undefined) {
				let responseTag = input.responseTag;
				let response = parsedResponse[responseTag];
				if (input.type == 'image' && isValidUrl(response)) {
					const res = await fetch(response);
					const blob = await res.blob();
					const arrBuff = await blob.arrayBuffer();
					const tags = ExifReader.load(arrBuff);
					input.exif = tags;
				}
				newInputs = [...newInputs, input];
				if (input.type == 'select') {
					let key = responseTag;
					let valueArr = [];
					let collectionName = input.collection;
					if (input.options == undefined) {
						let dropdownValSnap = await getFormDropdowns(
							organization,
							formId,
							collectionName
						);
						let objArr = [];
						dropdownValSnap.forEach((option) => {
							objArr.push(option.data());
						});
						let sortedObjArr = sortNumbers(objArr, 'order');
						for (let obj of sortedObjArr) {
							valueArr.push(obj.name);
						}
						newDropdown[key] = valueArr;
					}
				}
			} else {
				for (const subInput of input) {
					let responseTag = subInput.responseTag;
					if (subInput.type == 'select') {
						let key = responseTag;
						let valueArr = [];
						let collectionName = subInput.collection;
						if (subInput.options == undefined) {
							let dropdownValSnap;
							if (collectionName == DB_PATH.MANUFACTURERS) {
								dropdownValSnap = await getManufacturers();
							} else {
								dropdownValSnap = await getFormDropdowns(
									organization,
									formId,
									collectionName
								);
							}
							let objArr = [];
							dropdownValSnap.forEach((option) => {
								objArr.push(option.data());
							});
							let sortedObjArr = sortNumbers(objArr, 'order');
							for (let obj of sortedObjArr) {
								valueArr.push(obj.name);
							}
							newDropdown[key] = valueArr;
						}
					}
				}
				newInputs = [...newInputs, ...input];
			}
		}
		await setDropdown(newDropdown);
		return newInputs;
	};

	const updateLinearData = async (assetType, oldResponse) => {
		// First, remove old conditional inputs from inputs and updatedResponse
		const oldInputs = [];
		for (const input of inputs) {
			if (input.conditional) delete oldResponse[input.responseTag];
			else oldInputs.push(input);
		}
		// Second, get new conditional inputs and add to oldInputs arr
		const condInputs = await getConditionalInputs(
			updatedResponse.organization,
			updatedResponse.formId,
			assetType
		);
		let condIndex;
		for (let i = 0; i < oldInputs.length; i++) {
			const input = oldInputs[i];
			if (input.length == undefined) {
				if (input.type == 'image') {
					condIndex = i;
					break;
				}
			}
		}
		const updatedCondInputs = [];
		let newDropdown = await JSON.parse(JSON.stringify(dropdown));
		for (let input of condInputs) {
			if (input.length == undefined) {
				let responseTag = input.responseTag;
				let response = updatedResponse[responseTag];
				if (input.type == 'image' && isValidUrl(response)) {
					const res = await fetch(response);
					const blob = await res.blob();
					const arrBuff = await blob.arrayBuffer();
					const tags = ExifReader.load(arrBuff);
					input.exif = tags;
				}
				updatedCondInputs.push(input);
				if (input.type == 'select') {
					let key = responseTag;
					let valueArr = [];
					let collectionName = input.collection;
					if (input.options == undefined) {
						let dropdownValSnap = await getFormDropdowns(
							updatedResponse.organization,
							updatedResponse.formId,
							collectionName
						);
						let objArr = [];
						dropdownValSnap.forEach((option) => {
							objArr.push(option.data());
						});
						let sortedObjArr = sortNumbers(objArr, 'order');
						for (let obj of sortedObjArr) {
							valueArr.push(obj.name);
						}
						newDropdown[key] = valueArr;
					}
				}
			} else {
				updatedCondInputs.push(input);
			}
		}
		const newInputs = [
			...oldInputs.slice(0, condIndex),
			...updatedCondInputs,
			...oldInputs.slice(condIndex),
		];
		return {
			inputs: newInputs,
			response: oldResponse,
			dropdown: newDropdown,
		};
	};

	// Retrieves conditional inputs from form's asset types
	const getConditionalInputs = async (organization, formId, assetType) => {
		let condInputs = [];
		const assetTypesQuery = await getFormAssetTypes(
			organization,
			formId,
			assetType
		);

		if (!assetTypesQuery.empty) {
			const JSONtypes = assetTypesQuery.docs[0].data().inputs || [];
			for (const JSONtype of JSONtypes) {
				const type = JSON.parse(JSONtype);
				if (type.length == undefined) {
					type.conditional = true;
					condInputs.push(type);
				} else {
					for (const item of type) {
						item.conditional = true;
						condInputs.push(item);
					}
				}
			}
		}
		return condInputs;
	};

	// Sorts numbers from least to greatest
	const sortNumbers = (array, key) => {
		return array.sort((a, b) => Number(a[key]) - Number(b[key]));
	};

	// Updates updatedResponse state for linear submission response values
	// EG, key:val pairs
	// IE, old Cushman or current AMC
	const updateNewResponse = async (key, value) => {
		setUpdatedResponseChanges(false);
		const updRes = await JSON.parse(JSON.stringify(updatedResponse));
		if (updatedResponse.assetRef != undefined)
			updRes.assetRef = updatedResponse.assetRef;
		if (updatedResponse.absorbedTicketRef != undefined)
			updRes.absorbedTicketRef = updatedResponse.absorbedTicketRef;
		if (value === '' && parsedResponse[key] == undefined) {
			delete updRes[key];
		} else {
			updRes[key] = value;
		}
		if (key == 'assetType' && isConditional) {
			const data = await updateLinearData(value, updRes);
			await setInputs(data.inputs);
			await setUpdatedResponse(data.response);
			await setDropdown(data.dropdown);
			await setInputsChange(true);
			await setDropdownChange(true);
		} else {
			await setUpdatedResponse(updRes);
		}
		setUpdatedResponseChanges(true);
	};

	// Updates updatedResponse state for nested submission reponse values
	// IE, new Cushman (response object -> inputs property (is object) -> key property (is object) -> index -> subkey:value)
	const updateNewResponseNested = async (
		key,
		index,
		subkey,
		value,
		imageIndex
	) => {
		setUpdatedResponseChanges(false);
		const updRes = await JSON.parse(JSON.stringify(updatedResponse));
		if (updatedResponse.assetRef != undefined)
			updRes.assetRef = updatedResponse.assetRef;
		if (updatedResponse.absorbedTicketRef != undefined)
			updRes.absorbedTicketRef = updatedResponse.absorbedTicketRef;
		if (value == '') {
			delete updRes.inputs[key][index][subkey];
		} else if (updRes.inputs[key] == undefined) {
			updRes.inputs[key] = [];
			updRes.inputs[key][index] = {};
			if (imageIndex === undefined)
				updRes.inputs[key][index][subkey] = value;

			if (!isNaN(imageIndex)) {
				const imageBeforeUrl = imageIndex == 0 ? value : '';
				const imageAfterUrl = imageIndex == 1 ? value : '';

				updRes.inputs[key][index][subkey] = [
					{
						responseTag: 'AssetImageBefore',
						url: imageBeforeUrl,
					},
					{
						responseTag: 'AssetImageAfter',
						url: imageAfterUrl,
					},
				];
			}
		} else {
			// Check if multi-image exists
			Array.isArray(updRes.inputs[key][index][subkey])
				? (updRes.inputs[key][index][subkey][imageIndex].url = value)
				: (updRes.inputs[key][index][subkey] = value);
		}
		await setUpdatedResponse(updRes);
		setUpdatedResponseChanges(true);
	};

	// Updates image in updatedResponse state for linear submission response values
	// EG, key:val pairs
	// IE, old Cushman or current AMC
	const uploadNewImage = async (key, file) => {
		setUpdatedResponseChanges(false);
		let reference = ref(
			storage,
			'FieldCaptures/' + new Date().toISOString() + '.png'
		);
		try {
			await uploadBytes(reference, file);
			const url = await getDownloadURL(reference);
			await updateNewResponse(key, url);
			setUpdatedResponseChanges(true);
		} catch (error) {
			return;
		}
	};

	// Updates image in updatedResponse state for nested submission reponse values
	// IE, new Cushman (response object -> inputs property (is object) -> key property (is object) -> index -> subkey:value)
	const uploadNewImageNested = async (
		key,
		index,
		subkey,
		file,
		imageIndex
	) => {
		setUpdatedResponseChanges(false);
		let reference = ref(
			storage,
			'FieldCaptures/' + new Date().toISOString() + '.png'
		);
		try {
			await uploadBytes(reference, file);
			const url = await getDownloadURL(reference);
			await updateNewResponseNested(key, index, subkey, url, imageIndex);
			setUpdatedResponseChanges(true);
		} catch (error) {
			return;
		}
	};

	// Updates response data in the db
	const updateDb = async () => {
		try {
			// First, update the form response doc
			const submissionRef = doc(
				db,
				'organizations',
				parsedResponse.organization,
				'forms',
				parsedResponse.formId,
				'responses',
				parsedResponse.submissionId
			);
			// Send alert to PM with callable gc function
			// const functions = getFunctions()
			// const submissionEditAlert = httpsCallable(functions, 'submissionEditAlert')
			// const manager = parsedResponse.location.manager
			// await submissionEditAlert({ managerName: manager, submissionId: parsedResponse.submissionId })
			// Update editing user and date and add edit log
			let editLog = parsedResponse.editLog || [];
			const edits = {};
			for (const [key, value] of Object.entries(updatedResponse)) {
				if (
					key === 'location' ||
					key === 'editLog' ||
					key === 'inputs' ||
					key == 'userLocation'
				)
					continue;
				if (parsedResponse[key] !== value) {
					edits[key] = {
						beforeEdit: parsedResponse[key] || false,
						afterEdit: value,
					};
				}
			}
			editLog.push({
				edittingUser: auth.currentUser.displayName,
				edittedDate: new Date().toISOString(),
				edits,
			});
			editLog = editLog.sort(
				(a, b) => new Date(b.edittedDate) - new Date(a.edittedDate)
			);
			await updateWithRef(submissionRef, {
				...updatedResponse,
				editLog,
			});
			if (updatedResponse.assetRef != undefined) {
				// Second, update the asset doc
				let updatedAsset = {
					responseRef: submissionRef,
					assetType: updatedResponse.assetType,
					make: updatedResponse.manufacturer || null,
					model: updatedResponse.modelNumber || null,
					serial: updatedResponse.serialNumber || null,
					dateOfBirth: updatedResponse.manufacturerDate || null,
					locationId: updatedResponse.location.id,
					other: updatedResponse,
				};
				const assetRef = updatedResponse.assetRef;
				await updateWithRef(assetRef, updatedAsset);
			}
			// Third, update the front-end
			let newResponse = JSON.parse(JSON.stringify(updatedResponse));
			if (updatedResponse.assetRef != undefined)
				newResponse.assetRef = updatedResponse.assetRef;
			if (updatedResponse.absorbedTicketRef != undefined)
				newResponse.absorbedTicketRef =
					updatedResponse.absorbedTicketRef;
			await setParsedResponse(newResponse);
			await setParsedResponseChanges(true);
			await updateResData();
			await toggleUpdate();
			toggleEdit();
		} catch (error) {
			console.log(error);
			return;
		}
	};

	// Resets updatedResponse data in the db
	const resetSubmission = async () => {
		toggleReset();
		let newObj = await JSON.parse(JSON.stringify(parsedResponse));
		if (updatedResponse.assetRef != undefined)
			newObj.assetRef = updatedResponse.assetRef;
		if (updatedResponse.absorbedTicketRef != undefined)
			newObj.absorbedTicketRef = updatedResponse.absorbedTicketRef;
		await setUpdatedResponse(newObj);
		setUpdatedResponseChanges(true);
		toggleEdit();
	};

	const getCroppedPhoto = async (data) => {
		setIsCropShowing(false);
		const res = await fetch(data);
		const blob = await res.blob();
		const file = new File([blob], 'New Image', { type: 'image/png' });
		uploadNewImage(selectedItem, file);

		setPhotoToBeCropped(null);
	};

	const getData = async () => {
		// First, set updatedResponse to parsedResponse
		let newObj = await JSON.parse(JSON.stringify(parsedResponse));
		if (parsedResponse.assetRef != undefined)
			newObj.assetRef = parsedResponse.assetRef;
		if (parsedResponse.absorbedTicketRef != undefined)
			newObj.absorbedTicketRef = parsedResponse.absorbedTicketRef;
		await setUpdatedResponse(newObj);
		let newInputs;
		// DB calls to get form data
		const formId = parsedResponse.formId;
		const organization = parsedResponse.organization;
		const formSnap = await getForm(organization, formId);
		// Declare if form contains conditional inputs
		setIsConditional(formSnap.data().conditionalInputs);
		// Declare if form data is nested or linear
		const isFormNested = formSnap.data().multiplePages;
		setisNested(isFormNested);
		// If form data is nested, iterate through & modify the data a specific way before assigning to the inputs state
		if (isFormNested == true) {
			// Before parsing through the data, update the dropdown state
			const dropdownSnap = await getFormDropdowns(
				organization,
				formId,
				DB_PATH.ASSET_QUALITY
			);
			let newDropdown = [];
			dropdownSnap.forEach((option) => {
				newDropdown.push(option.data().name);
			});
			setDropdown(newDropdown);
			// Declare pages array from formSnap data
			const pages = formSnap.data().inputs.pages;
			// Iterate through the pages
			for (let x = 0; x < pages.length; x++) {
				let page = pages[x];
				let pageKeys = Object.keys(page);
				// Iterate through page keys
				for (let pageKey of pageKeys) {
					// Iterate through any value (ie, the inputs array) that doesn't have the key 'page'
					if (pageKey == 'page') {
						delete pages[x][pageKey];
					} else {
						// Remove location and date input values on first page
						if (x == 0) {
							pages[x][pageKey].splice(0, 2);
						}
						const pageInputs = page[pageKey];
						for (let y = 0; y < pageInputs.length; y++) {
							// Parse the JSON input with an object or array
							let input = JSON.parse(pageInputs[y]);
							pages[x][pageKey][y] = input;
						}
					}
				}
			}
			newInputs = await nestAndFilterData(pages, organization, formId);
			// Otherwise, if form data is linear, iterate & modify the data in a different way before assigning to the inputs state
		} else {
			let linearInputs = formSnap.data().inputs.pages[0].Details;
			for (let x = 0; x < linearInputs.length; x++) {
				let input = JSON.parse(linearInputs[x]);
				linearInputs[x] = input;
			}
			newInputs = await filterLinearData(
				linearInputs,
				organization,
				formId
			);
		}
		await setInputs(newInputs);
		setInputsChange(true);
	};

	const renderSubmission = async () => {
		let lineItems = [];
		let index = 0;
		let firstLine;
		if (parsedResponse.organization == DB_ORG.CUSHMANWAKEFIELD) {
			let checked = false;
			if (parsedResponse.reviewed == 'true') checked = true;
			firstLine = (
				<FormGroup
					key={index}
					className='p-3 d-flex align-items-center'
				>
					<Label className='fw-bolder pt-0 mb-0'>
						Report Reviewed and Approved?
					</Label>
					<Input
						className=''
						type='checkbox'
						defaultChecked={checked}
						onChange={(e) => {
							updateNewResponse(
								'reviewed',
								String(e.target.checked)
							);
						}}
					/>
				</FormGroup>
			);
			index++;
		}
		const secondLine = (
			<FormGroup key={index} className='p-3'>
				<Label className='fw-bolder pt-0'>Date of Visit</Label>
				<Input
					disabled
					type='date'
					value={convertDateToISOString(
						updatedResponse.dateOfVisit ||
							updatedResponse.submittedDate
					)}
				/>
			</FormGroup>
		);
		index++;

		let address = '';
		if (updatedResponse.location.address) {
			address = updatedResponse.location.address;
		}
		if (updatedResponse.location.address1) {
			address = address + ' ' + updatedResponse.location.address1;
		}
		if (updatedResponse.location.address2) {
			address = address + ' ' + updatedResponse.location.address2;
		}
		const thirdLine = (
			<FormGroup key={index} className='p-3'>
				<Label className='fw-bolder pt-0'>Location</Label>
				<Input
					disabled
					type='text'
					value={
						address +
						', ' +
						updatedResponse.location.city +
						', ' +
						updatedResponse.location.state +
						' ' +
						updatedResponse.location.zip
					}
				/>
			</FormGroup>
		);
		index++;

		// remove later
		let fourthLine;
		if (isNested) {
			fourthLine = (
				<FormGroup key={index} className='p-3'>
					<Label className='fw-bolder pt-0'>Summary</Label>
					<Input
						onChange={(e) =>
							updateNewResponse('summary', e.target.value)
						}
						className='bg-white'
						type='textarea'
						value={updatedResponse.summary}
					/>
				</FormGroup>
			);
		}
		index++;

		// Render multipage data
		if (isNested == true) {
			inputs.forEach((page) => {
				// Create page header
				let pageHeader = (
					<FormGroup
						key={index}
						className='pb-3 pt-4 px-3 submission-tab'
					>
						{page.page}
					</FormGroup>
				);
				lineItems = [...lineItems, pageHeader];
				index++;

				if (page.sections.length > 0) {
					for (let section of page.sections) {
						// Create section header
						let sectionHeader = (
							<FormGroup
								key={index}
								className='px-3 pt-3 pb-2 submission-section'
							>
								<Label className='submission-section-label'>
									{section.label}
								</Label>
							</FormGroup>
						);
						lineItems = [...lineItems, sectionHeader];
						index++;

						let inputs = [];
						// Check if data is old (before commit 69e0450) and render differently
						if (updatedResponse.inputs == undefined) {
							for (let input of section.inputs) {
								let columns = [];
								// Create input rows
								for (let subInput of input) {
									let col;
									let formKey = subInput.responseTag;
									let response = updatedResponse[formKey];
									// Cushman specific code for Asset Quality and Asset Image:
									// If submission response is blank or defined, assign new value
									if (response == undefined) response = '';
									let label = subInput.label;
									let isImage = isValidUrl(response);
									if (isImage) {
										col = (
											<Col xs='12' lg='6'>
												<FormGroup
													key={index}
													className='p-3 d-flex flex-column'
												>
													<Label className='submission-label fw-bold'>
														{label}
													</Label>
													<div className='position-relative submitted-image-parent'>
														<img
															className='submitted-image'
															alt=''
															src={response}
														/>
														<Input
															className='position-absolute top-0 h-100 opacity-0'
															type='file'
															accept='image/*'
															onChange={(e) =>
																uploadNewImage(
																	formKey,
																	e.target
																		.files[0]
																)
															}
														/>
														<span className='button-array'>
															<Button
																onClick={() => {
																	setSelectedItem(
																		formKey
																	);
																	setPhotoToBeCropped(
																		response
																	);
																}}
															>
																Edit
															</Button>
															<label
																className='image-btn'
																htmlFor={
																	index +
																	'image'
																}
															>
																<span className='btn'>
																	Replace
																</span>
															</label>
															<Button
																color='danger'
																onClick={async () => {
																	await updateNewResponse(
																		formKey,
																		''
																	);
																}}
															>
																Remove
															</Button>
														</span>
													</div>
												</FormGroup>
											</Col>
										);
									} else if (formKey.includes('AssetImage')) {
										col = (
											<Col xs='12' lg='6'>
												<FormGroup
													key={index}
													className='p-3'
												>
													<Label className='submission-label fw-bold'>
														{label}
													</Label>
													<div className='position-relative'>
														<Input
															disabled
															className='bg-white'
															type='text'
															value={response}
														/>
														<Input
															className='position-absolute top-0 h-100 opacity-0'
															type='file'
															accept='image/*'
															onChange={(e) =>
																uploadNewImage(
																	formKey,
																	e.target
																		.files[0]
																)
															}
														/>
													</div>
												</FormGroup>
											</Col>
										);
									} else if (
										formKey.includes('assetQuality')
									) {
										col = (
											<Col xs='12' lg='6'>
												<FormGroup
													key={index}
													className='p-3'
												>
													<Label className='submission-label fw-bold'>
														{label}
													</Label>
													<Input
														className='bg-white'
														type='select'
														onChange={(e) => {
															updateNewResponse(
																formKey,
																e.target.value
															);
														}}
													>
														<option
															value=''
															key={uuid()}
															selected={
																response == ''
															}
															disabled
														/>
														{dropdown.map(
															(option) => {
																return (
																	<option
																		value={
																			option
																		}
																		key={uuid()}
																		selected={
																			response ==
																			option
																		}
																	>
																		{option}
																	</option>
																);
															}
														)}
													</Input>
												</FormGroup>
											</Col>
										);
									} else {
										col = (
											<Col xs='12' lg='6'>
												<FormGroup
													key={index}
													className='p-3'
												>
													<Label className='submission-label fw-bold'>
														{label}
													</Label>
													<Input
														className='bg-white'
														type='text'
														defaultValue={response}
														onChange={(e) => {
															updateNewResponse(
																formKey,
																e.target.value
															);
														}}
													/>
												</FormGroup>
											</Col>
										);
									}
									columns = [...columns, col];
									index++;
								}
								let newInput = <Row>{columns}</Row>;
								inputs = [...inputs, newInput];
							}
						} else {
							for (let input of section.inputs) {
								// Create input rows
								// NOTE: responseTag is the key for updateNewResponseNested
								let responseTag = input[0].responseTag;
								let responseArray =
									updatedResponse.inputs[responseTag];
								let responseLength = 1;
								const resExists = responseArray != undefined;
								if (resExists)
									responseLength = responseArray.length;
								// NOTE: i is the index for updateNewResponseNested
								for (let i = 0; i < responseLength; i++) {
									let cols = [];
									for (let subInput of input) {
										let formKey = subInput.responseTag;
										let response = '';
										let imageDetail = {
											index: null,
											url: null,
										};
										let col;
										let subKey;
										// NOTE: either value, image, or quality is the subKey for updateNewResponseNested
										// Cushman specific code for Asset Quality and Asset Image:
										// If submission response is blank or undefined, assign new value
										if (formKey.includes('assetQuality')) {
											subKey = 'quality';
											if (resExists)
												response =
													responseArray[i].quality ||
													'';
										} else if (
											formKey.includes('AssetImage')
										) {
											subKey = 'image';
											if (resExists)
												response =
													responseArray[i].image ||
													'';

											imageDetail = getImageDetail(
												response,
												input,
												subInput
											);

											if (!imageDetail) continue;

											imageDetail = {
												index: imageDetail.index,
												url:
													isValidUrl(
														imageDetail?.url
													) && imageDetail.url,
											};
										} else {
											subKey = 'value';
											if (resExists)
												response =
													responseArray[i].value ||
													'';
										}
										let label = subInput.label;
										if (imageDetail?.url) {
											col = (
												<Col xs='12' lg='6'>
													<FormGroup
														key={index}
														className='p-3 d-flex flex-column'
													>
														<Label className='submission-label fw-bold'>
															{label}
														</Label>
														<div className='position-relative submitted-image-parent'>
															<img
																className='submitted-image'
																alt=''
																src={
																	imageDetail.url
																}
															/>
															<Input
																className='position-absolute top-0 h-100 opacity-0'
																type='file'
																accept='image/*'
																onChange={(e) =>
																	uploadNewImageNested(
																		responseTag,
																		i,
																		subKey,
																		e.target
																			.files[0],
																		imageDetail.index
																	)
																}
															/>
															<span className='button-array'>
																<Button
																	onClick={() => {
																		setSelectedItem(
																			formKey
																		);
																		setPhotoToBeCropped(
																			response
																		);
																	}}
																>
																	Edit
																</Button>
																<label
																	className='image-btn'
																	htmlFor={
																		index +
																		'image'
																	}
																>
																	<span className='btn'>
																		Replace
																	</span>
																</label>
																<Button
																	color='danger'
																	onClick={async () => {
																		await updateNewResponse(
																			formKey,
																			''
																		);
																	}}
																>
																	Remove
																</Button>
															</span>
														</div>
													</FormGroup>
												</Col>
											);
										} else if (
											formKey.includes('AssetImage')
										) {
											col = (
												<Col xs='12' lg='6'>
													<FormGroup
														key={index}
														className='p-3'
													>
														<Label className='submission-label fw-bold'>
															{label}
														</Label>
														<div className='position-relative'>
															<Input
																disabled
																className='bg-white'
																type='text'
																value='No Image'
															/>
															<Input
																className='position-absolute top-0 h-100 opacity-0'
																type='file'
																accept='image/*'
																onChange={(e) =>
																	uploadNewImageNested(
																		responseTag,
																		i,
																		subKey,
																		e.target
																			.files[0],
																		imageDetail.index
																	)
																}
															/>
														</div>
													</FormGroup>
												</Col>
											);
										} else if (
											formKey.includes('assetQuality')
										) {
											col = (
												<Col xs='12' lg='6'>
													<FormGroup
														key={index}
														className='p-3'
													>
														<Label className='submission-label fw-bold'>
															{label}
														</Label>
														<Input
															className='bg-white'
															type='select'
															onChange={(e) => {
																updateNewResponseNested(
																	responseTag,
																	i,
																	subKey,
																	e.target
																		.value
																);
															}}
														>
															<option
																value=''
																key={uuid()}
																selected={
																	response ===
																	''
																}
																disabled
															/>
															{dropdown.map(
																(option) => {
																	return (
																		<option
																			value={
																				option
																			}
																			selected={
																				response ===
																				option
																			}
																			key={uuid()}
																		>
																			{
																				option
																			}
																		</option>
																	);
																}
															)}
														</Input>
													</FormGroup>
												</Col>
											);
										} else {
											col = (
												<Col xs='12' lg='6'>
													<FormGroup
														key={index}
														className='p-3'
													>
														<Label className='submission-label fw-bold'>
															{label}
														</Label>
														<Input
															className='bg-white'
															type='text'
															defaultValue={
																response
															}
															onChange={(e) => {
																updateNewResponseNested(
																	responseTag,
																	i,
																	subKey,
																	e.target
																		.value
																);
															}}
														/>
													</FormGroup>
												</Col>
											);
										}
										index++;
										cols = [...cols, col];
									}
									const newInput = <Row>{cols}</Row>;
									inputs = [...inputs, newInput];
								}
							}
						}
						lineItems = [...lineItems, inputs];
					}
				}
				// Add generic message if page wasn't filled out
			});
		}
		// Render linear data
		else {
			for (let input of inputs) {
				if (
					input.label == 'Required Details' ||
					input.label == 'Specific Details'
				) {
					continue;
				}
				if (input.responseTag == 'location') {
					continue;
				}
				let formKey = input.responseTag;
				if (formKey == 'assetQuality') {
					formKey = formKey + input[0].responseTag;
				}
				let label = input.label;
				let response = updatedResponse[formKey];

				let newRow;
				if (isValidUrl(response)) {
					newRow = (
						<FormGroup
							key={index}
							className='p-3 d-flex flex-column'
						>
							<Label className='submission-label'>{label}</Label>
							<div className='position-relative submitted-image-parent'>
								<img
									className='submitted-image'
									alt=''
									src={response}
								/>
								<Input
									className='position-absolute top-0 h-100 opacity-0'
									type='file'
									id={index + 'image'}
									accept='image/*'
									onChange={(e) => {
										uploadNewImage(
											formKey,
											e.target.files[0]
										);
									}}
								/>
								<span className='button-array'>
									<Button
										onClick={() => {
											setSelectedItem(formKey);
											setPhotoToBeCropped(response);
										}}
									>
										Edit
									</Button>
									<label
										className='image-btn'
										htmlFor={index + 'image'}
									>
										<span className='btn'>Replace</span>
									</label>
									<Button
										color='danger'
										onClick={async () => {
											await updateNewResponse(
												formKey,
												''
											);
										}}
									>
										Remove
									</Button>
								</span>
							</div>
						</FormGroup>
					);
				} else if (input.type == 'image') {
					newRow = (
						<FormGroup
							key={index}
							className='p-3 d-flex flex-column'
						>
							<Label className='submission-label'>{label}</Label>
							<div className='position-relative'>
								<Input
									disabled
									className='bg-white'
									type='text'
									value={response}
								/>
								<Input
									className='position-absolute top-0 h-100 opacity-0'
									type='file'
									accept='image/*'
									onChange={(e) =>
										uploadNewImage(
											formKey,
											e.target.files[0]
										)
									}
								/>
							</div>
						</FormGroup>
					);
				} else if (input.type == 'checkbox') {
					const checked = response || false;
					newRow = (
						<FormGroup key={index} className='p-3'>
							<Label className='submission-label'>{label}</Label>
							<Input
								className=''
								type='checkbox'
								defaultChecked={checked}
								onChange={(e) => {
									updateNewResponse(
										formKey,
										e.target.checked
									);
								}}
							/>
						</FormGroup>
					);
				} else if (input.responseTag == 'manufacturer') {
					let options;
					if (input.collection != undefined) {
						options = dropdown[input.responseTag]
							.sort()
							.map((option) => {
								return { label: option, value: option };
							});
					} else {
						options = input.options.sort().map((option) => {
							return { label: option, value: option };
						});
					}
					newRow = (
						<FormGroup key={index} className='p-3'>
							<Label className='submission-label'>{label}</Label>
							<Select
								options={options}
								defaultValue={{
									label: response,
									value: response,
								}}
								onChange={(e) => {
									updateNewResponse(formKey, e.value);
								}}
								isDisabled={
									input.tiedTo
										? updatedResponse[input.tiedTo] === true
										: false
								}
							/>
						</FormGroup>
					);
				} else if (input.type == 'select') {
					newRow = (
						<FormGroup key={index} className='p-3'>
							<Label className='submission-label'>{label}</Label>
							<Input
								className='bg-white'
								type='select'
								onChange={(e) => {
									updateNewResponse(formKey, e.target.value);
								}}
							>
								<option
									value=''
									key={uuid()}
									selected={response == ''}
									disabled
								/>
								{input.collection != undefined
									? dropdown[input.responseTag]
											.sort()
											.map((option) => {
												return (
													<option
														value={option}
														key={uuid()}
														selected={
															response == option
														}
													>
														{option}
													</option>
												);
											})
									: input.options.sort().map((option) => {
											return (
												<option
													value={option}
													key={uuid()}
													selected={
														response == option
													}
												>
													{option}
												</option>
											);
									  })}
							</Input>
						</FormGroup>
					);
				} else if (input.type == 'date') {
					newRow = (
						<Row>
							<FormGroup key={index} className='px-4'>
								<Label className='single-form-label'>
									{input.label}
									{input.required == 'true' ? (
										<span style={{ color: 'red' }}> *</span>
									) : (
										''
									)}
								</Label>
								<br />
								<DatePicker
									showPopperArrow={false}
									key={input.responseTag}
									onChange={(e) => {
										updateNewResponse(
											formKey,
											dayjs(e).format('MM/DD/YYYY')
										);
									}}
									popperPlacement='top-start'
									className='form-inputs form-control'
									disabled={
										updatedResponse[input.tiedTo] == true ||
										updatedResponse[input.tiedTo] == 'true'
									}
									selected={
										updatedResponse[input.responseTag]
											? new Date(
													updatedResponse[
														input.responseTag
													]
											  )
											: null
									}
									placeholderText={input.placeholder}
									minDate={
										input.futureDateOnly ? new Date() : null
									}
									maxDate={
										input.futureDateOnly ? null : new Date()
									}
								/>
							</FormGroup>
						</Row>
					);
				} else if (input.type == 'switch') {
					newRow = (
						<Row>
							<FormGroup key={index}>
								<Label className='single-form-label'>
									{input.label}
								</Label>
								<br />
								<div className='toggle'>
									<input
										key={index}
										type='checkbox'
										onChange={() => {
											if (
												updatedResponse[
													input.responseTag
												] == 'true'
											) {
												updateNewResponse(
													formKey,
													'false'
												);
											} else {
												updateNewResponse(
													formKey,
													'true'
												);
											}
										}}
										className='toggle-checkbox'
									/>
									<div className='toggle-switch'>
										<span className='toggle-on-text'>
											ON
										</span>
										<span className='toggle-off-text'>
											OFF
										</span>
									</div>
									<div className='toggle-label'>
										{!updatedResponse[input.responseTag]
											? ''
											: updatedResponse[
													input.responseTag
											  ] == 'false'
											? 'No'
											: 'Yes'}
									</div>
								</div>
							</FormGroup>
						</Row>
					);
				} else {
					newRow = (
						<FormGroup
							key={index}
							className='p-3'
							hidden={
								(input.responseTag == 'ifOther' &&
									updatedResponse['assetType'] !== 'Other') ||
								(input.responseTag == 'ifOtherManufacturer' &&
									(updatedResponse['manufacturer'] !==
										'(other)' ||
										updatedResponse['makeNotAvailable'] ==
											true))
							}
						>
							<Label className='submission-label'>{label}</Label>
							<Input
								className='bg-white'
								type='text'
								defaultValue={response}
								disabled={
									updatedResponse[input.tiedTo] == true ||
									updatedResponse[input.tiedTo] == 'true'
								}
								onChange={(e) => {
									updateNewResponse(formKey, e.target.value);
								}}
							/>
						</FormGroup>
					);
				}
				lineItems = [...lineItems, newRow];
				index++;
			}
		}

		lineItems = await Promise.all(lineItems);
		const component = (
			<div id='activities-tab-form-submission'>
				{firstLine}
				{secondLine}
				{thirdLine}
				{/* remove later */}
				{fourthLine}
				{lineItems}
			</div>
		);
		setSubmission(component);
	};

	useEffect(() => {
		if (photoToBeCropped) setIsCropShowing(true);
	}, [photoToBeCropped]);

	// Update inputs and updatedResponse states when parsedResponse state changes
	useEffect(() => {
		if (parsedResponse != null) {
			getData();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [parsedResponse]);

	// Update submission state when inputs or dropdown state is initialized
	useEffect(() => {
		if (inputs != null && updatedResponse != null && inputsChange) {
			renderSubmission();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [inputs, dropdown]);

	// Update submission state when inputs or dropdown state changes
	useEffect(() => {
		if (
			inputs != null &&
			updatedResponse != null &&
			(inputsChange || dropdownChange)
		) {
			renderSubmission();
			if (inputsChange) setInputsChange(false);
			if (dropdownChange) setDropdownChange(false);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [inputsChange, dropdownChange]);

	// Update submission state when updatedResponseChanges state changes
	useEffect(() => {
		if (
			inputs != null &&
			updatedResponse != null &&
			updatedResponseChanges
		) {
			renderSubmission();
			setUpdatedResponseChanges(false);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [updatedResponseChanges]);

	// Update submissionParent state when submission state changes
	useEffect(() => {
		if (submission != null) {
			setSubmissionParent(
				<Card className='p-3' id='submission'>
					<div className='submission-header d-flex flex-row align-items-center justify-content-between'>
						{(() => {
							if (toggleSubmission != null) {
								return (
									<div
										className='activities-page-header-buttons d-flex p-1 toggleSubmissionwidth-fit-content'
										onClick={toggleReset}
									>
										<i className='bi bi-arrow-left me-3'></i>
										<div>Return Without Saving Changes</div>
									</div>
								);
							} else {
								return <div />;
							}
						})()}
						{(() => {
							if (toggleSubmission != null)
								return (
									<div className='d-flex flex-row'>
										<Button
											color='success'
											disabled={!canEdit()}
											onClick={toggleUpdate}
										>
											Save Changes
										</Button>
									</div>
								);
						})()}
					</div>
					{(() => {
						if (toggleSubmission != null)
							return (
								<img
									src={parsedResponse.logo}
									className='submission-logo'
									alt='logo'
								/>
							);
					})()}
					<CardHeader
						className='d-flex flex-row align-items-center justify-content-between'
						style={{ backgroundColor: '#ffffff', border: 'none' }}
					>
						{(() => {
							if (toggleSubmission == null) {
								return (
									<div>
										<div className='submission-title'>
											Editing Latest{' '}
											{parsedResponse.formName}
										</div>
										<div className='d-flex flex-row'>
											<Button
												color='success'
												disabled={!canEdit()}
												onClick={toggleUpdate}
											>
												Save Changes
											</Button>
										</div>
									</div>
								);
							} else {
								return (
									<div className='submission-title'>
										Editing {parsedResponse.formName}
									</div>
								);
							}
						})()}
					</CardHeader>
					{submission}
					{parsedResponse.editLog && (
						<EditLog editLog={parsedResponse.editLog} />
					)}
					<ConfirmDialog
						showConfirm={showReset}
						toggle={toggleReset}
						title='Confirm Reset'
						body='Would you like to reset the changes made to this submission?'
						functionality={resetSubmission}
					/>
					<ConfirmDialog
						showConfirm={showUpdate}
						toggle={toggleUpdate}
						title='Confirm Update'
						body='Would you like to update this submission in the database?'
						functionality={updateDb}
					/>
					<CropperModal
						showing={isCropShowing}
						photoUrl={photoToBeCropped}
						onCrop={(data) => getCroppedPhoto(data)}
						cancel={() => {
							setIsCropShowing(false);
							setPhotoToBeCropped(null);
						}}
					/>
				</Card>
			);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [submission, showReset, showUpdate, parsedResponse, isCropShowing]);

	return submissionParent;
};

export { SubmissionEdit };
