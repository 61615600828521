import { useEffect, useState } from 'react';
import { PredSpendMenu } from './misc/PredSpendMenu';
import { AssetTypesMasterList } from './AssetTypesMasterList/AssetTypesMasterList';
import { AssetTypesOrgList } from './AssetTypesOrgList/AssetTypesOrgList';
import { MasterAssetsAllList } from './MasterAssetsAllList/MasterAssetsAllList';
import { MasterAssetsOrgList } from './MasterAssetsOrgList/MasterAssetsOrgList';

const PredictiveSpend = (props) => {
	const { isFocused, org } = props;

	// Variable to track if user is viewing asset types or master assets
	const [optionOne, setOptionOne] = useState('types');

	// Variable to track if user is viewing for assigned org or all orgs
	const [optionTwo, setOptionTwo] = useState('all');

	const renderData = () => {
		return;
	};

	// if page is focused on tab, render pred spend data
	useEffect(() => {
		if (isFocused) renderData();
	}, [isFocused]);

	return isFocused ? (
		<div className='pred-spend-parent'>
			<PredSpendMenu
				org={org}
				optionOne={optionOne}
				setOptionOne={setOptionOne}
				optionTwo={optionTwo}
				setOptionTwo={setOptionTwo}
			/>
			{optionOne == 'types' && optionTwo == 'all' ? (
				<AssetTypesMasterList />
			) : optionOne == 'types' && optionTwo == 'one' ? (
				<AssetTypesOrgList org={org} />
			) : optionOne == 'assets' && optionTwo == 'all' ? (
				<MasterAssetsAllList />
			) : optionOne == 'assets' && optionTwo == 'one' ? (
				<MasterAssetsOrgList org={org} />
			) : null}
		</div>
	) : null;
};

export { PredictiveSpend };
