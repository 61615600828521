import {
	addDoc,
	collection,
	doc,
	getDoc,
	getDocs,
	query,
	updateDoc,
	where,
} from 'firebase/firestore';
import { db } from '@assets/services/auth-service';
import { DB_FILTER, DB_PATH } from '@constants/db';

export const getSubmission = async (organization, form, id) => {
	const snap = await getDoc(
		doc(
			db,
			DB_PATH.ORGANIZATIONS,
			organization,
			DB_PATH.FORMS,
			form,
			DB_PATH.RESPONSES,
			id
		)
	);
	return snap.data();
};

export const getSubmissions = async (
	organization,
	form,
	location,
	tririgaBuildingSystemId,
	onlyApproved
) => {
	const searchParams = [where(DB_FILTER.DELETED, '==', false)];
	if (location) {
		searchParams.push(where(DB_FILTER.LOCATION_ID, '==', location));
	}
	if (tririgaBuildingSystemId) {
		searchParams.push(
			where(
				DB_FILTER.LOCATION_TRIRIGA_BUILDING_SYSTEM_ID,
				'==',
				tririgaBuildingSystemId
			)
		);
	}
	if (onlyApproved == true) {
		searchParams.push(where(DB_FILTER.APPROVED, '==', true));
	}
	const responsesSnap = await getDocs(
		query(
			collection(
				db,
				DB_PATH.ORGANIZATIONS,
				organization,
				DB_PATH.FORMS,
				form,
				DB_PATH.RESPONSES
			),
			...searchParams
		)
	);
	return responsesSnap;
};

export const getSubmissionWithTag = async (
	organization,
	form,
	tagName,
	tagValue
) => {
	const snap = await getDocs(
		query(
			collection(
				db,
				DB_PATH.ORGANIZATIONS,
				organization,
				DB_PATH.FORMS,
				form,
				DB_PATH.RESPONSES
			),
			where(DB_FILTER.DELETED, '==', false),
			where(tagName, '==', tagValue)
		)
	);
	return snap;
};

export const getSubmissionWithFilter = async (
	organization,
	formId,
	filters
) => {
	let queries = [];
	if (filters[0]?.name === 'submittedDate') {
		queries.push(where('submittedDate', '>', filters[0].value[0]));
		queries.push(where('submittedDate', '<', filters[0].value[1]));
		if (filters[filters.length - 1].name === DB_FILTER.LOCATION_ID) {
			queries.push(
				where(
					DB_FILTER.LOCATION_ID,
					'==',
					filters[filters.length - 1].value
				)
			);
		}
	} else {
		queries = filters.map((filter) => {
			return where(filter.name, '==', filter.value);
		});
	}
	const snap = await getDocs(
		query(
			collection(
				db,
				DB_PATH.ORGANIZATIONS,
				organization,
				DB_PATH.FORMS,
				formId,
				DB_PATH.RESPONSES
			),
			where(DB_FILTER.DELETED, '==', false),
			...queries
		)
	);
	return snap;
};

export const getSubmissionRef = async (organization, form, id) => {
	return doc(
		db,
		DB_PATH.ORGANIZATIONS,
		organization,
		DB_PATH.FORMS,
		form,
		DB_PATH.RESPONSES,
		id
	);
};

export const addSubmission = async (organization, formId, data) => {
	const ref = await addDoc(
		collection(
			db,
			DB_PATH.ORGANIZATIONS,
			organization,
			DB_PATH.FORMS,
			formId,
			DB_PATH.RESPONSES
		),
		data
	);
	return ref;
};
export const deleteSubmission = async (submissionRef) => {
	const response = await getDoc(submissionRef);
	// First, mark asset as deleted
	const assetRef = await response.data().assetRef;
	if (assetRef) {
		await updateDoc(assetRef, { deleted: true });
	}
	// Second, mark submission as deleted
	await updateDoc(submissionRef, { deleted: true });
};
