import { Card, Table, CardBody, CardHeader } from 'reactstrap';
import { useState, useEffect } from 'react';
import { getLocationTickets } from '@services/location-service';

const LastAbsorbedTicketCard = (props) => {
	const { formId, locationId, org } = props;
	const [ticketsCard, setTicketsCard] = useState();

	const updateTicketsCard = async () => {
		const ticketsSnap = await getLocationTickets(org, locationId);
		const tickets = ticketsSnap.docs;

		let rows = [];
		for (const ticket of tickets) {
			const row = (
				<tr
					key={ticket.id}
					// onClick={(e) => {
					// 	if (
					// 		!e.target.className.includes(
					// 			'submission-checkbox'
					// 		)
					// 	)
					// 		toggle(response, i);
					// }}
				>
					<td>{ticket.data()['Po Number']}</td>
					<td>{ticket.data()['Createdate']}</td>
					<td>{ticket.data()['Completed Date']}</td>
				</tr>
			);
			rows = [...rows, row];
		}
		setTicketsCard(
			<Card className='px-5 py-2 d-flex flex-column gen-info-card-wrapper gen-tab-submission h-100'>
				<CardHeader className='absorbed-tickets-title'>
					Absorbed Tickets
				</CardHeader>
				<CardBody>
					<Table className='absorbed-tickets-table' responsive hover>
						<thead>
							<tr>
								<th>PO Number</th>
								<th>Date Created</th>
								<th>Date Completed</th>
							</tr>
						</thead>
						<tbody>{rows}</tbody>
					</Table>
				</CardBody>
			</Card>
		);
	};

	useEffect(() => {
		if (formId && locationId && org) {
			updateTicketsCard();
		}
		// eslint-disable-next-line
	}, [formId, locationId, org]);

	return ticketsCard;
};

export { LastAbsorbedTicketCard };
