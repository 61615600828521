import { Button, Col, Modal, ModalBody, ModalFooter, Row } from 'reactstrap';
import QrReader from 'react-qr-reader';

const QRModal = (props) => {
	///////////////////////////////////////////////////////////////////////

	return (
		<div>
			<Modal isOpen={props.showing}>
				<ModalBody>
					<div style={{ width: '100%' }}>
						<QrReader
							constraints={{
								facingMode: 'environment',
							}}
							onScan={(result) => {
								const regEx = /^[a-zA-Z\d\s.]+$/;
								if (result) {
									if (!regEx.test(result)) {
										alert(
											'QR code contains special characters. Please scan a valid QR code.'
										);
										return;
									}
									props.gotData(result);
									props.cancel();
								}
							}}
							style={{
								width: '100%',
								boxShadow: 'none !important',
							}}
							showViewFinder={false}
						/>
					</div>
				</ModalBody>
				<ModalFooter>
					<Row>
						<Col>
							<Button
								onClick={() => {
									props.cancel();
								}}
								className='modal-btn'
							>
								Cancel
							</Button>
						</Col>
					</Row>
				</ModalFooter>
			</Modal>
		</div>
	);
	///////////////////////////////////////////////////////////////////////
};
export { QRModal };
