import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

const DeleteAssetTypeModal = (props) => {
	const { isOpen, close, body, title, deleteType } = props;

	return (
		<div>
			<Modal isOpen={isOpen} toggle={close} centered>
				<ModalHeader toggle={close}>{title}</ModalHeader>
				<ModalBody>{body}</ModalBody>
				<ModalFooter>
					<Button className='cancel-btn' onClick={close}>
						Cancel
					</Button>
					<Button className='confirm-btn' onClick={deleteType}>
						Confirm
					</Button>
				</ModalFooter>
			</Modal>
		</div>
	);
};

export { DeleteAssetTypeModal };
