import {
	Input,
	Label,
	FormGroup,
	Row,
	Col,
	Button,
	Modal,
	ModalHeader,
	ModalBody,
	ModalFooter,
	FormFeedback,
} from 'reactstrap';
import { useEffect, useState } from 'react';
import { doc } from 'firebase/firestore';
import { auth, db, storage } from '@assets/services/auth-service';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import ExifReader from 'exifreader';
import { v4 as uuid } from 'uuid';
import { ConfirmDialog } from '@SignedIn/views/Locations/children/ConfirmDialog';
import Select from 'react-select';
import { CropperModal } from '@SignedIn/views/SingleForm/CropperModal';
import { AiOutlineEdit } from 'react-icons/ai';
import DatePicker from 'react-datepicker';
import { isValidUrl } from '@utils/validation';
import 'react-datepicker/dist/react-datepicker.css';
import { getUser } from '@services/user-service';
import {
	addAssetVersionHistory,
	getAssets,
	getFormAssetTypes,
} from '@services/asset-service';
import {
	getDocFromRef,
	getManufacturers,
	updateWithRef,
} from '@services/organization-service';
import { getForm, getFormDropdowns, getFormId } from '@services/form-service';
import { DB_FORM, DB_ORG, DB_PATH } from '@constants/db';

const AssetEdit = (props) => {
	const { parsedResponse, setAssetChange, org, assetId } = props;

	const [userRole, setUserRole] = useState();

	const [QRError, setQRError] = useState(false);

	const [modal, setModal] = useState(false);

	const getUserRole = async () => {
		const userDoc = await getUser(auth.currentUser.uid);
		setUserRole(userDoc.role);
	};

	const toggle = () => {
		setModal(!modal);
	};

	function debounce(func, timeout = 100) {
		let timer;
		return (...args) => {
			clearTimeout(timer);
			timer = setTimeout(() => {
				func.apply(this, args);
			}, timeout);
		};
	}

	const checkDuplicateQR = async (tag) => {
		const querySnapshot = await getAssets(org, null, null, tag);
		return !querySnapshot.empty;
	};

	const checkDuplicateAfterDelay = debounce(async (e) => {
		const val = e.target.value;
		const duplicateQrExists = await checkDuplicateQR(val);
		setQRError(duplicateQrExists);
	});

	// Show update modal state
	const [showUpdate, setShowUpdate] = useState(false);
	const toggleUpdate = () => setShowUpdate(!showUpdate);

	// Show revert modal state
	const [showReset, setShowReset] = useState(false);
	const toggleReset = () => setShowReset(!showReset);

	// Updated response data state (for rendering & also updating the DB record)
	const [updatedResponse, setUpdatedResponse] = useState();

	// Helper state that tracks changes for updatedResponse
	const [updatedResponseChanges, setUpdatedResponseChanges] = useState(false);

	// Form inputs data state
	const [inputs, setInputs] = useState();

	// Helper state that tracks changes for inputs state
	const [inputsChange, setInputsChange] = useState();

	// Dropdown array of object
	const [dropdown, setDropdown] = useState();

	// Helper state that tracks changes for inputs state
	const [dropdownChange, setDropdownChange] = useState(false);

	// Bool state for determining if form inputs is nested or linear
	const [isNested, setisNested] = useState();

	// Bool state for determining if form has conditional inputs
	const [isConditional, setIsConditional] = useState();

	// Rendered data (ie, child component) state
	const [submission, setSubmission] = useState();

	// Parent component state for submission child component state
	const [submissionParent, setSubmissionParent] = useState();

	const [isCropShowing, setIsCropShowing] = useState(false);

	const [photoToBeCropped, setPhotoToBeCropped] = useState(null);

	const [selectedItem, setSelectedItem] = useState(null);

	// // Converts date field between formats
	// const convertDateToISOString = (date) => {
	//   if (date) {
	//     const tempDate = new Date(date).toISOString().split('T');
	//     return tempDate[0];
	//   }
	// };

	// Takes nested data from form & further nests the inputs under the labels
	const nestAndFilterData = async (data) => {
		let newData = [];
		for (let page of data) {
			let key = Object.keys(page)[0];
			let newPage = {
				page: key,
				sections: [],
			};
			let pageInputs = page[key];
			// Parent object for form section
			let section;
			// Child array for section inputs
			let sectionInputs = [];
			for (let input of pageInputs) {
				// Assign first section
				if (section == null) {
					section = input;
				}
				// Add input values to section inputs IF same inputs exist in parsedResponse
				// Also check if data is old (before commit 69e0450) and parse differently
				else if (
					input.length != undefined &&
					parsedResponse.inputs == undefined
				) {
					input[1].responseTag =
						input[1].responseTag + input[0].responseTag;
					input[2].responseTag =
						input[0].responseTag + input[2].responseTag;
					// Also, check if any of the subinputs are images, then check if they need to be rotated
					for (let i = 0; i < input.length; i++) {
						let subResponse = parsedResponse[input[i].responseTag];
						if (
							input[i].type == 'image' &&
							subResponse != undefined
						) {
							const res = await fetch(subResponse);
							const blob = await res.blob();
							const arrBuff = await blob.arrayBuffer();
							const tags = ExifReader.load(arrBuff);
							input[i].exif = tags;
						}
					}
					sectionInputs = [...sectionInputs, input];
				} else if (
					input.length != undefined &&
					parsedResponse.inputs != undefined
				) {
					input[1].responseTag =
						input[1].responseTag + input[0].responseTag;
					input[2].responseTag =
						input[0].responseTag + input[2].responseTag;
					let responseTag = input[0].responseTag;
					let response;
					if (parsedResponse.inputs)
						response = parsedResponse.inputs[responseTag];
					else response = parsedResponse[responseTag];
					// Also, check if any of the subinputs are images, then check if they need to be rotated
					if (response != undefined) {
						for (let i = 0; i < response.length; i++) {
							let imageUrl = response[i].image;
							if (imageUrl != undefined) {
								const res = await fetch(imageUrl);
								const blob = await res.blob();
								const arrBuff = await blob.arrayBuffer();
								const tags = ExifReader.load(arrBuff);
								if (input[2].exif == undefined) {
									input[2].exif = { [i]: tags };
								} else {
									input[2].exif[i] = tags;
								}
							}
						}
					}
					sectionInputs = [...sectionInputs, input];
				} else {
					// Add sectionInputs to section as property + add section to page.sections
					section.inputs = sectionInputs;
					newPage.sections = [...newPage.sections, section];
					// Reset section & sectionInputs for next section
					section = input;
					sectionInputs = [];
				}
			}
			// Add final section to page.sections
			section.inputs = sectionInputs;
			newPage.sections = [...newPage.sections, section];
			// Add update page to newData array
			newData = [...newData, newPage];
		}
		return newData;
	};

	// Takes linear data from form & updates as needed, plus adds conditional inputs
	const filterLinearData = async (oldInputs, organization, formId) => {
		// First, add in missing conditional inputs to end of array, before image inputs
		const condInputs = await getConditionalInputs(
			organization,
			formId,
			parsedResponse.assetType
		);
		let condIndex;
		for (let i = 0; i < oldInputs.length; i++) {
			const input = oldInputs[i];
			if (input.length == undefined) {
				if (input.type == 'image') {
					condIndex = i;
					break;
				}
			}
		}
		const oldInputsWithCond = [
			...oldInputs.slice(0, condIndex),
			...condInputs,
			...oldInputs.slice(condIndex),
		];
		let newInputs = [];
		const newDropdown = {};
		for (let input of oldInputsWithCond) {
			if (input.length == undefined) {
				let responseTag = input.responseTag;
				let response = parsedResponse[responseTag];
				if (input.type == 'image' && isValidUrl(response)) {
					const res = await fetch(response);
					const blob = await res.blob();
					const arrBuff = await blob.arrayBuffer();
					const tags = ExifReader.load(arrBuff);
					input.exif = tags;
				}
				newInputs = [...newInputs, input];
				if (input.type == 'select') {
					let key = responseTag;
					let valueArr = [];
					let collectionName = input.collection;
					if (input.options == undefined) {
						let dropdownValSnap = await getFormDropdowns(
							organization,
							formId,
							collectionName
						);
						let objArr = [];
						dropdownValSnap.forEach((option) => {
							objArr.push(option.data());
						});
						let sortedObjArr = sortNumbers(objArr, 'order');
						for (let obj of sortedObjArr) {
							valueArr.push(obj.name);
						}
						newDropdown[key] = valueArr;
					}
				}
			} else {
				for (const subInput of input) {
					let responseTag = subInput.responseTag;
					if (subInput.type == 'select') {
						let key = responseTag;
						let valueArr = [];
						let collectionName = subInput.collection;
						if (subInput.options == undefined) {
							let dropdownValSnap;
							if (collectionName == 'manufacturers') {
								dropdownValSnap = await getManufacturers();
							} else {
								dropdownValSnap = await getFormDropdowns(
									organization,
									formId,
									collectionName
								);
							}
							let objArr = [];
							dropdownValSnap.forEach((option) => {
								objArr.push(option.data());
							});
							let sortedObjArr = sortNumbers(objArr, 'order');
							for (let obj of sortedObjArr) {
								valueArr.push(obj.name);
							}
							newDropdown[key] = valueArr;
						}
					}
				}
				newInputs = [...newInputs, ...input];
			}
		}
		await setDropdown(newDropdown);
		return newInputs;
	};

	const updateLinearData = async (assetType, oldResponse) => {
		// First, remove old conditional inputs from inputs and updatedResponse
		const oldInputs = [];
		for (const input of inputs) {
			if (input.conditional) delete oldResponse[input.responseTag];
			else oldInputs.push(input);
		}
		// Second, get new conditional inputs and add to oldInputs arr
		const condInputs = await getConditionalInputs(
			updatedResponse.organization,
			updatedResponse.formId,
			assetType
		);
		let condIndex;
		for (let i = 0; i < oldInputs.length; i++) {
			const input = oldInputs[i];
			if (input.length == undefined) {
				if (input.type == 'image') {
					condIndex = i;
					break;
				}
			}
		}
		const updatedCondInputs = [];
		let newDropdown = await JSON.parse(JSON.stringify(dropdown));
		for (let input of condInputs) {
			if (input.length == undefined) {
				let responseTag = input.responseTag;
				let response = updatedResponse[responseTag];
				if (input.type == 'image' && isValidUrl(response)) {
					const res = await fetch(response);
					const blob = await res.blob();
					const arrBuff = await blob.arrayBuffer();
					const tags = ExifReader.load(arrBuff);
					input.exif = tags;
				}
				updatedCondInputs.push(input);
				if (input.type == 'select') {
					let key = responseTag;
					let valueArr = [];
					let collectionName = input.collection;
					if (input.options == undefined) {
						let dropdownValSnap = await getFormDropdowns(
							updatedResponse.organization,
							updatedResponse.formId,
							collectionName
						);
						let objArr = [];
						dropdownValSnap.forEach((option) => {
							objArr.push(option.data());
						});
						let sortedObjArr = sortNumbers(objArr, 'order');
						for (let obj of sortedObjArr) {
							valueArr.push(obj.name);
						}
						newDropdown[key] = valueArr;
					}
				}
			} else {
				updatedCondInputs.push(input);
			}
		}
		const newInputs = [
			...oldInputs.slice(0, condIndex),
			...updatedCondInputs,
			...oldInputs.slice(condIndex),
		];
		return {
			inputs: newInputs,
			response: oldResponse,
			dropdown: newDropdown,
		};
	};

	// Retrieves conditional inputs from form's asset types
	const getConditionalInputs = async (organization, formId, assetType) => {
		let condInputs = [];
		const assetTypesQuery = await getFormAssetTypes(
			organization,
			formId,
			assetType
		);

		if (!assetTypesQuery.empty) {
			const JSONtypes = assetTypesQuery.docs[0].data().inputs || [];
			for (const JSONtype of JSONtypes) {
				const type = JSON.parse(JSONtype);
				if (type.length == undefined) {
					type.conditional = true;
					condInputs.push(type);
				} else {
					for (const item of type) {
						item.conditional = true;
						condInputs.push(item);
					}
				}
			}
		}
		return condInputs;
	};

	// Sorts numbers from least to greatest
	const sortNumbers = (array, key) => {
		return array.sort((a, b) => Number(a[key]) - Number(b[key]));
	};

	// Updates updatedResponse state for linear submission response values
	// EG, key:val pairs
	// IE, old Cushman or current AMC
	const updateNewResponse = async (key, value) => {
		setUpdatedResponseChanges(false);
		const updRes = await JSON.parse(JSON.stringify(updatedResponse));
		if (value === '' && parsedResponse.other[key] == undefined) {
			delete updRes[key];
		} else {
			updRes.other[key] = value;
		}
		if (key == 'assetType' && isConditional) {
			const data = await updateLinearData(value, updRes);
			await setInputs(data.inputs);
			await setUpdatedResponse(data.response);
			await setDropdown(data.dropdown);
			await setInputsChange(true);
			await setDropdownChange(true);
		} else {
			await setUpdatedResponse(updRes);
		}
		setUpdatedResponseChanges(true);
	};

	// Updates updatedResponse state for nested submission reponse values
	// IE, new Cushman (response object -> inputs property (is object) -> key property (is object) -> index -> subkey:value)
	const updateNewResponseNested = async (key, index, subkey, value) => {
		setUpdatedResponseChanges(false);
		const updRes = await JSON.parse(JSON.stringify(updatedResponse));
		if (value == '') {
			delete updRes.inputs[key][index][subkey];
		} else if (updRes.other.inputs[key] == undefined) {
			updRes.other.inputs[key] = [];
			updRes.other.inputs[key][index] = {};
			updRes.other.inputs[key][index][subkey] = value;
		} else {
			updRes.other.inputs[key][index][subkey] = value;
		}
		await setUpdatedResponse(updRes);
		setUpdatedResponseChanges(true);
	};

	// Updates image in updatedResponse state for linear submission response values
	// EG, key:val pairs
	// IE, old Cushman or current AMC
	const uploadNewImage = async (key, file) => {
		setUpdatedResponseChanges(false);
		let reference = ref(
			storage,
			'FieldCaptures/' + new Date().toISOString() + '.png'
		);
		try {
			await uploadBytes(reference, file);
			const url = await getDownloadURL(reference);
			await updateNewResponse(key, url);
			setUpdatedResponseChanges(true);
		} catch (error) {
			return;
		}
	};

	// Updates image in updatedResponse state for nested submission reponse values
	// IE, new Cushman (response object -> inputs property (is object) -> key property (is object) -> index -> subkey:value)
	const uploadNewImageNested = async (key, index, subkey, file) => {
		setUpdatedResponseChanges(false);
		let reference = ref(
			storage,
			'FieldCaptures/' + new Date().toISOString() + '.png'
		);
		try {
			await uploadBytes(reference, file);
			const url = await getDownloadURL(reference);
			await updateNewResponseNested(key, index, subkey, url);
			setUpdatedResponseChanges(true);
		} catch (error) {
			return;
		}
	};

	// Updates response data in the db
	const updateDb = async () => {
		if (QRError) {
			alert('Please enter a unique asset tag ID.');
			return;
		}
		try {
			const organization = parsedResponse.other.organization || org;

			// add a snapshot of asset to version history before updating
			const assetRef = doc(
				db,
				'organizations',
				organization,
				'assets',
				assetId
			);
			let edittingUser = 'n/a';
			if (auth.currentUser.uid) {
				const user = await getUser(auth.currentUser.uid);
				if (user) {
					edittingUser = user.name;
				} else edittingUser = auth.currentUser.uid;
			}
			const previousAssetDocSnap = await getDocFromRef(assetRef);
			const previousVersion = {
				...previousAssetDocSnap.data(),
				edittedDate: new Date().toISOString(),
				edittingUser: edittingUser,
			};
			await addAssetVersionHistory(
				organization,
				assetRef.id,
				previousVersion
			);
			// Update the asset doc, but not the submission
			let updatedAsset = {
				assetType: updatedResponse.other.assetType,
				make: updatedResponse.other.manufacturer || null,
				model: updatedResponse.other.modelNumber || null,
				serial: updatedResponse.other.serialNumber || null,
				dateOfBirth: updatedResponse.other.manufacturerDate || null,
				locationId: updatedResponse.other.location.id,
				other: updatedResponse.other,
			};
			await updateWithRef(assetRef, updatedAsset);

			let newResponse = JSON.parse(JSON.stringify(updatedResponse));
			if (updatedResponse.other.assetRef != undefined)
				newResponse.other.assetRef = updatedResponse.other.assetRef;
			await setAssetChange(true);
			toggle();
			toggleUpdate();
		} catch (error) {
			return;
		}
	};

	// Resets updatedResponse data in the db
	const resetSubmission = async () => {
		toggleReset();
		setQRError(false);
		let newObj = await JSON.parse(JSON.stringify(parsedResponse));
		if (updatedResponse.assetRef != undefined)
			newObj.assetRef = updatedResponse.assetRef;
		if (updatedResponse.absorbedTicketRef != undefined)
			newObj.absorbedTicketRef = updatedResponse.absorbedTicketRef;
		await setUpdatedResponse(newObj);
		setUpdatedResponseChanges(true);
		toggle();
	};

	const getCroppedPhoto = async (data) => {
		setIsCropShowing(false);
		const res = await fetch(data);
		const blob = await res.blob();
		const file = new File([blob], 'New Image', { type: 'image/png' });
		uploadNewImage(selectedItem, file);

		setPhotoToBeCropped(null);
	};

	const getData = async () => {
		// First, set updatedResponse to parsedResponse
		let newObj = await JSON.parse(JSON.stringify(parsedResponse));
		// if (parsedResponse.assetRef != undefined)
		//   newObj.assetRef = parsedResponse.assetRef;
		await setUpdatedResponse(newObj);
		let newInputs;
		// DB calls to get form data
		const formId =
			parsedResponse.formId || (await getFormId(org, DB_FORM.INVENTORY));
		const organization = parsedResponse.organization || org;
		const formSnap = await getForm(organization, formId);
		// Declare if form contains conditional inputs
		setIsConditional(formSnap.data().conditionalInputs);
		// Declare if form data is nested or linear
		const isFormNested = formSnap.data().multiplePages;
		setisNested(isFormNested);
		// If form data is nested, iterate through & modify the data a specific way before assigning to the inputs state
		if (isFormNested == true) {
			// Before parsing through the data, update the dropdown state
			const dropdownSnap = await getFormDropdowns(
				organization,
				formId,
				DB_PATH.ASSET_QUALITY
			);
			let newDropdown = [];
			dropdownSnap.forEach((option) => {
				newDropdown.push(option.data().name);
			});
			setDropdown(newDropdown);
			// Declare pages array from formSnap data
			const pages = formSnap.data().inputs.pages;
			// Iterate through the pages
			for (let x = 0; x < pages.length; x++) {
				let page = pages[x];
				let pageKeys = Object.keys(page);
				// Iterate through page keys
				for (let pageKey of pageKeys) {
					// Iterate through any value (ie, the inputs array) that doesn't have the key 'page'
					if (pageKey == 'page') {
						delete pages[x][pageKey];
					} else {
						// Remove location and date input values on first page
						if (x == 0) {
							pages[x][pageKey].splice(0, 2);
						}
						const pageInputs = page[pageKey];
						for (let y = 0; y < pageInputs.length; y++) {
							// Parse the JSON input with an object or array
							let input = JSON.parse(pageInputs[y]);
							pages[x][pageKey][y] = input;
						}
					}
				}
			}
			newInputs = await nestAndFilterData(pages, organization, formId);
			// Otherwise, if form data is linear, iterate & modify the data in a different way before assigning to the inputs state
		} else {
			let linearInputs = formSnap.data().inputs.pages[0].Details;
			for (let x = 0; x < linearInputs.length; x++) {
				let input = JSON.parse(linearInputs[x]);
				linearInputs[x] = input;
			}
			newInputs = await filterLinearData(
				linearInputs,
				organization,
				formId
			);
		}
		await setInputs(newInputs);
		setInputsChange(true);
	};

	const renderSubmission = async () => {
		let lineItems = [];
		let index = 0;
		let firstLine;
		if (parsedResponse.organization == DB_ORG.CUSHMANWAKEFIELD) {
			const checked = updatedResponse['reviewed'] || false;
			firstLine = (
				<FormGroup
					key={index}
					className='p-3 d-flex align-items-center'
				>
					<Label className='fw-bolder pt-0 mb-0'>
						Report Reviewed and Approved?
					</Label>
					<Input
						className=''
						type='checkbox'
						defaultChecked={checked}
						onChange={(e) => {
							updateNewResponse('reviewed', e.target.checked);
						}}
					/>
				</FormGroup>
			);
			index++;
		}
		const secondLine = (
			<FormGroup key={index} className='p-3'>
				<Label className='fw-bolder pt-0'>Date of Visit</Label>
				<DatePicker
					disabled={userRole != 'superuser' && userRole != 'admin'}
					showPopperArrow={false}
					onChange={(e) => {
						updateNewResponse('submittedDate', e.toISOString());
					}}
					popperPlacement='top-start'
					className='form-inputs form-control'
					selected={new Date(updatedResponse.other.submittedDate)}
					maxDate={new Date()}
				/>
			</FormGroup>
		);
		index++;

		let address = '';
		if (updatedResponse.other.location.address) {
			address = updatedResponse.location.address;
		}
		if (updatedResponse.other.location.address1) {
			address = address + ' ' + updatedResponse.other.location.address1;
		}
		if (updatedResponse.other.location.address2) {
			address = address + ' ' + updatedResponse.other.location.address2;
		}
		const thirdLine = (
			<FormGroup key={index} className='p-3'>
				<Label className='fw-bolder pt-0'>Location</Label>
				<Input
					disabled
					type='text'
					value={
						address +
						', ' +
						updatedResponse.other.location.city +
						', ' +
						updatedResponse.other.location.state +
						' ' +
						updatedResponse.other.location.zip
					}
				></Input>
			</FormGroup>
		);
		index++;

		// Render multipage data
		if (isNested == true) {
			inputs.forEach((page) => {
				// Create page header
				let pageHeader = (
					<FormGroup
						key={index}
						className='pb-3 pt-4 px-3 submission-tab'
					>
						{page.page}
					</FormGroup>
				);
				lineItems = [...lineItems, pageHeader];
				index++;

				if (page.sections.length > 0) {
					for (let section of page.sections) {
						// Create section header
						let sectionHeader = (
							<FormGroup
								key={index}
								className='px-3 pt-3 pb-2 submission-section'
							>
								<Label className='submission-section-label'>
									{section.label}
								</Label>
							</FormGroup>
						);
						lineItems = [...lineItems, sectionHeader];
						index++;

						let inputs = [];
						// Check if data is old (before commit 69e0450) and render differently
						if (updatedResponse.other.inputs == undefined) {
							for (let input of section.inputs) {
								let columns = [];
								// Create input rows
								for (let subInput of input) {
									let col;
									let formKey = subInput.responseTag;
									let response = updatedResponse[formKey];
									// Cushman specific code for Asset Quality and Asset Image:
									// If submission response is blank or defined, assign new value
									if (response == undefined) response = '';
									let label = subInput.label;
									let isImage = isValidUrl(response);
									if (isImage) {
										col = (
											<Col xs='12' lg='6'>
												<FormGroup
													key={index}
													className='p-3 d-flex flex-column'
												>
													<Label className='submission-label fw-bold'>
														{label}
													</Label>
													<div className='position-relative submitted-image-parent'>
														<img
															className='submitted-image'
															alt=''
															src={response}
														/>
														<Input
															className='position-absolute top-0 h-100 opacity-0'
															type='file'
															accept='image/*'
															onChange={(e) =>
																uploadNewImage(
																	formKey,
																	e.target
																		.files[0]
																)
															}
														/>
														<span className='button-array'>
															<Button
																onClick={() => {
																	setSelectedItem(
																		formKey
																	);
																	setPhotoToBeCropped(
																		response
																	);
																}}
															>
																Edit
															</Button>
															<label
																className='image-btn'
																htmlFor={
																	index +
																	'image'
																}
															>
																<span className='btn'>
																	Replace
																</span>
															</label>
															<Button
																color='danger'
																onClick={async () => {
																	await updateNewResponse(
																		formKey,
																		''
																	);
																}}
															>
																Remove
															</Button>
														</span>
													</div>
												</FormGroup>
											</Col>
										);
									} else if (formKey.includes('AssetImage')) {
										col = (
											<Col xs='12' lg='6'>
												<FormGroup
													key={index}
													className='p-3'
												>
													<Label className='submission-label fw-bold'>
														{label}
													</Label>
													<div className='position-relative'>
														<Input
															disabled
															className='bg-white'
															type='text'
															value={response}
														/>
														<Input
															className='position-absolute top-0 h-100 opacity-0'
															type='file'
															accept='image/*'
															onChange={(e) =>
																uploadNewImage(
																	formKey,
																	e.target
																		.files[0]
																)
															}
														/>
													</div>
												</FormGroup>
											</Col>
										);
									} else if (
										formKey.includes('assetQuality')
									) {
										col = (
											<Col xs='12' lg='6'>
												<FormGroup
													key={index}
													className='p-3'
												>
													<Label className='submission-label fw-bold'>
														{label}
													</Label>
													<Input
														className='bg-white'
														type='select'
														onChange={(e) => {
															updateNewResponse(
																formKey,
																e.target.value
															);
														}}
													>
														<option
															value=''
															key={uuid()}
															selected={
																response == ''
															}
															disabled
														/>
														{dropdown.map(
															(option) => {
																return (
																	<option
																		value={
																			option
																		}
																		key={uuid()}
																		selected={
																			response ==
																			option
																		}
																	>
																		{option}
																	</option>
																);
															}
														)}
													</Input>
												</FormGroup>
											</Col>
										);
									} else {
										col = (
											<Col xs='12' lg='6'>
												<FormGroup
													key={index}
													className='p-3'
												>
													<Label className='submission-label fw-bold'>
														{label}
													</Label>
													<Input
														className='bg-white'
														type='text'
														defaultValue={response}
														onChange={(e) => {
															updateNewResponse(
																formKey,
																e.target.value
															);
														}}
													/>
												</FormGroup>
											</Col>
										);
									}
									columns = [...columns, col];
									index++;
								}
								let newInput = <Row>{columns}</Row>;
								inputs = [...inputs, newInput];
							}
						} else {
							for (let input of section.inputs) {
								// Create input rows
								// NOTE: responseTag is the key for updateNewResponseNested
								let responseTag = input[0].responseTag;
								let responseArray =
									updatedResponse.inputs[responseTag];
								let responseLength = 1;
								const resExists = responseArray != undefined;
								if (resExists)
									responseLength = responseArray.length;
								// NOTE: i is the index for updateNewResponseNested
								for (let i = 0; i < responseLength; i++) {
									let cols = [];
									for (let subInput of input) {
										let formKey = subInput.responseTag;
										let response = '';
										let isImage = false;
										let col;
										let subKey;
										// NOTE: either value, image, or quality is the subKey for updateNewResponseNested
										// Cushman specific code for Asset Quality and Asset Image:
										// If submission response is blank or undefined, assign new value
										if (formKey.includes('assetQuality')) {
											subKey = 'quality';
											if (resExists)
												response =
													responseArray[i].quality ||
													'';
										} else if (
											formKey.includes('AssetImage')
										) {
											subKey = 'image';
											if (resExists)
												response =
													responseArray[i].image ||
													'';
											isImage = isValidUrl(response);
										} else {
											subKey = 'value';
											if (resExists)
												response =
													responseArray[i].value ||
													'';
										}
										let label = subInput.label;
										if (isImage) {
											col = (
												<Col xs='12' lg='6'>
													<FormGroup
														key={index}
														className='p-3 d-flex flex-column'
													>
														<Label className='submission-label fw-bold'>
															{label}
														</Label>
														<div className='position-relative submitted-image-parent'>
															<img
																className='submitted-image'
																alt=''
																src={response}
															/>
															<Input
																className='position-absolute top-0 h-100 opacity-0'
																type='file'
																accept='image/*'
																onChange={(e) =>
																	uploadNewImageNested(
																		responseTag,
																		i,
																		subKey,
																		e.target
																			.files[0]
																	)
																}
															/>
															<span className='button-array'>
																<Button
																	onClick={() => {
																		setSelectedItem(
																			formKey
																		);
																		setPhotoToBeCropped(
																			response
																		);
																	}}
																>
																	Edit
																</Button>
																<label
																	className='image-btn'
																	htmlFor={
																		index +
																		'image'
																	}
																>
																	<span className='btn'>
																		Replace
																	</span>
																</label>
																<Button
																	color='danger'
																	onClick={async () => {
																		await updateNewResponse(
																			formKey,
																			''
																		);
																	}}
																>
																	Remove
																</Button>
															</span>
														</div>
													</FormGroup>
												</Col>
											);
										} else if (
											formKey.includes('AssetImage')
										) {
											col = (
												<Col xs='12' lg='6'>
													<FormGroup
														key={index}
														className='p-3'
													>
														<Label className='submission-label fw-bold'>
															{label}
														</Label>
														<div className='position-relative'>
															<Input
																disabled
																className='bg-white'
																type='text'
																value={response}
															/>
															<Input
																className='position-absolute top-0 h-100 opacity-0'
																type='file'
																accept='image/*'
																onChange={(e) =>
																	uploadNewImageNested(
																		responseTag,
																		i,
																		subKey,
																		e.target
																			.files[0]
																	)
																}
															/>
														</div>
													</FormGroup>
												</Col>
											);
										} else if (
											formKey.includes('assetQuality')
										) {
											col = (
												<Col xs='12' lg='6'>
													<FormGroup
														key={index}
														className='p-3'
													>
														<Label className='submission-label fw-bold'>
															{label}
														</Label>
														<Input
															className='bg-white'
															type='select'
															onChange={(e) => {
																updateNewResponseNested(
																	responseTag,
																	i,
																	subKey,
																	e.target
																		.value
																);
															}}
														>
															<option
																value=''
																key={uuid()}
																selected={
																	response ===
																	''
																}
																disabled
															/>
															{dropdown.map(
																(option) => {
																	return (
																		<option
																			value={
																				option
																			}
																			selected={
																				response ===
																				option
																			}
																			key={uuid()}
																		>
																			{
																				option
																			}
																		</option>
																	);
																}
															)}
														</Input>
													</FormGroup>
												</Col>
											);
										} else {
											col = (
												<Col xs='12' lg='6'>
													<FormGroup
														key={index}
														className='p-3'
													>
														<Label className='submission-label fw-bold'>
															{label}
														</Label>
														<Input
															className='bg-white'
															type='text'
															defaultValue={
																response
															}
															onChange={(e) => {
																updateNewResponseNested(
																	responseTag,
																	i,
																	subKey,
																	e.target
																		.value
																);
															}}
														/>
													</FormGroup>
												</Col>
											);
										}
										index++;
										cols = [...cols, col];
									}
									const newInput = <Row>{cols}</Row>;
									inputs = [...inputs, newInput];
								}
							}
						}
						lineItems = [...lineItems, inputs];
					}
				}
				// Add generic message if page wasn't filled out
			});
		}
		// Render linear data
		else {
			for (let input of inputs) {
				if (
					input.responseTag == 'location' ||
					input.label == 'Required Details' ||
					input.label == 'Specific Details'
				) {
					continue;
				}
				let formKey = input.responseTag;
				if (formKey == 'assetQuality') {
					formKey = formKey + input[0].responseTag;
				}
				let label = input.label;
				let response = updatedResponse.other[formKey];

				let newRow;
				if (isValidUrl(response)) {
					newRow = (
						<FormGroup
							key={index}
							className='p-3 d-flex flex-column'
						>
							<Label className='submission-label'>{label}</Label>
							<div className='position-relative submitted-image-parent'>
								<img
									className='submitted-image'
									alt=''
									src={response}
								/>
								<Input
									className='position-absolute top-0 h-100 opacity-0'
									type='file'
									id={index + 'image'}
									accept='image/*'
									onChange={(e) => {
										uploadNewImage(
											formKey,
											e.target.files[0]
										);
									}}
								/>
								<span className='button-array'>
									<Button
										onClick={() => {
											setSelectedItem(formKey);
											setPhotoToBeCropped(response);
										}}
									>
										Edit
									</Button>
									<label
										className='image-btn'
										htmlFor={index + 'image'}
									>
										<span className='btn'>Replace</span>
									</label>
									<Button
										color='danger'
										onClick={async () => {
											await updateNewResponse(
												formKey,
												''
											);
										}}
									>
										Remove
									</Button>
								</span>
							</div>
						</FormGroup>
					);
				} else if (input.type == 'image') {
					newRow = (
						<FormGroup
							key={index}
							className='p-3 d-flex flex-column'
						>
							<Label className='submission-label'>{label}</Label>
							<div className='position-relative'>
								<Input
									disabled
									className='bg-white'
									type='text'
									value={response}
								/>
								<Input
									className='position-absolute top-0 h-100 opacity-0'
									type='file'
									accept='image/*'
									onChange={(e) =>
										uploadNewImage(
											formKey,
											e.target.files[0]
										)
									}
								/>
							</div>
						</FormGroup>
					);
				} else if (input.type == 'checkbox') {
					const checked = response || false;
					newRow = (
						<FormGroup key={index} className='p-3'>
							<Label className='submission-label'>{label}</Label>
							<Input
								className=''
								type='checkbox'
								defaultChecked={checked}
								onChange={(e) => {
									updateNewResponse(
										formKey,
										e.target.checked
									);
								}}
							/>
						</FormGroup>
					);
				} else if (input.responseTag == 'manufacturer') {
					let options;
					if (input.collection != undefined) {
						options = dropdown[input.responseTag]
							.sort()
							.map((option) => {
								return { label: option, value: option };
							});
					} else {
						options = input.options.sort().map((option) => {
							return { label: option, value: option };
						});
					}
					newRow = (
						<FormGroup key={index} className='p-3'>
							<Label className='submission-label'>{label}</Label>
							<Select
								options={options}
								defaultValue={{
									label: response,
									value: response,
								}}
								onChange={(e) => {
									updateNewResponse(formKey, e.value);
								}}
								isDisabled={
									input.tiedTo
										? updatedResponse[input.tiedTo] === true
										: false
								}
							/>
						</FormGroup>
					);
				} else if (input.type == 'select') {
					newRow = (
						<FormGroup key={index} className='p-3'>
							<Label className='submission-label'>{label}</Label>
							<Input
								className='bg-white'
								type='select'
								onChange={(e) => {
									updateNewResponse(formKey, e.target.value);
								}}
							>
								<option
									value=''
									key={uuid()}
									selected={response == ''}
									disabled
								/>
								{input.collection != undefined
									? dropdown[input.responseTag]
											.sort()
											.map((option) => {
												return (
													<option
														value={option}
														key={uuid()}
														selected={
															response == option
														}
													>
														{option}
													</option>
												);
											})
									: input.options.sort().map((option) => {
											return (
												<option
													value={option}
													key={uuid()}
													selected={
														response == option
													}
												>
													{option}
												</option>
											);
									  })}
							</Input>
						</FormGroup>
					);
				} else if (input.type === 'switch') {
					newRow = (
						<FormGroup key={index} className='p-3'>
							<Label
								style={{ display: 'block' }}
								className='submission-label'
							>
								{label}
							</Label>
							<div className='toggle'>
								<input
									type='checkbox'
									onChange={() => {
										if (
											updatedResponse.other[formKey] ===
											'true'
										) {
											updateNewResponse(formKey, 'false');
										} else {
											updateNewResponse(formKey, 'true');
										}
									}}
									defaultChecked={
										parsedResponse.other[formKey] === 'true'
											? true
											: false
									}
									className='toggle-checkbox'
								/>
								<div className='toggle-switch'></div>
								<span className='toggle-on-text'>Yes</span>
								<span className='toggle-off-text'>No</span>
							</div>
						</FormGroup>
					);
				} else if (input.label === 'Asset Tag ID') {
					newRow = (
						<FormGroup key={index} className='p-3'>
							<Label className='submission-label'>{label}</Label>
							<Input
								className='bg-white'
								type='text'
								defaultValue={response}
								invalid={QRError}
								onChange={(e) => {
									checkDuplicateAfterDelay(e);
									updateNewResponse(formKey, e.target.value);
								}}
								onKeyPress={(e) => {
									const regex = /^[a-zA-Z0-9]+$/;
									if (!regex.test(e.key)) {
										e.preventDefault();
									}
								}}
							/>
							<FormFeedback invalid>
								ID already exists in database. Please enter a
								unique asset tag ID.
							</FormFeedback>
						</FormGroup>
					);
				} else {
					newRow = (
						<FormGroup
							key={index}
							className='p-3'
							hidden={
								(input.responseTag == 'ifOther' &&
									updatedResponse.other['assetType'] !==
										'Other') ||
								(input.responseTag == 'ifOtherManufacturer' &&
									(updatedResponse.other['manufacturer'] !==
										'(other)' ||
										updatedResponse.other[
											'makeNotAvailable'
										] == true))
							}
						>
							<Label className='submission-label'>{label}</Label>
							<Input
								type='text'
								disabled={
									updatedResponse.other[input.tiedTo] ===
										'true' ||
									updatedResponse.other[input.tiedTo] === true
								}
								defaultValue={response}
								onChange={(e) => {
									updateNewResponse(formKey, e.target.value);
								}}
								onKeyPress={(e) => {
									if (
										input.label === 'Serial Number' ||
										input.label === 'Model Number'
									) {
										const regex = /^[a-zA-Z0-9]+$/;
										if (!regex.test(e.key)) {
											e.preventDefault();
											return;
										}
									}
								}}
							/>
						</FormGroup>
					);
				}
				lineItems = [...lineItems, newRow];
				index++;
			}
		}

		lineItems = await Promise.all(lineItems);
		const component = (
			<div id='activities-tab-form-submission'>
				{firstLine}
				{secondLine}
				{thirdLine}
				{lineItems}
			</div>
		);
		setSubmission(component);
	};

	useEffect(() => {
		getUserRole();
	}, []);

	useEffect(() => {
		if (photoToBeCropped) setIsCropShowing(true);
	}, [photoToBeCropped]);

	// Update inputs and updatedResponse states when parsedResponse state changes
	useEffect(() => {
		if (parsedResponse != null && userRole) {
			getData();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [parsedResponse, QRError, userRole]);

	// Update submission state when inputs or dropdown state is initialized
	useEffect(() => {
		if (inputs != null && updatedResponse != null && inputsChange) {
			renderSubmission();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [inputs, dropdown]);

	// Update submission state when inputs or dropdown state changes
	useEffect(() => {
		if (
			inputs != null &&
			updatedResponse != null &&
			(inputsChange || dropdownChange)
		) {
			renderSubmission();
			if (inputsChange) setInputsChange(false);
			if (dropdownChange) setDropdownChange(false);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [inputsChange, dropdownChange]);

	// Update submission state when updatedResponseChanges state changes
	useEffect(() => {
		if (
			inputs != null &&
			updatedResponse != null &&
			updatedResponseChanges
		) {
			renderSubmission();
			setUpdatedResponseChanges(false);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [updatedResponseChanges]);

	// Update submissionParent state when submission state changes
	useEffect(() => {
		if (submission != null) {
			setSubmissionParent(
				<>
					<AiOutlineEdit
						className='asset-profile-edit'
						onClick={toggle}
					/>
					<Modal
						isOpen={modal}
						size='xl'
						toggle={toggleReset}
						className='asset-edit-modal'
						centered={true}
					>
						<ModalHeader toggle={toggleReset}>
							Edit Asset Information
						</ModalHeader>
						<ModalBody
							style={{ overflowY: 'scroll', maxHeight: '700px' }}
						>
							{submission}
							<ConfirmDialog
								showConfirm={showReset}
								toggle={toggleReset}
								title='Confirm Reset'
								body='Would you like to reset the changes made to this asset?'
								functionality={resetSubmission}
							/>
							<ConfirmDialog
								showConfirm={showUpdate}
								toggle={toggleUpdate}
								title='Confirm Update'
								body='Would you like to update this submission in the database?'
								functionality={updateDb}
							/>
							<CropperModal
								showing={isCropShowing}
								photoUrl={photoToBeCropped}
								onCrop={(data) => getCroppedPhoto(data)}
								cancel={() => {
									setIsCropShowing(false);
									setPhotoToBeCropped(null);
								}}
							/>
						</ModalBody>
						<ModalFooter>
							<Button color='secondary' onClick={toggleReset}>
								Cancel
							</Button>
							<Button color='success' onClick={toggleUpdate}>
								Save Changes
							</Button>{' '}
						</ModalFooter>
					</Modal>
				</>
			);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		submission,
		showReset,
		showUpdate,
		parsedResponse,
		isCropShowing,
		modal,
		QRError,
	]);

	return submissionParent;
};

export { AssetEdit };
