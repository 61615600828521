// ** Redux Imports
import { createSlice } from '@reduxjs/toolkit'

const initialThemeMode = () => {
  const item = window.localStorage.getItem('themestatemode')
  //** Parse stored json or if none return initialValue
  return item ? item : (window.matchMedia('(prefers-color-scheme: dark)').matches) ? 'dark' : 'light';
}

export const layoutSlice = createSlice({
  name: 'thememode',
  initialState: {
    thememode: initialThemeMode()
  },
  reducers: {
    handleThemeMode: (state, action) => {
      state.thememode = action.payload
      window.localStorage.setItem('themestatemode', action.payload)
    }
  }
})

export const {
  handleThemeMode
} = layoutSlice.actions

export default layoutSlice.reducer
