import {
	Accordion,
	AccordionBody,
	AccordionHeader,
	AccordionItem,
	Button,
	Card,
	CardHeader,
	Col,
	Form,
	FormFeedback,
	FormGroup,
	Input,
	Label,
	Modal,
	ModalBody,
	ModalHeader,
	Nav,
	NavItem,
	NavLink,
	Row,
	TabContent,
	TabPane,
} from 'reactstrap';
import { auth, storage } from '@assets/services/auth-service';
import { getUser } from '@services/user-service';
import { ref, uploadString, getDownloadURL } from 'firebase/storage';
import { useState, useEffect } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { GrAddCircle, GrCheckmark, GrSubtractCircle } from 'react-icons/gr';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import * as dayjs from 'dayjs';
import * as _ from 'lodash';
import { SubmittedImageModal } from '@SignedIn/views/SingleForm/SubmittedImageModal';
import { CropperModal } from '@SignedIn/views/SingleForm/CropperModal';
import { QRModal } from '@SignedIn/views/SingleForm/QRModal';
import { BarcodeModal } from '@SignedIn/views/SingleForm/BarcodeModal';
import { LocationPicker } from '@SignedIn/views/SingleForm/LocationPicker';
import { v4 as uuid } from 'uuid';
import { Loader } from '@SignedIn/Loader/Loader';
import { LoadingModal } from '@SignedIn/LoadingModal/LoadingModal';
import ImageOptions from '@SignedIn/views/SingleForm/ImageOptions';
import Select from 'react-select';
import { isUndefined } from 'lodash';
import {
	addSubmission,
	getSubmissionWithFilter,
	getSubmissionWithTag,
} from '@services/submission-service';
import {
	getOrganization,
	getManufacturers as getManufacts,
	updateWithRef,
} from '@services/organization-service';
import { getForm, getFormDropdowns } from '@services/form-service';
import { addAsset, getFormAssetTypes } from '@services/asset-service';
import {
	getLocation,
	getOrganizationLocations,
} from '@services/location-service';
import { addQA } from '@services/qa-service';
import { deleteDraft, setDraft } from '@services/draft-service';
import { DB_FILTER, DB_ORG } from '@constants/db';
import { useContext } from 'react';
import AuthContext from '@assets/services/Auth';

const SingleForm = (props) => {
	const navigate = useNavigate();
	const location = useLocation();
	const { authInfo } = useContext(AuthContext);
	const [isLoading, setIsLoading] = useState(true);
	const [inputs, setInputs] = useState([]);
	const [locations, setLocations] = useState([]);
	const [manufacturers, setManufacturers] = useState([]);
	const [assets, setAssets] = useState({});
	const [formName, setFormName] = useState(null);
	const [formLogo, setFormLogo] = useState(null);
	const [flags, setFlags] = useState(null);
	const [responseData, setResponseData] = useState(
		location.state.responseData || {}
	);
	const [formId, setFormId] = useState(location.state.formId || null);
	const { id, org } = useParams();
	const [formInputs, setFormInputs] = useState([]);
	const [uniqueAsset, setUniqueAsset] = useState(false);
	const [isAssetForm, setIsAssetForm] = useState(false);
	const [multiplePages, setMultiplePages] = useState(false);
	const [currentActiveTab, setCurrentActiveTab] = useState('1');
	const [currentPage, setCurrentPage] = useState(0);
	const [selectedPhotoUrl, setSelectedPhotoUrl] = useState(null);
	const [isModalShowing, setIsModalShowing] = useState(false);
	const [selectedItem, setSelectedItem] = useState(null);
	const [isCropShowing, setIsCropShowing] = useState(false);
	const [isQRShowing, setIsQRShowing] = useState(false);
	const [isBarcodeShowing, setIsBarcodeShowing] = useState(false);
	const [scannedTag, setScannedTag] = useState();
	const [assetTagScanned, setAssetTagScanned] = useState(false);
	const [photoToBeCropped, setPhotoToBeCropped] = useState(null);
	const [addedInputs, setAddedInputs] = useState(0);
	const [showRequiredDetails, setShowRequiredDetails] = useState(
		location.state.responseData
			? location.state.responseData.assetType
				? true
				: false
			: false
	);
	const [savedLocation, setSavedLocation] = useState(
		location.state.savedLocation || null
	);
	const [showLoader, setShowLoader] = useState(false);
	const isMobile = useState(window.innerWidth < 768);
	const [assetTagError, setAssetTagError] = useState(false);
	const [draftId, setDraftId] = useState(props.draftId);
	const [shouldAutoSave, setShouldAutoSave] = useState(false);
	const [alertModal, setAlertModal] = useState({
		show: false,
		title: '',
		body: '',
	});
	const [dupWarningShown, setDupWarningShown] = useState(false);
	const [submitDisabled, setSubmitDisabled] = useState(false);
	const [userLocation, setUserLocation] = useState({});

	let organization;
	if (org != undefined) {
		organization = org;
	} else {
		organization = authInfo.org;
	}

	/**
	 *
	 *
	 * Use Effects
	 *
	 *
	 */

	useEffect(() => {
		// Init form
		setFormId(id);
		getLocations();
		getManufacturers();
		getOrgData();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (locations.length > 0 && manufacturers.length > 0) {
			getData();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [locations, manufacturers, responseData.assetType]);

	useEffect(() => {
		getDataFromCollection();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [inputs]);

	useEffect(() => {
		if (photoToBeCropped) setIsCropShowing(true);
	}, [photoToBeCropped]);

	useEffect(() => {
		setFormInputs(createFormInputs());
		setIsLoading(false);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [assets]);

	useEffect(() => {
		setFormInputs(createFormInputs());
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [currentActiveTab, currentPage, showRequiredDetails, assetTagError]);

	useEffect(() => {
		saveProgress();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [formInputs]);
	/**
	 *
	 *
	 * End Use Effects
	 *
	 *
	 */

	/**
	 *
	 * Grab the current progress of the form and save it to the org
	 *
	 */
	const saveProgress = async () => {
		if (location.state.responseData) {
			setDraftId(location.state.draftId);
		}
		if (
			shouldAutoSave &&
			responseData.assetLocation &&
			responseData.location &&
			responseData.assetType &&
			responseData.manufacturer &&
			responseData.modelNumber
		) {
			let newDraftId = draftId;
			if (!draftId) {
				let newId = uuid();
				setDraftId(newId);
				newDraftId = newId;
			}

			let submittingUser = 'n/a';
			if (auth.currentUser.uid) {
				const user = await getUser(auth.currentUser.uid);
				if (user) {
					submittingUser = {
						name: user.name,
						id: user.uid,
					};
				} else submittingUser = user.uid;
			}
			const data = {
				responseData,
				formId,
				formName,
				submittingUser,
				newDraftId,
				submittedDate: new Date().toISOString(),
				savedLocation,
			};
			if (userLocation.latitude && userLocation.longitude)
				data.userLocation = userLocation;
			await setDraft(organization, draftId, data);
		}
	};

	const clearState = () => {
		return new Promise((resolve) => {
			setIsLoading(true);
			setInputs([]);
			setLocations([]);
			setManufacturers([]);
			setAssets([]);
			setFormName(null);
			setFormLogo(null);
			setFlags(null);
			setResponseData({});
			setFormId({});
			setFormInputs([]);
			setUniqueAsset(false);
			setIsAssetForm(false);
			setMultiplePages(false);
			setCurrentActiveTab('1');
			setCurrentPage(0);
			setSelectedPhotoUrl(null);
			setIsModalShowing(false);
			setSelectedItem(null);
			setIsCropShowing(false);
			setIsQRShowing(false);
			setPhotoToBeCropped(null);
			setAddedInputs(0);
			setShowRequiredDetails(false);
			setDupWarningShown(false);
			resolve();
		});
	};
	/**
	 * Values are stored in the firebase db like so:
	 * type, label, collection, responseTag
	 *
	 * type = the type of input element
	 *  - text: simple text input
	 *  - select: dropdown menu, options are filled via the collection value
	 *  - checkbox: simple checkbox value
	 *
	 * label = what to display above the input
	 * collection = currently only used to populate the checkobox dropdowns
	 * responseTag = when building the response on submit, the data will use this value for the response
	 *  - responseTag="make". Store the make input field data on the response object like so { make: "" }
	 *
	 * Even though we are making these forms dynamic in the backend, there is a standard.
	 * That is what will help us on the front end. Example, QR code scanning. Locations dropdown. etc.
	 *
	 * We eventually will make a WYSIWYG in the front end for our users to build their own forms.
	 * But for now we need to make them ourselves manually or write a script that can do it.
	 *
	 * TODO:
	 * add a required value. That way the form knows if it should be required or not, obviously.
	 */

	const getOrgData = async () => {
		const docSnap = await getOrganization(organization);

		setShouldAutoSave(
			isUndefined(docSnap.data().autoSaveDrafts)
				? true
				: docSnap.data().autoSaveDrafts
		);
	};
	const getData = async () => {
		const docSnap = await getForm(organization, id);
		if (docSnap.exists()) {
			let dbInputs = [];
			setFormName(docSnap.data().formName);
			setFormLogo(docSnap.data().logo);
			setIsAssetForm(docSnap.data().isAssetForm);
			setUniqueAsset(docSnap.data().isUnique);
			if (docSnap.data().multiplePages) {
				setMultiplePages(true);
			}

			dbInputs = docSnap.data().inputs.pages;

			setFlags(docSnap.data());
			// append conditional inputs for drafted forms
			if (responseData.assetType) {
				const assetTypeSnap = await getFormAssetTypes(
					organization,
					formId,
					responseData.assetType
				);
				if (!assetTypeSnap.empty) {
					for (const assetType of assetTypeSnap.docs) {
						if (assetType.data().inputs) {
							const specAssetInputs = assetType.data().inputs;
							dbInputs[0].Details.push(...specAssetInputs);
							await setAddedInputs(specAssetInputs.length);
							break;
						}
					}
				}
			}
			await setInputs(dbInputs);
		}
	};

	const getLocations = async () => {
		const locationsSnap = await getOrganizationLocations(organization);
		const locationsFromDB = [];
		if (!locationsSnap.empty) {
			for (const location of locationsSnap.docs) {
				locationsFromDB.push({
					data: location.data(),
					id: location.id,
				});
			}
		}
		locationsFromDB.sort(function (a, b) {
			return a.data.name - b.data.name;
		});
		setLocations(locationsFromDB);
	};

	const getManufacturers = async () => {
		const manufacturersSnap = await getManufacts();
		const newManufacturers = [];
		if (!manufacturersSnap.empty) {
			for (const option of manufacturersSnap.docs) {
				newManufacturers.push(option.data().name);
			}
		}
		newManufacturers.sort();
		setManufacturers(newManufacturers);
	};

	const getOptionsAssets = async (item, event) => {
		let docs = await getFormAssetTypes(
			organization,
			formId,
			event.target.value
		);
		let keys = Object.keys(inputs[0]);
		let currentInputs = [...inputs[0][keys[0]]];
		for (let i = 0; i < addedInputs; i++) {
			currentInputs.pop();
		}
		let ogInputs = inputs;
		docs.forEach((d) => {
			if (d.data().inputs) {
				let specAssetInputs = d.data().inputs;
				currentInputs.push(...specAssetInputs);
				ogInputs[0][keys[0]] = currentInputs;
				setInputs(ogInputs);
				setAddedInputs(specAssetInputs.length);
			} else {
				ogInputs[0][keys[0]] = currentInputs;
				setInputs(ogInputs);
				setAddedInputs(0);
			}

			setShowRequiredDetails(d.data().showRequired);
		});

		await setFormInputs(createFormInputs());
	};
	const pushToNewAssets = async (colInp, newAssets) => {
		if (colInp.collection) {
			let collectionName = colInp.collection;
			newAssets[collectionName] = [];

			let docSnap = await getFormDropdowns(
				organization,
				formId,
				collectionName
			);
			// let docSnap;
			// if (collectionName != 'asset-types') {
			// 	docSnap = await getFormDropdowns(
			// 		organization,
			// 		formId,
			// 		collectionName
			// 	);
			// } else {
			// 	docSnap = await getFormAssetTypes(organization, formId);
			// }
			let names = [];
			docSnap.forEach((doc) => {
				names.push({
					key: doc.id,
					value: doc.data().name,
					order: doc.data().order,
				});
			});
			names.sort(function (a, b) {
				a = a.order || a.value.toLowerCase();
				b = b.order || b.value.toLowerCase();
				if (typeof a === 'string' && a.charAt(0) === ' ')
					a = a.slice(1);
				if (typeof b === 'string' && b.charAt(0) === ' ')
					b = b.slice(1);
				return a < b ? -1 : a > b ? 1 : 0;
			});

			names.forEach((name) => {
				newAssets[collectionName].push(
					<option key={name.id} value={name.value}>
						{name.value}
					</option>
				);
			});
		}

		return newAssets;
	};
	/**
	 * If data is needed from external collection, get collection name and get the data here
	 */
	const getDataFromCollection = async () => {
		let newAssets = {};
		for (let i = 0; i < inputs.length; i++) {
			let inp = inputs[i];
			let keys = Object.keys(inp);
			for (let k of keys) {
				if (Array.isArray(inp[k])) {
					for (let j = 0; j < inp[k].length; j++) {
						let subInput = JSON.parse(inp[k][j]);
						if (Array.isArray(subInput)) {
							for (let l = 0; l < subInput.length; l++) {
								let colInp = subInput[l];
								newAssets = await pushToNewAssets(
									colInp,
									newAssets
								);
							}
						} else {
							newAssets = await pushToNewAssets(
								subInput,
								newAssets
							);
						}
					}
				}
			}
		}
		setAssets(newAssets);
	};

	/**
	 * When input is entered in a field, gather the data here and save it.
	 * @param {*} item
	 * @param {*} e
	 */
	const setInputData = (item, e) => {
		let response = responseData;

		if (e.id) {
			e = {
				target: {
					value: e.id,
				},
			};
		}

		if (
			e.target.value === 'on' ||
			(e.target.type === 'checkbox' && e.target.checked === true)
		) {
			e.target.value = true;
		} else if (
			e.target.value === 'off' ||
			(e.target.type === 'checkbox' && e.target.checked === false)
		) {
			e.target.value = false;
		}

		if (item.responseTag === 'assetType') {
			getOptionsAssets(item, e);
		}
		response[item.responseTag] = e.target.value;
		setResponseData(response);
		setFormInputs(createFormInputs());
	};

	/**
	 * For select fields, we need the <options> get them here.
	 * @param {*} item
	 * @returns
	 */
	const getOptions = (item) => {
		if (item.collection === 'location') {
			return locations;
		} else if (item.collection === 'manufacturers') {
			return manufacturers;
		} else if (item.options) {
			let options = [<option key={'default'} selected disabled></option>];
			let optionsArr = item.options;
			optionsArr.sort((a, b) => {
				return a < b ? -1 : a > b ? 1 : 0;
			});
			for (let i of optionsArr) {
				options.push(<option key={i}>{i}</option>);
			}

			return options;
		} else {
			return assets[item.collection];
		}
	};

	/**
	 * If camera was clicked, we need to capture an image and assign it to this asset.
	 * @param {*} item
	 */
	const capturePhoto = (e, item, responseTag = 'AssetImage') => {
		if (e.target.files) {
			if (e.target.files.length !== 0) {
				setShowLoader(true);

				var file = e.target.files[0];
				if (!file.type.includes('image')) {
					setShowLoader(false);
					setAlertModal({
						title: 'Missing Fields',
						body: 'Please only import an image',
						show: true,
					});
					return;
				}
				var reader = new FileReader();
				reader.onloadend = async function () {
					let obj = { ...item[0] };
					obj.responseTag += responseTag;
					setInputData(obj, { target: { value: 'loading' } });
					// Upload image to firebase and get downloadUrl.
					let baseRes = reader.result;
					let newImg = new Image();
					newImg.onload = async () => {
						var canvas = document.createElement('canvas');
						var context = canvas.getContext('2d');
						canvas.width = newImg.width - newImg.width * 0.5;
						canvas.height = newImg.height - newImg.height * 0.5;

						context.drawImage(
							newImg,
							0,
							0,
							canvas.width,
							canvas.height
						);
						var dataURL = canvas.toDataURL('image/png');
						let imageRef = ref(
							storage,
							'FieldCaptures/' + new Date().toISOString() + '.png'
						);
						let task = await uploadString(
							imageRef,
							dataURL,
							'data_url'
						);
						let url = await getDownloadURL(task.ref);

						setInputData(obj, { target: { value: url } });
						setShowLoader(false);
					};
					newImg.src = baseRes;
				};
				reader.readAsDataURL(file);
			}
		}
	};

	// const checkDate = (e, item) => {
	// 	const val = new Date(e.target.value);
	// 	const currDate = new Date();
	// 	if (val > currDate && !item.futureDateOnly) {
	// 		document.getElementById(id).value = '';
	// 		setInputData(item, { target: { value: '' } });
	// 		setAlertModal({
	// 			title: 'Missing Fields',
	// 			body: 'You cannot input a date in the future.',
	// 			show: true,
	// 		});
	// 		return;
	// 	} else if (val < currDate && item.futureDateOnly) {
	// 		document.getElementById(id).value = '';
	// 		setInputData(item, { target: { value: '' } });
	// 		setAlertModal({
	// 			title: 'Missing Fields',
	// 			body: 'You cannot input a date in the past.',
	// 			show: true,
	// 		});
	// 		return;
	// 	} else {
	// 		setInputData(item, e);
	// 	}
	// };

	const getCroppedPhoto = async (data) => {
		setIsCropShowing(false);
		let obj = selectedItem;
		let imageRef = ref(
			storage,
			'FieldCaptures/' + new Date().toISOString() + '.png'
		);
		let task = await uploadString(imageRef, data, 'data_url');
		let url = await getDownloadURL(task.ref);
		// Then attach the url to this asset.
		setInputData(obj, { target: { value: url } });
		setPhotoToBeCropped(null);
	};

	/**
	 * Sometimes inputs are in an array, here is where we loop through them and display them
	 * @param {*} item
	 * @returns
	 */
	const getInputsFromSubArray = (item, index, pageName) => {
		let shouldOverrideFlex = false;
		let localInputs = [];
		for (let i = 0; i < item.length; i++) {
			if (!item[i].hide) {
				if (
					item[i].type === 'button' &&
					item[i].responseTag === 'scan'
				) {
					localInputs.push(
						<Col className={isMobile ? 'col-6' : 'col-2'}>
							<FormGroup
								key='qr'
								className={isMobile ? 'col-12' : 'col-12'}
							>
								<br></br>
								<Button
									className='in-form-btn'
									onClick={() => {
										showScanner(
											item[i].scanTag,
											item[i].scanType
										);
									}}
								>
									{item[i].label}
								</Button>{' '}
							</FormGroup>
						</Col>
					);
				} else if (item[i].type === 'switch') {
					if (!responseData[item[i].responseTag] && item[i].default) {
						let response = { ...responseData };
						response[item[i].responseTag] = item[i].default;
						setResponseData(response);
					}
					shouldOverrideFlex = true;
					let checked;
					if (responseData[item[i].responseTag] === undefined) {
						checked = item[i].default;
					} else {
						checked = responseData[item[i].responseTag];
					}
					localInputs.push(
						<Col className={isMobile ? 'col-6' : 'col-2'}>
							<FormGroup
								key={index}
								className={isMobile ? 'col-12' : 'col-12'}
								hidden={
									(item[i].responseTag == 'ifOther' &&
										responseData['assetType'] !==
											'Other') ||
									responseData[item.tiedTo] === 'No' ||
									(item[i].responseTag === 'otherAssetType' &&
										responseData['assetType'] !== 'Other')
								}
							>
								<Label className='single-form-label'>
									{item[i].label}
									{item[i].required == 'true' ? (
										<span style={{ color: 'red' }}> *</span>
									) : (
										''
									)}
								</Label>
								<br />
								<div className='toggle'>
									<input
										key={index}
										type='checkbox'
										onChange={(e) => {
											setInputData(item[i], e);
										}}
										defaultChecked={
											checked == 'false' ? false : true
										}
										className='toggle-checkbox'
									/>
									<div className='toggle-switch'></div>
									<span className='toggle-on-text'>Yes</span>
									<span className='toggle-off-text'>No</span>
								</div>
							</FormGroup>
						</Col>
					);
				} else if (item[i].type === 'select') {
					if (item[i - 1]) {
						item[i].responseTag += item[i - 1].responseTag;
					}
					localInputs.push(
						<Col className={isMobile ? 'col-12' : 'col-6'}>
							<FormGroup key={item[i].responseTag + 'Form'}>
								{item[i].label !== 'n/a' ? (
									<Label className='single-form-label'>
										{item[i].label}
										{item[i].required == 'true' ? (
											<span style={{ color: 'red' }}>
												{' '}
												*
											</span>
										) : (
											''
										)}
									</Label>
								) : (
									<></>
								)}
								<span
									style={{
										display: 'flex',
										flexDirection: 'row',
										alignItems: 'center',
									}}
								>
									{(() => {
										if (
											item[i].responseTag ==
											'manufacturer'
										) {
											const options = getOptions(
												item[i]
											).map((option) => {
												return {
													label: option,
													value: option,
												};
											});
											return (
												<Select
													key={item[i].responseTag}
													onChange={(e) => {
														e.target = {};
														e.target.value =
															e.value;
														setInputData(
															item[i],
															e
														);
													}}
													style={{ width: '100%' }}
													options={options}
													isDisabled={
														item[i].tiedTo
															? responseData[
																	item[i]
																		.tiedTo
															  ] === 'true'
															: false
													}
													className='form-inputs'
													defaultValue={{
														label: responseData[
															item[i].responseTag
														],
														value: responseData[
															item[i].responseTag
														],
													}}
													placeholder={
														item[i].placeholder
													}
												/>
											);
										} else {
											return (
												<Input
													key={item[i].responseTag}
													type={item[i].type}
													onChange={(e) => {
														setInputData(
															item[i],
															e
														);
													}}
													style={{ width: '100%' }}
													disabled={
														item[i].tiedTo
															? responseData[
																	item[i]
																		.tiedTo
															  ] === 'true'
															: false
													}
													className='form-inputs'
													defaultValue={
														responseData[
															item[i].responseTag
														]
													}
													placeholder={
														item[i].placeholder
													}
												>
													{getOptions(item[i])}
												</Input>
											);
										}
									})()}
								</span>
							</FormGroup>
						</Col>
					);
				} else if (item[i].type === 'date') {
					const id = item[i].responseTag + i;
					localInputs.push(
						<Col className={isMobile ? 'col-12' : 'col-6'}>
							<FormGroup key={item[i].responseTag + 'Form'}>
								{item[i].label !== 'n/a' ? (
									<Label className='single-form-label'>
										{item[i].label}
										{item[i].required == 'true' ? (
											<span style={{ color: 'red' }}>
												{' '}
												*
											</span>
										) : (
											''
										)}
									</Label>
								) : (
									<></>
								)}
								<span
									style={{
										display: 'flex',
										flexDirection: 'row',
										alignItems: 'center',
									}}
								>
									<DatePicker
										showPopperArrow={false}
										key={item[i].responseTag}
										id={id}
										onChange={(e) => {
											setInputData(item[i], {
												target: {
													value: dayjs(e).format(
														'MM/DD/YYYY'
													),
												},
											});
											// checkDate(
											// 	{
											// 	target: {
											// 		value: dayjs(e).format(
											// 			'MM/DD/YYYY'
											// 			),
											// 		},
											// 	},
											// 	item[i]
											// 	);
										}}
										popperPlacement='top-start'
										className='form-inputs form-control'
										selected={
											responseData[item[i].responseTag]
												? new Date(
														responseData[
															item[i].responseTag
														]
												  )
												: null
										}
										placeholderText={item[i].placeholder}
										minDate={
											item[i].futureDateOnly
												? new Date()
												: null
										}
										maxDate={
											item[i].futureDateOnly
												? null
												: new Date()
										}
									/>
								</span>
							</FormGroup>
						</Col>
					);
				} else if (item[i].type === 'checkbox') {
					localInputs.push(
						<Col className={isMobile ? 'col-12' : 'col-6'}>
							<FormGroup
								key={item[i].responseTag + 'Form'}
								hidden={
									item[i].altTiedTo &&
									(responseData[item[i].altTiedTo] === '' ||
										responseData[item[i].altTiedTo] ===
											'Connected to a Rack' ||
										responseData[item[i].altTiedTo] ===
											'Rack' ||
										!responseData[item[i].altTiedTo])
								}
							>
								<span
									style={{
										display: 'flex',
										flexDirection: 'row',
										alignItems: 'center',
									}}
								>
									<Input
										key={item[i].responseTag}
										type={item[i].type}
										onChange={(e) => {
											setInputData(item[i], e);
										}}
										disabled={
											item[i].tiedTo &&
											responseData[item[i].tiedTo]
												? responseData[
														item[i].tiedTo
												  ] === 'true'
												: false
										}
										defaultChecked={Boolean(
											responseData[item[i].responseTag]
										)}
										placeholder={item[i].placeholder}
									/>
									{item[i].label !== 'n/a' ? (
										<Label style={{ marginBottom: '0' }}>
											{item[i].label}
											{item[i].required == 'true' ||
											(item[i].type === 'text' &&
												(responseData[
													item[i].altTiedTo
												] === 'Rack' ||
													responseData[
														item[i].altTiedTo
													] ===
														'Self Contained Asset')) ? (
												<span style={{ color: 'red' }}>
													{' '}
													*
												</span>
											) : (
												''
											)}
										</Label>
									) : (
										<></>
									)}
								</span>
							</FormGroup>
						</Col>
					);
				} else {
					localInputs.push(
						<Col className={isMobile ? 'col-12' : 'col-6'}>
							<FormGroup
								key={item[i].responseTag + 'Form'}
								hidden={
									item[i].altTiedTo &&
									(responseData[item[i].altTiedTo] === '' ||
										responseData[item[i].altTiedTo] ===
											'Connected to a Rack' ||
										!responseData[item[i].altTiedTo])
								}
							>
								{item[i].label !== 'n/a' ? (
									<Label className='single-form-label'>
										{item[i].label}
										{item[i].required == 'true' ||
										(item[i].type === 'text' &&
											(responseData[item[i].altTiedTo] ===
												'Rack' ||
												responseData[
													item[i].altTiedTo
												] ===
													'Self Contained Asset')) ? (
											<span style={{ color: 'red' }}>
												{' '}
												*
											</span>
										) : (
											''
										)}
									</Label>
								) : (
									<></>
								)}
								<span
									style={{
										display: 'flex',
										flexDirection: 'row',
										alignItems: 'center',
									}}
								>
									<Input
										key={item[i].responseTag}
										type={item[i].type}
										onChange={(e) => {
											setInputData(item[i], e);
										}}
										onPaste={(e) => {
											if (
												item[i].label ===
													'Model Number' ||
												item[i].label ===
													'Serial Number'
											) {
												preventSpecialChars(
													e.clipboardData.getData(
														'text'
													),
													e,
													true
												);
											} else {
												preventSpecialChars(e.key, e);
											}
										}}
										onKeyPress={(e) => {
											if (
												item[i].label ===
													'Model Number' ||
												item[i].label ===
													'Serial Number'
											) {
												preventSpecialChars(
													e.key,
													e,
													true
												);
											} else {
												preventSpecialChars(e.key, e);
											}
										}}
										disabled={
											item[i].tiedTo &&
											responseData[item[i].tiedTo]
												? responseData[
														item[i].tiedTo
												  ] === 'true'
												: false
										}
										defaultValue={
											responseData[item[i].responseTag]
										}
										placeholder={item[i].placeholder}
									/>
								</span>
							</FormGroup>
						</Col>
					);
				}
			}
		}
		if (inputs[currentActiveTab - 1][pageName]) {
			let currentInput = inputs[currentActiveTab - 1][pageName][index];
			currentInput = JSON.parse(currentInput);
			if (uniqueAsset) {
				let rowLocalInputs = [
					<span key={formName}>
						<Row
							style={{
								display: 'flex',
								flexWrap: 'nowrap',
								flexDirection: isMobile ? 'column' : 'row',
								alignItems: 'flex-end',
							}}
						>
							{localInputs}
						</Row>
						<Row>
							<Col className='d-flex justify-content-end'>
								<ImageOptions
									item={item}
									responseData={responseData}
									capturePhoto={capturePhoto}
									setSelectedPhotoUrl={setSelectedPhotoUrl}
									setSelectedItem={setSelectedItem}
									setIsModalShowing={setIsModalShowing}
								/>
								<div>
									<GrAddCircle
										hidden={currentInput[0].isAdd}
										onClick={() => {
											let tempInputs = [...inputs];
											let newItems = [...currentInput];
											newItems.forEach((i) => {
												i.responseTag =
													i.responseTag +
													tempInputs[
														currentActiveTab - 1
													][pageName].length;
												i.isAdd = true;
											});
											tempInputs[currentActiveTab - 1][
												pageName
											].splice(
												index + 1,
												0,
												JSON.stringify(newItems)
											);

											setFormInputs(createFormInputs());
										}}
										style={{
											marginLeft: '15px',
											cursor: 'pointer',
										}}
									/>
									<GrSubtractCircle
										hidden={!currentInput[0].isAdd}
										onClick={() => {
											let item =
												inputs[currentActiveTab - 1][
													pageName
												][index];

											let response = { ...responseData };
											delete response[item.responseTag];
											setResponseData(response);
											// before splicing, we need to clear input data
											inputs[currentActiveTab - 1][
												pageName
											].splice(index, 1);

											setFormInputs(createFormInputs());
										}}
										style={{
											marginLeft: '15px',
											cursor: 'pointer',
										}}
									/>
								</div>
							</Col>
						</Row>
					</span>,
				];

				return rowLocalInputs;
			} else {
				let rowLocalInputs = [
					<span key={formName}>
						<Row
							style={{
								display: 'flex',
								flexWrap: 'nowrap',
								flexDirection:
									isMobile && !shouldOverrideFlex
										? 'column'
										: 'row',
								alignItems: 'flex-end',
							}}
						>
							{localInputs}
						</Row>
					</span>,
				];

				return rowLocalInputs;
			}
		}
	};

	const shouldItemHide = (item) => {
		if (item.hideFor) {
			let hideFor = item.hideFor;
			return hideFor.includes(responseData['assetType'].toLowerCase());
		}
		return false;
	};

	function debounce(func, timeout = 300) {
		let timer;
		return (...args) => {
			clearTimeout(timer);
			timer = setTimeout(() => {
				func.apply(this, args);
			}, timeout);
		};
	}

	const checkDuplicateAfterDelay = debounce(async (e, responseTag) => {
		const val = e.target.value;
		const dupExists = await checkDuplicateScan(val, responseTag);
		dupExists ? setAssetTagError(true) : setAssetTagError(false);
	});

	const getFormInputsLoop = (pageName = null, inputs) => {
		const formInputs = [];
		let index = 0;
		if (pageName)
			formInputs.push(
				<Label className='single-form-label'>{pageName}</Label>
			);
		for (let item of inputs) {
			let input;
			if (typeof item === 'string') {
				item = JSON.parse(item);
			}
			if (!item.hide) {
				if (
					!item.collection &&
					item.type !== 'label' &&
					!Array.isArray(item)
				) {
					if (shouldShowInput(item)) {
						if (
							item.type === 'button' &&
							item.responseTag === 'scan'
						) {
							input = (
								<Col className={isMobile ? 'col-6' : 'col-2'}>
									<FormGroup
										key='qr'
										className={
											isMobile ? 'col-12' : 'col-12'
										}
									>
										<br></br>
										<Button
											className='in-form-btn'
											onClick={() => {
												showScanner(
													item.scanTag,
													item.scanType
												);
											}}
										>
											{item.label}
										</Button>{' '}
									</FormGroup>
								</Col>
							);
						} else if (item.type === 'select') {
							if (item.default) {
								if (
									responseData[item.responseTag] !=
									item.default
								) {
									setInputData(item, {
										target: { value: item.default },
									});
								}
							}
							input = (
								<Row>
									<FormGroup
										key={index}
										className={
											isMobile ? 'col-12' : 'col-6'
										}
									>
										<Label className='single-form-label'>
											{item.label}
											{item.required == 'true' ? (
												<span style={{ color: 'red' }}>
													{' '}
													*
												</span>
											) : (
												''
											)}
										</Label>
										<br />
										<Input
											key={index}
											type={item.type}
											disabled={item.disabled || false}
											onChange={(e) => {
												setInputData(item, e);
											}}
											className={
												item.type !== 'checkbox'
													? 'form-inputs'
													: ''
											}
											placeholder={item.placeholder}
											value={
												responseData[item.responseTag]
											}
										>
											{getOptions(item)}
										</Input>
									</FormGroup>
								</Row>
							);
						} else if (item.type === 'image') {
							// Do nothing
						} else if (item.type === 'textarea') {
							input = (
								<Row>
									<FormGroup
										key={index}
										className={
											isMobile ? 'col-12' : 'col-6'
										}
										hidden={
											(item.responseTag == 'ifOther' &&
												responseData['assetType'] !==
													'Other') ||
											responseData[item.tiedTo] ===
												'No' ||
											(item.responseTag ===
												'otherAssetType' &&
												responseData['assetType'] !==
													'Other')
										}
									>
										<Label className='single-form-label'>
											{item.label}
											{item.required == 'true' ? (
												<span style={{ color: 'red' }}>
													{' '}
													*
												</span>
											) : (
												''
											)}
										</Label>
										<br />
										<Input
											key={index}
											type={item.type}
											onChange={(e) => {
												setInputData(item, e);
											}}
											className='text-area'
											defaultValue={
												responseData[item.responseTag]
											}
											placeholder={item.placeholder}
										/>
									</FormGroup>
								</Row>
							);
						} else if (item.type === 'switch') {
							if (
								!responseData[item.responseTag] &&
								item.default
							) {
								let response = { ...responseData };
								response[item.responseTag] = item.default;
								setResponseData(response);
							}
							input = (
								<Row>
									<FormGroup
										key={index}
										className={
											isMobile ? 'col-12' : 'col-6'
										}
										hidden={
											(item.responseTag == 'ifOther' &&
												responseData['assetType'] !==
													'Other') ||
											responseData[item.tiedTo] ===
												'No' ||
											(item.responseTag ===
												'otherAssetType' &&
												responseData['assetType'] !==
													'Other')
										}
									>
										<Label className='single-form-label'>
											{item.label}
											{item.required == 'true' ? (
												<span style={{ color: 'red' }}>
													{' '}
													*
												</span>
											) : (
												''
											)}
										</Label>
										<br />
										<div className='toggle'>
											<input
												key={index}
												type='checkbox'
												onChange={(e) => {
													setInputData(item, e);
												}}
												className='toggle-checkbox'
												defaultChecked={
													responseData[
														item.responseTag
													]
												}
											/>
											<div className='toggle-switch'>
												<span className='toggle-on-text'>
													ON
												</span>
												<span className='toggle-off-text'>
													OFF
												</span>
											</div>
											<div className='toggle-label'>
												{!responseData[item.responseTag]
													? ''
													: responseData[
															item.responseTag
													  ] == 'false'
													? 'No'
													: 'Yes'}
											</div>
										</div>
									</FormGroup>
								</Row>
							);
						} else if (item.type === 'date') {
							input = (
								<Row>
									<FormGroup
										key={index}
										className={
											isMobile ? 'col-12' : 'col-6'
										}
										hidden={
											(item.responseTag == 'ifOther' &&
												responseData['assetType'] !==
													'Other') ||
											(item.tiedTo &&
												(responseData[item.tiedTo] ===
													'No' ||
													responseData[
														item.tiedTo
													] ===
														'Self Contained Asset' ||
													responseData[
														item.tiedTo
													] === '' ||
													!responseData[
														item.tiedTo
													])) ||
											(item.responseTag ===
												'otherAssetType' &&
												responseData['assetType'] !==
													'Other')
										}
									>
										<Label className='single-form-label'>
											{item.label}
											{item.required == 'true' ? (
												<span style={{ color: 'red' }}>
													{' '}
													*
												</span>
											) : (
												''
											)}
										</Label>
										<br />
										<DatePicker
											showPopperArrow={false}
											key={item.responseTag}
											id={id}
											onChange={(e) => {
												setInputData(item, {
													target: {
														value: dayjs(e).format(
															'MM/DD/YYYY'
														),
													},
												});
												// checkDate(
												// 	{
												// 		target: {
												// 			value: dayjs(
												// 				e
												// 				).format(
												// 					'MM/DD/YYYY'
												// 					),
												// 				},
												// 	},
												// 			item
												// 		);
											}}
											popperPlacement='top-start'
											className='form-inputs form-control'
											selected={
												responseData[item.responseTag]
													? new Date(
															responseData[
																item.responseTag
															]
													  )
													: null
											}
											placeholderText={item.placeholder}
											minDate={
												item.futureDateOnly
													? new Date()
													: null
											}
											maxDate={
												item.futureDateOnly
													? null
													: new Date()
											}
										/>
									</FormGroup>
								</Row>
							);
						} else if (item.responseTag == 'ifOtherManufacturer') {
							input = (
								<Row>
									<FormGroup
										key={index}
										className={
											isMobile ? 'col-12' : 'col-6'
										}
										hidden={
											responseData[item.tiedTo] !==
											'(other)'
										}
									>
										<Label className='single-form-label'>
											{item.label}
											{item.required == 'true' ? (
												<span style={{ color: 'red' }}>
													{' '}
													*
												</span>
											) : (
												''
											)}
										</Label>
										<br />

										<Input
											key={index}
											type={item.type}
											onChange={(e) => {
												setInputData(item, e);
											}}
											onPaste={(e) =>
												preventSpecialChars(
													e.clipboardData.getData(
														'text'
													),
													e
												)
											}
											onKeyPress={(e) =>
												preventSpecialChars(e.key, e)
											}
											className={
												item.type !== 'checkbox'
													? 'form-inputs'
													: ''
											}
											defaultValue={
												responseData[item.responseTag]
											}
											placeholder={item.placeholder}
										/>
									</FormGroup>
								</Row>
							);
						} else {
							input = (
								<Row>
									<FormGroup
										key={index}
										className={
											isMobile ? 'col-12' : 'col-6'
										}
										hidden={
											(item.responseTag == 'ifOther' &&
												responseData['assetType'] !==
													'Other') ||
											(item.tiedTo &&
												(responseData[item.tiedTo] ===
													'No' ||
													responseData[
														item.tiedTo
													] ===
														'Self Contained Asset' ||
													responseData[
														item.tiedTo
													] === '' ||
													!responseData[
														item.tiedTo
													])) ||
											(item.responseTag ===
												'otherAssetType' &&
												responseData['assetType'] !==
													'Other') ||
											(item.responseTag === 'rackType' &&
												responseData[item.tiedTo] ===
													'Rack')
										}
									>
										<Label className='single-form-label'>
											{item.label}
											{item.required == 'true' ? (
												<span style={{ color: 'red' }}>
													{' '}
													*
												</span>
											) : (
												''
											)}
										</Label>
										<br />
										{item.label === 'Asset Tag ID' ? (
											<Form>
												<FormGroup>
													<Input
														value={
															responseData[
																item.responseTag
															]
														}
														invalid={assetTagError}
														valid={
															responseData[
																item.responseTag
															]
																? !assetTagError
																: false
														}
														type={item.type}
														key={index}
														disabled={
															assetTagScanned
														}
														onChange={(e) => {
															checkDuplicateAfterDelay(
																e,
																'assetTag'
															);
															setInputData(
																item,
																e
															);
														}}
														onPaste={(e) =>
															preventSpecialChars(
																e.clipboardData.getData(
																	'text'
																),
																e,
																true
															)
														}
														onKeyPress={(e) =>
															preventSpecialChars(
																e.key,
																e,
																true
															)
														}
														className='form-inputs'
													/>
													<FormFeedback invalid>
														ID already exists in
														database. Please enter a
														unique asset tag ID.
													</FormFeedback>
												</FormGroup>
											</Form>
										) : (
											<Input
												key={index}
												type={item.type}
												onChange={(e) => {
													setInputData(item, e);
												}}
												className={
													item.type !== 'checkbox'
														? 'form-inputs'
														: ''
												}
												defaultValue={
													responseData[
														item.responseTag
													]
												}
												placeholder={item.placeholder}
											/>
										)}
									</FormGroup>
								</Row>
							);
						}
					}
				} else {
					if (item.type === 'select') {
						if (shouldShowInput(item)) {
							if (item.default) {
								let response = { ...responseData };
								response[item.responseTag] = item.default;
								setResponseData(response);
							}
							input =
								item.collection === 'stores' ? (
									<LocationPicker
										isOpen={true}
										locations={locations}
										chosenLocation={
											savedLocation
												? `${savedLocation.e.data.name}: ${savedLocation.e.data.address1}`
												: 'Select a Location'
										}
										setChosenLocation={(e) => {
											setInputData(item, e);
											setSavedLocation({ item, e });
										}}
										setUserLocation={setUserLocation}
										organization={organization}
									/>
								) : (
									<Row>
										<FormGroup
											key={index}
											className={
												isMobile ? 'col-12' : 'col-6'
											}
										>
											<Label
												className='single-form-label'
												hidden={shouldItemHide(item)}
											>
												{item.label}
												{item.required == 'true' ? (
													<span
														style={{ color: 'red' }}
													>
														{' '}
														*
													</span>
												) : (
													''
												)}
											</Label>
											<br />
											<Input
												key={index}
												type={item.type}
												onChange={(e) => {
													setInputData(item, e);
												}}
												defaultValue={
													responseData[
														item.responseTag
													] || item.default
												}
												className='form-inputs'
												multiple={item.allowMultiple}
												hidden={shouldItemHide(item)}
											>
												<option></option>
												{getOptions(item)}
											</Input>
										</FormGroup>
									</Row>
								);
						}
					} else if (item.type === 'label') {
						if (shouldShowInput(item)) {
							input = (
								<FormGroup
									key={index}
									id='split-here'
									required={item.isRequired}
								>
									<Label
										className='single-form-label'
										style={{ fontWeight: 'bold' }}
									>
										{item.label}
									</Label>
								</FormGroup>
							);
						}
					} else if (Array.isArray(item)) {
						if (shouldShowInput(item)) {
							input = (
								<Row key={index}>
									{getInputsFromSubArray(
										item,
										index,
										pageName
									)}
								</Row>
							);
						}
					}
				}
			}
			if (input) formInputs.push(input);

			index++;
		}
		return formInputs;
	};

	const toggle = (tab) => {
		setCurrentPage(0);
		if (currentActiveTab !== tab) setCurrentActiveTab(tab);
	};

	const getPageNav = (formInputs) => {
		let tabs = [];
		if (multiplePages) {
			for (let i = 0; i < formInputs.length; i++) {
				let obj = (
					<NavItem key={i}>
						<NavLink
							className={
								currentActiveTab === (i + 1).toString()
									? 'active'
									: 'inactive'
							}
							onClick={() => {
								toggle((i + 1).toString());
							}}
						>
							<span style={{ fontWeight: 'bolder' }}>
								{formInputs[i][0]}
							</span>
						</NavLink>
					</NavItem>
				);

				tabs.push(obj);
			}
		}

		return tabs;
	};

	const handlePageClick = (i) => {
		setCurrentPage(i);
	};

	const getTabPanes = (formInputs) => {
		let panes = [];
		for (let i = 0; i < formInputs.length; i++) {
			let inputsToShow;
			if (Array.isArray(formInputs[i])) {
				inputsToShow = formInputs[i].splice(
					1,
					formInputs[i].length - 1
				);
			} else {
				inputsToShow = formInputs.splice(1, formInputs.length - 1);
			}
			let chunkedInputs = [];
			let index = 0;
			let paginationItems = [];

			for (let j = 0; j < inputsToShow.length; j++) {
				if (inputsToShow[j].props.id === 'split-here') {
					if (j != 0) index += 1;
				}
				if (chunkedInputs[index] === undefined)
					chunkedInputs[index] = [];
				chunkedInputs[index].push(inputsToShow[j]);
			}
			for (let j = 0; j < chunkedInputs.length; j++) {
				let label = (
					<span style={{ fontWeight: 'bold' }}>Location Details</span>
				);
				if (chunkedInputs[j][0].props.id === 'split-here') {
					label = chunkedInputs[j].shift();
				}
				if (label.props.required) {
					if (showRequiredDetails) {
						paginationItems.push(
							<AccordionItem key={j}>
								<AccordionHeader
									key={j}
									targetId={j}
									onClick={() =>
										setCurrentPage(
											currentPage === j ? null : j
										)
									}
								>
									{label}
								</AccordionHeader>
								<AccordionBody accordionId={j}>
									{chunkedInputs[j]}
								</AccordionBody>
							</AccordionItem>
						);
					}
				} else {
					if (chunkedInputs[j].length > 0) {
						paginationItems.push(
							<AccordionItem key={j}>
								<AccordionHeader
									key={j}
									targetId={j}
									onClick={() =>
										setCurrentPage(
											currentPage === j ? null : j
										)
									}
								>
									{label}
								</AccordionHeader>
								<AccordionBody accordionId={j}>
									{chunkedInputs[j]}
								</AccordionBody>
							</AccordionItem>
						);
					}
				}
			}

			panes.push(
				<TabPane tabId={(i + 1).toString()}>
					<Accordion
						open={currentPage}
						toggle={() => handlePageClick(currentPage)}
					>
						{paginationItems}
					</Accordion>
				</TabPane>
			);
		}

		if (!multiplePages) {
			panes.push(
				<TabPane tabId={formInputs.length.toString()}>
					<Accordion
						open={currentPage}
						toggle={() => handlePageClick(currentPage)}
					>
						<AccordionItem key={formInputs.length}>
							<AccordionHeader
								key={formInputs.length}
								targetId={formInputs.length + 2}
								onClick={() => {
									setCurrentPage(
										currentPage === formInputs.length + 2
											? null
											: formInputs.length + 2
									);
								}}
							>
								<span style={{ fontWeight: 'bold' }}>
									Photos
								</span>
							</AccordionHeader>
							<AccordionBody accordionId={formInputs.length + 2}>
								{getButtons()}
							</AccordionBody>
						</AccordionItem>
					</Accordion>
				</TabPane>
			);
		}

		if (flags && flags.allowWarrantyFields) {
			panes.push(
				<TabPane tabId={formInputs.length.toString()}>
					<Accordion
						open={currentPage}
						toggle={() => handlePageClick(currentPage)}
					>
						<AccordionItem key={formInputs.length}>
							<AccordionHeader
								key={formInputs.length}
								targetId={formInputs.length + 3}
								onClick={() => {
									setCurrentPage(
										currentPage === formInputs.length + 3
											? null
											: formInputs.length + 3
									);
								}}
							>
								<span style={{ fontWeight: 'bold' }}>
									Warranty Details
								</span>
							</AccordionHeader>
							<AccordionBody accordionId={formInputs.length + 3}>
								<Row>
									<FormGroup
										className={
											isMobile ? 'col-12' : 'col-6'
										}
									>
										<Label className='single-form-label'>
											Warranty Type
										</Label>
										<Input
											value={responseData['warrantyType']}
											type='select'
											onChange={(e) => {
												setInputData(
													{
														responseTag:
															'warrantyType',
													},
													e
												);
											}}
											className='form-inputs'
										>
											<option></option>
											<option>Extended</option>
											<option>Manufacturers</option>
										</Input>
									</FormGroup>
								</Row>
								<Row>
									<FormGroup
										className={
											isMobile ? 'col-12' : 'col-6'
										}
									>
										<Label className='single-form-label'>
											Warranty Provider
										</Label>
										<Input
											value={
												responseData['warrantyProvider']
											}
											type='text'
											onChange={(e) => {
												setInputData(
													{
														responseTag:
															'warrantyProvider',
													},
													e
												);
											}}
											onPaste={(e) =>
												preventSpecialChars(
													e.clipboardData.getData(
														'text'
													),
													e
												)
											}
											onKeyPress={(e) =>
												preventSpecialChars(e.key, e)
											}
											className='form-inputs'
										></Input>
									</FormGroup>
								</Row>
								<Row>
									<FormGroup
										className={
											isMobile ? 'col-12' : 'col-6'
										}
									>
										<Label className='single-form-label'>
											Warranty Expiration Date
										</Label>
										<DatePicker
											showPopperArrow={false}
											onChange={(e) => {
												setInputData(
													{
														responseTag:
															'warrantyExpiration',
													},
													{
														target: {
															value: dayjs(
																e
															).format(
																'MM/DD/YYYY'
															),
														},
													}
												);
											}}
											popperPlacement='top-start'
											className='form-inputs form-control'
											selected={
												responseData[
													'warrantyExpiration'
												]
													? new Date(
															responseData[
																'warrantyExpiration'
															]
													  )
													: null
											}
											minDate={new Date()}
										/>
									</FormGroup>
								</Row>
								<Row>
									<FormGroup
										className={
											isMobile ? 'col-12' : 'col-6'
										}
									>
										<Label className='single-form-label'>
											Warranty Term (Months)
										</Label>
										<Input
											value={responseData['warrantyTerm']}
											type='number'
											onChange={(e) => {
												setInputData(
													{
														responseTag:
															'warrantyTerm',
													},
													e
												);
											}}
											onPaste={(e) =>
												preventSpecialChars(
													e.clipboardData.getData(
														'text'
													),
													e
												)
											}
											onKeyPress={(e) =>
												preventSpecialChars(e.key, e)
											}
											min='1'
											max='120'
											invalid={
												responseData['warrantyTerm'] <
													1 ||
												responseData['warrantyTerm'] >
													120
											}
											className='form-inputs'
										></Input>
										<FormFeedback invalid>
											Please enter a valid number.
										</FormFeedback>
									</FormGroup>
								</Row>
							</AccordionBody>
						</AccordionItem>
					</Accordion>
				</TabPane>
			);
		}

		return panes;
	};

	const createFormInputs = () => {
		let formInputs = [];
		if (multiplePages) {
			let sortedInputs = _.sortBy(inputs, 'page');
			for (let i = 0; i < sortedInputs.length; i++) {
				delete sortedInputs[i].page;

				let keys = Object.keys(sortedInputs[i]);

				for (let key of keys) {
					formInputs.push(
						getFormInputsLoop(key, sortedInputs[i][key])
					);
				}
			}
		} else {
			if (inputs.length > 0) {
				delete inputs[0].page;
				let keys = Object.keys(inputs[0]);
				formInputs = getFormInputsLoop(keys[0], inputs[0][keys[0]]);
			}
		}

		if (multiplePages) {
			return (
				<div>
					<Nav tabs className='form'>
						{getPageNav(formInputs)}
					</Nav>
					<TabContent
						activeTab={currentActiveTab}
						className='fullSize'
					>
						{getTabPanes(formInputs)}
					</TabContent>
				</div>
			);
		} else {
			return (
				<div>
					<Nav tabs className='form'>
						{getPageNav(formInputs)}
					</Nav>
					<TabContent
						activeTab={currentActiveTab}
						className='fullSize'
					>
						{getTabPanes(formInputs)}
					</TabContent>
				</div>
			);
		}
	};

	/**
	 * Time for some hard coding. Can't avoid this forever. The forms we are doing are too specific.
	 * This is a function that takes the current state of selections and decides to show/hide inputs.
	 *
	 * If it doesn't match the proper
	 * @param {input object} item
	 * @returns boolean
	 */
	const shouldShowInput = (item) => {
		// Always show asset type and location
		if (
			item.responseTag === 'assetType' ||
			item.responseTag === 'location'
		) {
			return true;
		}
		// Once we pick a asset type, start doing boolean logic
		if (responseData.assetType) {
			return true;
			// If the asset type is roof, only show roof type input
			// if (responseData.assets.toLowerCase() === 'roof') {
			// 	if (item.responseTag === 'roofType') return true;
			// }
			// // If the asset type is parking lot, only show parking lot condition
			// else if (responseData.assets.toLowerCase() === 'parking lot') {
			// 	if (item.responseTag === 'parkingLotCondition') return true;
			// }
			// // If the asset type is exit signs, only show exit sign input
			// else if (responseData.assets.toLowerCase() === 'exit signs') {
			// 	if (
			// 		item.responseTag === 'exitSigns' ||
			// 		item.responseTag === 'exitSignsExp'
			// 	)
			// 		return true;
			// } else {
			// 	if (
			// 		item.responseTag !== 'roofType' &&
			// 		item.responseTag !== 'parkingLotCondition' &&
			// 		item.responseTag !== 'exitSigns' &&
			// 		item.responseTag !== 'exitSignsExp'
			// 	)
			// 		return true;
			// }
		} else if (uniqueAsset) {
			return true;
		}

		return false;
	};

	const getButtons = () => {
		let buttons = [];
		if (flags) {
			if (flags.allowPhotoOFEquipment) {
				buttons.push(
					<Col>
						<FormGroup key='equipment'>
							<Label className='single-form-label'>
								Photo of Equipment
							</Label>
							<br />
							<label
								hidden={responseData['equipmentAssetImage']}
								htmlFor={'equipmentImage'}
							>
								<span className='btn'>Take Photo</span>
							</label>

							<Input
								style={{ display: 'none' }}
								id='equipmentImage'
								type='file'
								onChange={(e) =>
									capturePhoto(e, [
										{ responseTag: 'equipment' },
									])
								}
							/>
						</FormGroup>
						<span hidden={!responseData['equipmentAssetImage']}>
							<GrCheckmark
								style={{ cursor: 'pointer' }}
								onClick={() => {
									setSelectedPhotoUrl(
										responseData['equipmentAssetImage']
									);
									setIsModalShowing(true);
									setSelectedItem({
										responseTag: 'equipmentAssetImage',
									});
								}}
							/>
						</span>
					</Col>
				);
			}
			if (flags.allowManufacturersPlate) {
				buttons.push(
					<Col>
						<FormGroup key='manufacturer'>
							<Label className='single-form-label'>
								Manufacturers Plate
							</Label>
							<br />
							<label
								hidden={
									responseData['manufacturersPlateAssetImage']
								}
								htmlFor={'manufacturersPlate'}
							>
								<span className='btn'>Take Photo</span>
							</label>

							<Input
								style={{ display: 'none' }}
								id='manufacturersPlate'
								type='file'
								onChange={(e) =>
									capturePhoto(e, [
										{ responseTag: 'manufacturersPlate' },
									])
								}
							/>
						</FormGroup>
						<span
							hidden={
								!responseData['manufacturersPlateAssetImage']
							}
						>
							<GrCheckmark
								style={{ cursor: 'pointer' }}
								onClick={() => {
									setSelectedPhotoUrl(
										responseData[
											'manufacturersPlateAssetImage'
										]
									);
									setIsModalShowing(true);
									setSelectedItem({
										responseTag:
											'manufacturersPlateAssetImage',
									});
								}}
							/>
						</span>
					</Col>
				);
			}
			if (flags.allowAssetIdTagImage) {
				buttons.push(
					<Col>
						<FormGroup key='asset'>
							<Label className='single-form-label'>
								Asset ID Tag
							</Label>
							<br />
							<label
								hidden={responseData['idTagAssetImage']}
								htmlFor={'idTag'}
							>
								<span className='btn'>Take Photo</span>
							</label>

							<Input
								style={{ display: 'none' }}
								// accept='image/*'
								id='idTag'
								type='file'
								// capture='environment'
								onChange={(e) =>
									capturePhoto(e, [{ responseTag: 'idTag' }])
								}
							/>
						</FormGroup>
						<span hidden={!responseData['idTagAssetImage']}>
							<GrCheckmark
								style={{ cursor: 'pointer' }}
								onClick={() => {
									setSelectedPhotoUrl(
										responseData['idTagAssetImage']
									);
									setIsModalShowing(true);
									setSelectedItem({
										responseTag: 'idTagAssetImage',
									});
								}}
							/>
						</span>
					</Col>
				);
			}
			if (flags.allowTempAlertIdImage) {
				buttons.push(
					<Col>
						<FormGroup key='temp-alert'>
							<Label className='single-form-label'>
								Temp Alert ID Tag
							</Label>
							<br />
							<label
								hidden={responseData['tempAlertIdAssetImage']}
								htmlFor={'tempAlertId'}
							>
								<span className='btn'>Take Photo</span>
							</label>

							<Input
								style={{ display: 'none' }}
								// accept='image/*'
								id='tempAlertId'
								type='file'
								// capture='environment'
								onChange={(e) =>
									capturePhoto(e, [
										{ responseTag: 'tempAlertId' },
									])
								}
							/>
						</FormGroup>
						<span hidden={!responseData['tempAlertIdAssetImage']}>
							<GrCheckmark
								style={{ cursor: 'pointer' }}
								onClick={() => {
									setSelectedPhotoUrl(
										responseData['tempAlertIdAssetImage']
									);
									setIsModalShowing(true);
									setSelectedItem({
										responseTag: 'tempAlertIdAssetImage',
									});
								}}
							/>
						</span>
					</Col>
				);
			}
			if (flags.allowWideAngleImage) {
				buttons.push(
					<Col>
						<FormGroup key='temp-alert'>
							<Label className='single-form-label'>
								Wide Angle Asset Image
							</Label>
							<br />
							<label
								hidden={responseData['wideAngleAssetImage']}
								htmlFor={'wideAngle'}
							>
								<span className='btn'>Take Photo</span>
							</label>

							<Input
								style={{ display: 'none' }}
								// accept='image/*'
								id='wideAngle'
								type='file'
								// capture='environment'
								onChange={(e) =>
									capturePhoto(e, [
										{ responseTag: 'wideAngle' },
									])
								}
							/>
						</FormGroup>
						<span hidden={!responseData['wideAngleAssetImage']}>
							<GrCheckmark
								style={{ cursor: 'pointer' }}
								onClick={() => {
									setSelectedPhotoUrl(
										responseData['wideAngleAssetImage']
									);
									setIsModalShowing(true);
									setSelectedItem({
										responseTag: 'wideAngleAssetImage',
									});
								}}
							/>
						</span>
					</Col>
				);
			}
		}
		let row = [];
		row.push(<Row className='form-btn-row'>{buttons}</Row>);
		return row;
	};

	const hasMainKeyBeenAdded = (key, group) => {
		let keys = Object.keys(group);
		for (let i = 0; i < keys.length; i++) {
			if (key.includes(keys[i])) {
				return { status: true, key: keys[i], index: i };
			}
		}
		return { status: false, index: -1 };
	};

	const generateProperResponse = () => {
		let keys = Object.keys(responseData);
		let groupedKeys = {};
		//groupedKeys.push(keys[i]);
		for (let j = 0; j < keys.length; j++) {
			// remove later
			if (keys[j] == 'summary') continue;
			if (hasMainKeyBeenAdded(keys[j], groupedKeys).status) {
				let newObj = {
					key: keys[j],
				};
				let key = keys[j];
				if (keys[j].includes('AssetImage')) {
					const index = key.indexOf('AssetImage');
					newObj.image = {
						url: responseData[keys[j]],
						responseTag: key.slice(index),
					};
					key = key.slice(0, index);
				} else if (keys[j].includes('assetQuality')) {
					key = key.replace('assetQuality', '');
					newObj.quality = responseData[keys[j]];
				} else {
					newObj.value = responseData[keys[j]];
				}
				let gKey = hasMainKeyBeenAdded(keys[j], groupedKeys).key;
				if (key === gKey) {
					// Is main key, edit index 0.

					const existingObj = groupedKeys[key][0];

					// Multi-image support
					if (newObj.image) {
						const existingImages = existingObj.image
							? existingObj.image
							: [];
						newObj.image = [...existingImages, newObj.image];
					}

					delete newObj.key;
					groupedKeys[key][0] = { ...existingObj, ...newObj };
				} else {
					let found = -1;
					if (!isNaN(parseInt(key.charAt(0)))) {
						key = key.slice(2, key.length);
						newObj.key = key;
					}

					for (let k = 0; k < groupedKeys[gKey].length; k++) {
						if (groupedKeys[gKey][k].key === key) {
							found = k;
						}
					}

					if (found > -1) {
						let existingObj = groupedKeys[gKey][found];
						groupedKeys[gKey][found] = {
							...existingObj,
							...newObj,
						};
					} else {
						newObj.key = key;
						groupedKeys[gKey].push(newObj);
					}
				}

				// if (!isNaN(parseInt(key.charAt(0)))) {
				// 	key = key.slice(2,key.length);
				// }

				// if (key === gKey) {
				// 	groupedKeys[gKey].push(newObj);
				// } else {
				// 	groupedKeys[gKey].push(newObj);
				// }
			} else {
				groupedKeys[keys[j]] = [];
				let newObj = {
					key: keys[j],
				};
				if (keys[j].includes('AssetImage'))
					newObj.image = responseData[keys[j]];
				else if (keys[j].includes('assetQuality'))
					newObj.quality = responseData[keys[j]];
				else newObj.value = responseData[keys[j]];

				groupedKeys[keys[j]].push(newObj);
			}
		}

		return groupedKeys;
	};

	const submit = async (enterNew = false) => {
		if (submitDisabled) return;
		else await setSubmitDisabled(true);
		// eslint-disable-next-line no-async-promise-executor
		return new Promise(async (resolve, reject) => {
			// Check Required Fields
			for (let i = 0; i < inputs.length; i++) {
				let inp = inputs[i];
				let keys = Object.keys(inp);
				for (let k of keys) {
					if (Array.isArray(inp[k])) {
						for (let j = 0; j < inp[k].length; j++) {
							let subInput = JSON.parse(inp[k][j]);
							if (Array.isArray(subInput)) {
								for (let l = 0; l < subInput.length; l++) {
									if (
										((subInput[0].altTiedTo &&
											(responseData[
												subInput[0].altTiedTo
											] === 'Rack' ||
												responseData[
													subInput[0].altTiedTo
												] ===
													'Self Contained Asset')) ||
											(subInput[0].required == 'true' &&
												subInput[1].altRequired ==
													'true')) &&
										!responseData[
											subInput[0].responseTag
										] &&
										(!responseData[
											subInput[1].responseTag
										] ||
											responseData[
												subInput[1].responseTag
											] == 'false')
									) {
										setAlertModal({
											title: 'Missing Fields',
											body: `Please enter a value for "${subInput[0].label} "(or check "${subInput[1].label}")`,
											show: true,
										});
										setSubmitDisabled(false);
										reject(false);
										return;
									}
								}
							} else {
								if (
									subInput.required == 'true' &&
									!responseData[subInput.responseTag]
								) {
									setAlertModal({
										title: 'Missing Fields',
										body: `Please enter a value for "${subInput.label}"`,
										show: true,
									});
									setSubmitDisabled(false);
									reject(false);
									return;
								}
							}
						}
					}
				}
			}

			// Check Required Photo Buttons
			if (!multiplePages) {
				if (flags.allowPhotoOFEquipment) {
					if (!responseData['equipmentAssetImage']) {
						setAlertModal({
							title: 'Missing Fields',
							body: 'Please add a photo of the Equipment',
							show: true,
						});
						setSubmitDisabled(false);
						reject(false);
						return;
					}
				}
				if (flags.allowManufacturersPlate) {
					if (!responseData['manufacturersPlateAssetImage']) {
						setAlertModal({
							title: 'Missing Fields',
							body: 'Please add a photo of the Manufacturers Plate',
							show: true,
						});
						setSubmitDisabled(false);
						reject(false);
						return;
					}
				}
				if (flags.allowAssetIdTagImage) {
					if (!responseData['idTagAssetImage']) {
						setAlertModal({
							title: 'Missing Fields',
							body: 'Please add a photo of the Asset Tag ID',
							show: true,
						});
						setSubmitDisabled(false);
						reject(false);
						return;
					}
				}
				if (flags.allowTempAlertIdImage) {
					if (!responseData['tempAlertIdAssetImage']) {
						setAlertModal({
							title: 'Missing Fields',
							body: 'Please add a photo of the Temp Alert ID',
							show: true,
						});
						setSubmitDisabled(false);
						reject(false);
						return;
					}
				}
				if (flags.allowWideAngleImage) {
					if (!responseData['wideAngleAssetImage']) {
						setAlertModal({
							title: 'Missing Fields',
							body: 'Please add a photo of the Wide Angle Asset Image',
							show: true,
						});
						setSubmitDisabled(false);
						reject(false);
						return;
					}
				}
			}

			// Check Location Field
			if (!responseData.location) {
				setAlertModal({
					title: 'Missing Fields',
					body: 'Please select a location',
					show: true,
				});
				setSubmitDisabled(false);
				reject(false);
				return;
			}

			// Check for duplicates based on make, model, & serial
			let dupsSnap = {};
			if (
				responseData.manufacturer &&
				responseData.modelNumber &&
				responseData.serialNumber
			) {
				dupsSnap = await getSubmissionWithFilter(organization, formId, [
					{
						name: DB_FILTER.MANUFACTURER,
						value: responseData.manufacturer,
					},
					{
						name: DB_FILTER.MODEL_NUMBER,
						value: responseData.modelNumber,
					},
					{
						name: DB_FILTER.SERIAL_NUMBER,
						value: responseData.serialNumber,
					},
				]);
			} else {
				dupsSnap.empty = true;
			}
			if (!dupsSnap.empty && !dupWarningShown) {
				setDupWarningShown(true);
				setAlertModal({
					title: 'Duplicate Asset Detected',
					body: 'It appears there is already another submission with the same make, model, and serial. Please confirm that the details are correct and try submitting again.',
					show: true,
				});
				setSubmitDisabled(false);
				reject(false);
				return;
			}

			// Check if duplicate asset tag id
			if (assetTagError) {
				setAlertModal({
					title: 'Duplicate Asset Tag ID',
					body: 'Unable to enter submission. Please enter a unique asset tag ID.',
					show: true,
				});
				setSubmitDisabled(false);
				reject(false);
				return;
			}

			// Check warranty date/term if there is warranty type
			let percentWarrantyLeft = null;
			let isWarrantyExpired = null;
			if (responseData.warrantyTerm && !responseData.warrantyExpiration) {
				setAlertModal({
					title: 'Missing Fields',
					body: 'Please enter a date for warranty expiration',
					show: true,
				});
				setSubmitDisabled(false);
				reject(false);
				return;
			} else if (
				responseData.warrantyExpiration &&
				!responseData.warrantyTerm
			) {
				setAlertModal({
					title: 'Missing Fields',
					body: 'Please enter a date for warranty term',
					show: true,
				});
				setSubmitDisabled(false);
				reject(false);
				return;
			} else if (
				responseData.warrantyExpiration &&
				responseData.warrantyTerm
			) {
				const today = new Date();
				const expDate = new Date(responseData.warrantyExpiration);
				const difference = expDate.getTime() - today.getTime();
				const days = difference / (1000 * 60 * 60 * 24);
				percentWarrantyLeft = (
					(days / (Number(responseData.warrantyTerm) * 30)) *
					100
				).toFixed(1);
				if (Number(percentWarrantyLeft) > 0) isWarrantyExpired = false;
				else if (Number(percentWarrantyLeft) <= 0)
					isWarrantyExpired = true;
			}

			if (
				responseData.manufacturer === '(other)' &&
				!responseData.ifOtherManufacturer &&
				responseData.makeNotAvailable !== 'true'
			) {
				setAlertModal({
					title: 'Missing Fields',
					body: 'Please enter other manufacturer.',
					show: true,
				});
				setSubmitDisabled(false);
				reject(false);
				return;
			}

			let locDoc = await getLocation(organization, responseData.location);
			if (locDoc.exists()) {
				// Generate new response
				const resp = generateProperResponse();
				const newResponse = responseData;
				// Update fields & add submission
				const locationId = locDoc.id;
				let submittingUser = 'n/a';
				if (auth.currentUser.uid) {
					const docSnap = await getUser(auth.currentUser.uid);
					if (docSnap) {
						submittingUser = docSnap.name;
					} else submittingUser = docSnap.uid;
				}
				newResponse.inputs = resp;
				newResponse.location = locDoc.data();
				newResponse.location.id = locationId;
				newResponse.submittedDate = new Date().toISOString();
				newResponse.submittingUserId = auth.currentUser.uid;
				newResponse.submittingUser = submittingUser;
				newResponse.formName = formName;
				newResponse.deleted = false;
				newResponse.formId = formId;
				newResponse.organization = organization;
				newResponse.createdTimestamp = new Date().getTime(); // unix timestamp for algolia
				// Add "reviewed" field to Cushman submissions
				if (organization == DB_ORG.CUSHMANWAKEFIELD) {
					newResponse.reviewed = 'false';
				}
				if (userLocation.latitude && userLocation.longitude)
					newResponse.userLocation = userLocation;
				// Add to qa-submissions if 'ifOther' is filled out
				// Otherwise, add to form responses & assets (if applicable)
				if (newResponse.ifOtherManufacturer || !dupsSnap.empty) {
					await addQA(organization, newResponse);
				} else {
					const responseRef = await addSubmission(
						organization,
						formId,
						newResponse
					);
					// Create new asset if required
					if (isAssetForm) {
						const asset = {
							responseRef: responseRef,
							assetType: newResponse.assetType,
							make: newResponse.manufacturer || null,
							model: newResponse.modelNumber || null,
							serial: newResponse.serialNumber || null,
							dateOfBirth: newResponse.manufacturerDate || null,
							submittingUser: submittingUser,
							locationId: locationId,
							other: newResponse,
							deleted: false,
							decommissioned: false,
							isWarrantyExpired,
							percentWarrantyLeft,
						};
						const assetRef = await addAsset(organization, asset);
						await updateWithRef(responseRef, {
							assetRef: assetRef,
						});
					}
				}

				// Delete draft if applicable
				if (draftId) {
					await deleteDraft(organization, draftId);
				}

				// Redirect if applicable
				if (!enterNew && !newResponse.ifOtherManufacturer) {
					navigate('/locations/' + locationId, {
						state: {
							data: responseData.location,
							id: locationId,
							tab: 'Submissions',
						},
					});
				} else if (
					!enterNew &&
					(newResponse.ifOtherManufacturer || !dupsSnap.empty)
				) {
					navigate('/locations/' + locationId, {
						state: {
							data: responseData.location,
							id: locationId,
							tab: 'QA Submissions',
						},
					});
				} else if (enterNew) {
					await clearState();
					await setResponseData({
						location: savedLocation.e.id,
					});
					await setFormId(id);
					await getLocations();
					await getManufacturers();
					await setSubmitDisabled(false);
				}
			}

			resolve(true);
		});
	};

	const checkDuplicateScan = async (e, responseTag) => {
		const querySnapshot = await getSubmissionWithTag(
			organization,
			formId,
			responseTag,
			e
		);
		return !querySnapshot.empty;
	};

	const setScanData = async (e) => {
		const isDup = await checkDuplicateScan(e, scannedTag);
		if (isDup) {
			alert(
				`Field ${scannedTag} already exists in database. Please submit a value.`
			);
			return;
		} else if (!isDup) {
			setInputData({ responseTag: scannedTag }, { target: { value: e } });
			if (scannedTag === 'assetTag') setAssetTagScanned(true);
		}
		setIsBarcodeShowing(false);
		setIsQRShowing(false);
	};

	const showScanner = async (tag, type) => {
		await setScannedTag(tag);
		if (type === 'qr') setIsQRShowing(true);
		else if (type === 'barcode') setIsBarcodeShowing(true);
	};

	const closedAlertModal = () => {
		setAlertModal({
			show: false,
			title: '',
			body: '',
		});
	};

	// only allows alphanumeric characters
	const preventSpecialChars = (val, e, disableSpaces = false) => {
		let regEx = /^[a-zA-Z\d\s.]+$/;
		if (disableSpaces) regEx = /^[a-zA-Z\d.]+$/;
		if (!regEx.test(val)) {
			e.preventDefault();
		}
	};

	return isLoading ? (
		<Loader />
	) : (
		<div>
			<Card className='px-2 pt-4 some-drop-shadow'>
				<span className='logo-container d-flex'>
					<img
						src={formLogo}
						className='form-logo'
						hidden={!formLogo}
						alt='logo'
					/>
				</span>
				<CardHeader
					style={{
						backgroundColor: '#ffffff',
						border: 'none',
						textAlign: 'center',
					}}
				>
					<span className='form-title'>{formName}</span>
				</CardHeader>
				<Form className='' onSubmit={(e) => e.preventDefault()}>
					{formInputs}
					<div className='form-btns-submit'>
						<Row>
							<Button
								color='secondary'
								className='submit-btn-form'
								onClick={() => submit(false)}
								disabled={submitDisabled}
							>
								{!submitDisabled ? 'Submit' : 'Submitting...'}
							</Button>
						</Row>
						<Row>
							<Button
								color='secondary'
								className='submit-btn-form'
								onClick={() => submit(true)}
								disabled={submitDisabled}
							>
								{!submitDisabled
									? 'Submit & Enter New'
									: 'Submitting...'}
							</Button>
						</Row>
					</div>
				</Form>
			</Card>
			<SubmittedImageModal
				showing={isModalShowing}
				photoUrl={selectedPhotoUrl}
				selectedItem={selectedItem}
				cancel={() => setIsModalShowing(false)}
				removeImage={() => {
					let data = responseData;
					delete data[selectedItem.responseTag];
					setResponseData(data);
					setIsModalShowing(false);
					setFormInputs(createFormInputs());
				}}
				switchToCrop={() => {
					setIsModalShowing(false);
					setIsCropShowing(true);
					setPhotoToBeCropped(selectedPhotoUrl);
				}}
			/>
			<CropperModal
				showing={isCropShowing}
				photoUrl={photoToBeCropped}
				onCrop={(data) => getCroppedPhoto(data)}
				cancel={() => {
					let data = responseData;
					delete data[selectedItem.responseTag];
					setResponseData(data);
					setIsCropShowing(false);
					setFormInputs(createFormInputs());
				}}
			/>

			<LoadingModal showing={showLoader} />

			{isQRShowing ? (
				<QRModal
					showing={isQRShowing}
					cancel={() => {
						setScannedTag();
						setIsQRShowing(false);
					}}
					gotData={(e) => setScanData(e)}
				/>
			) : (
				<></>
			)}
			{isBarcodeShowing ? (
				<BarcodeModal
					showing={isBarcodeShowing}
					cancel={() => {
						setScannedTag();
						setIsBarcodeShowing(false);
					}}
					gotData={(text, result) => setScanData(text, result)}
				/>
			) : (
				<></>
			)}
			<Modal centered isOpen={alertModal.show} toggle={closedAlertModal}>
				<ModalHeader toggle={closedAlertModal}>
					{alertModal.title}
				</ModalHeader>
				<ModalBody>{alertModal.body}</ModalBody>
			</Modal>
		</div>
	);
};

export { SingleForm };
