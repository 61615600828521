///////////////////////////////////////////////////////////////////////
import { Row, Col, Card, Form, Input, CardHeader, Button } from 'reactstrap';
import { auth, AuthProviders } from '@assets/services/auth-service';
import { useState } from 'react';
import {
	IoMailOpenOutline,
	IoLockClosedOutline,
	IoEyeOffOutline,
	IoEyeOutline,
} from 'react-icons/io5';
import logo1 from '@assets/img/logo-clear.png';
import logo2 from '@assets/img/Property_Eco_Logo-03.png';
import { getUser, setuser } from '@services/user-service';
import { getOrganization } from '@services/organization-service';
import { setUser } from '@sentry/react';
///////////////////////////////////////////////////////////////////////

const Login = () => {
	const [isResetting, setIsResetting] = useState(false);
	const [isCreating, setIsCreating] = useState(false);
	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');
	const [name, setName] = useState('');
	const [organization, setOrganization] = useState('');

	const [passwordShown, setPasswordShown] = useState(false);
	const togglePassword = (e) => {
		e.preventDefault();
		setPasswordShown(!passwordShown);
	};

	const submit = async () => {
		try {
			const authResponse = await AuthProviders.signInWithEmailAndPassword(
				auth,
				email,
				password
			);
			const user = await getUser(auth.currentUser.uid);
			if (user) {
				await AuthProviders.updateProfile(authResponse.user, {
					displayName: user.name,
				});
				window.location.href = '/';
			}
		} catch (error) {
			alert(error);
		}
	};

	const createAccount = async () => {
		try {
			const docSnap = await getOrganization(organization);
			if (docSnap.exists()) {
				const authResponse =
					await AuthProviders.createUserWithEmailAndPassword(
						auth,
						email,
						password
					);
				const user = authResponse.user;
				await AuthProviders.updateProfile(user, { displayName: name });

				const userData = {
					uid: user.uid,
					name,
					email: user.email,
					organization: organization,
					role: 'user',
				}
				await setuser(user.uid, userData)
				await setUser(user.uid, {
					...userData
				});

				window.location.href = '/';
			} else {
				throw new Error(
					'No Organization Found, Ask an Admin for the Correct URL'
				);
			}
			return;
		} catch (error) {
			alert(error);
		}
	};

	const resetPassword = async () => {
		try {
			await AuthProviders.sendPasswordResetEmail(auth, email);
			alert('Password Reset Sent');
		} catch (error) {
			alert(error);
		}
	};

	///////////////////////////////////////////////////////////////////////

	///////////////////////////////////////////////////////////////////////
	return (
		<div className='main'>
			{!isCreating && !isResetting ? (
				<Card className='logincard'>
					<img
						className='logo-img1 mt-3'
						src={logo1}
						alt='react'
					></img>
					<img className='logo-img2' src={logo2} alt='react'></img>
					<div className='login-motto mb-2 text-center'>
						Where Your Assets Live.
					</div>
					<Row>
						<Col>
							<Form>
								<span className='emailContainer'>
									<ul className='email-icon'>
										<IoMailOpenOutline />
									</ul>
									<Input
										value={email}
										className='email-input'
										placeholder='Email'
										type='email'
										onChange={(e) =>
											setEmail(e.target.value)
										}
										onKeyPress={(e) =>
											e.key === 'Enter' ? submit() : null
										}
									/>
								</span>
								<span className='passwordContainer'>
									<ul className='password-icon'>
										<IoLockClosedOutline />
									</ul>
									<Input
										value={password}
										className='password-input'
										placeholder='Password'
										type={
											passwordShown ? 'text' : 'password'
										}
										onChange={(e) =>
											setPassword(e.target.value)
										}
										onKeyPress={(e) =>
											e.key === 'Enter' ? submit() : null
										}
									/>
									<Button
										className='passtog-icon'
										onClick={togglePassword}
									>
										{passwordShown ? (
											<IoEyeOutline />
										) : (
											<IoEyeOffOutline />
										)}
									</Button>
								</span>
								<div className='lowerButtons'>
									<Button
										className='logButton'
										onClick={submit}
									>
										Log In
									</Button>
									<div className='orBar'>
										<span className='before'></span>
										<span className='text'>OR</span>
										<span className='after'></span>
									</div>
									<div className='row'>
										<div className='col'>
											<Button
												className='createButton'
												onClick={() =>
													setIsCreating(true)
												}
											>
												Create Account
											</Button>
										</div>
										<div className='col'>
											<Button
												className='createButton'
												onClick={() =>
													setIsResetting(true)
												}
											>
												Reset Password
											</Button>
										</div>
									</div>
								</div>
							</Form>
						</Col>
					</Row>
				</Card>
			) : !isResetting ? (
				<Card className='createcard'>
					<img className='logo-img1' src={logo1} alt='react'></img>
					<img className='logo-img2' src={logo2} alt='react'></img>
					<CardHeader className='cardHeader'>
						Create Account
					</CardHeader>
					<Row>
						<Col>
							<Form>
								<span className='nameContainer'>
									<Input
										value={name}
										placeholder='Full Name'
										className='name-input'
										type='text'
										onChange={(e) =>
											setName(e.target.value)
										}
									/>
								</span>
								<span className='organizationContainer'>
									<Input
										value={organization}
										placeholder='Organization'
										className='organization-input'
										type='text'
										onChange={(e) =>
											setOrganization(e.target.value)
										}
									/>
								</span>
								<span className='emailContainer'>
									<Input
										value={email}
										placeholder='Email'
										className='email-input'
										type='email'
										onChange={(e) =>
											setEmail(e.target.value)
										}
									/>
								</span>
								<span className='passwordContainer'>
									<Input
										value={password}
										placeholder='Password'
										className='password-input'
										type='password'
										onChange={(e) =>
											setPassword(e.target.value)
										}
									/>
								</span>
								<div className='lowerButtons'>
									<Button
										onClick={createAccount}
										className='logButton'
									>
										Submit
									</Button>
									<div className='orBar'>
										<span className='before'></span>
										<span className='text'>OR</span>
										<span className='after'></span>
									</div>
									<Button
										className='gobackButton'
										onClick={() => setIsCreating(false)}
									>
										Go Back
									</Button>
								</div>
							</Form>
						</Col>
					</Row>
				</Card>
			) : (
				<Card className='createcard'>
					<img className='logo-img1' src={logo1} alt='react'></img>
					<img className='logo-img2' src={logo2} alt='react'></img>
					<CardHeader className='cardHeader'>
						Create Account
					</CardHeader>
					<Row>
						<Col>
							<Form>
								<span className='emailContainer'>
									<Input
										value={email}
										placeholder='Email'
										className='email-input'
										type='email'
										onChange={(e) =>
											setEmail(e.target.value)
										}
									/>
								</span>
								<div className='lowerButtons'>
									<Button
										onClick={resetPassword}
										className='logButton'
									>
										Submit
									</Button>
									<div className='orBar'>
										<span className='before'></span>
										<span className='text'>OR</span>
										<span className='after'></span>
									</div>
									<Button
										className='gobackButton'
										onClick={() => setIsResetting(false)}
									>
										Go Back
									</Button>
								</div>
							</Form>
						</Col>
					</Row>
				</Card>
			)}
			<footer className='footer text-center text-lg-left fixed-bottom'>
				<div className='text-center p-3'>
					&copy; {new Date().getFullYear()} Copyright:{' '}
					<a
						className='text-dark'
						href='https://datafleetservices.com/'
					>
						Data Fleet LLC
					</a>
				</div>
			</footer>
		</div>
	);
	///////////////////////////////////////////////////////////////////////
};

export { Login };
