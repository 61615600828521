import { Card, CardBody, Col } from 'reactstrap';
import { HealthScore } from '@components/layouts/SignedIn/views/Dashboard/HealthScore/HealthScore';
import { useDashboard } from '@components/layouts/SignedIn/views/Dashboard/dashboardContext';
import Loader from './Loader';
import { useLocation } from 'react-router-dom';

const ExecutiveSummary = ({ data, isLocationPage }) => {
	if (!data) return <Loader />;

	const {
		allAssets,
		monthAssets,
		yearAssets,
		badAssets,
		locationsCount,
		noAssetLocations,
	} = data;

	const allAssetsCountDisplay = allAssets.toLocaleString();
	const monthsAssetsCountDisplay = monthAssets
		? monthAssets.toLocaleString()
		: 'no';
	const yearsAssetsCountDisplay = yearAssets.toLocaleString();
	const badAssetsCountDisplay = badAssets ? badAssets.toLocaleString() : 'no';
	const locationCountDisplay = locationsCount.toLocaleString();
	const emptyLocationsCountDisplay = noAssetLocations.toLocaleString();

	return (
		<div className='fs-5 alfreds-notes-text'>
			<div className='summary-note-container'>
				<div className='summary-note'>
					{`Total of ${allAssetsCountDisplay} assets`}
				</div>
			</div>
			{yearAssets ? (
				<div className='summary-note-container'>
					<div className='summary-note'>
						{yearAssets === 1
							? `There has been one asset added last month and ${yearsAssetsCountDisplay} year-to-date`
							: `There have been ${monthsAssetsCountDisplay} assets added last month and ${yearsAssetsCountDisplay} year-to-date`}
					</div>
				</div>
			) : null}
			<div className='summary-note-container'>
				<div className='summary-note'>
					{badAssets === 1
						? `There is one asset that is reported as being in broken or poor condition`
						: `There are ${badAssetsCountDisplay} assets that are reported as being in broken or poor condition`}
				</div>
			</div>
			{locationsCount && !isLocationPage ? (
				<div className='summary-note-container'>
					<div className='summary-note'>
						{`In total, ${locationCountDisplay} locations have been reported in Property Echo`}
					</div>
				</div>
			) : null}
			{locationsCount && !isLocationPage ? (
				<div className='summary-note-container'>
					<div className='summary-note'>
						{`There are ${emptyLocationsCountDisplay} locations with no submitted assets`}
					</div>
				</div>
			) : null}
		</div>
	);
};

const AlfredsNotes = ({ healthScore }) => {
	const { summary, loading } = useDashboard();
	const location = useLocation();
	const isLocationPage = location.pathname.includes('/locations');

	return (
		<Card className='alfreds-notes pt-2'>
			<CardBody className='p-0 d-md-flex' style={{ flex: 'none' }}>
				<Col className='col-12 col-md-8 alfreds-notes-title'>
					<h3 className='text-purple'>{`${
						isLocationPage ? 'Location' : 'Organization'
					} Overview`}</h3>
				</Col>
				<Col className='col-12 col-md-4 justify-content-center pt-3 alfreds-health-score-box'>
					<div className='alfreds-health-score'>
						<HealthScore
							healthScore={healthScore}
							label={'Health Score'}
						/>
					</div>
				</Col>
			</CardBody>
			<CardBody className='p-0 d-flex'>
				<Col className='col-12 col-md-8'>
					{loading ? (
						<Loader />
					) : (
						<ExecutiveSummary
							data={summary}
							isLocationPage={isLocationPage}
						/>
					)}
				</Col>
			</CardBody>
		</Card>
	);
};

export { AlfredsNotes };
