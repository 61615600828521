import {
	addDoc,
	collection,
	doc,
	endBefore,
	getCountFromServer,
	getDoc,
	getDocs,
	limit,
	orderBy,
	query,
	setDoc,
	startAfter,
	startAt,
	updateDoc,
	where,
} from 'firebase/firestore';
import { db } from '@assets/services/auth-service';
import { DB_FILTER, DB_ORDER, DB_PATH } from '@constants/db';
import { formatDate } from '@utils/ISOdateToMMDDYYYY';

export const getMasterAssetsTypes = async () => {
	const assetTypes = await getDocs(
		query(
			collection(db, DB_PATH.ASSET_TYPES),
			orderBy(DB_FILTER.NAME, DB_ORDER.ASC)
		)
	);
	return assetTypes;
};

export const addMasterAssetTypes = async (data) => {
	const ref = await addDoc(collection(db, DB_PATH.ASSET_TYPES), data);
	const doc = await getDoc(ref);
	return doc;
};

export const getAssetTypes = async (organization, assetName) => {
	const colRef = collection(
		db,
		DB_PATH.ORGANIZATIONS,
		organization,
		DB_PATH.ASSET_TYPES
	);

	const assetTypes = assetName
		? await getDocs(query(colRef, where(DB_FILTER.NAME, '==', assetName)))
		: await getDocs(query(colRef, orderBy(DB_FILTER.NAME, DB_ORDER.ASC)));
	return assetTypes;
};

export const addAssetType = async (path, isAll, data) => {
	const colRef = isAll
		? collection(db, DB_PATH.ORGANIZATIONS, path, DB_PATH.ASSET_TYPES)
		: collection(db, path);
	const ref = await addDoc(colRef, data);
	const doc = await getDoc(ref);
	return doc;
};

export const getFormAssetTypes = async (organization, form, type) => {
	let docQuery = query(
		collection(
			db,
			DB_PATH.ORGANIZATIONS,
			organization,
			DB_PATH.FORMS,
			form,
			DB_PATH.ASSET_TYPES
		)
	);
	if (type) {
		docQuery = query(docQuery, where(DB_FILTER.NAME, '==', type));
	}
	const assetTypes = await getDocs(docQuery);
	return assetTypes;
	// const params = [];
	// if (type) {
	// 	params.push(where(DB_FILTER.NAME, '==', type));
	// }
	// if (form) {
	// 	params.push(where('formIds', 'array-contains', form));
	// }
	// const docQuery = query(
	// 	collection(
	// 		db,
	// 		DB_PATH.ORGANIZATIONS,
	// 		organization,
	// 		DB_PATH.ASSET_TYPES
	// 	),
	// 	...params
	// );
	// const assetTypes = await getDocs(docQuery);
	// return assetTypes;
};

export const getAssetsWithPagination = async ({
	organization,
	locationId,
	datesFilter,
	orderByFields,
	pageSize,
	after,
	at,
	before,
}) => {
	const params = [where(DB_FILTER.DELETED, '==', false)];
	if (locationId) {
		params.push(where(DB_FILTER.OTHER_LOCATION_ID, '==', locationId));
	}
	if (datesFilter) {
		const [start, end] = datesFilter;
		params.push(
			where(DB_FILTER.ASSET_SUBMITTED_DATE, '>', formatDate(start))
		);
		params.push(
			where(DB_FILTER.ASSET_SUBMITTED_DATE, '<', formatDate(end))
		);
	}
	if (orderByFields) {
		const fields =
			datesFilter &&
			!orderByFields.includes(DB_FILTER.ASSET_SUBMITTED_DATE)
				? [DB_FILTER.ASSET_SUBMITTED_DATE, ...orderByFields]
				: orderByFields;
		params.push(orderBy(...fields));
	}
	if (after) {
		params.push(startAfter(after));
	}
	if (at && before) {
		params.push(startAt(at));
		params.push(endBefore(before));
	}
	if (pageSize) {
		params.push(limit(pageSize));
	}
	const assetsSnap = await getDocs(
		query(
			collection(db, DB_PATH.ORGANIZATIONS, organization, DB_PATH.ASSETS),
			...params
		)
	);
	return assetsSnap;
};

export const getAssets = async (organization, locationId, type, tag, order) => {
	const params = [where(DB_FILTER.DELETED, '==', false)];
	if (locationId) {
		params.push(where(DB_FILTER.OTHER_LOCATION_ID, '==', locationId));
	}
	if (type) {
		params.push(where(DB_FILTER.TYPE, '==', type));
	}
	if (tag) {
		params.push(where(DB_FILTER.OTHER_ASSET_TAG, '==', tag));
	}
	if (order) {
		params.push(orderBy(order, DB_ORDER.ASC));
	}
	const assetsSnap = await getDocs(
		query(
			collection(db, DB_PATH.ORGANIZATIONS, organization, DB_PATH.ASSETS),
			...params
		)
	);
	return assetsSnap;
};

export const getAsset = async (organization, id) => {
	const snap = await getDoc(
		doc(db, DB_PATH.ORGANIZATIONS, organization, DB_PATH.ASSETS, id)
	);
	return snap;
};

export const getLocationAssets = async (organization, locationid) => {
	const assetsSnap = await getDocs(
		query(
			collection(
				db,
				DB_PATH.ORGANIZATIONS,
				organization,
				DB_PATH.LOCATIONS,
				locationid,
				DB_PATH.ASSETS
			),
			where(DB_FILTER.DELETED, false)
		)
	);
	return assetsSnap;
};

export const getAssetsCount = async (
	organization,
	locationId,
	conditions,
	datesFilter
) => {
	const params = [where(DB_FILTER.DELETED, '==', false)];
	if (locationId) {
		params.push(where(DB_FILTER.OTHER_LOCATION_ID, '==', locationId));
	}
	if (conditions) {
		params.push(where(DB_FILTER.OTHER_ASSET_CONDITION, 'in', conditions));
	}
	if (datesFilter) {
		const [start, end] = datesFilter;
		params.push(
			where(DB_FILTER.ASSET_SUBMITTED_DATE, '>', formatDate(start))
		);
		params.push(
			where(DB_FILTER.ASSET_SUBMITTED_DATE, '<', formatDate(end))
		);
	}
	const assetsCount = await getCountFromServer(
		query(
			collection(db, DB_PATH.ORGANIZATIONS, organization, DB_PATH.ASSETS),
			...params
		)
	);
	return assetsCount.data().count;
};

export const addAsset = async (organization, data) => {
	const ref = await addDoc(
		collection(db, DB_PATH.ORGANIZATIONS, organization, DB_PATH.ASSETS),
		data
	);
	return ref;
};

export const addAssetVersionHistory = async (organization, id, data) => {
	await addDoc(
		collection(
			db,
			DB_PATH.ORGANIZATIONS,
			organization,
			DB_PATH.ASSETS,
			id,
			DB_PATH.VERSION_HISTORY
		),
		data
	);
};

export const updateAsset = async (organization, id, updatedAsset) =>
	setDoc(
		doc(db, DB_PATH.ORGANIZATIONS, organization, DB_PATH.ASSETS, id),
		updatedAsset
	);

export const deleteAsset = async (assetRef) => {
	const asset = await getDoc(assetRef);
	const submissionRef = asset.data().responseRef;
	await updateDoc(assetRef, { deleted: true });
	await updateDoc(submissionRef, { deleted: true });
};

export const getMasterAssetsAll = async () => {
	const params = [];
	const assetsSnap = await getDocs(
		query(collection(db, DB_PATH.ASSETS), ...params)
	);
	return assetsSnap;
};

export const getAssetSpecificDetails = async (assetDataOther, assetTypeRef) => {
	if (!assetDataOther || !assetTypeRef) {
		return null;
	}
	const specificDetails = [];
	const assetTypeDoc = await getDoc(assetTypeRef);
	const assetTypeInputs = assetTypeDoc.data().inputs;

	if (!assetTypeInputs) return null;

	for (const input of assetTypeInputs) {
		const parsedInput = JSON.parse(input);

		// if input is array, not object
		if (parsedInput.length) {
			console.log(parsedInput)
			const label = parsedInput[0].label;
			const responseTag = parsedInput[1].responseTag;

			let specificDetail = assetDataOther[responseTag];
			// 'true' means Not Found checkbox was checked for this input
			if (specificDetail == 'true' || specificDetail === undefined) {
				continue;
			}
			specificDetails.push({ label, value: specificDetail });
		} else {
			let { label, responseTag, type } = parsedInput;
			if (label == 'Power Source?') label = 'Power Source'
			const specificDetail = assetDataOther[responseTag];
			if (specificDetail === undefined) continue;
			specificDetails.push({ label, value: specificDetail, type });
		}
	}

	return specificDetails;
};
