import { useEffect, useState } from 'react';
import { CreateAssetTypeModal } from './children/CreateAssetTypeModal';
import { DeleteAssetTypeModal } from './children/DeleteAssetTypeModal';
import { UpdateAssetTypeModal } from './children/UpdateAssetTypeModal';
import { AiFillEdit, AiOutlinePlusSquare } from 'react-icons/ai';
import { IoMdTrash } from 'react-icons/io';
import {
	Card,
	CardHeader,
	Form,
	Button,
	CardBody,
	Table,
	Input,
} from 'reactstrap';
import { Loader } from '@SignedIn/Loader/Loader';
import { camelize } from '@utils/camelize';
import {
	deleteWithRef,
	getDocsFromPath,
	getOrganizations,
	updateWithRef,
} from '@services/organization-service';
import { getForms as getOrgForms } from '@services/form-service';
import { DB_FILTER, DB_ORG } from '@constants/db';
import {
	addAssetType,
	getAssetTypes as getOrgAssetTypes,
} from '@services/asset-service';

const AssetTypesOrgList = (props) => {
	const { org } = props;
	const fields = [
		'Name',
		'Org Name',
		'Sub Type',
		'Family',
		'SC Template Name',
	];

	// Loading state
	const [isLoading, setIsLoading] = useState(true);

	// Org's forms array state
	const [formsArr, setFormsArr] = useState([]);

	// Selected form index state
	const [formIndex, setFormIndex] = useState();

	// State for all asset types
	const [types, setTypes] = useState([]);

	// State for selected type (ie, to edit or delete)
	const [selected, setSelected] = useState();

	// States for showing modals
	const [showCreate, setShowCreate] = useState(false);
	const [showDelete, setShowDelete] = useState(false);
	const [showUpdate, setShowUpdate] = useState(false);

	let rendered = false;
	// Trigger getForms function on init render
	useEffect(() => {
		if (!rendered) {
			getForms();
			rendered = true;
		}
	}, []);

	// Trigger getAssetTypes functions when formIndex changes
	useEffect(() => {
		if (typeof formIndex == 'number' && formsArr.length > 0) {
			getAssetTypes();
		}
		// eslint-disable-next-line
	}, [formIndex, formsArr]);

	// Queries Firebase for all of org's forms, assigns them to formsArr state,
	// and determines which form index to be assigned to form index state (as the initial form)
	const getForms = async () => {
		const newForms = [];
		if (org == DB_ORG.DATAFLEET) {
			// Our org, so get all forms
			const orgsSnapshot = await getOrganizations();
			for (const org of orgsSnapshot.docs) {
				if (org.id == DB_ORG.URBANPLATES) continue;
				const formsSnap = await getOrgForms(org.id);
				for (const form of formsSnap.docs) newForms.push(form);
			}
		} else {
			// All other orgs
			const formsSnap = await getOrgForms(org);
			for (const form of formsSnap.docs) newForms.push(form);
		}
		newForms.push('all');
		setFormsArr(newForms);
		setFormIndex(0);
	};

	// Grabs asset types master list from Firebase and updates types state
	const getAssetTypes = async () => {
		let assetTypesSnap;
		if (formsArr[formIndex] === 'all') {
			assetTypesSnap = await getOrgAssetTypes(org);
		} else {
			assetTypesSnap = await getDocsFromPath(
				formsArr[formIndex].ref.path + '/asset-types',
				DB_FILTER.NAME
			);
		}
		if (assetTypesSnap.empty) {
			setIsLoading(false);
			return;
		}
		const newTypes = [];
		for (const type of assetTypesSnap.docs) {
			newTypes.push({ ref: type.ref, data: type.data(), id: type.id });
		}
		await setTypes(newTypes);
		setIsLoading(false);
	};

	// Create individual asset type on front-end and back-end
	const createType = async (data) => {
		const isAll = formsArr[formIndex] === 'all';
		const doc = await addAssetType(
			isAll ? org : formsArr[formIndex].ref.path + '/asset-types',
			isAll,
			data
		);
		const newTypes = [
			...types,
			{ id: doc.id, ref: doc.ref, data: doc.data() },
		];
		setTypes(newTypes);
	};

	// Delete individual asset type on front-end and back-end
	const deleteType = async () => {
		await deleteWithRef(selected.ref);
		const newTypes = [
			...types.slice(0, selected.index),
			...types.slice(selected.index + 1),
		];
		setTypes(newTypes);
		closeDelete();
	};

	// Update individual asset type on front-end and back-end
	const updateType = async (data) => {
		await updateWithRef(selected.ref, data);
		const newType = { ref: selected.ref, data: data, id: selected.id };
		const newTypes = [
			...types.slice(0, selected.index),
			newType,
			...types.slice(selected.index + 1),
		];
		setTypes(newTypes);
		closeUpdate();
	};

	// Opens & closes delete modal
	const openDelete = (type, i) => {
		const selObj = {};
		Object.keys(type).forEach((key) => (selObj[key] = type[key]));
		selObj.index = i;
		setSelected(selObj);
		setShowDelete(true);
	};
	const closeDelete = () => {
		setSelected();
		setShowDelete(false);
	};

	// Opens & closes update modal
	const openUpdate = (type, i) => {
		const selObj = {};
		Object.keys(type).forEach((key) => (selObj[key] = type[key]));
		selObj.index = i;
		setSelected(selObj);
		setShowUpdate(true);
	};
	const closeUpdate = () => {
		setSelected();
		setShowUpdate(false);
	};

	// Update page when new form is selected in dropdown
	const changeForm = async (i) => {
		setSelected();
		setFormIndex(i);
	};

	return isLoading ? (
		<Loader />
	) : (
		<Card className='mt-4 submissions-table'>
			<CardHeader className='p-3 d-flex flex-column flex-md-row justify-content-between'>
				<Form
					// onSubmit={(e) => e.preventDefault()}
					className='d-flex flex-column justify-content-center'
				>
					<input
						id='search'
						className='searchbar rounded-pill'
						type='text'
						disabled={isLoading}
						placeholder='Search'
						// value={searchVal}
						// onChange={(e) => {
						// 	setSearchVal(e.target.value);
						// }}
					/>
				</Form>
				<Input
					className='my-3 my-md-0 w-auto abs-center-x'
					type='select'
					onChange={(e) => changeForm(Number(e.target.value))}
				>
					{formsArr.map((form, i) => {
						const key = form.id || 'all-forms-org-assets-list';
						let body = 'All Forms';
						if (form.data != undefined) body = form.data().formName;
						return (
							<option
								value={i}
								selected={i == formIndex}
								key={key}
							>
								{body}
							</option>
						);
					})}
				</Input>
				<div className='d-flex flex-row-reverse flex-md-row justify-content-between align-end'>
					<button
						onClick={() => setShowCreate(true)}
						color='primary'
						className='rounded-circle no-border table-btn'
						disabled={isLoading}
					>
						<AiOutlinePlusSquare className='larger-font-icon larger-font' />
					</button>
				</div>
			</CardHeader>
			{isLoading ? (
				<Loader />
			) : types.length < 1 ? (
				<CardBody>
					<div
						className='d-flex flex-column justify-content-center align-items-center w-100'
						style={{ minHeight: '200px' }}
					>
						<div>No asset types to display.</div>
					</div>
				</CardBody>
			) : (
				<CardBody>
					<Table responsive size='sm' className='asset-types-table'>
						<thead>
							<tr>
								{fields.map((field) => {
									return (
										<th key={'header-' + field}>{field}</th>
									);
								})}
								<th
									key='header-buttons'
									style={{ 'text-align': 'center' }}
								>
									Actions
								</th>
							</tr>
						</thead>
						<tbody>
							{types.map((type, i) => {
								return (
									<tr key={type.id} className=''>
										{fields.map((field) => {
											const data =
												type.data[camelize(field)];
											return (
												<td
													key={type.id + '-' + field}
													style={{
														whiteSpace:
															field == 'Name'
																? 'nowrap'
																: 'normal',
														minWidth:
															field == 'Sub Type'
																? '300px'
																: '',
													}}
												>
													{Array.isArray(data)
														? data.join(', ')
														: data}
												</td>
											);
										})}
										<td
											key={type.id + '-buttons'}
											className=''
											style={{
												whiteSpace: 'nowrap',
												textAlign: 'center',
											}}
										>
											<Button
												color='purple'
												className='mx-1 btn-sm'
												onClick={() =>
													openUpdate(type, i)
												}
											>
												<AiFillEdit />
											</Button>
											<Button
												color='secondary'
												className='mx-1 btn-sm'
												onClick={() =>
													openDelete(type, i)
												}
											>
												<IoMdTrash />
											</Button>
										</td>
									</tr>
								);
							})}
						</tbody>
					</Table>
				</CardBody>
			)}
			{showCreate && (
				<CreateAssetTypeModal
					isOpen={showCreate}
					toggle={() => setShowCreate(!showCreate)}
					createType={createType}
					title={
						formsArr[formIndex] !== 'all'
							? `Add New Asset Type to ${
									formsArr[formIndex].data().formName
							  }`
							: 'Add New Asset Type to Org'
					}
					isAll={formsArr[formIndex] === 'all' ? true : false}
					org={org}
					crossRefTypes={types}
				/>
			)}
			{showDelete && (
				<DeleteAssetTypeModal
					isOpen={showDelete}
					close={closeDelete}
					deleteType={deleteType}
					title='Delete Asset Type'
					body={
						formsArr[formIndex] !== 'all'
							? `Are you sure you want to delete "${
									selected.data.name
							  }" from the ${
									formsArr[formIndex].data().formName
							  }?`
							: `Are you sure you want to delete "${selected.data.name}" from this org's asset types lists?`
					}
				/>
			)}
			{showUpdate && (
				<UpdateAssetTypeModal
					isOpen={showUpdate}
					close={closeUpdate}
					updateType={updateType}
					title='Update Asset Type'
					selected={selected.data}
					fields={fields}
				/>
			)}
		</Card>
	);
};

export { AssetTypesOrgList };
