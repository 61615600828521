import {
	Page,
	Text,
	View,
	Document,
	PDFDownloadLink,
	Image,
	StyleSheet,
} from '@react-pdf/renderer';
import { useEffect, useState } from 'react';
import { isValidUrl } from '@utils/validation';
import { DB_ORG } from '@constants/db';
import { getImageDetail } from './submissionUtils';
import { getForms } from '@services/form-service';

const DownloadPdf = (props) => {
	const { form, inputs, isMultiPage } = props;

	// Filename state
	const [filename, setFilename] = useState('');

	// PDF file state
	const [pdf, setPdf] = useState();

	const convertDateToISOString = (date) => {
		if (date) {
			const tempDate = new Date(date).toISOString().split('T');
			return tempDate[0];
		}
	};

	const styles = StyleSheet.create({
		doc: {
			fontFamily: 'Helvetica',
			flexDirection: 'column',
			padding: '24px',
			fontSize: '12px',
		},
		title: {
			fontSize: '28px',
			padding: '0 6px 6px 6px',
			fontFamily: 'Helvetica-Bold',
		},
		question: {
			fontFamily: 'Helvetica-Bold',
			marginBottom: '2px',
		},
		questionImage: {
			fontFamily: 'Helvetica-Bold',
			marginBottom: '18px',
		},
		answer: {
			border: '1px solid #ced4da',
			padding: '6px',
			borderRadius: '4px',
		},
		answerImageOrientationOne: {
			width: '100%',
			marginTop: '-15px',
		},
		answerImageOrientationSix: {
			transform: 'rotate(90deg)',
			width: '100%',
			marginLeft: '-33px',
			marginTop: '18px',
		},
		answerImageOrientationThree: {
			transform: 'rotate(180deg)',
			width: '100%',
			marginTop: '-15px',
		},
		answerImageOrientationEight: {
			transform: 'rotate(270deg)',
			width: '100%',
			marginLeft: '-33px',
			marginTop: '18px',
		},
		questionAnswer: {
			display: 'flex',
			flexDirection: 'column',
			padding: '6px',
		},
		questionAnswerImage: {
			display: 'flex',
			flexDirection: 'column',
			width: '273px',
			padding: '6px',
			overflow: 'hidden',
			marginBottom: '6px',
		},
		questionAnswerColText: {
			display: 'flex',
			flexDirection: 'column',
			width: '273px',
			padding: '6px',
		},
		questionAnswerColImage: {
			display: 'flex',
			flexDirection: 'column',
			width: '273px',
			padding: '6px',
			overflow: 'hidden',
			marginBottom: '6px',
		},
		questionAnswerColParent: {
			display: 'flex',
			flexDirection: 'row',
			flexWrap: 'wrap',
		},
		pageHeader: {
			fontSize: '20px',
			padding: '6px',
		},
		sectionHeader: {
			fontFamily: 'Helvetica-Bold',
			border: '1px solid #ced4da',
			padding: '6px',
			margin: '6px',
			borderRadius: '4px',
			backgroundColor: '#f3f3f3',
		},
		logoImage: {
			width: '173px',
		},
	});

	const updateDoc = async () => {
		let lineItems = [];
		let index = 0;

		let logo_src = await (async () => {
			for (const f of (await getForms(form.organization)).docs) {
				const _data_logo = f.data().logo;
				if (_data_logo) return _data_logo;
			}
			return null;
		})();

		const logoLine = (
			<View key={index} style={styles.questionAnswer}>
				<View>
					<Image style={styles.logoImage} src={logo_src} />
				</View>
			</View>
		);
		index++;

		const firstLine = (
			<Text key={index} style={styles.title}>
				{form.formName}
			</Text>
		);
		index++;

		let secondLine;
		if (form.organization == DB_ORG.CUSHMANWAKEFIELD) {
			let val;
			if (form.reviewed != undefined) val = form.reviewed;
			else val = 'false';
			secondLine = (
				<View key={index} style={styles.questionAnswer}>
					<Text style={styles.question}>
						Report Reviewed and Approved?
					</Text>
					<Text style={styles.answer}>{val}</Text>
				</View>
			);
			index++;
		}

		const thirdLine = (
			<View key={index} style={styles.questionAnswer}>
				<Text style={styles.question}>Date of Visit</Text>
				<Text style={styles.answer}>
					{convertDateToISOString(
						form.dateOfVisit || form.submittedDate
					)}
				</Text>
			</View>
		);
		index++;

		let address = '';
		if (form.location.address) {
			address = form.location.address;
		}
		if (form.location.address1) {
			address = address + ' ' + form.location.address1;
		}
		if (form.location.address2) {
			address = address + ' ' + form.location.address2;
		}
		if (form.location.city) {
			address = address + ', ' + form.location.city;
		}
		if (form.location.state) {
			address = address + ', ' + form.location.state;
		}
		if (form.location.zip) {
			address = address + ' ' + form.location.zip;
		}
		const fourthLine = (
			<View key={index} style={styles.questionAnswer}>
				<Text style={styles.question}>Location</Text>
				<Text style={styles.answer}>{address}</Text>
			</View>
		);
		index++;

		// remove later
		let fifthLine;
		if (isMultiPage) {
			fifthLine = (
				<View key={index} style={styles.questionAnswer}>
					<Text style={styles.question}>Summary</Text>
					<Text style={styles.answer}>{form.summary}</Text>
				</View>
			);
		}
		index++;

		// Render multipage data
		if (isMultiPage == true) {
			inputs.forEach(async (page) => {
				// Create page heaer
				let pageHeader = (
					<Text key={index} style={styles.pageHeader}>
						{page.page}
					</Text>
				);
				lineItems = [...lineItems, pageHeader];
				index++;

				if (page.sections.length > 0) {
					for (let section of page.sections) {
						// Create section header
						let sectionHeader = (
							<Text key={index} style={styles.sectionHeader}>
								{section.label}
							</Text>
						);
						lineItems = [...lineItems, sectionHeader];
						index++;

						let inputs = [];
						// Check if data is old (before commit 69e0450) and render differently
						if (form.inputs == undefined) {
							for (let input of section.inputs) {
								let columns = [];
								// Create input rows
								for (let subInput of input) {
									let col;
									let formKey = subInput.responseTag;
									let response = form[formKey];
									// Cushman specific code for Asset Quality and Asset Image:
									// If submission response is blank or defined, assign new value
									if (
										response == '' ||
										response == undefined
									) {
										if (formKey.includes('assetQuality')) {
											response = 'No Action Needed';
										} else if (
											formKey.includes('AssetImage')
										) {
											response = 'No Image';
										}
									}
									let label = subInput.label;
									let isImage = isValidUrl(response);
									if (isImage) {
										let style;
										let rotVal;
										let orientation =
											subInput.exif.Orientation;
										if (orientation != undefined) {
											rotVal = orientation.value;
										} else {
											rotVal = undefined;
										}
										if (
											rotVal == 1 ||
											rotVal == undefined
										) {
											style =
												styles.answerImageOrientationOne;
										} else if (rotVal == 3) {
											style =
												styles.answerImageOrientationThree;
										} else if (rotVal == 6) {
											style =
												styles.answerImageOrientationSix;
										} else if (rotVal == 8) {
											style =
												styles.answerImageOrientationEight;
										}
										col = (
											<View
												wrap
												key={index}
												style={
													styles.questionAnswerColImage
												}
											>
												<Text
													style={styles.questionImage}
												>
													{label}
												</Text>
												<View
													style={
														styles.imageContainer
													}
												>
													<Image
														cache={true}
														style={style}
														src={response}
													/>
												</View>
											</View>
										);
									} else {
										col = (
											<View
												wrap
												key={index}
												style={
													styles.questionAnswerColText
												}
											>
												<Text style={styles.question}>
													{label}
												</Text>
												<Text style={styles.answer}>
													{response}
												</Text>
											</View>
										);
									}
									columns = [...columns, col];
									index++;
								}
								let newInput = (
									<View
										wrap
										key={index}
										style={styles.questionAnswerColParent}
									>
										{columns}
									</View>
								);
								index++;
								inputs = [...inputs, newInput];
							}
						} else {
							for (let input of section.inputs) {
								let responseTag = input[0].responseTag;
								let responseArray = form.inputs[responseTag];
								for (let i = 0; i < responseArray.length; i++) {
									let columns = [];
									// Create input rows
									for (let subInput of input) {
										let formKey = subInput.responseTag;
										let response;
										let imageDetail = {
											index: null,
											url: null,
										};
										let col;
										// Cushman specific code for Asset Quality and Asset Image:
										// If submission response is blank or defined, assign new value
										if (formKey.includes('assetQuality')) {
											response = responseArray[i].quality;
											if (
												response == '' ||
												response == undefined
											) {
												response = 'No Action Needed';
											}
										} else if (
											formKey.includes('AssetImage')
										) {
											response = responseArray[i].image;
											if (
												response == '' ||
												response == undefined
											) {
												response = 'No Image';
											} else {
												imageDetail = getImageDetail(
													response,
													input,
													subInput
												);

												if (!imageDetail?.url) continue;

												imageDetail =
													isValidUrl(
														imageDetail.url
													) && imageDetail;
											}
										} else {
											response =
												responseArray[i].value || ' ';
										}
										let label = subInput.label;
										if (imageDetail?.url) {
											let style;
											let rotVal;
											let orientation =
												subInput.exif[i].Orientation;
											if (orientation != undefined) {
												rotVal = orientation.value;
											} else {
												rotVal = undefined;
											}
											if (
												rotVal == 1 ||
												rotVal == undefined
											) {
												style =
													styles.answerImageOrientationOne;
											} else if (rotVal == 3) {
												style =
													styles.answerImageOrientationThree;
											} else if (rotVal == 6) {
												style =
													styles.answerImageOrientationSix;
											} else if (rotVal == 8) {
												style =
													styles.answerImageOrientationEight;
											}
											col = (
												<View
													wrap
													key={index}
													style={
														styles.questionAnswerColImage
													}
												>
													<Text
														style={
															styles.questionImage
														}
													>
														{label}
													</Text>
													<View
														style={
															styles.imageContainer
														}
													>
														<Image
															cache={true}
															style={style}
															src={
																imageDetail.url
															}
														/>
													</View>
												</View>
											);
										} else {
											col = (
												<View
													wrap
													key={index}
													style={
														styles.questionAnswerColText
													}
												>
													<Text
														style={styles.question}
													>
														{label}
													</Text>
													<Text style={styles.answer}>
														{typeof response ===
														'string'
															? response
															: ''}
													</Text>
												</View>
											);
										}
										columns = [...columns, col];
										index++;
									}
									let newInput = (
										<View
											wrap
											key={index}
											style={
												styles.questionAnswerColParent
											}
										>
											{columns}
										</View>
									);
									index++;
									inputs = [...inputs, newInput];
								}
							}
						}

						lineItems = [...lineItems, inputs];
					}
				}
				// Add generic message if page wasn't filled out
			});
		}
		// Render linear data
		else {
			for (let input of inputs) {
				if (input.responseTag == 'location') {
					continue;
				}
				let formKey = input.responseTag;
				if (formKey == 'assetQuality') {
					formKey = formKey + input[0].responseTag;
				}
				let label = input.label;
				let response = form[formKey];
				let newRow;
				if (isValidUrl(response) == true) {
					let style;
					let rotVal;
					let orientation = input.exif.Orientation;
					if (orientation != undefined) {
						rotVal = orientation.value;
					} else {
						rotVal = undefined;
					}
					if (rotVal == 1 || rotVal == undefined) {
						style = styles.answerImageOrientationOne;
					} else if (rotVal == 3) {
						style = styles.answerImageOrientationThree;
					} else if (rotVal == 6) {
						style = styles.answerImageOrientationSix;
					} else if (rotVal == 8) {
						style = styles.answerImageOrientationEight;
					}
					newRow = (
						<View
							wrap
							key={index}
							style={styles.questionAnswerImage}
						>
							<Text style={styles.questionImage}>{label}</Text>
							<View>
								<Image style={style} src={response} />
							</View>
						</View>
					);
				} else {
					newRow = (
						<View wrap key={index} style={styles.questionAnswer}>
							<Text style={styles.question}>{label}</Text>
							<Text style={styles.answer}>{response}</Text>
						</View>
					);
				}

				lineItems = [...lineItems, newRow];
				index++;
			}
		}

		setPdf(
			<Document>
				<Page size='A4' style={styles.doc}>
					<View>
						{logoLine}
						{firstLine}
						{secondLine}
						{thirdLine}
						{fourthLine}
						{fifthLine}
						{lineItems}
					</View>
				</Page>
			</Document>
		);

		setFilename(`${form.formName} - ${address}.pdf`);
	};

	// When the form prop changes, update the pdf & filename states
	useEffect(() => {
		if (
			form != undefined &&
			inputs != undefined &&
			isMultiPage != undefined
		)
			updateDoc();
		// eslint-disable-next-line
	}, [form, inputs, isMultiPage]);

	return (
		<PDFDownloadLink
			document={pdf}
			fileName={filename}
			className='text-decoration-none'
		>
			{
				(/* {blob, url, loading, error } */) => (
					<div className='activities-page-header-buttons d-flex p-2 width-fit-content '>
						<div className='d-flex justify-content-center'>
							PDF <i className='bi bi-cloud-download mx-2'></i>
						</div>
					</div>
				)
			}
		</PDFDownloadLink>
	);
};

export { DownloadPdf };
