import { Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import { MatterportTab } from '@SignedIn/views/SingleLocation/MatterportTab/MatterportTab';
import { GeneralTab } from '@SignedIn/views/SingleLocation/GeneralTab/GeneralTab.js';
import { ActivitiesTab } from '@SignedIn/views/SingleLocation/ActivitiesTab/ActivitiesTab';
import { AssetsTable } from '@SignedIn/views/Admin/AssetsTable/AssetsTable';
import { PredictiveSpendTab } from '@components/layouts/SignedIn/views/SingleLocation/PredictiveSpendTab/PredictiveSpendTab'; // import { NewMatterportModal } from '@SignedIn/views/SingleLocation/children/NewMatterportModal';
import { useState, useEffect } from 'react';
import {
	AiOutlineLineChart,
	AiOutlineCloudServer,
	AiOutlineHome,
} from 'react-icons/ai';
import { MdOutlineHealthAndSafety } from 'react-icons/md';
import { TbClipboardText } from 'react-icons/tb';
import { FiSettings } from 'react-icons/fi';
import { QATab } from '@SignedIn/views/SingleLocation/QATab/QATab';
import { DB_ORG } from '@constants/db';
import {
	getOrganization,
	getOrganizations,
} from '@services/organization-service';
import {
	canViewVerizonLocation,
	getLocation,
} from '@services/location-service';
import AuthContext from '@assets/services/Auth';
import { useContext } from 'react';
import { IGuideTab } from '@components/layouts/SignedIn/views/SingleLocation/IGuideTab/IGuide';
import { VirtualModal } from '@components/layouts/SignedIn/views/SingleLocation/VirtualModal/VirtualModal';
import { updateDoc } from 'firebase/firestore';
import { SettingsTab } from '@components/layouts/SignedIn/views/SingleLocation/SettingsTab/SettingsTab';
import { isVerizonUser } from '@assets/services/user-role-service';

const SingleLocation = (props) => {
	const { userRole, setToast } = props;
	const { authInfo } = useContext(AuthContext);
	const [virtualTab, setVirtualTab] = useState();
	const [isVerified, setIsVerified] = useState(false);

	// helper state to rerender when user uploads virtual model
	const [virtualChange, setVirtualChange] = useState(false);

	const { id } = useParams();
	const location = useLocation();

	const navigate = useNavigate();

	// let matterport;
	// let iguide;
	let organization;

	if (location.state != null) {
		// matterport = location.state.data.matterport;
		// iguide = location.state.data.iguide;
		// if (location.state.orgId)
		organization = location.state.orgId || authInfo.org;
	} else {
		organization = authInfo.org;
	}

	let tabNames;
	if (organization == DB_ORG.CUSHMANWAKEFIELD) {
		tabNames = ['Overview', 'Virtual', 'Submissions', 'Settings'];
	} else {
		tabNames = [
			'Overview',
			'Virtual',
			'Assets',
			'Predictive Spend',
			'Submissions',
			'QA Submissions',
			'Settings',
		];
	}

	const [selectedTab, setSelectedTab] = useState(() => {
		if (location.state)
			return Number(tabNames.indexOf(location.state.tab)) + 1;
		else return 1;
	});

	// Toggle active state for Tab
	const toggle = (tab) => {
		if (selectedTab !== tab) setSelectedTab(tab);
	};

	const [locationHealth, setLocationHealth] = useState(null);

	// Get assets health from db
	const getLocationHealth = async () => {
		let locSnapshot;
		if (organization === DB_ORG.DATAFLEET) {
			// Our org, so get all forms
			const orgSnap = await getOrganizations();
			let promises = [];
			orgSnap.forEach((d) => {
				let orgId = d.id;
				promises.push(getLocation(orgId, id));
			});

			let d = await Promise.all(promises);
			d.forEach((doc) => {
				if (doc.exists()) {
					organization =
						doc._document.key.path.segments[
							doc._document.key.path.segments.length - 3
						];
				}
			});
			locSnapshot = await getLocation(organization, id);
		} else {
			locSnapshot = await getLocation(organization, id);
		}
		let hs = locSnapshot.data().healthscore;
		return !isNaN(hs) || hs !== undefined ? hs.toFixed(1) : 0;
	};

	const getVirtualTab = async () => {
		const orgSnap = await getOrganization(organization);
		const showMatterport = orgSnap.data().showMatterport;
		const showIguide = orgSnap.data().showIguide;
		const locSnap = await getLocation(organization, id);
		const matterport = locSnap.data().matterport || null;
		const iguide = locSnap.data().iguide || null;
		if (matterport) {
			setVirtualTab(
				<MatterportTab
					matterport={matterport}
					locSnap={locSnap}
					setVirtualChange={setVirtualChange}
					userRole={userRole}
				/>
			);
		} else if (iguide) {
			setVirtualTab(
				<IGuideTab
					locationId={id}
					iguide={iguide}
					locSnap={locSnap}
					setVirtualChange={setVirtualChange}
					userRole={userRole}
				/>
			);
		} else {
			setVirtualTab(
				<div
					style={{
						height: 'calc(100% - 120px)',
						display: 'flex',
						flexDirection: 'column',
						justifyContent: 'center',
					}}
				>
					<div className='d-flex flex-column align-items-center justify-content-evenly'>
						<span
							className='mb-2 fs-4'
							style={{
								textShadow: '2px 2px 12px rgba(0, 0, 0, 0.5)',
							}}
						>
							No Model Currently Connected.
						</span>
						{showMatterport && (
							<VirtualModal
								virtualType={'Matterport'}
								locationId={id}
								organization={organization}
								setVirtualChange={setVirtualChange}
							/>
						)}
						{showIguide && (
							<VirtualModal
								virtualType={'iGuide'}
								locationId={id}
								organization={organization}
								setVirtualChange={setVirtualChange}
							/>
						)}
					</div>
				</div>
			);
		}
		setVirtualChange(false);
	};

	const verifyCushmanUser = async () => {
		const isVerizon = await isVerizonUser();
		if (isVerizon) {
			const canViewPage = await canViewVerizonLocation(authInfo.id, id);
			if (canViewPage) setIsVerified(true);
			else navigate('/locations');
		} else setIsVerified(true);
	};

	// If Cushman org + VZW user, redirect if in wrong location
	useEffect(() => {
		if (isVerified) return;
		if (organization != DB_ORG.CUSHMANWAKEFIELD) setIsVerified(true);
		else verifyCushmanUser();
	}, []);

	// Update locationHealth state upon render
	useEffect(() => {
		if (isVerified) {
			getLocationHealth().then((newHealth) =>
				setLocationHealth(newHealth)
			);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isVerified]);

	useEffect(() => {
		getVirtualTab();
	}, [virtualChange]);

	const createTabs = () => {
		let tabs = [];
		for (let i = 0; i < tabNames.length; i++) {
			let tab = (
				<NavItem className='location-tab' key={i}>
					<NavLink
						className={
							selectedTab == i + 1 ? 'active-tab' : 'inactive-tab'
						}
						onClick={() => {
							toggle(i + 1);
						}}
					>
						{tabNames[i] === 'Overview' && (
							<AiOutlineHome className='tab-icon' />
						)}
						{tabNames[i] === 'Virtual' && (
							<AiOutlineCloudServer className='tab-icon' />
						)}
						{tabNames[i] === 'Assets' && (
							<MdOutlineHealthAndSafety className='tab-icon' />
						)}
						{tabNames[i] === 'Predictive Spend' && (
							<AiOutlineLineChart className='tab-icon' />
						)}
						{tabNames[i] === 'Submissions' && (
							<TbClipboardText className='tab-icon' />
						)}
						{tabNames[i] === 'Settings' && (
							<FiSettings className='tab-icon' />
						)}
						<div className='tab-title'>{tabNames[i]}</div>
					</NavLink>
				</NavItem>
			);
			tabs.push(tab);
		}

		return tabs;
	};

	// array of tab widths
	let tabs = [];

	// get width of each tab
	const tabElements = document.querySelectorAll('.location-tab');
	tabElements.forEach((tab) => {
		tabs.push(tab.getBoundingClientRect().width);
	});

	// function to retrieve value where tab indicator should be placed
	const sumWidth = (currentTab) => {
		return tabs.slice(0, currentTab).reduce((a, c) => {
			return a + c;
		}, 0);
	};

	const resetVirtual = async () => {
		const locSnap = await getLocation(organization, id);
		let virtual = null;
		let oldVirtual = locSnap.data().matterport || locSnap.data().iguide;
		if (locSnap.data().matterport) {
			virtual = 'matterport';
		} else if (locSnap.data().iguide) {
			virtual = 'iguide';
		}
		if (!oldVirtual) {
			return;
		}
		await updateDoc(locSnap.ref, {
			[virtual]: '',
			oldVirtual: oldVirtual,
		});
		setVirtualChange(true);
		setToast({
			showing: true,
			title: 'Virtual Reset',
			message: 'Virtual ID for this location has been reset.',
			type: 'success',
			position: 'bottom',
		});
		setTimeout(() => {
			setToast({
				showing: false,
			});
		}, 4500);
	};

	const goBackToLocations = () => {
		navigate('/locations', { state: location.state });
	};

	return isVerified ? (
		<div className='single-location-parent'>
			<div className='d-flex flex-column flex-md-row justify-content-between align-items-center'>
				<div
					className='back-button-wrapper d-flex flex-column justify-content-center align-items-center align-items-md-start mb-3'
					style={{
						paddingTop: '5px',
						paddingBottom: '5px',
						marginLeft: '5px',
						fontSize: '14px',
					}}
				>
					<div
						className='location-back-button d-flex p-1 width-fit-content'
						onClick={goBackToLocations}
					>
						<i className='bi bi-arrow-left me-3'></i>
						<div style={{ fontWeight: 'bold' }}>{`${
							location.state.data.address ||
							location.state.data.address1
						}, ${location.state.data.city}, ${
							location.state.data.state
						} ${location.state.data.zip}`}</div>
					</div>
				</div>
			</div>
			<Nav className='single-location-navbar-animated'>
				<div className='d-flex position-relative'>
					{createTabs()}
					<div
						className='tab-indicator'
						style={{
							left: `${sumWidth(selectedTab - 1)}px`,
							width: `${tabs[selectedTab - 1]}px`,
						}}
					></div>
				</div>
			</Nav>
			<TabContent activeTab={selectedTab} className='fullSize'>
				<TabPane tabId={tabNames.indexOf('Overview') + 1}>
					<GeneralTab
						healthScore={locationHealth}
						info={location.state}
						locationId={id}
						userRole={userRole}
						setToast={setToast}
					/>
				</TabPane>
				<TabPane
					tabId={tabNames.indexOf('Virtual') + 1}
					className='fullSize'
				>
					{virtualTab}
				</TabPane>
				{organization != DB_ORG.CUSHMANWAKEFIELD ? (
					<TabPane tabId={tabNames.indexOf('Assets') + 1}>
						<AssetsTable
							organization={organization}
							displayAddress={false}
							browserLocationId={id}
							isFocused={
								selectedTab == tabNames.indexOf('Assets') + 1
							}
							leaveBehindReportInfo={location.state}
							setToast={setToast}
						/>
					</TabPane>
				) : null}
				<TabPane tabId={tabNames.indexOf('Predictive Spend') + 1}>
					<PredictiveSpendTab
						locationId={id}
						organization={organization}
						isFocused={
							selectedTab ==
							tabNames.indexOf('Predictive Spend') + 1
						}
					/>
				</TabPane>
				<TabPane tabId={tabNames.indexOf('Submissions') + 1}>
					<ActivitiesTab
						browserLocationId={id}
						organization={organization}
						isFocused={
							selectedTab == tabNames.indexOf('Submissions') + 1
						}
					/>
				</TabPane>
				{organization != DB_ORG.CUSHMANWAKEFIELD ? (
					<TabPane tabId={tabNames.indexOf('QA Submissions') + 1}>
						<QATab
							organization={organization}
							browserLocationId={id}
							isFocused={
								selectedTab ==
								tabNames.indexOf('QA Submissions') + 1
							}
						/>
					</TabPane>
				) : null}
				<TabPane tabId={tabNames.indexOf('Settings') + 1}>
					{/* <NewMatterportModal /> */}
					<SettingsTab
						userRole={userRole}
						resetVirtual={resetVirtual}
					/>
				</TabPane>
			</TabContent>
		</div>
	) : null;
};

export { SingleLocation };
