import { useState, useEffect, useRef } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { auth } from '@assets/services/auth-service';
import { Button, Table } from 'reactstrap';
import { Loader } from '@SignedIn/Loader/Loader';
import { AssetEdit } from '@SignedIn/views/AssetProfile/AssetEdit';
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from 'react-icons/md';
import { BsShieldCheck } from 'react-icons/bs';
import {
	getAsset,
	getAssetSpecificDetails,
	updateAsset,
} from '@services/asset-service';
import { getDocFromRef } from '@services/organization-service';
import { getUser } from '@services/user-service';
import { getDoc } from 'firebase/firestore';
import { generateAssetDescription } from '@services/openai-service';
import numWords from 'num-words';
import { ChatbotMainCard } from '@components/layouts/SignedIn/views/Dashboard/ChatbotMainCard/ChatbotMainCard';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { MdOutlineContentCopy } from 'react-icons/md';
import { Toast, ToastBody } from 'reactstrap';
import { canDelete } from '@assets/services/user-role-service';
import AssetDecommission from '@components/layouts/SignedIn/views/AssetProfile/AssetDecommission';

const conditionColorDict = {
	Excellent: '#228B22',
	Good: '#228B22',
	Average: '#d9a843',
	Poor: '#b04b10',
	Broken: '#ff3b3b',
};

const AssetProfile = () => {
	const [assetData, setAssetData] = useState();
	const [specificDetails, setSpecificDetails] = useState([]);
	const [activeImg, setActiveImg] = useState();
	const [allImgs, setAllImgs] = useState();
	const [isLoading, setIsLoading] = useState(true);
	const [imageViewerModal, setImageViewerModal] = useState(false);
	const [org, setOrg] = useState();
	const [submission, setSubmission] = useState();
	const [queryingOpenAI, setQueryingOpenAI] = useState(false);
	const [isHealthScoreHovered, setIsHealthScoreHovered] = useState(false);
	const [isHovered, setIsHovered] = useState(false);
	const [isCopied, setIsCopied] = useState(false);
	const [decommissioned, setDecommissioned] = useState(false);

	const showDecommission = canDelete();

	const handleIconClick = () => {
		setIsCopied(true);
		setTimeout(() => setIsCopied(false), 3000);
	};

	const [isDesktop, setDesktop] = useState(window.innerWidth > 768);
	const [isLaptop, setLaptop] = useState(
		window.innerWidth < 1200 && window.innerWidth > 768
	);

	const updateMedia = () => {
		setDesktop(window.innerWidth > 768);
		setLaptop(window.innerWidth < 1200 && window.innerWidth > 768);
	};

	useEffect(() => {
		window.addEventListener('resize', updateMedia);
		return () => window.removeEventListener('resize', updateMedia);
	});

	// helper state to track changes to asset doc
	const [assetChange, setAssetChange] = useState(false);

	const { id: locationId, assetid } = useParams();
	const location = useLocation();
	const navigate = useNavigate();

	const getAssetData = async () => {
		const assetDoc = await getAsset(org, assetid);
		const parentAssetRef = assetDoc?.data()?.parentAssetRef || null;
		const parentAssetSnap = parentAssetRef
			? await getDoc(parentAssetRef)
			: null;

		const remainingUsefulLife = getRemainingUsefulLife(
			parentAssetSnap ? parentAssetSnap.data().avgUsefulLifeYears : null,
			assetDoc.data().dateOfBirth || assetDoc.data().estDateOfBirth
		);

		const specificDetails = await getAssetSpecificDetails(
			assetDoc.data().other,
			assetDoc.data().assetTypeRef
		);
		setSpecificDetails(specificDetails);

		parentAssetSnap
			? setAssetData({
					...assetDoc.data(),
					...parentAssetSnap.data(),
					remainingUsefulLife,
			  })
			: setAssetData(assetDoc.data());

		console.log(assetDoc.data());

		setActiveImg(assetDoc.data().other.equipmentAssetImage);

		let allImgsArr = [
			assetDoc.data().other.equipmentAssetImage,
			assetDoc.data().other.idTagAssetImage,
			assetDoc.data().other.manufacturersPlateAssetImage,
		];
		allImgsArr = allImgsArr.filter((img) => img);

		const submissionRef = assetDoc.data().responseRef;
		const submissionDoc = await getDocFromRef(submissionRef);
		setSubmission(submissionDoc.data());
		setAllImgs(allImgsArr);
		setIsLoading(false);
	};

	const getOrg = async () => {
		const user = await getUser(auth.currentUser.uid);
		setOrg(user.organization);
	};

	// eslint-disable-next-line no-unused-vars
	const toggle = () => setImageViewerModal(!imageViewerModal);

	const returnToAssetsTable = () => {
		const urlPath = locationId ? `/locations/${locationId}` : '/admin/';
		navigate(urlPath, {
			state: { ...location.state, tab: 'Assets' },
		});
	};

	const getAssetHealthScore = (health) => {
		let healthScore;
		if (health >= 75) {
			healthScore = 'green';
		} else if (health >= 25 && health < 75) {
			healthScore = 'yellow';
		} else {
			healthScore = 'red';
		}
		return healthScore;
	};

	const getRemainingUsefulLife = (usefulLife, dob) => {
		const diff = Math.abs(new Date(dob).getTime() - new Date().getTime());
		const millisecondsPerYear = 1000 * 60 * 60 * 24 * 365.25; // accounting for leap years
		const years = Math.floor(diff / millisecondsPerYear);
		const remainingYears = usefulLife - years;
		let remainingYearsStr = Math.abs(remainingYears).toString() + ' years';
		if (remainingYears < 1) {
			remainingYearsStr += ' expired';
		}
		return remainingYearsStr;
	};

	useEffect(() => {
		getOrg();
	}, []);

	useEffect(() => {
		if (org) {
			getAssetData();
			setAssetChange(false);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [org, assetChange, decommissioned]);

	// ChatGPT generated asset description
	const generateDescription = async () => {
		const color = getAssetHealthScore(assetData.healthScore);
		const pertinentData = {
			assetType: assetData.assetType?.toLowerCase(),
			dateOfManufacture:
				assetData.dateOfBirth ?? assetData.estDateOfBirth,
			healthScore: assetData.healthScore,
			generalHealth:
				color == 'red'
					? isNaN(assetData.healthScore)
						? 'unknown'
						: 'poor'
					: color == 'yellow'
					? 'decent'
					: color == 'green'
					? 'great'
					: 'unknown',
			avgReplacementCostUSD: `$${assetData.avgReplCostUSD}`,
			make: assetData.make,
			avgUsefulLifeYears: assetData.avgUsefulLifeYears,
			remainingUsefulLife: assetData.remainingUsefulLife,
		};
		const prompt = `Write a professionally formatted summary of this ${
			pertinentData.assetType
		},
		describing the assets' current state in full sentences.
		Do not use the words 'asset' or 'summary'. 
		Do not use the word 'make' after the make of the asset is written.
		The asset is given by the following object: 
		${JSON.stringify(pertinentData)}.
		The health score considers factors such as the
		asset's age, maintenance history, and expected
		useful life.`;

		setQueryingOpenAI(true);
		await generateAssetDescription(prompt)
			.then((res) => {
				const description = res.data.choices[0].message.content;
				setAssetData({ ...assetData, description });
			})
			.catch((err) => console.log(err));
		setQueryingOpenAI(false);
	};

	// Generate description if there is none
	useEffect(() => {
		if (!assetData) return;
		if (!assetData.description) {
			generateDescription();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [assetData]);

	// Update description in firebase
	useEffect(() => {
		if (!assetData) return;
		if (!assetData.description) return;

		updateAsset(org, assetid, assetData);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [assetData]);

	// Checks if health score has changed since last description has been generated
	const healthUpdated = (health, description) =>
		!health ? false : !description.includes(health.toString());

	// Checks if remaining useful years has decreased since description was generated
	const remainingYearsUpdated = (years, description) => {
		if (!years) return false;
		const containsWordYears = description.includes(
			numWords(Math.abs(years))
		);

		const indexOfYear = description.indexOf(
			` ${Math.abs(years).toString()} `
		);
		const containsYearsSurroundedBySpaces = indexOfYear > 0;

		// If both conditions are false, description likely doesn't match current data
		return !(containsWordYears || containsYearsSurroundedBySpaces);
	};

	const goToLocation = () => {
		navigate('/locations/' + assetData.other.location.id, {
			state: { data: assetData.other.location, tab: 'Assets' },
		});
	};

	// Check if description needs to be regenerated
	useEffect(() => {
		if (!assetData) return;
		if (!assetData.description) return;
		if (
			healthUpdated(assetData.healthScore, assetData.description) |
			remainingYearsUpdated(
				assetData.remainingUsefulLife,
				assetData.description
			)
		) {
			// Generate new description if necessary
			generateDescription();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [assetData]);

	const divRef = useRef(null);
	useEffect(() => {
		if (!isLoading) {
			// const div = document.getElementsByClassName('asset-profile-card');

			window.scrollTo({
				top: divRef.current.offsetTop,
				behavior: 'smooth',
			});
		}
	}, [isLoading]);

	const tableCellRef = useRef(null);

	useEffect(() => {
		if (tableCellRef.current) {
			if (tableCellRef.current.scrollWidth) {
				if (
					tableCellRef.current.scrollWidth >
					tableCellRef.current.clientWidth
				) {
					tableCellRef.current.classList.add('overflowing');
				} else {
					tableCellRef.current.classList.remove('overflowing');
				}
			}
		}
	}, [tableCellRef.current]);

	// useEffect(() => {
	// 	cons
	// }, [])

	return isLoading ? (
		<Loader />
	) : (
		<div>
			<div className='d-flex flex-column justify-content-center align-items-center align-items-md-start mb-4'>
				<div
					className='location-back-button d-flex p-1'
					onClick={returnToAssetsTable}
				>
					<i className='bi bi-arrow-left me-3'></i>
					<div
						style={{
							fontWeight: 'bold',
							fontSize: '14px',
							transform: 'scale(1.06)',
							paddingLeft: '5px',
							paddingRight: '15px',
							marginLeft: '5px',
						}}
					>
						{locationId
							? `${
									location.state.data.address ||
									location.state.data.address1
							  }, ${location.state.data.city}, ${
									location.state.data.state
							  } ${location.state.data.zip}`
							: 'Return To Admin'}
					</div>
				</div>
			</div>
			<div
				className='asset-profile-card position-relative align-items-center d-flex flex-column'
				ref={divRef}
			>
				<div className='asset-header'>
					<h4 className='text-center'>
						<strong>{assetData?.assetType}</strong>
					</h4>
					<h6
						className='text-center'
						style={{ color: 'rgb(111 111 111)' }}
					>
						Located at
						<span
							className='asset-profile-location-link'
							onClick={goToLocation}
							style={{ cursor: 'pointer' }}
						>
							{' '}
							{assetData?.other.location.address1}
							{assetData?.other.location.name}:{' '}
						</span>
					</h6>
					{assetData.decommissioned && (
						<h6 className='text-center bg-danger text-white'>
							Decommissioned
						</h6>
					)}
				</div>
				<div
					className={`mx-2 mb-2 d-flex justify-content-around w-100 ${
						isDesktop ? 'flex-row' : 'flex-column'
					}`}
				>
					{/* Image div with sidebar */}
					<div
						className='d-flex flex-column-reverse align-items-center w-100'
						style={{
							minWidth: '200px',
							overflowX: 'hidden',
							maxWidth: isDesktop ? '600px' : '100vw',
						}}
					>
						<div className={`${isDesktop ? '' : 'm-2'} mt-1`}>
							{queryingOpenAI ? (
								<span className='loader'>
									Hang on, I&apos;m generating a description!
								</span>
							) : (
								<p
									className='mb-0'
									style={{ textAlign: 'justify' }}
								>
									{assetData.description}
								</p>
							)}
						</div>
						<div className='d-flex justify-content-between align-items-center h-100 w-100'>
							<Button
								className='toggle-img-btn'
								style={
									isDesktop
										? {}
										: {
												transform: isDesktop
													? ''
													: 'scale(0.75)',
												margin: 0,
										  }
								}
							>
								<MdKeyboardArrowLeft />
							</Button>
							<div
								className='d-flex align-items-center'
								style={{ overflow: 'scroll' }}
							>
								{allImgs.map((img) => (
									<img
										key={img}
										style={{
											width: isDesktop ? '80px' : '20vw',
											aspectRatio: '1 / 1',
											borderRadius: '15px',
											cursor: 'pointer',
											outline:
												img === activeImg
													? '2px solid #5b2286'
													: '',
										}}
										src={img}
										className='m-2'
										onClick={() => {
											setActiveImg(img);
										}}
									/>
								))}
							</div>
							<Button
								className='toggle-img-btn'
								style={
									isDesktop
										? {}
										: {
												transform: isDesktop
													? ''
													: 'scale(0.75)',
												margin: 0,
										  }
								}
							>
								<MdKeyboardArrowRight />
							</Button>
						</div>

						<img
							src={activeImg}
							className='mx-4 my-2 asset-profile-active-img d-inline-block h-auto'
							style={{ maxWidth: '100%', aspectRatio: '1 / 1' }}
						/>
					</div>

					<div
						className={`p-0 d-flex flex-column ${
							isDesktop ? 'm-3' : 'm-0'
						}`}
						style={{
							maxWidth: isDesktop ? '55%' : '100%',
							width: isDesktop ? '55%' : '100%',
						}}
					>
						<div className='mb-4' style={{ height: '26em' }}>
							<ChatbotMainCard
								style={{ maxHeight: '600px', height: '26em' }}
							/>
						</div>
						<div className='d-flex flex-column'>
							<Table
								className={`asset-profile-table ${
									specificDetails ? 'mb-0' : 'mb-3'
								} ${isDesktop ? 'mt-1' : 'mt-3'}`}
								style={{
									borderRadius: specificDetails
										? '10px 10px 0 0'
										: '10px',
								}}
							>
								<tbody>
									<tr>
										<th className='fs-6 py-2 px-4 fw-bold text-center'>
											Tag ID
										</th>
										<th className='fs-6 py-2 px-4 fw-bold text-center'>
											Make
										</th>
										<th className='fs-6 py-2 px-4 fw-bold text-center'>
											Model
										</th>
										<th className='fs-6 py-2 px-4 fw-bold text-center'>
											Serial
										</th>
										<th className='fs-6 py-2 px-4 fw-bold text-center'>
											DOB
										</th>
									</tr>
									<tr>
										<td
											className={`table-unit fs-6 py-2 px-4 ${
												isDesktop
													? 'text-center'
													: 'text-start'
											}`}
										>
											{assetData?.other.assetTag || 'N/A'}
										</td>
										<td
											className={`table-unit fs-6 py-2 px-4 ${
												isDesktop
													? 'text-center'
													: 'text-start'
											}`}
										>
											{assetData?.make === '(other)'
												? assetData?.other
														.ifOtherManufacturer
												: assetData?.make || 'N/A'}
										</td>
										<td
											ref={tableCellRef}
											className={`table-unit fs-6 py-2 px-4 ${
												isDesktop
													? 'text-center'
													: 'text-start'
											}`}
											onMouseEnter={() =>
												setIsHovered(true)
											}
											onMouseLeave={() => {
												setIsHovered(false);
											}}
										>
											{isHovered && (
												<CopyToClipboard
													text={
														assetData?.model ||
														'N/A'
													}
												>
													<MdOutlineContentCopy
														className='table-btn'
														style={{
															cursor: 'pointer',
														}}
														onClick={
															handleIconClick
														}
													/>
												</CopyToClipboard>
											)}
											{assetData?.model || 'N/A'}
										</td>
										<td
											className={`table-unit fs-6 py-2 px-4 ${
												isDesktop
													? 'text-center'
													: 'text-start'
											}`}
										>
											{assetData?.serial || 'N/A'}
										</td>
										<td
											className={`table-unit fs-6 py-2 px-4 ${
												isDesktop
													? 'text-center'
													: 'text-start'
											}`}
										>
											{assetData?.dateOfBirth ||
												assetData?.estDateOfBirth ||
												'N/A'}
										</td>
									</tr>
								</tbody>
							</Table>

							{specificDetails && (
								<>
									<Table
										className={`asset-profile-table mb-2 ${
											isDesktop ? 'mt-0' : 'mt-3'
										}`}
										style={{
											borderRadius: '0 0 10px 10px',
											borderTop: '3px solid #D3D3D3',
											tableLayout: 'fixed',
										}}
									>
										<tbody>
											<tr>
												{specificDetails.map(
													(input) => (
														<th
															className='fs-6 py-2 px-4 fw-bold text-center'
															key={
																input.responseTag
															}
														>
															{input.label}
														</th>
													)
												)}
											</tr>
											<tr>
												{specificDetails.map(
													(input) => (
														<th
															className='fs-6 py-0 px-4 fw-bold text-center'
															key={
																input.responseTag
															}
														>
															<td
																className={`d-flex justify-content-center fs-6 py-2 px-4 ${
																	isDesktop
																		? 'text-center'
																		: 'text-start'
																}`}
															>
																{input.value}
															</td>
														</th>
													)
												)}
											</tr>
										</tbody>
									</Table>
								</>
							)}

							<div
								className={`d-flex ${
									isDesktop ? 'flex-row' : 'flex-column'
								}`}
							>
								<div
									className='justify-content-center align-items-center mb-2 asset-health-row w-100'
									onMouseEnter={() =>
										setIsHealthScoreHovered(true)
									}
									onMouseLeave={() => {
										setIsHealthScoreHovered(false);
									}}
								>
									<div className='d-flex flex-row justify-content-center align-content-center align-self-start m-2 mb-3'>
										<strong
											className='fs-6 align-self-center'
											style={{ whiteSpace: 'nowrap' }}
										>
											Asset Health
										</strong>
										<div className='w-100 position-relative mx-2'>
											<div className='position-absolute w-50 d-flex'>
												<div
													style={{
														width: '30%',
														textAlign: 'center',
														transition:
															'all 200ms ease',
														opacity:
															isHealthScoreHovered
																? '1'
																: '0',
														transform:
															isHealthScoreHovered
																? 'translateY(-24px)'
																: null,
														fontSize: '13px',
														fontWeight: 'bold',
													}}
												>
													Condition
												</div>
												<div
													style={{
														width: '30%',
														transition:
															'all 200ms ease',
														opacity:
															isHealthScoreHovered
																? '1'
																: '0',
														backgroundColor:
															conditionColorDict[
																assetData?.other
																	?.assetCondition
															],
														position: 'absolute',
														left: '0px',
														height: '100%',
														borderRadius:
															'10px 0px 0px 10px',
														color: 'white',
														textAlign: 'center',
														fontSize: '12px',
													}}
												>
													{
														assetData?.other
															.assetCondition
													}
												</div>
												<div
													style={{
														width: '70%',
														textAlign: 'center',
														transition:
															'all 200ms ease',
														opacity:
															isHealthScoreHovered
																? '1'
																: '0',
														transform:
															isHealthScoreHovered
																? 'translateY(-24px)'
																: null,
														fontSize: '13px',
														fontWeight: 'bold',
													}}
												>
													Useful Life
												</div>

												<div
													style={{
														width: '70%',
														transition:
															'all 200ms ease',
														opacity:
															isHealthScoreHovered
																? '1'
																: '0',
														backgroundColor:
															'#696969',
														position: 'absolute',
														height: '100%',
														borderRadius:
															'0 10px 10px 0',
														color: 'white',
														textAlign: 'center',
														fontSize: '12px',
														right: '0',
													}}
												>
													{assetData?.remainingUsefulLife
														? assetData?.remainingUsefulLife
														: 'No Asset Information Found'}
												</div>
											</div>
											<div
												className={`fs-6 asset-profile-tag mx-2 asset-health-score asset-health-${getAssetHealthScore(
													assetData?.healthScore
												)}`}
											>
												{!isNaN(assetData.healthScore)
													? assetData.healthScore +
													  '%'
													: 'N/A'}
											</div>
										</div>
									</div>
									<Table className='asset-profile-table'>
										<tbody>
											<tr>
												<th
													style={{
														maxWidth: isLaptop
															? '50px'
															: '',
													}}
												>
													<div
														className={`d-flex ${
															isLaptop
																? 'flex-column'
																: ''
														} align-items-center justify-content-center`}
													>
														<BsShieldCheck
															className='fs-4'
															style={{
																height: '36px',
															}}
														/>
														<span className='fw-bold mx-1'>
															Warranty
														</span>
													</div>
												</th>
												<th
													style={{
														maxWidth: isLaptop
															? '50px'
															: '',
													}}
												>
													<div
														className={`d-flex ${
															isLaptop
																? 'flex-column'
																: ''
														} align-items-center justify-content-center`}
													>
														<i className='bi-heart-pulse fs-4' />
														<span className='fw-bold mx-1'>
															Useful Life
															Remaining
														</span>
													</div>
												</th>
												<th
													style={{
														maxWidth: isLaptop
															? '50px'
															: '',
													}}
												>
													<div
														className={`d-flex ${
															isLaptop
																? 'flex-column'
																: ''
														} align-items-center justify-content-center`}
													>
														<i className='bi-cash fs-4' />
														<span className='fw-bold mx-1'>
															Maintenance Spend
														</span>
													</div>
												</th>
												<th
													style={{
														maxWidth: isLaptop
															? '50px'
															: '',
													}}
												>
													<div
														className={`d-flex ${
															isLaptop
																? 'flex-column'
																: ''
														} align-items-center justify-content-center`}
													>
														<i className='bi-arrow-clockwise fs-4' />
														<span className='fw-bold mx-1'>
															Replacement Cost
														</span>
													</div>
												</th>
											</tr>
											<tr>
												<td
													className='text-center'
													style={{
														paddingLeft: '24px',
													}}
												>
													{assetData.other
														.warrantyTerm
														? assetData?.other
																.warrantyTerm +
														  ' months'
														: 'None'}
												</td>
												<td className='text-center'>
													{assetData.remainingUsefulLife
														? assetData.remainingUsefulLife
														: 'N/A'}
													{assetData.remainingUsefulLife <
														1 && ' expired'}
												</td>

												<td className='text-center'>
													N/A
												</td>
												<td className='text-center'>
													{assetData?.avgReplCostUSD
														? '$' +
														  assetData.avgReplCostUSD.toLocaleString()
														: 'N/A'}
												</td>
											</tr>
											{/* <tr>
												<td className='text-center'>
													{assetData?.percentWarrantyLeft
														? assetData?.percentWarrantyLeft +
														'%'
														: 'Not Under Warranty'}
												</td>
												<td className='text-center'>N/A</td>
												<td className='text-center'>N/A</td>
												<td className='text-center'>N/A</td>
											</tr> */}
										</tbody>
									</Table>
								</div>
							</div>

							<div className='d-flex flex-column ms-2 my-2'>
								<div
									className='d-flex'
									style={{ marginLeft: '30px' }}
								>
									<div className='asset-profile-tag-wrapper'>
										<div className='asset-profile-tag'>
											<span>Top 10%</span>
										</div>
										<span
											className='mx-2'
											style={{ color: '#8c8c8c' }}
										>
											PE Assets
										</span>
									</div>
									<div className='d-flex align-items-center'>
										<div className='asset-profile-tag'>
											<span>Top 5%</span>
										</div>
										<span
											className='mx-2'
											style={{ color: '#8c8c8c' }}
										>
											Organization Assets
										</span>
									</div>
								</div>
							</div>
						</div>
						{showDecommission && !assetData.decommissioned && (
							<AssetDecommission
								assetId={assetid}
								assetData={assetData}
								org={org}
								setDecommissioned={setDecommissioned}
							/>
						)}
						<AssetEdit
							parsedResponse={assetData}
							assetId={assetid}
							submissionDoc={submission}
							setAssetChange={setAssetChange}
							org={org}
						/>
					</div>
				</div>
			</div>
			<Toast isOpen={isCopied} className='asset-toast'>
				<ToastBody>Text copied to clipboard.</ToastBody>
			</Toast>
		</div>
	);
};

export { AssetProfile };
