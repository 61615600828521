import { doc, getDoc } from 'firebase/firestore';
import { DB_ORG } from '@constants/db';
import { auth, db } from './auth-service';

let userRole = null;
let userOrg = null;

export const setUserRole = (role) => {
	userRole = role;
};
export const getUserRole = () => {
	if (!userRole) {
		let userDoc = doc(db, 'users', auth.currentUser.uid);
		getDoc(userDoc).then((user) => {
			setUserRole(user.data().role);
		});
	}
	return userRole;
};
export const getUserRolePromise = async () => {
	if (!userRole) {
		let userDoc = doc(db, 'users', auth.currentUser.uid);
		await getDoc(userDoc).then((user) => {
			setUserRole(user.data().role);
			return user.data().role;
		});
	} else {
		return userRole;
	}
};
export const setUserOrg = (org) => {
	userOrg = org;
};
export const getUserOrg = async () => {
	if (!userOrg) {
		let userDoc = doc(db, 'users', auth.currentUser.uid);
		const userSnap = await getDoc(userDoc);
		const org = userSnap.data().organization;
		await setUserOrg(org);
		return org;
	} else {
		return userOrg;
	}
};
export const canViewVendors = async () => {
	const userSnap = await getDoc(doc(db, 'users', auth.currentUser.uid));
	const org = userSnap.data().organization;
	const role = userSnap.data().role;
	if (org !== DB_ORG.CUSHMANWAKEFIELD) return true;
	else if (
		(role == 'superuser' || role == 'admin') &&
		org == DB_ORG.CUSHMANWAKEFIELD
	) {
		return true;
	} else return false;
};
export const canViewAdminPage = async () => {
	const userSnap = await getDoc(doc(db, 'users', auth.currentUser.uid));
	const role = userSnap.data().role;
	if (role == 'superuser' || role == 'admin') return true;
	else return false;
};
export const isVerizonUser = async () => {
	const userSnap = await getDoc(doc(db, 'users', auth.currentUser.uid));
	const role = userSnap.data().role;
	if (role == 'verizon') return true;
	else return false;
};
export const canViewOtherUsers = () => {
	if (getUserRole() === 'superuser' || getUserRole() === 'admin') {
		return true;
	} else return false;
};
export const canEditOtherUsers = () => {
	if (getUserRole() === 'superuser' || getUserRole() === 'admin') {
		return true;
	} else return false;
};

export const canAddOtherUsers = () => {
	if (getUserRole() === 'superuser' || getUserRole() === 'admin') {
		return true;
	} else return false;
};
export const canDeleteOtherUsers = () => {
	if (getUserRole() === 'superuser' || getUserRole() === 'admin') {
		return true;
	} else return false;
};

export const canAdd = () => {
	if (getUserRole() === 'superuser' || getUserRole() === 'admin') {
		return true;
	} else return false;
};

export const canEdit = () => {
	if (getUserRole() === 'superuser' || getUserRole() === 'admin') {
		return true;
	} else return false;
};

export const canDelete = () => {
	if (getUserRole() === 'superuser' || getUserRole() === 'admin') {
		return true;
	} else return false;
};

export const isSuperUser = () => {
	if (getUserRole() === 'superuser') return true;
	else return false;
};
