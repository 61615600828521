import { useEffect, useState } from 'react';
import { PredSpendAssetRow } from './PredSpendAssetRow';
import { MdOutlineInventory } from 'react-icons/md';
import { Card, CardBody, CardHeader, Table } from 'reactstrap';
import { Loader } from '@components/layouts/SignedIn/Loader/Loader';

const AssetsTable = (props) => {
	const { assets } = props;

	const [assetRows, setAssetRows] = useState([]);
	const [loading, setLoading] = useState(true);

	const headers = [
		{ title: 'Asset', key: 'make' },
		{ title: 'Asset Type', key: 'assetType' },
		{ title: 'Health Score', key: 'healthScore' },
		{ title: 'Useful Life Remaining', key: 'usefulLifeRemaining' },
		{ title: 'Optimal Replacement Year', key: 'replacementYear' },
	];

	// sorting states
	const [sortAsc, setSortAsc] = useState(true);
	const [sortBy, setSortBy] = useState('usefulLifeRemaining');

	const displaySortDirection = (col) => {
		if (sortBy !== col) return '';
		return sortAsc ? '↑' : '↓';
	};

	const sortAssets = (col) => {
		if (sortBy == col) {
			setSortAsc(!sortAsc);
		} else {
			setSortAsc(true);
			setSortBy(col);
		}
	};

	const generateRows = () => {
		setLoading(true);
		const newRows = [];
		const sortedAssets = [...assets];
		sortedAssets.sort((a, b) => {
			const valA = a.data[sortBy];
			const valB = b.data[sortBy];
			if (typeof valA == 'string') {
				return sortAsc
					? valA.localeCompare(valB)
					: valB.localeCompare(valA);
			} else {
				return sortAsc ? valA - valB : valB - valA;
			}
		});
		console.log(sortedAssets[0]);
		sortedAssets.forEach((asset) => {
			const row = <PredSpendAssetRow asset={asset} type={'all'} />;
			newRows.push(row);
		});
		setAssetRows(newRows);
		setLoading(false);
	};

	useEffect(() => {
		if (assets.length > 0) {
			generateRows();
		}
	}, [assets, sortBy, sortAsc]);

	return (
		<Card className='predictive-table-card set-table-height'>
			<CardHeader className='d-flex justify-content-between align-items-center'>
				<div className='d-flex align-items-center'>
					<MdOutlineInventory className='fs-4' />
					<span className='fw-bold text-purple mx-2'>Assets</span>
				</div>
			</CardHeader>
			<CardBody>
				{loading ? (
					<Loader small={true} />
				) : (
					<Table className='predictive-table' responsive>
						<thead>
							<tr className='predictive-table-row text-center bg-white'>
								{headers.map((header) => {
									return (
										<th
											key={`pred-spend-assets-table-${header.key}`}
											onClick={() =>
												sortAssets(header.key)
											}
										>
											<span></span>
											<span className='mx-1'>
												{`${
													header.title
												} ${displaySortDirection(
													header.key
												)}`}
											</span>
										</th>
									);
								})}
								{/* <th width='30%'>
									<span className='mx-1'>
										Asset {displaySortDirection(sortBy)}
									</span>
								</th>
								<th>
									<span></span>
									<span className='mx-1'>
										Asset Type{' '}
										{displaySortDirection(sortBy)}
									</span>
								</th>
								<th>
									<span className='mx-1'>
										Health Score{' '}
										{displaySortDirection(sortBy)}
									</span>
								</th>
								<th>
									<span></span>
									<span className='mx-1'>
										Useful Life Remaining{' '}
										{displaySortDirection(sortBy)}
									</span>
								</th>
								<th>
									<span></span>
									<span className='mx-1'>
										Optimal Replacement Year{' '}
										{displaySortDirection(sortBy)}
									</span>
								</th> */}
							</tr>
						</thead>
						<tbody>{assetRows}</tbody>
					</Table>
				)}
			</CardBody>
		</Card>
	);
};

export { AssetsTable };
