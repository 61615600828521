import { useCallback, useEffect, useState } from 'react';
import { auth } from '@assets/services/auth-service';
import { Card, CardHeader, Table, Form, CardBody, Input } from 'reactstrap';
import { searchResponses } from './children/Search';
import { MdDownload } from 'react-icons/md';
import { AiOutlineClose } from 'react-icons/ai';
import { BsThreeDotsVertical } from 'react-icons/bs';
import { IoMdTrash } from 'react-icons/io';
import { Filter } from '@SignedIn/views/Locations/children/Filter';
import {
	canDelete,
	canViewVendors,
	isVerizonUser,
} from '@assets/services/user-role-service';
import { ConfirmDialog } from '@SignedIn/views/Locations/children/ConfirmDialog';
import { Submission } from '@SignedIn/views/SingleLocation/ActivitiesTab/children/Submission';
import { Loader } from '@SignedIn/Loader/Loader';
import { ExportModal } from './children/ExportModal';
import { useLocation } from 'react-router-dom';
import {
	deleteSubmission,
	getSubmission,
	getSubmissions,
} from '@services/submission-service';
import { getUser, updateUserFilter } from '@services/user-service';
import {
	getDocFromPath,
	getOrganizations,
} from '@services/organization-service';
import { getFormId, getForms } from '@services/form-service';
import { DB_FORM, DB_ORG } from '@constants/db';

const SubmissionsTable = (props) => {
	const {
		organization,
		browserLocationId,
		setViewingDrafts,
		isFocused,
		extraProps,
	} = props;
	const {
		formsArr,
		setFormsArr,
		changeForm,
		checked,
		setChecked,
		allChecked,
		setAllChecked,
		pageNum,
		setPageNum,
		sortBy,
		setSortBy,
		sortAsc,
		setSortAsc,
		searchVal,
		setSearchVal,
		formIndex,
		setFormIndex,
	} = extraProps;
	let displayAddress = true;
	if (browserLocationId) displayAddress = false;
	const userId = auth.currentUser.uid;
	const location = useLocation();

	// Org's forms array state
	// const drafts = {
	// 	data() {
	// 		return { formName: 'Drafts' };
	// 	},
	// };
	// const [formsArr, setFormsArr] = useState([drafts]);

	// Selected form index state
	// const [formIndex, setFormIndex] = useState();

	const [collectionCount, setCollectionCount] = useState();

	const limitRows = 10;

	// Loader state
	const [isLoading, setIsLoading] = useState(true);

	// Search value state
	// const [searchVal, setSearchVal] = useState('');

	// Search input value
	const [searchText, setSearchText] = useState('');

	// Tracks whether all checkboxes are selected or not
	// const [allChecked, setAllChecked] = useState(false);

	// Checked submissions state
	// const [checked, setChecked] = useState([]);

	// Show data state
	const [responses, setResponses] = useState();

	// Pagination tracking number state
	// const [pageNum, setPageNum] = useState(1);

	// Sort by (which column) state
	// const [sortBy, setSortBy] = useState('submittedDate');

	// Sort ascending (vs descending) state
	// const [sortAsc, setSortAsc] = useState();

	// Helper state to trigger re-rendering when responses changes
	const [responsesChange, setResponsesChange] = useState(false);

	// Responses table component state
	const [resTable, setResTable] = useState();

	// Bool state for displaying individual responses
	const [showForm, setShowForm] = useState();

	// Individual response state (to be displayed)
	const [chosenResponse, setChosenResponse] = useState({});

	// Filter component state
	const [filter, setFilter] = useState();

	// Determines whether the filter menu is shown
	const [showFilter, setShowFilter] = useState(false);

	// Determines whether the export CSV model is shown
	const [showExportModal, setShowExportModal] = useState(false);

	// Determines which columns are shown
	const [columnsShown, setColumnsShown] = useState();

	// Tracks whether columnsShown state changes (for useEffect's sake)
	const [columnsShownChanges, setColumnsShownChanges] = useState(false);

	// Determines whether the delete confirmation is shown
	const [showDeleteModal, setShowDeleteModal] = useState(false);

	// Used to skip the effect of useEffect when a dependency changes and in that particular instance, we don't want useEffect to be kicked off
	const [skipEffect, setSkipEffect] = useState(false);

	// Toggles showFilter state
	const toggleFilter = () => setShowFilter(!showFilter);

	// Toggles showExportModal state
	const toggleExportModal = () => setShowExportModal(!showExportModal);

	const [pageCount, setPageCount] = useState(0);

	// Queries Firebase for all of org's forms, assigns them to formsArr state,
	// and determines which form index to be assigned to form index state (as the initial form)
	const init = async () => {
		const newForms = [];
		if (
			browserLocationId == undefined &&
			organization == DB_ORG.DATAFLEET
		) {
			const orgsSnapshot = await getOrganizations();
			for (const org of orgsSnapshot.docs) {
				if (org.id == DB_ORG.URBANPLATES) continue;
				const formsSnap = await getForms(org.id);
				for (const form of formsSnap.docs) newForms.push(form);
			}
		} else if (organization == DB_ORG.CUSHMANWAKEFIELD) {
			// If org is cushman, differentiate between admins & users
			const canViewAllForms = await canViewVendors();
			// If admin, get all forms, like any other org
			if (canViewAllForms) {
				const formsSnap = await getForms(organization);
				for (const form of formsSnap.docs) newForms.push(form);
			}
			// If user, only get Handyman Checklist
			else {
				const formsSnap = await getForms(
					DB_ORG.CUSHMANWAKEFIELD,
					DB_FORM.HANDYMAN
				);
				for (const form of formsSnap.docs) newForms.push(form);
			}
		} else {
			// All other orgs
			const formsSnap = await getForms(organization);
			for (const form of formsSnap.docs) newForms.push(form);
		}

		const newFormsArr = [...newForms, ...formsArr].filter(
			(item, index, self) =>
				self
					.map((i) => i.data().formName)
					.indexOf(item.data().formName) == index
		);
		setFormsArr(newFormsArr);
		setFormIndex(0);
	};

	const debounce = (func) => {
		let timer;
		return function (...args) {
			const context = this;
			if (timer) clearTimeout(timer);
			timer = setTimeout(() => {
				timer = null;
				func.apply(context, args);
			}, 1000);
		};
	};

	// Update checked attribute for given location
	const updateCheck = async (index, check) => {
		let newChecked = [...checked];
		const newResponses = responses;
		const newVal = newResponses[index].ref;
		if (check) newChecked.push(newVal);
		else newChecked = newChecked.filter((item) => item.id != newVal.id);
		await setAllChecked(newChecked.length == collectionCount);
		await setChecked(newChecked);
		setResponsesChange(true);
	};

	// Deletes checked off responses from front-end and database
	const deleteResponses = async () => {
		for (const responseRef of checked) {
			await deleteSubmission(responseRef);
		}
		toggleDeleteModal();
		await setAllChecked(false);
		await setChecked([]);
		await handleSearch(checked.length);
	};

	// Handles search & updates responses with results
	const handleSearch = async (numDeleted = 0) => {
		setIsLoading(true);

		// e.g. if on page two, and delete event lowered count from 11 to 10, we want the first page of results
		if (
			pageNum > 1 &&
			(collectionCount - numDeleted) % limitRows == 0 &&
			pageNum == pageCount
		) {
			setSkipEffect(true);
			// Would kick off another handleSearch() if skip effect wasn't in place
			await setPageNum(pageNum - 1);
			setSkipEffect(false);
		}
		let { results, count } = await searchResponses(
			searchVal,
			organization,
			formsArr[formIndex].data().formName,
			pageNum,
			limitRows + numDeleted,
			sortAsc ? `asc(${sortBy})` : `desc(${sortBy})`,
			browserLocationId
		);
		setCollectionCount(count - numDeleted);
		setPageCount(Math.ceil((count - numDeleted) / limitRows));
		let docs = [];
		for (let i = 0; i < results.length; i++) {
			if (
				organization !== DB_ORG.DATAFLEET &&
				results[i].path.includes(organization)
			) {
				let docSnap = getDocFromPath(results[i].path);
				docs.push(docSnap);
			}
		}
		let formResponses = [];
		let data = await Promise.all(docs);
		let isVZWUser = false;
		if (organization == DB_ORG.CUSHMANWAKEFIELD) {
			isVZWUser = await isVerizonUser();
		}
		for (const response of data) {
			const ref = response.ref;
			const id = response.id;
			const data = response.data();
			if (isVZWUser) {
				if (data.reviewed != 'true') continue;
			}
			const logo = await (async () => {
				for (const f of (await getForms(organization)).docs) {
					const _data_logo = await f.data().logo;
					if (_data_logo) return _data_logo;
				}
				return null;
			})();

			data.submissionId = id;
			data.formId = response.ref.parent.parent.id;
			data.logo = logo;
			data.organization = organization;
			data.address = `${
				data.location.address || data.location.address1
			}, ${data.location.city}, ${data.location.state} ${
				data.location.zip
			}`;
			if (organization == DB_ORG.CUSHMANWAKEFIELD) {
				data.siteName = data.location.name;
			}
			data.submittedDate = formatDate(data.submittedDate);
			const newResponse = { id: id, data: data, ref: ref };
			formResponses = [...formResponses, newResponse].filter(
				(resp) => resp.data.deleted == false
			);
		}
		setResponses(formResponses);
		setIsLoading(false);
		setResponsesChange(true);
	};

	// eslint-disable-next-line
	const optimizedFn = useCallback(debounce(setSearchVal), []);

	// Toggles showDeleteModal state
	const toggleDeleteModal = () => setShowDeleteModal(!showDeleteModal);

	// Checks or unchecks boxes for all locations
	const checkAll = async () => {
		setIsLoading(true);
		const newChecked = [];
		if (!allChecked) {
			const form = formsArr[formIndex];
			const formOrg = form.ref.parent.parent.id;
			let isVZWUser = false;
			if (organization == DB_ORG.CUSHMANWAKEFIELD) {
				isVZWUser = await isVerizonUser();
			}
			const responsesSnap = await getSubmissions(
				formOrg,
				form.id,
				browserLocationId,
				isVZWUser
			);
			for (const response of responsesSnap.docs) {
				newChecked.push(response.ref);
			}
		}
		await setChecked(newChecked);
		await setAllChecked(!allChecked);
		setIsLoading(true);
		setResponsesChange(true);
	};

	// Handles when pagination buttons are clicked
	const pageBackward = () => {
		if (pageNum > 1) {
			setPageNum(pageNum - 1);
		}
	};

	const pageForward = () => {
		if (pageNum < pageCount) {
			setPageNum(pageNum + 1);
		}
	};

	// Sort responses upon column click
	const sortResponses = async (column) => {
		let isAscending = true;
		// Change direction if same key
		if (column == sortBy) isAscending = !sortAsc;
		setPageNum(1);
		setSortBy(column);
		setSortAsc(isAscending);
	};

	// Formats submission date to "YYYY-MM-DD HH:MM:SS" format
	const formatDate = (oldDate) => {
		let date = new Date(oldDate);

		let month = String(date.getMonth() + 1).padStart(2, '0');
		let day = String(date.getDate()).padStart(2, '0');
		let hours = String(date.getHours()).padStart(2, '0');
		let minutes = String(date.getMinutes()).padStart(2, '0');
		let seconds = String(date.getSeconds()).padStart(2, '0');
		let year = String(date.getFullYear());

		return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
	};

	const getColsShown = async () => {
		let newColsShown;
		const data = await getUser(userId);
		let dataExists = true;
		if (data.savedFilters == undefined) dataExists = false;
		else if (data.savedFilters.submissionsTable == undefined)
			dataExists = false;
		if (dataExists) newColsShown = data.savedFilters.submissionsTable;
		else {
			newColsShown = {};
			newColsShown.formName = true;
			newColsShown.submittedDate = true;
			newColsShown.address = true;
			if (organization != DB_ORG.CUSHMANWAKEFIELD) {
				newColsShown.assetTag = true;
				newColsShown.assetType = true;
			} else {
				newColsShown.approved = true;
			}
		}
		if (newColsShown.submittingUser == undefined) {
			const canView = await canViewVendors();
			if (canView) newColsShown.submittingUser = true;
		}
		if (
			organization == DB_ORG.CUSHMANWAKEFIELD &&
			newColsShown.approved == undefined
		) {
			newColsShown.approved = true;
		}
		setColumnsShown(newColsShown);
		setColumnsShownChanges(true);
	};

	const updateFilters = async () => {
		const data = await getUser(userId);
		let savedFilters = data.savedFilters;
		if (savedFilters == undefined) savedFilters = {};
		savedFilters.submissionsTable = columnsShown;
		await updateUserFilter(userId, savedFilters);
	};

	useEffect(() => {
		optimizedFn(searchText);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [searchText]);

	// On mount, grab all of org's forms
	useEffect(() => {
		init();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (!skipEffect) {
			if (
				isFocused &&
				typeof formIndex == 'number' &&
				formsArr.length > 0
			) {
				handleSearch();
				getColsShown();
			} else {
				setFilter();
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		isFocused,
		formIndex,
		formsArr,
		searchVal,
		pageNum,
		sortAsc,
		sortBy,
		skipEffect,
	]);

	useEffect(() => {
		if (columnsShownChanges && columnsShown && isFocused) {
			updateFilters();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [columnsShownChanges]);

	useEffect(() => {
		if (columnsShown && isFocused) {
			setFilter(
				<Filter
					visible={showFilter}
					columns={columnsShown}
					setColumns={setColumnsShown}
					colsChange={columnsShownChanges}
					setColsChange={setColumnsShownChanges}
					org={organization}
					displayAddress={displayAddress}
				/>
			);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [showFilter, columnsShownChanges]);

	// Update resTable state when responsesChage & pageNum states change
	useEffect(() => {
		if (
			(responsesChange || columnsShownChanges) &&
			responses != undefined &&
			columnsShown != undefined &&
			isFocused
		) {
			let newResTable;
			if (responses.length > 0) {
				const resRows = [];
				for (let i = 0; i < responses.length; i++) {
					const response = responses[i];
					if (response) {
						if (!response.data.organization)
							response.data.organization = organization;
						const isChecked = Boolean(
							checked.find((item) => item.id == response.id)
						);
						const newRow = (
							<tr
								key={response.id}
								onClick={(e) => {
									if (
										!e.target.className.includes(
											'submission-checkbox'
										)
									) {
										toggle(response, i);
									}
								}}
							>
								<td>
									<input
										className='submission-checkbox'
										type='checkbox'
										checked={isChecked}
										onChange={() => {
											updateCheck(i, !isChecked);
										}}
									/>
								</td>
								{(() =>
									columnsShown.assetTag &&
									organization != DB_ORG.CUSHMANWAKEFIELD ? (
										<td>{response.data.assetTag}</td>
									) : null)()}
								{(() =>
									columnsShown.assetType &&
									organization != DB_ORG.CUSHMANWAKEFIELD ? (
										<td>{response.data.assetType}</td>
									) : null)()}
								{(() =>
									columnsShown.formName ? (
										<td>{response.data.formName}</td>
									) : null)()}
								{(() =>
									columnsShown.address &&
									displayAddress &&
									organization != DB_ORG.CUSHMANWAKEFIELD ? (
										<td>{`${
											response.data.location.address ||
											response.data.location.address1
										}, ${response.data.location.city}, ${
											response.data.location.state
										} ${response.data.location.zip}`}</td>
									) : columnsShown.address &&
									  displayAddress &&
									  organization ==
											DB_ORG.CUSHMANWAKEFIELD ? (
										<td>{response.data.siteName}</td>
									) : null)()}
								{(() =>
									columnsShown.submittingUser ? (
										<td>
											{response.data.submittingUser ||
												null}
										</td>
									) : null)()}
								{(() =>
									columnsShown.submittedDate ? (
										<td>{response.data.submittedDate}</td>
									) : null)()}
								{(() =>
									columnsShown.approved &&
									organization == DB_ORG.CUSHMANWAKEFIELD ? (
										<td>
											{response.data.reviewed != undefined
												? response.data.reviewed
												: 'false'}
										</td>
									) : null)()}
							</tr>
						);
						resRows.push(newRow);
					}
				}
				newResTable = (
					<CardBody>
						<Table responsive hover>
							<thead>
								<tr>
									<th>
										<input
											type='checkbox'
											onClick={checkAll}
											checked={allChecked}
										/>
									</th>
									{(() =>
										columnsShown.assetTag &&
										organization !=
											DB_ORG.CUSHMANWAKEFIELD ? (
											<th
												onClick={() =>
													sortResponses('assetTag')
												}
												className={`is-bold-${
													sortBy == 'assetTag'
												}`}
											>
												Asset ID
												{(() => {
													if (
														sortBy == 'assetTag' &&
														sortAsc
													) {
														return '↑';
													} else if (
														sortBy == 'assetTag'
													) {
														return '↓';
													}
												})()}
											</th>
										) : null)()}
									{(() =>
										columnsShown.assetType &&
										organization !=
											DB_ORG.CUSHMANWAKEFIELD ? (
											<th
												onClick={() =>
													sortResponses('assetType')
												}
												className={`is-bold-${
													sortBy == 'assetType'
												}`}
											>
												Asset Type
												{(() => {
													if (
														sortBy == 'assetType' &&
														sortAsc
													) {
														return '↑';
													} else if (
														sortBy == 'assetType'
													) {
														return '↓';
													}
												})()}
											</th>
										) : null)()}
									{(() =>
										columnsShown.formName ? (
											<th
												onClick={() =>
													sortResponses('formName')
												}
												className={`is-bold-${
													sortBy == 'formName'
												}`}
											>
												Form Name
												{(() => {
													if (
														sortBy == 'formName' &&
														sortAsc
													) {
														return '↑';
													} else if (
														sortBy == 'formName'
													) {
														return '↓';
													}
												})()}
											</th>
										) : null)()}
									{(() =>
										columnsShown.address &&
										displayAddress &&
										organization !=
											DB_ORG.CUSHMANWAKEFIELD ? (
											<th
												onClick={() =>
													sortResponses(
														'location.address1'
													)
												}
												className={`is-bold-${
													sortBy ==
													'location.address1'
												}`}
											>
												Address
												{(() => {
													if (
														sortBy ==
															'location.address1' &&
														sortAsc
													) {
														return '↑';
													} else if (
														sortBy ==
														'location.address1'
													) {
														return '↓';
													}
												})()}
											</th>
										) : columnsShown.address &&
										  displayAddress &&
										  organization ==
												DB_ORG.CUSHMANWAKEFIELD ? (
											<th
												onClick={() =>
													sortResponses(
														'location.name'
													)
												}
												className={`is-bold-${
													sortBy == 'location.name'
												}`}
											>
												Site Name
												{(() => {
													if (
														sortBy ==
															'location.name' &&
														sortAsc
													) {
														return '↑';
													} else if (
														sortBy ==
														'location.name'
													) {
														return '↓';
													}
												})()}
											</th>
										) : null)()}
									{(() =>
										columnsShown.submittingUser ? (
											<th
												onClick={() =>
													sortResponses(
														'submittingUser'
													)
												}
												className={`is-bold-${
													sortBy == 'submittingUser'
												}`}
											>
												Submitting User
												{(() => {
													if (
														sortBy ==
															'submittingUser' &&
														sortAsc
													) {
														return '↑';
													} else if (
														sortBy ==
														'submittingUser'
													) {
														return '↓';
													}
												})()}
											</th>
										) : null)()}
									{(() =>
										columnsShown.submittedDate ? (
											<th
												onClick={() =>
													sortResponses(
														'submittedDate'
													)
												}
												className={`is-bold-${
													sortBy == 'submittedDate'
												}`}
											>
												Submission Date
												{(() => {
													if (
														sortBy ==
															'submittedDate' &&
														sortAsc
													) {
														return '↑';
													} else if (
														sortBy ==
														'submittedDate'
													) {
														return '↓';
													}
												})()}
											</th>
										) : null)()}
									{(() =>
										columnsShown.approved &&
										organization ==
											DB_ORG.CUSHMANWAKEFIELD ? (
											<th
												onClick={() =>
													sortResponses('reviewed')
												}
												className={`is-bold-${
													sortBy == 'reviewed'
												}`}
											>
												Approved
												{(() => {
													if (
														sortBy == 'reviewed' &&
														sortAsc
													) {
														return '↑';
													} else if (
														sortBy == 'reviewed'
													) {
														return '↓';
													}
												})()}
											</th>
										) : null)()}
								</tr>
							</thead>
							<tbody>{resRows}</tbody>
						</Table>
						<div className='activities-page-buttons d-flex flex-row justify-content-end mt-2'>
							<button
								className='px-2 border rounded-start fs-5'
								onClick={pageBackward}
							>
								<i className='bi bi-arrow-left-short' />
							</button>
							<div
								className='border-top border-bottom p-2 h-100'
								style={{ fontSize: '12px' }}
							>{`${pageNum} of ${pageCount}`}</div>
							<button
								className='px-2 border rounded-end fs-5'
								onClick={pageForward}
							>
								<i className='bi bi-arrow-right-short' />
							</button>
						</div>
					</CardBody>
				);
			} else if (searchVal.length > 0) {
				newResTable = (
					<CardBody>
						<div
							className='d-flex flex-column justify-content-center align-items-center w-100'
							style={{ minHeight: '200px' }}
						>
							<div>No search results. Try again.</div>
						</div>
					</CardBody>
				);
			} else {
				newResTable = (
					<CardBody>
						<div
							className='d-flex flex-column justify-content-center align-items-center w-100'
							style={{ minHeight: '200px' }}
						>
							<div>No submissions to display.</div>
						</div>
					</CardBody>
				);
			}
			setResTable(newResTable);
		}
		if (responsesChange) setResponsesChange(false);
		if (columnsShownChanges) setColumnsShownChanges(false);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [responsesChange, columnsShownChanges]);

	useEffect(() => {
		if (location?.state?.submissionId != null) {
			showSubmissionFromAlert();
		}
		// eslint-disable-next-line
	}, [location.state]);

	const showSubmissionFromAlert = async () => {
		const formId = await getFormId(organization, DB_FORM.INVENTORY);
		const submission = await getSubmission(
			organization,
			formId,
			location.state.submissionId
		);
		setChosenResponse(submission);
		setShowForm(true);
	};

	const toggle = async (submission = null, searchIndex = null) => {
		if (submission != undefined && searchIndex != null) {
			setChosenResponse(submission.data);
		}
		if (showForm == true) {
			await setShowForm(false);
			setResponsesChange(true);
		} else {
			setShowForm(true);
		}
	};

	// const refreshData = async () => {
	// 	getColsShown();
	// };

	return showForm ? (
		<div>
			<Card className='mt-4 submissions-table no-border'>
				<Submission
					chosenResponse={chosenResponse}
					updateResData={handleSearch}
					toggleSubmission={toggle}
					organization={chosenResponse.organization}
				/>
			</Card>
		</div>
	) : (
		<div>
			<Card className='mt-4 submissions-table'>
				<CardHeader className='p-3 d-flex flex-column flex-md-row justify-content-between'>
					<Form
						onSubmit={(e) => e.preventDefault()}
						className='d-flex flex-column justify-content-center'
					>
						<input
							id='search'
							className='searchbar rounded-pill'
							type='text'
							disabled={isLoading}
							placeholder='Search'
							value={searchText}
							onChange={(e) => {
								setSearchText(e.target.value);
							}}
						/>
					</Form>
					<Input
						className='my-3 my-md-0 w-auto'
						type='select'
						onChange={(e) => {
							changeForm(Number(e.target.value));
							if (e.target.value == formsArr.length - 1) {
								setViewingDrafts(true);
							}
						}}
					>
						{formsArr.map((form, i) => {
							return (
								<option
									value={i}
									selected={i == formIndex}
									key={form.id}
								>
									{form.data().formName}
								</option>
							);
						})}
					</Input>
					<div className='d-flex flex-row-reverse flex-md-row justify-content-between align-end'>
						<button className='filter-btn table-btn rounded-circle no-border'>
							<AiOutlineClose
								className={`filter-close ${
									showFilter && 'filter-close-expand'
								}`}
								onClick={toggleFilter}
							/>
							{!showFilter && (
								<BsThreeDotsVertical
									className='filter-dots'
									onClick={toggleFilter}
								/>
							)}
							{filter}
						</button>
						<button
							onClick={toggleDeleteModal}
							color='primary'
							className='rounded-circle no-border table-btn'
							hidden={!canDelete()}
						>
							<IoMdTrash className='larger-font-icon larger-font' />
						</button>
						<button
							color='primary'
							className='rounded-circle no-border table-btn'
							onClick={toggleExportModal}
						>
							<MdDownload className='larger-font-icon larger-font' />
						</button>
						{/* <button
							color='primary'
							className='rounded-circle no-border table-btn'
							// hidden={!canAdd()}
							onClick={() => setViewingDrafts(true)}
						>
							<MdOutlineDrafts className='larger-font-icon larger-font' />
						</button> */}
						{/* <Button
							color='primary'
							className='rounded-circle no-border table-btn'
							outline
							onClick={() => refreshData()}
						>
							<MdRefresh className='larger-font-icon larger-font' />
						</Button> */}
					</div>
				</CardHeader>
				{isLoading ? <Loader /> : resTable}
			</Card>
			<ConfirmDialog
				showConfirm={showDeleteModal}
				toggle={toggleDeleteModal}
				title='Confirm Deletion'
				body={`Are you sure you want to delete ${checked.length} of ${collectionCount} submissions?`}
				functionality={deleteResponses}
			/>
			<ExportModal
				organization={organization}
				showExport={showExportModal}
				browserLocationId={browserLocationId || null}
				toggle={toggleExportModal}
			/>
		</div>
	);
};
export { SubmissionsTable };
