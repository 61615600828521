import Chatbot from 'react-chatbot-kit';
import 'react-chatbot-kit/build/main.css';
import config from './config.js';
import { ActionProvider } from './ActionProvider.jsx';
import { MessageParser } from './MessageParser.jsx';

const ChatbotMainCard = () => {
	const validateInput = (input) => (input.length ? true : false);
	return (
		<div className='chatbot-main-card rounded-md lg-m-0'>
			<Chatbot
				config={config}
				messageParser={MessageParser}
				actionProvider={ActionProvider}
				validator={validateInput}
			/>
		</div>
	);
};

export { ChatbotMainCard };
