import { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { SubmissionsTable } from '@SignedIn/views/Admin/SubmissionsTable/SubmissionsTable';
import { DraftsTable } from './children/DraftsTable.js';

const ActivitiesTab = (props) => {
	const { browserLocationId, organization, isFocused } = props;
	const location = useLocation();

	const drafts = {
		data() {
			return { formName: 'Drafts' };
		},
	};
	const [formsArr, setFormsArr] = useState([drafts]);

	const [checked, setChecked] = useState([]);
	const [allChecked, setAllChecked] = useState(false);
	const [pageNum, setPageNum] = useState(1);
	const [sortBy, setSortBy] = useState('submittedDate');
	const [sortAsc, setSortAsc] = useState();
	const [searchVal, setSearchVal] = useState('');
	const [formIndex, setFormIndex] = useState();


	const changeForm = async (i) => {
		setChecked([]);
		setAllChecked(false);
		setPageNum(1);
		setSortBy('submittedDate');
		setSortAsc(false);
		setSearchVal('');
		setFormIndex(i);
	};

	const extraProps = {
		formsArr,
		setFormsArr,
		changeForm,
		checked,
		setChecked,
		allChecked,
		setAllChecked,
		pageNum,
		setPageNum,
		sortBy,
		setSortBy,
		sortAsc,
		setSortAsc,
		searchVal,
		setSearchVal,
		formIndex,
		setFormIndex,
	};

	// Viewing drafts state
	const [viewingDrafts, setViewingDrafts] = useState(
		(() => {
			if (location.state) {
				return location.state.viewDrafts || false;
			} else {
				return false;
			}
		})()
	);

	return !viewingDrafts ? (
		<SubmissionsTable
			organization={organization}
			browserLocationId={browserLocationId}
			setViewingDrafts={setViewingDrafts}
			isFocused={isFocused}
			extraProps={extraProps}
		/>
	) : (
		<DraftsTable
			organization={organization}
			browserLocationId={browserLocationId}
			setViewingDrafts={setViewingDrafts}
			isFocused={isFocused}
			extraProps={extraProps}
		/>
	);
};
export { ActivitiesTab };
