import { useEffect, useState } from 'react';
import { Card, CardHeader, Table, CardBody, Button } from 'reactstrap';
import { Loader } from '@SignedIn/Loader/Loader';
import { HealthScore } from '@SignedIn/views/Dashboard/HealthScore/HealthScore';
import { csvParse } from 'd3';
import { WeekDropdown } from './WeekDropdown';
import { MdDownload } from 'react-icons/md';
import { cushmanExport } from '@SignedIn/views/Admin/cushmanExport/cushmanExport';
import {
	getEvenOddLocations,
	updateWeekStoreLocations,
} from '@services/location-service';
import { getSubmissions } from '@services/submission-service';
import { getFormId } from '@services/form-service';
import { DB_FORM } from '@constants/db';
const CurrentMonthTab = (props) => {
	const { org, isFocused, setToast } = props;
	const [tableRows, setTableRows] = useState();
	const [noSubmissionRows, setNoSubmissionRows] = useState();
	const [submissionsTable, setSubmissionsTable] = useState();
	const [noSubmissionsTable, setNoSubmissionsTable] = useState();
	const [isLoading, setIsLoading] = useState(true);
	const [tabChange, setTabChange] = useState(false);
	const [week1Percent, setWeek1Percent] = useState();
	const [week2Percent, setWeek2Percent] = useState();
	const [week3Percent, setWeek3Percent] = useState();
	const [week4Percent, setWeek4Percent] = useState();
	const weeklyHeaders = 'storenumberweek';

	const getLocations = async () => {
		let locationsSnap = await getEvenOddLocations(org, true);
		let locationsWithSubmission = [];
		let locationsWithoutSubmission = [];
		const formId = await getFormId(org, DB_FORM.HANDYMAN);
		const end = locationsSnap.docs.length;
		let i = 1;
		locationsSnap.forEach(async (location) => {
			let row = {
				sitename: location.data().name,
				address: location.data().address1,
				city: location.data().city,
				zip: location.data().zip,
				state: location.data().state,
			};
			const querySnap = await getSubmissions(
				org,
				formId,
				null,
				location.data().tririgaBuildingSystemId
			);
			if (!querySnap.empty) {
				let dateArr = [];
				querySnap.forEach((item) => {
					dateArr.push(item.data().submittedDate);
				});

				dateArr = dateArr.sort((a, b) => new Date(b) - new Date(a));

				let mostRecentSubmissionDate = new Date(dateArr[0]);

				const thisMonthStartDate = new Date();
				thisMonthStartDate.setDate(1);
				thisMonthStartDate.setMonth(thisMonthStartDate.getMonth());

				if (mostRecentSubmissionDate >= thisMonthStartDate) {
					locationsWithSubmission.push(row);
				} else {
					locationsWithoutSubmission.push(row);
				}
			} else {
				locationsWithoutSubmission.push(row);
			}
			if (i == end) {
				setTableRows(locationsWithSubmission);
				setNoSubmissionRows(locationsWithoutSubmission);
			}
			i++;
		});
	};

	const createSubmissionsTable = () => {
		let rowArr = [];
		for (const item of tableRows) {
			let row = (
				<tr className='pmreport-table-row'>
					<td>{item.sitename}</td>
					<td>{item.address}</td>
					<td>{item.city}</td>
					<td>{item.state}</td>
					<td>{item.zip}</td>
				</tr>
			);
			rowArr.push(row);
		}
		setSubmissionsTable(<tbody>{rowArr}</tbody>);
	};

	const createNoSubmissionsTable = () => {
		let data = [];
		for (const item of noSubmissionRows) {
			let row = (
				<tr className='pmreport-table-row'>
					<td>{item.sitename}</td>
					<td>{item.address}</td>
					<td>{item.city}</td>
					<td>{item.state}</td>
					<td>{item.zip}</td>
				</tr>
			);
			data.push(row);
		}
		setNoSubmissionsTable(<tbody>{data}</tbody>);
	};

	const getCurrentMonthAndYear = () => {
		const date = new Date();
		const lastMonth = date.toLocaleString('default', { month: 'long' });
		return `${lastMonth} ${date.getFullYear()}`;
	};

	const getPercentWeeklyLocations = async (weekVal, setWeekPercent) => {
		const formId = await getFormId(org);
		let completedLocationsForWeek = 0;
		let numLocationsWeek;

		const querySnapshot = await getEvenOddLocations(org, true, weekVal);
		numLocationsWeek = querySnapshot.size;
		let i = 1;
		querySnapshot.forEach(async (location) => {
			const querySnap = await getSubmissions(
				org,
				formId,
				null,
				location.data().tririgaBuildingSystemId
			);

			if (!querySnap.empty) {
				let dateArr = [];
				querySnap.forEach((item) => {
					dateArr.push(item.data().submittedDate);
				});

				dateArr = dateArr.sort((a, b) => new Date(b) - new Date(a));

				let mostRecentSubmissionDate = new Date(dateArr[0]);

				const thisMonth = new Date();
				thisMonth.setDate(1);
				thisMonth.setHours(0);
				thisMonth.setMinutes(0);
				thisMonth.setSeconds(0);

				if (mostRecentSubmissionDate >= thisMonth) {
					completedLocationsForWeek++;
				}
			}

			if (i == numLocationsWeek) {
				const percentResult = Math.round(
					(completedLocationsForWeek / numLocationsWeek) * 100
				);
				setWeekPercent(percentResult);
			}
			i++;
		});
	};

	const readCsvFile = async (e, callback) => {
		const file = e.target.files[0];
		const reader = new FileReader();

		reader.onload = async (e) => {
			const text = e.target.result;
			const parsedFile = csvParse(text);
			callback(parsedFile);
		};

		reader.readAsText(file);
	};

	const uploadLocationSchedule = (e) => {
		readCsvFile(e, async (data) => {
			let uploadedHeaders;
			if (data[0]) {
				uploadedHeaders = Object.keys(data[0])
					.sort()
					.join('')
					.toLowerCase();
			}

			if (uploadedHeaders !== weeklyHeaders) {
				setToast({
					showing: true,
					title: 'Error',
					message:
						'Invalid columns. Please reformat to match appropriate columns.',
					type: 'danger',
					position: 'bottom',
				});
				setTimeout(() => {
					setToast({
						showing: false,
					});
				}, 4500);
				return;
			}

			for (let i = 0; i < data.length; ++i) {
				const row = data[i];

				// simple function to check if there is an entered value and if its a number
				if (!row.Week && !Number.isInteger(parseInt(row.Week))) {
					continue;
				}
				await updateWeekStoreLocations(org, row.Storenumber, row.Week);
			}

			setToast({
				showing: true,
				title: 'Success',
				message: 'Successfully updated locations.',
				type: 'success',
				position: 'bottom',
			});
			setTimeout(() => {
				setToast({
					showing: false,
				});
			}, 4500);
		});
	};

	useEffect(() => {
		if (isFocused) {
			setTabChange(true);
		}
	}, [isFocused]);

	useEffect(() => {
		if (isFocused) {
			const func = async () => await getLocations();
			func();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [tabChange]);

	useEffect(() => {
		if (tableRows && noSubmissionRows) {
			createSubmissionsTable();
			createNoSubmissionsTable();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [tableRows, noSubmissionRows]);

	useEffect(() => {
		if (noSubmissionsTable && submissionsTable) {
			setIsLoading(false);
		}
	}, [submissionsTable, noSubmissionsTable]);

	useEffect(() => {
		if (isFocused) {
			getPercentWeeklyLocations(1, setWeek1Percent);
			getPercentWeeklyLocations(2, setWeek2Percent);
			getPercentWeeklyLocations(3, setWeek3Percent);
			getPercentWeeklyLocations(4, setWeek4Percent);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [tabChange]);

	return (
		<div>
			<div className='d-none d-xl-block'>
				{isLoading ? (
					<Loader />
				) : (
					<div className='current-month-health-wrapper'>
						<div className='current-month-single-health-wrapper'>
							<HealthScore
								healthScore={week1Percent}
								label={'Week 1 PM Reports'}
							/>
						</div>
						<div className='current-month-single-health-wrapper'>
							<HealthScore
								healthScore={week2Percent}
								label={'Week 2 PM Reports'}
							/>
						</div>
						<div className='current-month-single-health-wrapper'>
							<HealthScore
								healthScore={week3Percent}
								label={'Week 3 PM Reports'}
							/>
						</div>
						<div className='current-month-single-health-wrapper'>
							<HealthScore
								healthScore={week4Percent}
								label={'Week 4 PM Reports'}
							/>
						</div>
					</div>
				)}
			</div>
			<div className='d-xl-none'>
				{isLoading ? (
					<Loader />
				) : (
					<WeekDropdown
						week1Percent={week1Percent}
						week2Percent={week2Percent}
						week3Percent={week3Percent}
						week4Percent={week4Percent}
					/>
				)}
			</div>
			<div className='w-100 d-flex justify-content-center justify-content-md-end'>
				<button className='upload-pm-btn'>
					<label
						htmlFor='upload-locations-schedule'
						className='upload-pm-label'
					>
						Upload Location Schedule
					</label>
					<input
						onChange={uploadLocationSchedule}
						id='upload-locations-schedule'
						type='file'
						accept='.csv'
					/>
				</button>
			</div>
			<div
				style={{
					gap: '20px',
				}}
				className='d-flex flex-column flex-md-row mt-3'
			>
				<Card className='current-month-progress-table'>
					<CardHeader className='p-3 d-flex flex-column flex-lg-row justify-content-between align-items-start align-items-sm-center'>
						<div className='table-title m-0 opacity-75 px-2'>
							Locations With PM Report{' '}
							{`for ${getCurrentMonthAndYear()}`}
						</div>
						<Button
							color='primary'
							className='rounded-circle no-border table-btn'
							outline
							onClick={() =>
								cushmanExport(
									tableRows,
									`Locations With PM Report ${getCurrentMonthAndYear()}`
								)
							}
						>
							<MdDownload className='larger-font-icon larger-font' />
						</Button>
					</CardHeader>
					<CardBody>
						{isLoading ? (
							<Loader />
						) : (
							<Table>
								<thead>
									<tr>
										<th>Site Name</th>
										<th>Address</th>
										<th>City</th>
										<th>State</th>
										<th>Zip</th>
									</tr>
								</thead>
								{submissionsTable}
							</Table>
						)}
					</CardBody>
				</Card>
				<Card className='current-month-progress-table'>
					<CardHeader className='p-3 d-flex flex-row justify-content-between'>
						<div className='m-0 opacity-75'>
							Locations Without PM Report{' '}
							{`for ${getCurrentMonthAndYear()}`}
						</div>
						<Button
							color='primary'
							className='rounded-circle no-border table-btn'
							outline
							onClick={() =>
								cushmanExport(
									noSubmissionRows,
									`Locations Without PM Report ${getCurrentMonthAndYear()}`
								)
							}
						>
							<MdDownload className='larger-font-icon larger-font' />
						</Button>
					</CardHeader>
					<CardBody>
						{isLoading ? (
							<Loader />
						) : (
							<Table>
								<thead>
									<tr>
										<th>Site Name</th>
										<th>Address</th>
										<th>City</th>
										<th>State</th>
										<th>Zip</th>
									</tr>
								</thead>
								{noSubmissionsTable}
							</Table>
						)}
					</CardBody>
				</Card>
			</div>
		</div>
	);
};

export { CurrentMonthTab };
