import { BsArrowRight } from 'react-icons/bs';
import { Table } from 'reactstrap';
import { formatDate } from '@utils/ISOdateToMMDDYYYY';
import {
	Accordion,
	AccordionBody,
	AccordionHeader,
	AccordionItem,
} from 'reactstrap';
import { useEffect, useState } from 'react';

const EditLog = ({ editLog }) => {
	const [open, setOpen] = useState('0');
	const [accordionEdits, setAccordionEdits] = useState();

	const toggleAccordion = (id) => {
		if (open == id) {
			setOpen();
		} else {
			setOpen(id);
		}
	};

	const decamelize = (str) => {
		return str
			.replace(/([a-z])([A-Z])/g, '$1 $2')
			.replace(/([A-Z])([A-Z][a-z])/g, '$1 $2')
			.replace(/([a-z])([A-Z]{2,})/g, '$1 $2')
			.replace(
				/\b(\w)(\w*)/g,
				(_, first, rest) =>
					`${first.toUpperCase()}${rest.toLowerCase()}`
			);
	};

	useEffect(() => {
		let editEntries = [];
		for (let i = 0; i < editLog.length; ++i) {
			const entry = editLog[i];
			const editEntry = (
				<tr>
					<td className='w-75'>
						<Accordion open={open} toggle={toggleAccordion}>
							<AccordionItem>
								<AccordionHeader targetId={`${i}`}>
									<span className='fw-bold'>
										{formatDate(entry.edittedDate)}
									</span>
								</AccordionHeader>
								<AccordionBody accordionId={`${i}`}>
									{Object.entries(entry.edits).map(
										([field, value]) => {
											const item = (
												<div className='my-1'>
													<span className='fw-bold fs-5'>
														{decamelize(field)}
													</span>
													<div>
														<span
															style={{
																color: 'red',
															}}
														>
															{value.beforeEdit.toString()}
														</span>
														<span className='mx-2'>
															<BsArrowRight />
														</span>
														<span className='text-pe-green'>
															{value.afterEdit.toString()}
														</span>
													</div>
												</div>
											);
											return item;
										}
									)}
								</AccordionBody>
							</AccordionItem>
						</Accordion>
					</td>
					<td>{formatDate(entry.edittedDate)}</td>
					<td>{entry.edittingUser}</td>
				</tr>
			);
			editEntries.push(editEntry);
		}
		setAccordionEdits(editEntries);
		// eslint-disable-next-line
	}, [open]);

	return (
		<div className='edit-log'>
			<Table className='p-4 w-100 edit-log-table'>
				<thead>
					<tr>
						<td className='fw-bold'>Changes</td>
						<td className='fw-bold'>Edited At</td>
						<td className='fw-bold'>Edited By</td>
					</tr>
				</thead>
				<tbody>{accordionEdits}</tbody>
			</Table>
		</div>
	);
};

export { EditLog };
