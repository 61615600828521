import Fuse from 'fuse.js';
import { DB_ORG } from '@constants/db';
const searchLocations = async (val, list, org) => {
	if (val.length > 2) {
		const options = {
			includeScore: true,
			keys: ['data.name', 'data.city', 'data.state', 'data.zip'],
			threshold: 0.25,
		};

		if (org != DB_ORG.CUSHMANWAKEFIELD) {
			options.keys.push('data.address');
			options.keys.push('data.address1');
			options.keys.push('data.address2');
		}

		const fuse = new Fuse(list, options);

		const results = await fuse.search(val);

		let returnVal = [];

		for (let res of results) {
			returnVal = [...returnVal, res.item];
		}

		return returnVal;
	} else {
		return [];
	}
};

export { searchLocations };
