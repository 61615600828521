import { useEffect, useState } from 'react';
import { Col, Row, Card } from 'reactstrap';
// import { AssetsCardRow } from './children/AssetsCardRow';
import { Submission } from '../ActivitiesTab/children/Submission';
import { LastAbsorbedTicketCard } from './children/LastAbsorbedTicketCard';
import { DB_FORM, DB_ORG } from '@constants/db';
import { getForms } from '@services/form-service';
import { getSubmissions } from '@services/submission-service';
import { getDocFromRef } from '@services/organization-service';
import { getUser } from '@services/user-service';
import { CampingWorldReport } from '../ActivitiesTab/children/CampingWorldReport';
import { getOrganization } from '@services/organization-service';
import { isVerizonUser } from '@assets/services/user-role-service';
import { CushmanLocationInfoCard } from './children/CushmanLocationInfoCard';
import { ChatbotMainCard } from '../../Dashboard/ChatbotMainCard/ChatbotMainCard';
import { AlfredsNotes } from '@components/layouts/SignedIn/AlfredsNotes/AlfredsNotes';

const GeneralTab = (props) => {
	const { info, setToast, healthScore } = props;

	// const [genInfo, setGenInfo] = useState({
	// 	client: info.org,
	// 	address: info.data.address,
	// 	address1: info.data.address1,
	// 	address2: info.data.address2,
	// 	city: info.data.city,
	// 	state: info.data.state,
	// 	zip: info.data.zip,
	// 	propertyType: info.data.type || 'Commercial',
	// 	buildingType: info.data.buildingType || 'Offices',
	// 	storeDesign: info.data.storeDesign || 'Next Gen Refresh',
	// 	buildingSize: info.data.squareFootage || '2000',
	// });

	// Last submission state
	const [lastSubmission, setLastSubmission] = useState(null);

	// General tab state
	const [generalTab, setGeneralTab] = useState();

	// org flags state
	const [flags, setFlags] = useState();

	// Queries the db for the last submission in this org / location
	const updateLastSubmission = async () => {
		let formsSnap;
		// If org is cushman, only pull handyman checklist data
		if (info.org == DB_ORG.CUSHMANWAKEFIELD) {
			formsSnap = await getForms(
				DB_ORG.CUSHMANWAKEFIELD,
				DB_FORM.HANDYMAN
			);
		}
		// All other orgs
		else {
			formsSnap = await getForms(info.org);
		}
		let responses = [];
		for (const form of formsSnap.docs) {
			const formResponses = await getSubmissions(
				info.org,
				form.id,
				info.id
			);
			if (!formResponses.empty) responses.push(...formResponses.docs);
		}
		if (info.org == DB_ORG.CUSHMANWAKEFIELD) {
			const isVerizon = await isVerizonUser();
			if (isVerizon) {
				responses = responses.filter((response) => {
					return response.data().reviewed == 'true';
				});
			}
		}
		if (responses.length > 0) {
			responses.sort((a, b) => {
				const valA = formatDate(a.data().submittedDate);
				const valB = formatDate(b.data().submittedDate);
				if (valA > valB) {
					return -1;
				} else if (valA < valB) {
					return 1;
				} else {
					return 0;
				}
			});
			const latestResForm = await getDocFromRef(
				responses[0].ref.parent.parent
			);
			const id = responses[0].id;
			const data = responses[0].data();
			data.submissionId = id;
			data.formId = responses[0].ref.parent.parent.id;
			data.logo = latestResForm.data().logo;
			data.submittingUser = await getUserByID(data.submittingUserId);
			data.address = `${
				data.location.address || data.location.address1
			}, ${data.location.state} ${data.location.zip}`;
			data.submittedDate = formatDate(data.submittedDate);
			data.organization = info.org;
			const latestResponse = { id: id, data: data };

			setLastSubmission(latestResponse);
		} else {
			setLastSubmission(undefined);
		}
	};

	// Formats submission date to "YYYY-MM-DD HH:MM:SS" format
	const formatDate = (oldDate) => {
		let date = new Date(oldDate);

		let month = String(date.getMonth() + 1).padStart(2, '0');
		let day = String(date.getDate()).padStart(2, '0');
		let hours = String(date.getHours()).padStart(2, '0');
		let minutes = String(date.getMinutes()).padStart(2, '0');
		let seconds = String(date.getSeconds()).padStart(2, '0');
		let year = String(date.getFullYear());

		return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
	};

	const getUserByID = async (id) => {
		if (id) {
			const user = await getUser(id);
			if (user) {
				return user.name;
			}
		}
		return 'n/a';
	};

	const getOrgFlags = async () => {
		const orgSnap = await getOrganization(info.org);
		setFlags(orgSnap.data());
	};

	useEffect(() => {
		getOrgFlags();
	}, []);

	// Updates lastSubmission when info changes
	useEffect(() => {
		if (info != undefined) {
			updateLastSubmission();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [info]);

	// Updates generalTab state when lastSubmission state changes
	useEffect(() => {
		if (flags && lastSubmission !== null) {
			setGeneralTab(
				<div color='light' className='general-tab tab-content pt-3'>
					<Row className='light-border-b mb-2 md-0'>
						<Col className='col-12 col-sm-12 col-xl-5 mb-5 mb-lg-0'>
							<ChatbotMainCard />
						</Col>
						<Col className='col-12 col-xl-7 mb-2 mb-md-0 d-flex justify-content-center align-items-center'>
							<AlfredsNotes healthScore={healthScore} />
						</Col>
					</Row>
					{/* <Row>
						<Col>
							<AssetsCardRow info={info} />
						</Col>
					</Row> */}
					<Row className='my-2 gen-tab-responsive-height'>
						<Col xs='12' lg='6' className='h-100'>
							{info.org == DB_ORG.CUSHMANWAKEFIELD && (
								<Card className='submissions-table gen-tab-submission gen-info-card-wrapper h-100'>
									{lastSubmission !== undefined ? (
										<Submission
											chosenResponse={lastSubmission.data}
											updateResData={() => {}}
											toggleSubmission={null}
										/>
									) : (
										<Col
											xs='12'
											lg='6'
											className='d-flex justify-content-center align-items-center w-100 py-5 h-100'
										>
											<div>No recent submissions.</div>
										</Col>
									)}
								</Card>
							)}
						</Col>
						<Col
							xs='12'
							lg='6'
							className='d-flex flex-column justify-content-between h-100 mt-3 mt-lg-0'
						>
						{info.org !=
							DB_ORG.CUSHMANWAKEFIELD ? null : lastSubmission !==
							  undefined ? (
								<Card className='submissions-table gen-tab-submission gen-info-card-wrapper h-50'>
									<LastAbsorbedTicketCard
										formId={lastSubmission.data.formId}
										locationId={info.id}
										org={info.org}
									/>
								</Card>
							) : (
								<Card className='mt-4 submissions-table gen-tab-submission gen-info-card-wrapper h-50'>
									<Col
										xs='12'
										lg='6'
										className='d-flex justify-content-center align-items-center w-100 py-5 h-100'
									>
										<div>No recently absorbed tickets.</div>
									</Col>
								</Card>
							)}
						{info.org != DB_ORG.CUSHMANWAKEFIELD ? null : (
								<Card className='mt-4 submissions-table gen-tab-submission gen-info-card-wrapper'>
									<CushmanLocationInfoCard
										locationId={info.id}
										org={info.org}
									/>
								</Card>
							)}
						{flags.showSiteInspectionReport && (
								<CampingWorldReport
									address1={info.data.address1}
									address2={`${info.data.city}, ${info.data.state} ${info.data.zip}`}
									org={info.org}
									locationId={info.id}
									setToast={setToast}
								/>
							)}
						</Col>
					</Row>
				</div>
			);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [lastSubmission, flags]);

	return generalTab;
};

export { GeneralTab };
