import { useEffect, useState } from 'react';
import { useThemeMode } from '../../../../../../hooks/themeMode';
import styles from './BotChatMessage.module.css';
import Loader from '@components/layouts/SignedIn/Chatbot/Loader';
import { useGraph } from '../graphContext';

const FAILED_REQUEST_MESSAGE =
	'Sorry, it seems I am having some technical difficulties at the moment. Please try your question again later.';

const BotChatMessage = ({ message }) => {
	const [messageText, setMessageText] = useState(null);
	const [responseIds, setResponseIds] = useState(null);
	const { addGraph } = useGraph();
	const { thememode } = useThemeMode();

	const scrollIntoView = () => {
		setTimeout(() => {
			const container = document.querySelector(
				'.react-chatbot-kit-chat-message-container'
			);

			container.scrollTo(0, container.scrollHeight);
		}, 100);
	};

	useEffect(() => {
		if (!responseIds) return;

		const eventSource = new EventSource(
			process.env.ALFRED_SERVICE_URL + `/result?ids=${responseIds.join()}`
		);

		eventSource.addEventListener('message_event', ({ data }) => {
			console.log('message event', data);
			setMessageText(data);
			scrollIntoView();
		});

		eventSource.addEventListener('graph_event', ({ data }) => {
			console.log('graph event', data);
			addGraph(data);
		});

		eventSource.addEventListener('end_event', () => {
			eventSource.close();
		});

		return () => {
			eventSource.close();
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [responseIds]);

	useEffect(() => {
		if (typeof message === 'string') {
			setMessageText(message);
			return;
		}

		message
			.then((res) =>
				res
					.clone()
					.json()
					.then((data) => setResponseIds(data.id))
			)
			.catch(() => setMessageText(FAILED_REQUEST_MESSAGE));
	}, [message]);

	const messageContainerStyleClasses =
		thememode !== 'dark'
			? styles.messageContainer
			: styles.messageContainer_dark;
	const messageTextStyleClasses =
		thememode !== 'dark' ? styles.messageText : styles.messageText_dark;

	return (
		<div className={messageContainerStyleClasses}>
			{messageText ? (
				<span className={messageTextStyleClasses}>{messageText}</span>
			) : (
				<Loader dashboard={true} />
			)}
			<div className='react-chatbot-kit-chat-bot-message-arrow'></div>
		</div>
	);
};

export default BotChatMessage;
