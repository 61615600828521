const IGuideTab = ({ iguide }) => {
	return (
		<div className='iguide-wrapper'>
			<iframe
				src={`//youriguide.com/embed/${iguide}`}
				scrolling='no'
				frameBorder='0'
				allowfullscreen
				width={'100%'}
				height={'100%'}
			></iframe>
		</div>
	);
};

export { IGuideTab };
