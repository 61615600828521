import { Input } from 'reactstrap';
import { GrCheckmark, GrCamera } from 'react-icons/gr';
import { BiRefresh } from 'react-icons/bi';

const IS_LOADING = 'loading';
const IMAGE_TYPE = 'image';
const NO_ACTION = 'no action needed';

const ImageOptions = ({
	item,
	responseData,
	capturePhoto,
	setSelectedPhotoUrl,
	setSelectedItem,
	setIsModalShowing,
}) => {
	if (
		responseData[item[1]?.responseTag] === undefined ||
		responseData[item[1].responseTag].toLowerCase() === NO_ACTION
	)
		return;

	const imageOptions = item
		.filter((input) => input.type === IMAGE_TYPE)
		.map((imageInput, index) => {
			const { label, responseTag } = imageInput;
			const hasFile = responseData[item[0].responseTag + responseTag];

			if (hasFile === IS_LOADING) {
				return (
					<label className='me-4' key={label}>
						<BiRefresh
							className='me-2'
							style={{
								animation: `spin 3s linear infinite`,
							}}
						/>
						{label}
					</label>
				);
			}

			if (hasFile) {
				return (
					<label
						className='me-4'
						key={label}
						style={{
							cursor: 'pointer',
						}}
						onClick={() => {
							setSelectedPhotoUrl(
								responseData[item[0].responseTag + responseTag]
							);
							setIsModalShowing(true);
							setSelectedItem({
								responseTag: item[0].responseTag + responseTag,
							});
						}}
					>
						<GrCheckmark className='me-2' />
						{label}
					</label>
				);
			}

			return (
				<label
					className='me-4'
					key={label}
					htmlFor={`icon-button-file-${index}-${item[1].responseTag}`}
					style={{
						cursor: 'pointer',
					}}
				>
					<GrCamera className='me-2' />
					{label}
					<Input
						hidden={true}
						id={`icon-button-file-${index}-${item[1].responseTag}`}
						type='file'
						onChange={(e) => capturePhoto(e, item, responseTag)}
					/>
				</label>
			);
		});

	return <div className='d-flex align-items-center'>{imageOptions}</div>;
};

export default ImageOptions;
