import { useState } from 'react';
import { Button, Modal, ModalBody } from 'reactstrap';
import { BsFillCheckCircleFill } from 'react-icons/bs';
import { AiOutlineClose } from 'react-icons/ai';

function PricingModal(args) {
	const [modal, setModal] = useState(false);

	const toggle = () => setModal(!modal);

	return (
		<div>
			<div className='toggle__modal' onClick={toggle}>
				View our plans
			</div>
			<Modal
				contentClassName='modal'
				centered='true'
				isOpen={modal}
				toggle={toggle}
				{...args}
			>
				<div className='modal__content'>
					<ModalBody className='modal__body'>
						<button className='modal__close' onClick={toggle}>
							<AiOutlineClose />
						</button>
						<div className='modal__header--text text-center'>
							Our flexible plans.
						</div>{' '}
						<div className='plans__wrapper'>
							<div className='plan'>
								<h3 className='plan__title text-center'>
									Starter
								</h3>
								<p className='plan__para text-center'>
									Best for one team member looking to automate
									documents at their company.
								</p>
								<div className='price__div'>
									<div className='price__symbol'>$</div>
									<h3 className='price__num num-1'>99</h3>
									<div className='price__month'>/ month</div>
								</div>
								<ul className='check__wrapper'>
									<li className='check__item'>
										<div className='check__icon'>
											<BsFillCheckCircleFill />
										</div>
										<div className='check__text'>
											5 document automation templates
										</div>
									</li>
									<li className='check__item'>
										<div className='check__icon'>
											<BsFillCheckCircleFill />
										</div>
										<div className='check__text'>
											5 document automation templates
										</div>
									</li>
									<li className='check__item'>
										<div className='check__icon'>
											<BsFillCheckCircleFill />
										</div>
										<div className='check__text'>
											5 document automation templates
										</div>
									</li>
								</ul>
								<Button className='plan__btn btn-1'>
									Buy Now
								</Button>
							</div>
							<div className='plan plan-2'>
								<h3 className='plan__title title-2 text-center'>
									Starter
								</h3>
								<p className='plan__para text-center'>
									Best for one team member looking to automate
									documents at their company.
								</p>
								<div className='price__div'>
									<div className='price__symbol'>$</div>
									<h3 className='price__num num-2'>99</h3>
									<div className='price__month'>/ month</div>
								</div>
								<ul className='check__wrapper'>
									<li className='check__item'>
										<div className='check__icon'>
											<BsFillCheckCircleFill />
										</div>
										<div className='check__text'>
											5 document automation templates
										</div>
									</li>
									<li className='check__item'>
										<div className='check__icon'>
											<BsFillCheckCircleFill />
										</div>
										<div className='check__text'>
											5 document automation templates
										</div>
									</li>
									<li className='check__item'>
										<div className='check__icon'>
											<BsFillCheckCircleFill />
										</div>
										<div className='check__text'>
											5 document automation templates
										</div>
									</li>
								</ul>
								<Button className='plan__btn btn-2'>
									Buy Now
								</Button>
							</div>
							<div className='plan plan-3'>
								<h3 className='plan__title title-3 text-center'>
									Starter
								</h3>
								<p className='plan__para text-center'>
									Best for one team member looking to automate
									documents at their company.
								</p>
								<div className='price__div'>
									<div className='price__symbol'>$</div>
									<h3 className='price__num num-3'>99</h3>
									<div className='price__month'>/ month</div>
								</div>
								<ul className='check__wrapper'>
									<li className='check__item'>
										<div className='check__icon'>
											<BsFillCheckCircleFill />
										</div>
										<div className='check__text'>
											5 document automation templates
										</div>
									</li>
									<li className='check__item'>
										<div className='check__icon'>
											<BsFillCheckCircleFill />
										</div>
										<div className='check__text'>
											5 document automation templates
										</div>
									</li>
									<li className='check__item'>
										<div className='check__icon'>
											<BsFillCheckCircleFill />
										</div>
										<div className='check__text'>
											5 document automation templates
										</div>
									</li>
								</ul>
								<Button className='plan__btn btn-3'>
									Buy Now
								</Button>
							</div>
						</div>
					</ModalBody>
				</div>
			</Modal>
		</div>
	);
}

export default PricingModal;
