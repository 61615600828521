import { useState } from 'react';
import DatePicker from 'react-datepicker';
import { Label } from 'reactstrap';
import { formatDate } from '../../../../../../../utils/formatDateForFilter';

const DateRangeFilter = ({ setOption, setOptionChange }) => {
	const [dateRange, setDateRange] = useState([null, null]);
	const [startDate, endDate] = dateRange;
	return (
		<div className='date-range-input'>
			<Label>Select a Date Range</Label>
			<DatePicker
				selectsRange={true}
				startDate={startDate}
				endDate={endDate}
				onChange={(update) => {
					setDateRange(update);
					setOption({
						label: 'submittedDate',
						value: [formatDate(update[0]), formatDate(update[1])],
					});
					setOptionChange(true);
				}}
				isClearable={true}
			/>
		</div>
	);
};
export { DateRangeFilter };
