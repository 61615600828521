export const DB_PATH = {
	ORGANIZATIONS: 'organizations',
	FORMS: 'forms',
	LOCATIONS: 'locations',
	ABSORBED_TICKETS: 'absorbedTickets',
	RESPONSES: 'responses',
	QA: 'qa-submissions',
	USERS: 'users',
	NOTIFICATIONS: 'notifications',
	TEMP_USERS: 'temp-users',
	ASSET_TYPES: 'asset-types',
	ASSETS: 'assets',
	ASSET_QUALITY: 'asset-quality',
	VERSION_HISTORY: 'version-history',
	MANUFACTURERS: 'manufacturers',
	RELEASES: 'releases',
	DRAFTS: 'drafts',
};

export const DB_FILTER = {
	NAME: 'name',
	TYPE: 'assetType',
	DELETED: 'deleted',
	EVEN_OR_ODD: 'evenOrOdd',
	WEEK: 'week',
	OTHER_ASSET_TAG: 'other.assetTag',
	LOCATION_ID: 'location.id',
	OTHER_ASSET_CONDITION: 'other.assetCondition',
	ASSET_SUBMITTED_DATE: 'other.submittedDate',
	OTHER_LOCATION_ID: 'other.location.id',
	SAVED_LOCATION_E_ID: 'savedLocation.e.id',
	FORM_NAME: 'formName',
	ORGANIZATION: 'organization',
	TRIRIGA_BUILDING_SYSTEM_ID: 'tririgaBuildingSystemId',
	LOCATION_TRIRIGA_BUILDING_SYSTEM_ID: 'location.tririgaBuildingSystemId',
	MANUFACTURER: 'manufacturer',
	MODEL_NUMBER: 'modelNumber',
	SERIAL_NUMBER: 'serialNumber',
	SUBMISSION_COUNT: 'submissionCount',
	TICKET_ID: 'Ticket ID',
	APPROVED: 'reviewed',
	SR_ANALYST: 'greSrAnalystId',
	SR_AREA_MNGR: 'greSrAreaManagerId',
	LOC_SPECS: 'locationSpecFields',
	HEALTH_SCORE: 'healthScore',
};

export const DB_ORDER = {
	ADDRESS: 'address1',
	ASSET_TYPES: 'assetType',
	DATE: 'date',
	ASC: 'asc',
	DESC: 'desc',
};

export const DB_FORM = {
	HANDYMAN: 'Handyman Checklist',
	INVENTORY: 'Inventory & Tagging Form',
};

export const DB_ORG = {
	CUSHMANWAKEFIELD: 'cushmanwakefield.com',
	URBANPLATES: 'urbanplates.com',
	DATAFLEET: 'datafleetservices.com',
	CAMPINGWORLD: 'campingworld.com',
};
