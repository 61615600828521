import { canViewVendors } from '@assets/services/user-role-service';
import { DB_FILTER, DB_FORM, DB_ORG } from '@constants/db';
import { getFormAssetTypes } from '@services/asset-service';
import { getForms } from '@services/form-service';
import { getSubmissionWithFilter } from '@services/submission-service';
import { getUser } from '@services/user-service';

const exportCsv = async (
	organization,
	field,
	option,
	sortOption,
	direction,
	setLoading,
	browserLocationId,
	locations,
	toggle
) => {
	// Tracks submitting user IDs and names, to help save time with queries.
	const submittingUserArr = [];

	// Retrieves form snapshot docs
	const getFormDocs = async (organization) => {
		let formsSnapshot;
		if (organization === DB_ORG.DATAFLEET) {
			// Our org, so get all forms
			// Update later on when we decide how to handle multiple-form CSV exports
			return null;
		} else if (organization === DB_ORG.CUSHMANWAKEFIELD) {
			formsSnapshot = await getForms(organization, DB_FORM.HANDYMAN);
			return formsSnapshot.docs;
		} else {
			formsSnapshot = await getForms(organization);
			return formsSnapshot.docs;
		}
	};

	// Retrieves form(s) and its inputs from the database
	const getFormsData = async (formDocs) => {
		let formsData = [];
		if (formDocs.length > 1) {
			// Update later on when we decide how to handle multiple-form CSV exports
		} else {
			const formSnap = formDocs[0];
			// Declare if form data is multi-paged or not
			const isMultiPaged = formSnap.data().multiplePages;
			// If form data is paginated, iterate & modify the data in a one way before returning it
			if (isMultiPaged == true) {
				// Declare pages array from formSnap data
				const pages = formSnap.data().inputs.pages;
				// Iterate through the pages
				for (let x = 0; x < pages.length; x++) {
					let page = pages[x];
					let pageKeys = Object.keys(page);
					// Iterate through page keys
					for (let pageKey of pageKeys) {
						// Iterate through any value (ie, the inputs array) that doesn't have the key 'page'
						if (pageKey == 'page') {
							delete pages[x][pageKey];
						} else {
							// Remove location and date input values on first page
							if (x == 0) {
								pages[x][pageKey].splice(0, 2);
							}
							const pageInputs = page[pageKey];
							for (let y = 0; y < pageInputs.length; y++) {
								// Parse the JSON input with an object or array
								let input = JSON.parse(pageInputs[y]);
								// Replace the old JSON input with the parsed & modified input
								pages[x][pageKey][y] = input;
							}
						}
					}
				}
				// Further update & filter the data
				for (let page of pages) {
					let key = Object.keys(page)[0];
					let pageInputs = page[key];
					for (let input of pageInputs) {
						if (input.length != undefined) {
							input[1].responseTag =
								input[1].responseTag + input[0].responseTag;
							input[2].responseTag =
								input[0].responseTag + input[2].responseTag;
							for (let subinput of input) {
								formsData = [...formsData, subinput];
							}
						} else if (input.type != 'label') {
							formsData = [...formsData, input];
						}
					}
				}
			} // Otherwise, if form data is linear, iterate & modify the data in a different way before returning it
			else {
				let linearInputs = formSnap.data().inputs.pages[0].Details;
				for (let x = 0; x < linearInputs.length; x++) {
					let input = JSON.parse(linearInputs[x]);
					if (input.length == undefined) {
						if (input.type != 'label') formsData.push(input);
					} else {
						for (let i = 0; i < input.length; i++) {
							if (i == 0) {
								formsData.push(input[0]);
							} else {
								input[
									i
								].label = `${input[0].label} ${input[i].label}`;
								formsData.push(input[i]);
							}
						}
					}
				}
				const condInputs = await getConditionalInputs(
					formSnap.data().organization,
					formSnap.id
				);
				let condIndex;
				for (let i = 0; i < formsData.length; i++) {
					const input = formsData[i];
					if (input.length == undefined) {
						if (input.type == 'image') {
							condIndex = i;
							break;
						}
					}
				}
				formsData = [
					...formsData.slice(0, condIndex),
					...condInputs,
					...formsData.slice(condIndex),
				];
			}
			return formsData;
		}
	};

	// Retrieves conditional inputs from form's asset types
	const getConditionalInputs = async (organization, formId) => {
		const condInputs = [];
		// Get all assetType objects
		const assetTypesSnap = await getFormAssetTypes(organization, formId);
		const assetTypes = assetTypesSnap.docs;
		// Iterate over each assetType
		for (const assetType of assetTypes) {
			// Iterate over each input of the assetType
			// Skip to next assetType if no inputs
			if (assetType.data().inputs == undefined) continue;
			for (const inputJson of assetType.data().inputs) {
				const input = JSON.parse(inputJson);
				// Add the input to condInputs if not already in it
				if (input.length != undefined) {
					for (let i = 0; i < input.length; i++) {
						let addInput = true;
						for (const condInput of condInputs) {
							if (input[i].responseTag == condInput.responseTag) {
								addInput = false;
								break;
							}
						}
						if (addInput) {
							if (i == 0) {
								condInputs.push(input[0]);
							} else {
								input[
									i
								].label = `${input[0].label} ${input[i].label}`;
								condInputs.push(input[i]);
							}
						}
					}
				} else {
					let addInput = true;
					for (const condInput of condInputs) {
						if (input.responseTag == condInput.responseTag) {
							addInput = false;
							break;
						}
					}
					if (addInput) condInputs.push(input);
				}
			}
		}
		return condInputs;
	};

	// Returns all form(s) submissions from database
	const getSubmissionsData = async (formDocs) => {
		let locsExist = true;
		if (!locations) locsExist = false;
		else if (locations.length == 0) locsExist = false;

		if (formDocs.length > 1) {
			// Update later on when we decide how to handle multiple-form CSV exports
		}
		// If formDocs only contain 1 form, then return one array of submissions
		else if (!locsExist) {
			const formId = formDocs[0].id;
			const queryParams = [];
			if (field && option) {
				queryParams.push({ name: field, value: option.value });
			}
			if (browserLocationId) {
				queryParams.push({
					name: DB_FILTER.LOCATION_ID,
					value: browserLocationId,
				});
			}
			const submissionsSnap = await getSubmissionWithFilter(
				organization,
				formId,
				queryParams
			);

			const submissions = [];
			for (const item of submissionsSnap.docs) {
				const submission = item.data();
				// (AMC specific code)
				// Check if submission contains 'images' key and assign new keys
				if (submission.images != undefined) {
					if (submission.images.length == 2) {
						submission['equipmentAssetImage'] =
							submission.images[0];
						submission['manufacturersPlateAssetImage'] =
							submission.images[1];
					} else {
						submission['equipmentAssetImage'] =
							submission.images[0];
					}
				}
				// Update submitted date field
				submission.submittedDate = formatDate(
					submission.submittedDate || submission.dateOfVisit
				);
				// Update submitting user field
				const userId = submission.submittingUserId;
				let user = null;
				for (const submittingUser of submittingUserArr) {
					if (submittingUser.id == userId) {
						user = submittingUser.name;
						submission.submittingUser = user;
						break;
					}
				}
				if (user == null) {
					user = await getUserByID(userId);
					submission.submittingUser = user;
					submittingUserArr.push({ id: userId, name: user });
				}
				// add firebase doc id
				submission.id = item.id;
				submissions.push(submission);
			}

			const sortedSubmissions = submissions.sort((a, b) => {
				if (sortOption == 'submittedDate') {
					if (direction == 'asc') {
						return (
							new Date(a.submittedDate) -
							new Date(b.submittedDate)
						);
					} else {
						return (
							new Date(b.submittedDate) -
							new Date(a.submittedDate)
						);
					}
				} else {
					let valA, valB;
					if (sortOption.includes('.')) {
						const index = sortOption.indexOf('.');
						const key1 = sortOption.slice(0, index);
						const key2 = sortOption.slice(index + 1);
						valA = a[key1][key2];
						valB = a[key1][key2];
					} else {
						if (sortOption == 'submittingUserId')
							sortOption = 'submittingUser';
						valA = a[sortOption];
						valB = b[sortOption];
					}
					if (direction == 'asc') {
						if (valA < valB) return -1;
						else if (valA > valB) return 1;
						else return 0;
					} else {
						if (valA > valB) return -1;
						else if (valA < valB) return 1;
						else return 0;
					}
				}
			});

			return sortedSubmissions;
		} else if (!browserLocationId && locsExist) {
			const formId = formDocs[0].id;
			const submissions = [];
			for (const location of locations) {
				const queryParams = [];
				const locationId = location.id;
				if (field && option) {
					queryParams.push({ name: field, value: option.value });
				}
				queryParams.push({
					name: DB_FILTER.LOCATION_ID,
					value: locationId,
				});
				const submissionsSnap = await getSubmissionWithFilter(
					organization,
					formId,
					queryParams
				);

				for (const item of submissionsSnap.docs) {
					const submission = item.data();
					// (AMC specific code)
					// Check if submission contains 'images' key and assign new keys
					if (submission.images != undefined) {
						if (submission.images.length == 2) {
							submission['equipmentAssetImage'] =
								submission.images[0];
							submission['manufacturersPlateAssetImage'] =
								submission.images[1];
						} else {
							submission['equipmentAssetImage'] =
								submission.images[0];
						}
					}
					// Update submitted date field
					submission.submittedDate = formatDate(
						submission.submittedDate || submission.dateOfVisit
					);
					// Update submitting user field
					const userId = submission.submittingUserId;
					let user = null;
					for (const submittingUser of submittingUserArr) {
						if (submittingUser.id == userId) {
							user = submittingUser.name;
							submission.submittingUser = user;
							break;
						}
					}
					if (user == null) {
						user = await getUserByID(userId);
						submission.submittingUser = user;
						submittingUserArr.push({ id: userId, name: user });
					}
					// add firebase doc id
					submission.id = item.id;
					submissions.push(submission);
				}
			}

			const sortedSubmissions = submissions.sort((a, b) => {
				if (sortOption == 'submittedDate') {
					if (direction == 'asc') {
						return (
							new Date(a.submittedDate) -
							new Date(b.submittedDate)
						);
					} else {
						return (
							new Date(b.submittedDate) -
							new Date(a.submittedDate)
						);
					}
				} else {
					let valA, valB;
					if (sortOption.includes('.')) {
						const index = sortOption.indexOf('.');
						const key1 = sortOption.slice(0, index);
						const key2 = sortOption.slice(index + 1);
						valA = a[key1][key2];
						valB = a[key1][key2];
					} else {
						if (sortOption == 'submittingUserId')
							sortOption = 'submittingUser';
						valA = a[sortOption];
						valB = b[sortOption];
					}
					if (direction == 'asc') {
						if (valA < valB) return -1;
						else if (valA > valB) return 1;
						else return 0;
					} else {
						if (valA > valB) return -1;
						else if (valA < valB) return 1;
						else return 0;
					}
				}
			});
			return sortedSubmissions;
		}
	};

	const getResponseTagHeaders = async (formsData, formDocs) => {
		if (formDocs.length > 1) {
			// Update later on when we decide how to handle multiple-form CSV exports
		}
		// If formDocs only contain 1 form, then return one array for the CSV header
		else {
			let headers = [];
			// Iterate through formsData and add input labels to header variable
			for (let input of formsData) headers.push(input.responseTag);
			// Add date of visit
			if (
				organization != DB_ORG.CUSHMANWAKEFIELD ||
				(await canViewVendors())
			) {
				headers = [
					'Submission ID',
					'submittedDate',
					'submittingUser',
					...headers,
				];
			} else {
				headers = ['Submission ID', 'submittedDate', ...headers];
			}
			return headers;
		}
	};

	// Returns CSV header array
	const getHeader = async (formsData, formDocs) => {
		if (formDocs.length > 1) {
			// Update later on when we decide how to handle multiple-form CSV exports
		}
		// If formDocs only contain 1 form, then return one array for the CSV header
		else {
			let headers = [];
			// Iterate through formsData and add input labels to header variable
			for (let input of formsData) headers.push(input.label);
			// Add date of visit
			if (
				organization != DB_ORG.CUSHMANWAKEFIELD ||
				(await canViewVendors())
			) {
				headers = [
					'Submission ID',
					'Date of Visit',
					'Submitting User',
					...headers,
				];
			} else {
				headers = ['Submission ID', 'Date of Visit', ...headers];
			}
			return headers;
		}
	};

	// Returns CSV rows array
	const getRows = async (submissionsData, formsData, formDocs) => {
		if (formDocs.length > 1) {
			// Update later on when we decide how to handle multiple-form CSV exports
		}
		// If formDocs only contain 1 form, then following code will return an array of rows for the CSV rows
		else {
			const rows = [];
			for (let submission of submissionsData) {
				let row = [];
				for (let input of formsData) {
					const responseTag = input.responseTag;
					let response;
					if (submission[responseTag] != undefined) {
						if (responseTag == 'location' && submission.location) {
							// let location = '';
							// if (submission.location.address) {
							// 	location = submission.location.address;
							// }
							// if (submission.location.address1) {
							// 	location =
							// 		location +
							// 		' ' +
							// 		submission.location.address1;
							// }
							// if (submission.location.address2) {
							// 	location =
							// 		location +
							// 		' ' +
							// 		submission.location.address2;
							// }
							// location =
							// 	location +
							// 	', ' +
							// 	submission.location.city +
							// 	', ' +
							// 	submission.location.state +
							// 	' ' +
							// 	submission.location.zip;
							submission[responseTag] = submission.location.name;
						}
						response = submission[responseTag];
					} else {
						response = 'n/a';
					}
					row = [...row, response];
				}

				// Add date of visit and submitting user
				const date = submission.submittedDate;
				const user = submission.submittingUser;
				if (
					organization != DB_ORG.CUSHMANWAKEFIELD ||
					(await canViewVendors())
				) {
					row = [submission.id, date, user, ...row];
				} else {
					row = [submission.id, date, ...row];
				}

				// Add row to rows arr
				rows.push(row);
			}
			return rows;
		}
	};

	// Formats submission date to "YYYY-MM-DD HH:MM:SS" format
	const formatDate = (oldDate) => {
		let date = new Date(oldDate);

		let month = String(date.getMonth() + 1).padStart(2, '0');
		let day = String(date.getDate()).padStart(2, '0');
		let hours = String(date.getHours()).padStart(2, '0');
		let minutes = String(date.getMinutes()).padStart(2, '0');
		let seconds = String(date.getSeconds()).padStart(2, '0');
		let year = String(date.getFullYear());

		return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
	};

	// Grabs user's name from their id
	const getUserByID = async (userId) => {
		if (userId) {
			try {
				const docSnap = await getUser(userId);
				if (docSnap) {
					return docSnap.name;
				} else return docSnap.uid;
			} catch (e) {
				//
			}
			return 'n/a';
		}
	};

	// Converts a 2D array into a CSV string
	const getCsvString = (data) => {
		return data
			.map(
				(row) =>
					row
						.map(String) // convert every value to String
						.map((v) => v.replaceAll('"', '""')) // escape double colons
						.map((v) => `"${v}"`) // quote it
						.join(',') // comma-separated
			)
			.join('\r\n'); // rows starting on new lines
	};

	// Downloads CSV string as a CSV file
	const downloadFile = (csvString, filename, contentType) => {
		// Create a blob
		var blob = new Blob([csvString], { type: contentType });
		var url = URL.createObjectURL(blob);

		// Create a link to download it
		var pom = document.createElement('a');
		pom.href = url;
		pom.setAttribute('download', filename);
		pom.click();
		toggle();
	};

	// Converts any date to ISO string
	const convertDateToISOString = (date) => {
		if (date) {
			const tempDate = new Date(date).toISOString().split('T');
			return tempDate[0];
		}
	};

	const formDocs = await getFormDocs(organization);
	if (formDocs != null) {
		await setLoading(true);
		const formsData = await getFormsData(formDocs);
		const submissionsData = await getSubmissionsData(formDocs);
		const responseTagHeaders = await getResponseTagHeaders(
			formsData,
			formDocs
		);
		const header = await getHeader(formsData, formDocs);
		const rows = await getRows(submissionsData, formsData, formDocs);
		const csvString = getCsvString([responseTagHeaders, header, ...rows]);
		const filename = convertDateToISOString(new Date());
		const contentType = 'text/csv;charset=utf-8;';
		await setLoading(false);
		return downloadFile(csvString, filename, contentType);
	}
};

export { exportCsv };
