const PartnerWrapper = ({ children, bigger, border, shadow }) => {
	return (
		<div
			className={`partner-outer ${bigger && 'scale-120'} ${
				shadow === 'green' && 'shadow-green'
			}`}
		>
			<div
				className={`partner-inner ${
					border === 'border-green' && 'border-green'
				}`}
			>
				{children}
			</div>
		</div>
	);
};

export { PartnerWrapper };
