const capitalize = (string) => {
	return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
};

const camelize = (oldString) => {
	let updOldString = oldString;
	if (oldString == 'Replacement Cost') {
		updOldString = 'Avg Repl Cost USD';
	} else if (oldString == 'Useful Life (Years)') {
		updOldString = 'Avg Useful Life Years';
	}
	const string = updOldString
		.replace(/[\n\r]/g, ' ')
		.replace('.', '')
		.replace('/', '');
	const arr = string.split(' ');
	let newString = '';
	for (let i = 0; i < arr.length; i++) {
		if (arr[i].toLowerCase() == 'site') {
			arr.splice(i, 1);
			i--;
			continue;
		} else if (arr[i].toLowerCase() == 'usd') {
			newString += arr[i].toUpperCase();
		} else if (i == 0) {
			newString += arr[i].toLowerCase();
		} else {
			newString += capitalize(arr[i]);
		}
	}
	return newString;
};

export { camelize };
