import {
	Card,
	CardHeader,
	Input,
	Label,
	FormGroup,
	Row,
	Col,
} from 'reactstrap';
import { DownloadCsv } from './DownloadCsv';
import { DownloadPdf } from './DownloadPdf';
import { useEffect, useState, useRef } from 'react';
import ExifReader from 'exifreader';
import { getUserRole } from '@assets/services/user-role-service';
import { SubmissionEdit } from './SubmissionEdit';
import { AiOutlineEdit } from 'react-icons/ai';
import { SubmittedImageModal } from '@SignedIn/views/SingleForm/SubmittedImageModal';
import { isValidUrl } from '@utils/validation';
import { EditLog } from './EditLog';
import { getForm } from '@services/form-service';
import { getFormAssetTypes } from '@services/asset-service';
import { DB_ORG } from '@constants/db';
import { getImageDetail, getResponseImages } from './submissionUtils';
import { ViewportList } from 'react-viewport-list';

const Submission = (props) => {
	const { chosenResponse, toggleSubmission, updateResData } = props;

	const [showImageModal, setShowImageModal] = useState(false);
	const [selectedImage, setSelectedImage] = useState('');
	// Parsed response data state
	const [parsedResponse, setParsedResponse] = useState([]);

	// Helper state that tracks changes for parsedResponse
	const [parsedResponseChanges, setParsedResponseChanges] = useState(false);

	// Form inputs data state
	const [inputs, setInputs] = useState();

	// Bool state for determing if form inputs is nested or linear
	const [isNested, setisNested] = useState();

	// Rendered data (ie, child component) state
	const [submission, setSubmission] = useState();

	// Parent component state for submission child component state
	const [submissionParent, setSubmissionParent] = useState();

	// Determines if editing is turned on
	const [showEdit, setShowEdit] = useState(false);

	const toggleEdit = () => setShowEdit(!showEdit);

	// Converts date field between formats
	const convertDateToISOString = (date) => {
		if (date) {
			const tempDate = new Date(date).toISOString().split('T');
			return tempDate[0];
		}
	};

	// Takes nested data from form & further nests the inputs under the labels, leaving out blank sections
	const nestAndFilterData = async (data) => {
		let newData = [];
		for (let page of data) {
			let key = Object.keys(page)[0];
			// remove later
			if (key == 'Notes') continue;
			let newPage = {
				page: key,
				sections: [],
			};
			let pageInputs = page[key];
			// Parent object for form section
			let section;
			// Child array for section inputs
			let sectionInputs = [];
			for (let input of pageInputs) {
				// Assign first section
				if (section == null) {
					section = input;
				}
				// Add input values to section inputs IF same inputs exist in parsedResponse
				// Also check if data is old (before commit 69e0450) and parse differently
				else if (
					input.length != undefined &&
					parsedResponse.inputs == undefined
				) {
					let responseTag = input[0].responseTag;
					let response = parsedResponse[responseTag];
					if (response != undefined) {
						input[1].responseTag =
							input[1].responseTag + input[0].responseTag;
						input[2].responseTag =
							input[0].responseTag + input[2].responseTag;
						// Also, check if any of the subinputs are images, then check if they need to be rotated
						for (let i = 0; i < input.length; i++) {
							let subResponse =
								parsedResponse[input[i].responseTag];
							if (
								input[i].type == 'image' &&
								subResponse != undefined
							) {
								const res = await fetch(subResponse);
								const blob = await res.blob();
								const arrBuff = await blob.arrayBuffer();
								const tags = ExifReader.load(arrBuff);
								input[i].exif = tags;
							}
						}
						sectionInputs = [...sectionInputs, input];
					}
				} else if (
					input.length != undefined &&
					parsedResponse.inputs != undefined
				) {
					let responseTag = input[0].responseTag;
					let response;
					if (parsedResponse.inputs)
						response = parsedResponse.inputs[responseTag];
					else response = parsedResponse[responseTag];

					if (response != undefined) {
						// Also, check if any of the subinputs are images, then check if they need to be rotated
						for (let i = 0; i < response.length; i++) {
							if (response[i].image === undefined) continue;

							const responseImages = getResponseImages(
								response[i],
								input[2]
							);

							for (const { url, responseTag } of responseImages) {
								if (!url || !responseTag) continue;

								const imageInput = input.find((_input) =>
									_input.responseTag.includes(responseTag)
								);

								const res = await fetch(url);
								const blob = await res.blob();
								const arrBuff = await blob.arrayBuffer();
								const tags = ExifReader.load(arrBuff);
								if (imageInput.exif == undefined) {
									imageInput.exif = { [i]: tags };
								} else {
									imageInput.exif[i] = tags;
								}
							}
						}
						sectionInputs = [...sectionInputs, input];
					}
				}
				// Add sectionInputs to section as property + add section to page.sections if sectionInputs is not blank
				// Then, reset section & sectionInputs for next section
				else {
					if (sectionInputs.length > 0) {
						section.inputs = sectionInputs;
						newPage.sections = [...newPage.sections, section];
					}
					section = input;
					sectionInputs = [];
				}
			}
			// Check final section & add to page.sections if sectionInputs is not blank
			if (sectionInputs.length > 0) {
				section.inputs = sectionInputs;
				newPage.sections = [...newPage.sections, section];
			}
			// Add update page to newData array
			newData = [...newData, newPage];
		}
		return newData;
	};

	// Takes linear data and filters out blank sections, plus adds conditional inputs
	const filterLinearData = async (data, organization, formId) => {
		// First, add in missing conditional inputs to end of array, before image inputs
		const condInputs = await getConditionalInputs(organization, formId);
		let condIndex;
		for (let i = 0; i < data.length; i++) {
			const input = data[i];
			if (input.length == undefined) {
				if (input.type == 'image') {
					condIndex = i;
					break;
				}
			}
		}
		const updatedData = [
			...data.slice(0, condIndex),
			...condInputs,
			...data.slice(condIndex),
		];
		let newData = [];
		for (let input of updatedData) {
			// If input is not an array, check if it's in the response then add it to the new data (or not)
			if (input.length == undefined) {
				let responseTag = input.responseTag;
				let response = parsedResponse[responseTag];
				if (response != undefined) {
					if (input.type == 'image') {
						const res = await fetch(response);
						const blob = await res.blob();
						const arrBuff = await blob.arrayBuffer();
						const tags = ExifReader.load(arrBuff);
						input.exif = tags;
					}
					newData = [...newData, input];
				}
			}
			// Otherwise, check whether the first array item's field has a response & decide on which array item to add to the new data
			else {
				if (parsedResponse[input[0].responseTag] != undefined) {
					newData = [...newData, input[0]];
				} else {
					const newLabel = input[0].label + ' ' + input[1].label;
					input[1].label = newLabel;
					parsedResponse[input[1].responseTag] = 'true';
					newData = [...newData, input[1]];
				}
			}
		}
		return newData;
	};

	// Retrieves conditional inputs from form's asset types
	const getConditionalInputs = async (organization, formId) => {
		let condInputs = [];
		const assetTypesQuery = await getFormAssetTypes(
			organization,
			formId,
			parsedResponse.assetType
		);

		if (!assetTypesQuery.empty) {
			const JSONtypes = assetTypesQuery.docs[0].data().inputs || [];
			for (const JSONtype of JSONtypes) {
				const type = JSON.parse(JSONtype);
				type.conditional = true;
				condInputs.push(type);
			}
		}
		return condInputs;
	};

	// // Sorts numbers from least to greatest
	// const sortNumbers = (array, key) => {
	// 	return array.sort((a, b) => Number(a[key]) - Number(b[key]));
	// };

	const getData = async () => {
		let newInputs;
		// DB calls to get form data
		const formId = parsedResponse.formId;
		const organization = parsedResponse.organization || props.organization;
		if (organization && formId) {
			const formSnap = await getForm(organization, formId);
			// Declare if form data is nested or linear
			const isFormNested = formSnap.data().multiplePages;
			setisNested(isFormNested && formSnap.exists());
			// If form data is nested, iterate through & modify the data a specific way before assigning to the inputs state
			if (isFormNested == true) {
				// Declare pages array from formSnap data
				const pages = formSnap.data().inputs.pages;
				// Iterate through the pages
				for (let x = 0; x < pages.length; x++) {
					let page = pages[x];
					let pageKeys = Object.keys(page);
					// Iterate through page keys
					for (let pageKey of pageKeys) {
						// Iterate through any value (ie, the inputs array) that doesn't have the key 'page'
						if (pageKey == 'page') {
							delete pages[x][pageKey];
						} else {
							// Remove location and date input values on first page
							if (x == 0) {
								pages[x][pageKey].splice(0, 2);
							}
							const pageInputs = page[pageKey];
							for (let y = 0; y < pageInputs.length; y++) {
								// Parse the JSON input with an object or array
								let input = JSON.parse(pageInputs[y]);
								pages[x][pageKey][y] = input;
							}
						}
					}
				}
				newInputs = await nestAndFilterData(
					pages,
					organization,
					formId
				);
				// Otherwise, if form data is linear, iterate & modify the data in a different way before assigning to the inputs state
			} else {
				let linearInputs = formSnap.data().inputs.pages[0].Details;
				for (let x = 0; x < linearInputs.length; x++) {
					let input = JSON.parse(linearInputs[x]);
					linearInputs[x] = input;
				}
				newInputs = await filterLinearData(
					linearInputs,
					organization,
					formId
				);
			}

			setInputs(newInputs);
		}
	};

	const openImgModal = (response) => {
		setSelectedImage(response);
		setShowImageModal(true);
	};

	const renderSubmission = async () => {
		let lineItems = [];
		let index = 0;
		let firstLine;
		if (chosenResponse.organization == DB_ORG.CUSHMANWAKEFIELD) {
			let checked = false;
			if (parsedResponse.reviewed == 'true') checked = true;
			firstLine = (
				<FormGroup
					key={index}
					className='p-3 d-flex align-items-center'
				>
					<Label className='fw-bolder pt-0 mb-0'>
						Report Reviewed and Approved?
					</Label>
					<Input
						disabled
						className=''
						type='checkbox'
						checked={checked}
					/>
				</FormGroup>
			);
			index++;
		}
		const secondLine = (
			<FormGroup key={index} className='p-3'>
				<Label className='fw-bolder pt-0'>Date of Visit</Label>
				<Input
					disabled
					className='bg-white'
					type='date'
					value={convertDateToISOString(
						parsedResponse.dateOfVisit ||
							parsedResponse.submittedDate
					)}
				/>
			</FormGroup>
		);
		index++;

		let address = '';
		if (parsedResponse.location.address) {
			address = parsedResponse.location.address;
		}
		if (parsedResponse.location.address1) {
			address = address + ' ' + parsedResponse.location.address1;
		}
		if (parsedResponse.location.address2) {
			address = address + ' ' + parsedResponse.location.address2;
		}
		const thirdLine = (
			<FormGroup key={index} className='p-3'>
				<Label className='fw-bolder pt-0'>Location</Label>
				<Input
					disabled
					className='bg-white'
					type='text'
					value={
						address +
						', ' +
						parsedResponse.location.city +
						', ' +
						parsedResponse.location.state +
						' ' +
						parsedResponse.location.zip
					}
				/>
			</FormGroup>
		);
		index++;

		// remove later
		let fourthLine;
		if (isNested) {
			fourthLine = (
				<FormGroup key={index} className='p-3'>
					<Label className='fw-bolder pt-0'>Summary</Label>
					<Input
						disabled
						className='bg-white'
						type='textarea'
						value={parsedResponse.summary}
					/>
				</FormGroup>
			);
		}
		index++;

		// Render multipage data
		if (isNested == true) {
			inputs.forEach((page) => {
				// Create page heaer
				let pageHeader = (
					<FormGroup
						key={index}
						className='pb-3 pt-4 px-3 submission-tab'
					>
						{page.page}
					</FormGroup>
				);
				lineItems = [...lineItems, pageHeader];
				index++;

				if (page.sections.length > 0) {
					for (let section of page.sections) {
						// Create section header
						let sectionHeader = (
							<FormGroup
								key={index}
								className='px-3 pt-3 pb-2 submission-section'
							>
								<Label className='submission-section-label'>
									{section.label}
								</Label>
							</FormGroup>
						);
						lineItems = [...lineItems, sectionHeader];
						index++;

						let inputs = [];
						// Check if data is old (before commit 69e0450) and render differently
						if (parsedResponse.inputs == undefined) {
							for (let input of section.inputs) {
								let columns = [];
								// Create input rows
								for (let subInput of input) {
									let col;
									let formKey = subInput.responseTag;
									let response = parsedResponse[formKey];
									// Cushman specific code for Asset Quality and Asset Image:
									// If submission response is blank or defined, assign new value
									if (
										response == '' ||
										response == undefined
									) {
										if (formKey.includes('assetQuality')) {
											response = 'No Action Needed';
										} else if (
											formKey.includes('AssetImage')
										) {
											response = 'No Image';
										}
									}
									let label = subInput.label;
									let isImage = isValidUrl(response);
									if (isImage) {
										col = (
											<Col xs='12' lg='6'>
												<FormGroup
													key={index}
													className='p-3 d-flex flex-column'
												>
													<Label className='submission-label fw-bold'>
														{label}
													</Label>
													<div className='position-relative submitted-image-parent'>
														<img
															className='submitted-image'
															alt=''
															src={response}
															onClick={() =>
																openImgModal(
																	response
																)
															}
														/>
													</div>
												</FormGroup>
											</Col>
										);
									} else if (subInput.type == 'image') {
										col = (
											<Col xs='12' lg='6'>
												<FormGroup
													key={index}
													className='p-3'
												>
													<Label className='submission-label fw-bold'>
														{label}
													</Label>
													<div className='position-relative'>
														<Input
															disabled
															className='bg-white'
															type='text'
															value={response}
														/>
													</div>
												</FormGroup>
											</Col>
										);
									} else {
										col = (
											<Col xs='12' lg='6'>
												<FormGroup
													key={index}
													className='p-3'
												>
													<Label className='submission-label fw-bold'>
														{label}
													</Label>
													<Input
														disabled
														className='bg-white'
														type='text'
														value={response}
													/>
												</FormGroup>
											</Col>
										);
									}
									columns = [...columns, col];
									index++;
								}
								let newInput = <Row>{columns}</Row>;
								inputs = [...inputs, newInput];
							}
						} else {
							for (let input of section.inputs) {
								// Create input rows
								// NOTE: responseTag is the key for updateNewResponseNested
								let responseTag = input[0].responseTag;
								let responseArray =
									parsedResponse.inputs[responseTag];
								// NOTE: i is the index for updateNewResponseNested
								for (let i = 0; i < responseArray.length; i++) {
									let cols = [];
									for (let subInput of input) {
										let formKey = subInput.responseTag;
										let response;
										let imageDetail = {
											index: null,
											url: null,
										};
										let col;
										// NOTE: either value, image, or quality is the subKey for updateNewResponseNested
										// Cushman specific code for Asset Quality and Asset Image:
										// If submission response is blank or defined, assign new value
										if (formKey.includes('assetQuality')) {
											response = responseArray[i].quality;
											if (
												response == '' ||
												response == undefined
											) {
												response = 'No Action Needed';
											}
										} else if (subInput.type == 'image') {
											response = responseArray[i].image;
											if (
												response == '' ||
												response == undefined
											) {
												response = 'No Image';
											} else {
												imageDetail = getImageDetail(
													response,
													input,
													subInput
												);

												if (!imageDetail) continue;

												imageDetail =
													isValidUrl(
														imageDetail?.url
													) && imageDetail;
											}
										} else {
											response = responseArray[i].value;
										}
										if (imageDetail?.url) {
											col = (
												<Col xs='12' lg='6'>
													<FormGroup
														key={index}
														className='p-3 d-flex flex-column'
													>
														<Label className='submission-label fw-bold'>
															{subInput.label}
														</Label>
														<div className='position-relative submitted-image-parent'>
															<img
																className='submitted-image'
																alt=''
																src={
																	imageDetail.url
																}
																onClick={() =>
																	openImgModal(
																		imageDetail.url
																	)
																}
															/>
														</div>
													</FormGroup>
												</Col>
											);
										} else if (subInput.type == 'image') {
											col = (
												<Col xs='12' lg='6'>
													<FormGroup
														key={index}
														className='p-3'
													>
														<Label className='submission-label fw-bold'>
															{subInput.label}
														</Label>
														<div className='position-relative'>
															<Input
																disabled
																className='bg-white'
																type='text'
																value='No Image'
															/>
														</div>
													</FormGroup>
												</Col>
											);
										} else {
											col = (
												<Col xs='12' lg='6'>
													<FormGroup
														key={index}
														className='p-3'
													>
														<Label className='submission-label fw-bold'>
															{subInput.label}
														</Label>
														<Input
															disabled
															className='bg-white'
															type='text'
															value={response}
														/>
													</FormGroup>
												</Col>
											);
										}
										index++;
										cols = [...cols, col];
									}
									const newInput = <Row>{cols}</Row>;
									inputs = [...inputs, newInput];
								}
							}
						}
						lineItems = [...lineItems, inputs];
					}
				}
				// Add generic message if page wasn't filled out
				else {
					return;
				}
			});
		}
		// Render linear data
		else {
			for (let input of inputs) {
				if (input.responseTag == 'location') {
					continue;
				}
				let formKey = input.responseTag;
				if (formKey == 'assetQuality') {
					formKey = formKey + input[0].responseTag;
				}
				let label = input.label;
				let response = parsedResponse[formKey];

				let newRow;
				if (isValidUrl(response)) {
					newRow = (
						<FormGroup
							key={index}
							className='p-3 d-flex flex-column'
						>
							<Label className='submission-label'>{label}</Label>
							<div className='position-relative submitted-image-parent'>
								<img
									className='submitted-image'
									alt=''
									src={response}
									onClick={() => openImgModal(response)}
								/>
							</div>
						</FormGroup>
					);
				} else if (input.type == 'image') {
					newRow = (
						<FormGroup
							key={index}
							className='p-3 d-flex flex-column'
						>
							<Label className='submission-label'>{label}</Label>
							<div className='position-relative'>
								<Input
									disabled
									className='bg-white'
									type='text'
									value={response}
								/>
							</div>
						</FormGroup>
					);
				} else if (input.type == 'checkbox') {
					newRow = (
						<FormGroup key={index} className='p-3'>
							<Label className='submission-label'>{label}</Label>
							<Input
								disabled
								className='bg-white'
								type='text'
								value={(() => {
									if (response === 'true') {
										return 'true';
									} else {
										return 'false';
									}
								})()}
							/>
						</FormGroup>
					);
				} else if (input.type == 'select') {
					newRow = (
						<FormGroup key={index} className='p-3'>
							<Label className='submission-label'>{label}</Label>
							<Input
								disabled
								className='bg-white'
								type='text'
								value={response}
							/>
						</FormGroup>
					);
				} else {
					newRow = (
						<FormGroup key={index} className='p-3'>
							<Label className='submission-label'>{label}</Label>
							<Input
								disabled
								className='bg-white'
								type='text'
								value={response}
							/>
						</FormGroup>
					);
				}
				lineItems = [...lineItems, newRow];
				index++;
			}
		}

		lineItems = await Promise.all(lineItems);
		const Content = () => {
			const ref = useRef(null);
			const listRef = useRef(null);

			return (
				<div className='scroll-container' ref={ref}>
					<ViewportList
						ref={listRef}
						viewportRef={ref}
						items={lineItems}
					>
						{(item) => item}
					</ViewportList>
					{/* <button
						className='up-button'
						onClick={() =>
							listRef.current.scrollToIndex({
								index: 0,
							})
						}
					/> */}
				</div>
			);
		};
		const component = (
			<div id='activities-tab-form-submission'>
				{firstLine}
				{secondLine}
				{thirdLine}
				{/* remove later */}
				{fourthLine}
				{/* {lineItems} */}
				<Content />
			</div>
		);
		setSubmission(component);
	};

	const canUserEdit = () => {
		const userRole = getUserRole();
		const date1 = new Date(chosenResponse.submittedDate);
		const date2 = new Date();
		const diffInMilliseconds = date2.getTime() - date1.getTime();
		const diffInHours = diffInMilliseconds / (1000 * 60 * 60);
		if (userRole === 'superuser') {
			return true;
		} else if (userRole === 'user' && diffInHours <= 72) {
			return true;
		} else if (userRole === 'admin') {
			const diffInWeeks = Math.floor(diffInHours / 168);
			if (diffInWeeks < 9) {
				return true;
			}
		}

		return false;
	};

	// Update parsedResponse state when chosenResponse prop changes
	useEffect(() => {
		if (chosenResponse != null) {
			let newResponse = JSON.parse(JSON.stringify(chosenResponse));
			newResponse.assetRef = chosenResponse.assetRef;
			// (AMC specific code)
			// Check if submission contains 'images' key and add new inputs
			if (newResponse.images != undefined) {
				newResponse.equipmentAssetImage = newResponse.images[0];
				if (newResponse.images[1] != undefined) {
					newResponse.manufacturersPlateAssetImage =
						newResponse.images[1];
				}
				newResponse.images = undefined;
			}
			setParsedResponse(newResponse);
			setParsedResponseChanges(true);
		}
	}, [chosenResponse]);

	// Update inputs and parsedResponse states when parsedResponse state changes
	useEffect(() => {
		if (parsedResponse != null && parsedResponseChanges) {
			getData();
			setParsedResponseChanges(false);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [parsedResponseChanges]);

	// Update submission state when inputs
	useEffect(() => {
		if (inputs != null) {
			renderSubmission();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [inputs, showImageModal]);

	// Update submissionParent state when submission state changes
	useEffect(() => {
		if (submission != null && !showEdit) {
			setSubmissionParent(
				<div className='h-100'>
					<Card className='p-3' id='submission'>
						<div className='submission-header d-flex flex-row align-items-center justify-content-between h-100'>
							{(() => {
								if (toggleSubmission != null) {
									return (
										<div
											className='activities-page-header-buttons d-flex p-2 width-fit-content'
											onClick={toggleSubmission}
										>
											<i className='bi bi-arrow-left me-3'></i>
											<div>
												Return to Form Submissions
											</div>
										</div>
									);
								} else {
									return <div />;
								}
							})()}
							{(() => {
								if (toggleSubmission != null)
									return (
										<div className='d-flex align-content-start'>
											<div className='d-flex flex-row'>
												<DownloadPdf
													form={parsedResponse}
													inputs={inputs}
													isMultiPage={isNested}
												/>
												<DownloadCsv
													form={parsedResponse}
												/>
											</div>
											<button
												className='edit-submission-btn p-2'
												color='success'
												disabled={!canUserEdit()}
												onClick={toggleEdit}
											>
												Edit
												<span>
													<AiOutlineEdit className='fs-5 mx-1' />
												</span>
											</button>
										</div>
									);
							})()}
						</div>
						{(() => {
							if (toggleSubmission != null)
								return (
									<img
										src={parsedResponse.logo}
										className='submission-logo'
										alt='logo'
									/>
								);
						})()}
						<CardHeader
							className='d-flex flex-row align-items-center justify-content-between'
							style={{
								backgroundColor: '#ffffff',
								border: 'none',
							}}
						>
							{(() => {
								if (toggleSubmission == null) {
									return (
										<div>
											<div className='submission-title'>
												Latest {parsedResponse.formName}
											</div>
											<div className='d-flex align-content-start'>
												<div className='d-flex flex-row'>
													<DownloadPdf
														form={parsedResponse}
														inputs={inputs}
														isMultiPage={isNested}
													/>
													<DownloadCsv
														form={parsedResponse}
													/>
												</div>
												<button
													className='edit-submission-btn'
													color='success'
													disabled={!canUserEdit()}
													onClick={toggleEdit}
												>
													Edit
													<span>
														<AiOutlineEdit className='fs-5 mx-1' />
													</span>
												</button>
											</div>
										</div>
									);
								} else {
									return (
										<div className='submission-title'>
											{parsedResponse.formName}
										</div>
									);
								}
							})()}
						</CardHeader>
						{submission}
						{parsedResponse.editLog && (
							<EditLog editLog={parsedResponse.editLog} />
						)}
					</Card>
					<SubmittedImageModal
						showing={showImageModal}
						photoUrl={selectedImage}
						isZoom={true}
						cancel={() => setShowImageModal(false)}
					/>
				</div>
			);
		} else if (submission != null && showEdit) {
			setSubmissionParent(
				<SubmissionEdit
					toggleEdit={toggleEdit}
					toggleSubmission={toggleSubmission}
					parsedResponse={parsedResponse}
					setParsedResponse={setParsedResponse}
					setParsedResponseChanges={setParsedResponseChanges}
					updateResData={updateResData}
					rerender={renderSubmission}
				/>
			);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [submission, parsedResponse, parsedResponseChanges, showEdit]);

	return submissionParent;
};

export { Submission };
