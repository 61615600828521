import { useEffect, useRef, useState } from 'react';
import { Button, Input, List, Modal, ModalBody, ModalHeader } from 'reactstrap';
import '@assets/scss/cropper.css';
import { FiChevronRight } from 'react-icons/fi';
import { searchLocations } from '@SignedIn/views/Locations/children/Search';
import { DB_ORG } from '@constants/db';
const LocationPicker = (props) => {
	// reference list
	const allLocations = props.locations;
	// reference list (if geotracking is on)
	const [sortedLocations, setSortedLocations] = useState([]);
	const setChosenLocation = props.setChosenLocation;
	const [isOpen, setIsOpen] = useState(false);
	const [locationText, setLocationText] = useState(props.chosenLocation);
	const [locations, setLocations] = useState(props.locations);
	const [searchVal, setSearchVal] = useState('');
	const [geoLocation, setGeoLocation] = useState({});

	const toggle = () => {
		setIsOpen(!isOpen);
	};

	const getLocations = () => {
		let locs = [];
		const sorted = sortedLocations.length > 0;
		for (let i = 0; i < locations.length; i++) {
			let sortIndex;
			if (sorted) sortIndex = sortedLocations.indexOf(locations[i]);
			locs.push(
				<li
					className='picker-item d-flex flex-column align-items-start'
					onClick={() => selectItem(locations[i])}
				>
					{sorted ? (
						<div style={{ fontSize: '12px' }}>
							{sortIndex == 0
								? `Closest Location`
								: sortIndex == 1
								? `2nd Closest Location`
								: sortIndex == 2
								? `3rd Closest Location`
								: null}
						</div>
					) : null}
					<div>
						{locations[i].data.name +
							': ' +
							(locations[i].data.address ||
								locations[i].data.address1)}{' '}
						<FiChevronRight />
					</div>
				</li>
			);
		}

		return locs;
	};

	const selectItem = (item) => {
		if (props.organization != DB_ORG.CUSHMANWAKEFIELD) {
			setLocationText(`${item.data.name}: ${item.data.address1}`);
		} else {
			setLocationText(item.data.name);
		}
		toggle();
		setChosenLocation(item);
	};

	const searchForLocation = async (e = null) => {
		if (e != null) {
			e.preventDefault();
		}
		let results;
		if (sortedLocations.length > 0) {
			results = await searchLocations(searchVal, sortedLocations);
		} else {
			results = await searchLocations(searchVal, allLocations);
		}
		if (results.length == 0 && searchVal == '') {
			await setLocations(allLocations);
			if (sortedLocations.length > 0) {
				await setLocations(sortedLocations);
			} else {
				await setLocations(allLocations);
			}
		} else if (results.length == 0) {
			await setLocations([]);
		} else {
			await setLocations(results);
		}
	};

	const haversineDistance = (coord1, coord2) => {
		const toRadians = (angle) => (angle * Math.PI) / 180;
		const lat1 = coord1.latitude;
		const lon1 = coord1.longitude;
		const lat2 = coord2.latitude;
		const lon2 = coord2.longitude;
		const R = 6371e3; // Earth's radius in meters
		const phi1 = toRadians(lat1);
		const phi2 = toRadians(lat2);
		const deltaPhi = toRadians(lat2 - lat1);
		const deltaLambda = toRadians(lon2 - lon1);
		const a =
			Math.sin(deltaPhi / 2) * Math.sin(deltaPhi / 2) +
			Math.cos(phi1) *
				Math.cos(phi2) *
				Math.sin(deltaLambda / 2) *
				Math.sin(deltaLambda / 2);
		const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
		const d = R * c;
		return d;
	};

	useEffect(() => {
		if (
			'geolocation' in navigator &&
			props.chosenLocation == 'Select a Location'
		) {
			navigator.geolocation.getCurrentPosition(
				(position) => {
					const loc = {
						latitude: position.coords.latitude,
						longitude: position.coords.longitude,
					};
					setGeoLocation(loc);
					props.setUserLocation(loc);
				},
				(error) => {
					console.log(error);
				},
				{ enableHighAccuracy: true, timeout: 20000, maximumAge: 1000 }
			);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (geoLocation.latitude && geoLocation.longitude) {
			const sortLocs = [...allLocations];
			sortLocs.sort((locationA, locationB) => {
				return (
					haversineDistance(geoLocation, locationA.data) -
					haversineDistance(geoLocation, locationB.data)
				);
			});
			setLocations(sortLocs);
			setSortedLocations(sortLocs);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [geoLocation]);

	useEffect(() => {
		if (sortedLocations.length > 0) {
			selectItem(sortedLocations[0]);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [sortedLocations]);

	const initRender = useRef(true);
	useEffect(() => {
		if (initRender.current) initRender.current = false;
		else searchForLocation();
		// searchForLocation();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [searchVal]);

	return (
		<div>
			<Button
				className='blank-btn'
				id='locationPopover'
				type='button'
				onClick={toggle}
			>
				{locationText} <FiChevronRight />
			</Button>
			<Modal className='modal-restrict' isOpen={isOpen}>
				<ModalHeader toggle={toggle}>
					<Input
						type='search'
						placeholder='Search For Location'
						value={searchVal}
						onChange={(e) => {
							setSearchVal(e.target.value);
						}}
						style={{ width: '100%' }}
					/>
				</ModalHeader>
				<ModalBody style={{ overflow: 'scroll', maxHeight: '500px' }}>
					<List style={{ overflow: 'scroll' }}>{getLocations()}</List>
				</ModalBody>
			</Modal>
		</div>
	);
	///////////////////////////////////////////////////////////////////////
};
export { LocationPicker };
