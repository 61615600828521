import { PlansPage } from '@SignedIn/views/Plans/PlansPage';
import { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import { Admin } from '@views/SignedIn/Admin/Admin';
import { canViewAdminPage } from '@assets/services/user-role-service';

const Plans = () => {
	const [page, setPage] = useState();

	const updatePage = async () => {
		const isPaid = await canViewAdminPage();
		if (isPaid) {
			setPage(<Navigate to='/admin' element={<Admin />} replace />);
		} else {
			setPage(<PlansPage />);
		}
	};

	useEffect(() => {
		updatePage();
	}, []);

	return page;
};

export { Plans };
