import { SignedIn } from '@layouts/SignedIn/SignedIn';
import { Landing } from '@views/Landing/Landing';
import AuthContext from './Auth';
import { useContext, useEffect, useState } from 'react';
import { Loader } from '@components/layouts/SignedIn/Loader/Loader';

export const ProtectedRoute = () => {
	const [loading, setLoading] = useState(true);
	const { authInfo } = useContext(AuthContext);

	const updateLoading = async () => {
		setTimeout(() => {
			setLoading(false);
		}, 1000);
	};

	useEffect(() => {
		updateLoading();
	}, []);

	return (
		// <AuthContext.Consumer>
		// {(context) =>
		// 	context.authInfo.loggedIn ? (
		// 		<SignedIn authInfo={context.authInfo} />
		// 	) : (
		// 		<Landing />
		// 	)
		// }
		// </AuthContext.Consumer>
		loading ? (
			<Loader />
		) : authInfo.loggedIn ? (
			<SignedIn authInfo={authInfo} />
		) : (
			<Landing />
		)
	);
};

export default ProtectedRoute;
