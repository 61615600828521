import { useEffect, useState } from 'react';
import Select from 'react-select';
import {
	Button,
	Modal,
	ModalHeader,
	ModalBody,
	ModalFooter,
	Input,
	Label,
	FormGroup,
} from 'reactstrap';
import { getAssetTypes, getMasterAssetsTypes } from '@services/asset-service';

const CreateAssetTypeModal = (props) => {
	const { isOpen, toggle, title, createType, isAll, org, crossRefTypes } =
		props;

	// State for type selection (to be added to current list in parent component)
	const [type, setType] = useState();

	// State for list of types to choose from
	const [types, setTypes] = useState([]);

	// Triggers getTypes function on init render
	useEffect(() => {
		getTypes();
		// eslint-disable-next-line
	}, []);

	// Retrieves asset types list from db
	const getTypes = async () => {
		let typesSnap;
		if (isAll) {
			typesSnap = await getMasterAssetsTypes();
		} else {
			typesSnap = await getAssetTypes(org);
		}
		const newTypes = [];
		for (const type of typesSnap.docs) {
			let add = true;
			for (const otherType of crossRefTypes) {
				if (otherType.data.name == type.data().name) {
					add = false;
					break;
				}
			}
			if (add) {
				newTypes.push({
					ref: type.ref,
					data: type.data(),
					id: type.id,
				});
			}
		}
		setTypes(newTypes);
	};

	const cancel = () => {
		toggle();
		setType();
		setTypes([]);
	};

	const confirm = async () => {
		const obj = {};
		Object.keys(type.data).forEach((key) => (obj[key] = type.data[key]));
		if (isAll) obj.masterListRef = type.ref;
		else obj.orgListRef = type.ref;
		await createType(obj);
		cancel();
	};

	const handleSelChange = (index) => {
		const newType = types[index];
		newType.index = index;
		setType(newType);
	};

	const handleTextChange = (e, key) => {
		const obj = {};
		Object.keys(type).forEach((key) => (obj[key] = type[key]));
		obj.data[key] = e.target.value;
		setType(obj);
	};

	return (
		<div>
			<Modal isOpen={isOpen} toggle={toggle} centered>
				<ModalHeader toggle={toggle}>{title}</ModalHeader>
				<ModalBody>
					<FormGroup>
						<Label>Name</Label>
						<Select
							val={
								type !== undefined
									? {
											label: type.data.name,
											value: type.data.index,
									  }
									: { label: '', value: '' }
							}
							options={types.map((type, i) => {
								return { label: type.data.name, value: i };
							})}
							onChange={(e) => handleSelChange(e.value)}
						/>
					</FormGroup>
					{isAll ? (
						<FormGroup>
							<Label>Org Name</Label>
							<Input
								type='text'
								value={
									type !== undefined
										? type.data.orgName
										: undefined
								}
								onChange={(e) => handleTextChange(e, 'orgName')}
							/>
						</FormGroup>
					) : null}
				</ModalBody>
				<ModalFooter>
					<Button className='cancel-btn' onClick={cancel}>
						Cancel
					</Button>
					<Button className='confirm-btn' onClick={confirm}>
						Confirm
					</Button>
				</ModalFooter>
			</Modal>
		</div>
	);
};

export { CreateAssetTypeModal };
