import { createChatBotMessage } from 'react-chatbot-kit';
import { Avatar } from '../Avatar/Avatar';
import { RiRobotLine } from 'react-icons/ri';
import BotChatMessage from './BotChatMessage';

const config = {
	initialMessages: [
		createChatBotMessage(
			`Hello, I'm FacilityAI! Ask me any question about your assets!`
		),
	],
	botName: 'FacilityAI',
	customStyles: {
		botMessageBox: {
			backgroundColor: '#e9ecef',
		},
		userMessageBox: {
			backgroundColor: '#250245',
		},
		chatButton: {
			backgroundColor: '#250245',
		},
	},
	customComponents: {
		userAvatar: (props) => (
			<div
				style={{
					width: '40px',
					height: '40px',
					borderRadius: '50%',
					overflow: 'hidden',
					marginLeft: '20px',
				}}
			>
				<Avatar {...props} alt='Avatar' className='avatar' />
			</div>
		),
		header: () => (
			<div
				className='chatbot-header'
				style={{
					backgroundColor: '#250245',
					padding: '8px',
					color: 'white',
					textAlign: 'center',
					borderRadius: '10px 10px 0 0',
					overflow: 'hidden',
				}}
			>
				FacilityAI
			</div>
		),
		botChatMessage: (props) => <BotChatMessage {...props} />,
		botAvatar: () => (
			<div className='chatbot-avatar'
				style={{
					width: '40px',
					height: '40px',
					borderRadius: '50%',
					backgroundColor: 'grey',
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
				}}
			>
				<RiRobotLine
					style={{
						width: '27px',
						height: '27px',
						color: 'white',
						transform: 'translatey(-2px)',
					}}
				/>
			</div>
		),
	},
};

export default config;
