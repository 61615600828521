import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

const ConfirmDialog = (props) => {
	const { showConfirm, toggle, title, body, functionality } = props;
	return (
		<div>
			<Modal isOpen={showConfirm} toggle={toggle} centered>
				<ModalHeader toggle={toggle}>{title}</ModalHeader>
				<ModalBody>{body}</ModalBody>
				<ModalFooter>
					<Button className='cancel-btn' onClick={toggle}>
						Cancel
					</Button>
					<Button className='confirm-btn' onClick={functionality}>
						Confirm
					</Button>
				</ModalFooter>
			</Modal>
		</div>
	);
};

export { ConfirmDialog };
