import { createContext, useContext, useReducer } from 'react';

function getLast6Months() {
	const months = [];
	const now = new Date();
	for (let i = 5; i >= 0; i--) {
		const d = new Date(now.getFullYear(), now.getMonth() - i, 1);
		const month = d
			.toLocaleString('default', { month: 'short' })
			.substring(0, 3);
		months.push(month);
	}
	return months;
}

const labels = getLast6Months();
const initialState = [
	{
		title: 'Avg. Asset Lifespan (hours)',
		type: 'bar',
		colorIndex: 2,
		labels,
		data: [15000, 25000, 11905, 18000, 15000, 19548],
	},
	{
		title: 'Active Locations',
		type: 'bar',
		colorIndex: 1,
		labels,
		data: [300, 500, 600, 1200, 800, 400],
	},
	{
		title: 'Alerts',
		type: 'bar',
		colorIndex: 0,
		labels,
		data: [20, 40, 80, 20, 50, 47],
	},
];

const GraphContext = createContext();

const ACTION_TYPE = {
	ADD_GRAPH: 'ADD_GRAPH',
};

function graphReducer(state, action) {
	if (action.type === ACTION_TYPE.ADD_GRAPH) {
		const lastColorIndex = state[0].colorIndex;
		const colorIndex = lastColorIndex > 2 ? 0 : lastColorIndex + 1;
		const graphs = [{ ...action.payload.data, colorIndex }, ...state];
		graphs.length > 4 && graphs.pop();
		return graphs;
	}

	return state;
}

function GraphProvider({ children }) {
	const [graphs, dispatch] = useReducer(graphReducer, initialState);

	const addGraph = (data) => {
		if (!data) return;

		dispatch({
			type: ACTION_TYPE.ADD_GRAPH,
			payload: {
				data: JSON.parse(data),
			},
		});
	};

	const value = { graphs, addGraph };
	return (
		<GraphContext.Provider value={value}>{children}</GraphContext.Provider>
	);
}

function useGraph() {
	const context = useContext(GraphContext);
	if (context === undefined) {
		throw new Error('useGraph must be used within a GraphProvider');
	}
	return context;
}

export { GraphProvider, useGraph };
