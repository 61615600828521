import {
	addDoc,
	collection,
	doc,
	getCountFromServer,
	getDoc,
	getDocs,
	orderBy,
	query,
	updateDoc,
	where,
} from 'firebase/firestore';
import { db } from '@assets/services/auth-service';
import { DB_FILTER, DB_ORDER, DB_PATH, DB_ORG } from '@constants/db';

const checkEvenOrOddMonths = (isCurrentMonth) => {
	let monthToCheck = new Date().getMonth();
	if (isCurrentMonth) monthToCheck += 1;
	return monthToCheck % 2 === 0 ? 'even' : 'odd';
};

export const getLocationsCount = async (organization, noSubmissions) => {
	const params = [where(DB_FILTER.DELETED, '==', false)];
	noSubmissions && params.push(where(DB_FILTER.SUBMISSION_COUNT, '==', 0));

	const locationsCount = await getCountFromServer(
		query(
			collection(
				db,
				DB_PATH.ORGANIZATIONS,
				organization,
				DB_PATH.LOCATIONS
			),
			...params
		)
	);
	return locationsCount.data().count;
};

export const getEvenOddLocations = async (
	organization,
	isCurrentMonth,
	weekVal
) => {
	const evenOrOdd = checkEvenOrOddMonths(isCurrentMonth);
	let locationQuery = query(
		collection(db, DB_PATH.ORGANIZATIONS, organization, DB_PATH.LOCATIONS),
		where(DB_FILTER.DELETED, '==', false),
		where(DB_FILTER.EVEN_OR_ODD, '==', evenOrOdd)
	);
	if (weekVal) {
		locationQuery = query(
			locationQuery,
			where(DB_FILTER.WEEK, '==', weekVal)
		);
	} else {
		locationQuery = query(
			locationQuery,
			orderBy(DB_ORDER.ADDRESS, DB_ORDER.ASC)
		);
	}
	const locationsSnap = await getDocs(locationQuery);
	return locationsSnap;
};

export const getOrganizationLocations = async (organization) => {
	const snap = await getDocs(
		collection(db, DB_PATH.ORGANIZATIONS, organization, DB_PATH.LOCATIONS)
	);
	return snap;
};

export const getVerizonLocations = async (userId) => {
	const locsRef = collection(
		db,
		DB_PATH.ORGANIZATIONS,
		DB_ORG.CUSHMANWAKEFIELD,
		DB_PATH.LOCATIONS
	);
	let snap = await getDocs(
		query(locsRef, where(DB_FILTER.SR_ANALYST, '==', userId))
	);
	if (snap.empty) {
		snap = await getDocs(
			query(locsRef, where(DB_FILTER.SR_AREA_MNGR, '==', userId))
		);
	}
	return snap;
};

export const getLocationIdFromBuildingId = async (organization, buildingId) => {
	const snap = await getDocs(
		query(
			collection(
				db,
				DB_PATH.ORGANIZATIONS,
				organization,
				DB_PATH.LOCATIONS
			),
			where(DB_FILTER.TRIRIGA_BUILDING_SYSTEM_ID, '==', buildingId)
		)
	);
	if (snap.empty) return '';
	let locationId;
	snap.forEach((doc) => {
		locationId = doc.id;
	});
	return locationId;
};

export const getLocation = async (organization, id) => {
	const locSnap = await getDoc(
		doc(db, DB_PATH.ORGANIZATIONS, organization, DB_PATH.LOCATIONS, id)
	);
	return locSnap;
};

export const getLocationTickets = async (organization, locId, ticketId) => {
	const colRef = collection(
		db,
		DB_PATH.ORGANIZATIONS,
		organization,
		DB_PATH.LOCATIONS,
		locId,
		DB_PATH.ABSORBED_TICKETS
	);
	const snap = ticketId
		? await getDocs(
				query(colRef, where(DB_FILTER.TICKET_ID, '==', ticketId))
		  )
		: await getDocs(colRef);
	return snap;
};

export const getLocationSpecs = async (locId) => {
	const fieldRef = doc(db, DB_PATH.ORGANIZATIONS, DB_ORG.CUSHMANWAKEFIELD);
	const fieldSnap = await getDoc(fieldRef);
	const fields = await JSON.parse(fieldSnap.data()[DB_FILTER.LOC_SPECS]);
	const locRef = doc(
		db,
		DB_PATH.ORGANIZATIONS,
		DB_ORG.CUSHMANWAKEFIELD,
		DB_PATH.LOCATIONS,
		locId
	);
	const locSnap = await getDoc(locRef);
	for (const field of fields) {
		field.value = locSnap.data()[field.key];
	}
	return fields;
};

export const updateLocation = async (orgId, locId, data) => {
	const locRef = doc(
		db,
		DB_PATH.ORGANIZATIONS,
		orgId,
		DB_PATH.LOCATIONS,
		locId
	);
	await updateDoc(locRef, data);
};

export const updateWeekStoreLocations = async (
	organization,
	storeNumber,
	week
) => {
	const locationsSnap = await getDoc(
		collection(db, DB_PATH.ORGANIZATIONS, organization, DB_PATH.LOCATIONS),
		where(DB_FILTER.TRIRIGA_BUILDING_SYSTEM_ID, '==', storeNumber)
	);
	locationsSnap.forEach(async (doc) => {
		await updateDoc(doc.ref, {
			week: parseInt(week),
		});
	});
};

export const addTicket = async (organization, locId, ticket) => {
	const ticketRef = await addDoc(
		collection(
			db,
			DB_PATH.ORGANIZATIONS,
			organization,
			DB_PATH.LOCATIONS,
			locId,
			DB_PATH.ABSORBED_TICKETS
		),
		ticket
	);
	return ticketRef;
};

// Checks is Verizon user has access to an individual location
export const canViewVerizonLocation = async (userId, locationId) => {
	const locationRef = doc(
		db,
		DB_PATH.ORGANIZATIONS,
		DB_ORG.CUSHMANWAKEFIELD,
		DB_PATH.LOCATIONS,
		locationId
	);
	const location = await getDoc(locationRef);
	if (
		location.data()[DB_FILTER.SR_ANALYST] == userId ||
		location.data()[DB_FILTER.SR_AREA_MNGR] == userId
	) {
		return true;
	} else return false;
};

export const addLocation = async (orgId, data) => {
	const locRef = collection(
		db,
		DB_PATH.ORGANIZATIONS,
		orgId,
		DB_PATH.LOCATIONS,
	);
	await addDoc(locRef, data);
};
