import { Row, Col } from 'reactstrap';
import { StatsCard } from '@SignedIn/views/Dashboard/StatsCard/StatsCard';
import { useEffect, useState } from 'react';
import { CostSavingsCard } from '@SignedIn/views/Dashboard/CostSavingsCard/CostSavingsCard';
import { Loader } from '@SignedIn/Loader/Loader';
import { useNavigate } from 'react-router-dom';

const CWDashboard = () => {
	const navigate = useNavigate();
	const [data, setData] = useState();
	const [absorbedData, setAbsorbedData] = useState();
	const width = useState(window.innerWidth);
	const [totalSubmissions, setTotalSubmissions] = useState();
	const [monthOptions, setMonthOptions] = useState();
	const [activeMonth, setActiveMonth] = useState();
	// Day 1 of this month at midnight for start range
	const [startDate, setStartDate] = useState(() => {
		const date = new Date();
		date.setDate(1);
		date.setHours(0);
		return date;
	});
	// Day 1 of next month at midnight for end range
	const [endDate, setEndDate] = useState(() => {
		const date = new Date();
		date.setMonth(date.getMonth() + 1);
		date.setDate(1);
		date.setHours(0);
		return date;
	});
	const [isLoading, setIsLoading] = useState(true);

	const getCostSavings = async () => {
		const res = await fetch(
			'https://us-central1-property-echo-4d747.cloudfunctions.net/getCushmanCostSavingsVariables',
			{
				method: 'POST',
				body: JSON.stringify({
					startDate: startDate,
					endDate: endDate,
				}),
			}
		);
		const data = await res.json();
		const categories = data.categories;
		const submissions = data.submissions;

		let ceilingCount = 0;
		let doorCount = 0;
		let flooringCount = 0;
		let lightingCount = 0;
		let millworkCount = 0;
		let plumbingCount = 0;
		let wallPaintCount = 0;

		let totalSavings = 0;

		for (let i = 0; i < submissions.length; ++i) {
			const submission = submissions[i];
			for (const inputField in submission) {
				// const submissionCategoryLength = submission[inputField].length;
				let submissionCategoryLength = 0;
				for (const item of submission[inputField]) {
					if (item.quality == 'Repair Performed') {
						submissionCategoryLength++;
					}
				}
				if (submissionCategoryLength == 0) continue;
				if (categories.ceiling.includes(inputField)) {
					ceilingCount += submissionCategoryLength;
				} else if (categories.door.includes(inputField)) {
					doorCount += submissionCategoryLength;
				} else if (categories.flooring.includes(inputField)) {
					flooringCount += submissionCategoryLength;
				} else if (categories.lighting.includes(inputField)) {
					lightingCount += submissionCategoryLength;
				} else if (categories.millwork.includes(inputField)) {
					millworkCount += submissionCategoryLength;
				} else if (categories.plumbing.includes(inputField)) {
					plumbingCount += submissionCategoryLength;
				} else if (categories.wallPaint.includes(inputField)) {
					wallPaintCount += submissionCategoryLength;
				}
			}
		}

		totalSavings =
			wallPaintCount * 330.74 +
			plumbingCount * 300.36 +
			lightingCount * 291.59 +
			doorCount * 284.94 +
			flooringCount * 256.23 +
			ceilingCount * 252.7 +
			millworkCount * 246.39;

		setTotalSubmissions(submissions.length);
		setData({
			ceiling: {
				savings: Math.round(ceilingCount * 252.7),
				count: ceilingCount,
			},
			door: { savings: Math.round(doorCount * 284.94), count: doorCount },
			flooring: {
				savings: Math.round(flooringCount * 256.23),
				count: flooringCount,
			},
			lighting: {
				savings: Math.round(lightingCount * 291.59),
				count: lightingCount,
			},
			millwork: {
				savings: Math.round(millworkCount * 246.39),
				count: millworkCount,
			},
			plumbing: {
				savings: Math.round(plumbingCount * 300.36),
				count: plumbingCount,
			},
			wallPaint: {
				savings: Math.round(wallPaintCount * 330.74),
				count: wallPaintCount,
			},
			total: { savings: totalSavings.toLocaleString() },
			cost: (submissions.length * 350).toLocaleString(),
		});
		setIsLoading(false);
	};

	const getAbsorbedTicketsData = async () => {
		const res = await fetch(
			'https://us-central1-property-echo-4d747.cloudfunctions.net/getCushmanAbsorbedTicketsData',
			{
				method: 'POST',
				body: JSON.stringify({
					startDate: startDate,
					endDate: endDate,
				}),
			}
		);
		const data = await res.json();
		setAbsorbedData(data);
	};

	// Get last 12 months including this month in 'Month Year' format
	const getMonthOptions = () => {
		let options = [];
		for (let i = 0; i < 12; ++i) {
			const date = new Date();
			date.setDate(1);
			date.setMonth(date.getMonth() - i);
			const month = date.toLocaleString('default', { month: 'long' });
			const year = date.getFullYear();
			options.push(`${month} ${year}`);
		}
		setMonthOptions(options);
	};

	const onMonthChange = (e) => {
		setIsLoading(true);
		const selectedMonthStart = new Date(e);
		const selectedMonthEnd = new Date(e);
		selectedMonthEnd.setMonth(selectedMonthEnd.getMonth() + 1);
		setStartDate(selectedMonthStart);
		setEndDate(selectedMonthEnd);
		setActiveMonth(e);
	};

	useEffect(() => {
		if (width > 768) {
			getMonthOptions();
			getAbsorbedTicketsData();
			getCostSavings();
		} else {
			setIsLoading(false);
			navigate('/forms');
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [endDate]);

	return isLoading ? (
		<Loader />
	) : !absorbedData ? (
		<div />
	) : (
		<div className='dashboard'>
			<Row className='light-border-b row-1'>
				<Col className='col-12'>
					<CostSavingsCard
						monthOptions={monthOptions}
						costData={data}
						activeMonth={activeMonth}
						onMonthChange={onMonthChange}
					/>
				</Col>
			</Row>
			<Row>
				<Col className='col-12 col-sm-6 col-xl'>
					<StatsCard
						stats={{
							name: 'Total Visits',
							data: totalSubmissions,
							color: '#250245',
							icon: 'calendar-check',
							id: 'svisits',
						}}
					/>
				</Col>
				<Col className='col-12 col-sm-6 col-xl'>
					<StatsCard
						stats={{
							name: 'Total Absorbed Savings',
							data: `$${absorbedData.savings}`,
							color: '#228B22',
							icon: 'cash-coin',
							id: 'avglifespan',
						}}
					/>
				</Col>
				<Col className='col-12 col-sm-6 col-xl'>
					<StatsCard
						stats={{
							name: 'Total Prevented',
							data: `$${data.total.savings}`,
							color: '#00ccff',
							icon: 'cash-stack',
							id: 'activeloc',
						}}
					/>
				</Col>
				<Col className='col-12 col-sm-6 col-xl'>
					<StatsCard
						stats={{
							name: 'Total Tickets Absorbed',
							data: absorbedData.count.toLocaleString(),
							color: '#fbc658',
							icon: 'ticket-perforated',
							id: 'alerts',
						}}
					/>
				</Col>
			</Row>
		</div>
	);
};

export { CWDashboard };
