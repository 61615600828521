import { Card, CardBody, CardHeader } from 'reactstrap';
import {
	Chart as ChartJS,
	CategoryScale,
	LinearScale,
	PointElement,
	LineElement,
	BarElement,
	Title,
	Tooltip,
	Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import { useEffect, useState } from 'react';
import { Loader } from '@components/layouts/SignedIn/Loader/Loader';
import { BiCoinStack } from 'react-icons/bi';

const NormalizedSpendCard = (props) => {
	const { assets } = props;
	const [loading, setLoading] = useState(true);

	const currYear = new Date().getFullYear();
	const labels = [
		currYear + 1,
		currYear + 2,
		currYear + 3,
		currYear + 4,
		currYear + 5,
	];
	let data = {
		labels,
		datasets: [
			{
				label: 'Dataset 1',
				data: [],
			},
		],
	};
	const [chartData, setChartData] = useState(data);

	const lineOptions = {
		responsive: true,
		maintainAspectRatio: false,
		plugins: {
			legend: {
				display: false,
				position: 'top',
			},
			// title: {
			// 	display: true,
			// 	text: '5-Year Normalized Spend',
			// },
		},
		scales: {
			y: {
				ticks: {
					color: '#9f9f9f',
					beginAtZero: false,
					maxTicksLimit: 5,
					//padding: 20
				},
				grid: {
					// drawBorder: false,
					display: false,
				},
			},
			x: {
				grid: {
					// drawBorder: false,
					display: false,
				},
				ticks: {
					padding: 10,
					color: '#9f9f9f',
				},
			},
		},
	};

	useEffect(() => {
		// const chart = document.getElementById("normalized-chart");
		// const ctx = chart.getContext("2d");
		// const gradientBg = ctx.createLinearGradient(0, 0, 0, 307);
		// gradientBg.addColorStop(0, "#8315e8");
		// gradientBg.addColorStop(0.7, "#d5a8ff");
		// gradientBg.addColorStop(0.9, "#fff");
		if (assets.length > 0) {
			const data = [0, 0, 0, 0, 0];
			assets.forEach((asset) => {
				const index = labels.indexOf(asset.data.replacementYear);
				data[index] = data[index] + asset.data.avgReplCostUSD;
				setChartData({
					labels,
					datasets: [
						{
							label: 'Dataset 1',
							data: data,
							borderColor: '#250245',
							// backgroundColor: gradientBg,
							fill: false,
							tension: 0.2,
							borderWidth: 3.5,
							pointRadius: 0,
						},
					],
				});
				setLoading(false);
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [assets]);

	ChartJS.register(
		CategoryScale,
		LinearScale,
		PointElement,
		LineElement,
		BarElement,
		Title,
		Tooltip,
		Legend
	);

	return (
		<Card className='predictive-spend-card'>
			<CardHeader className='d-flex justify-content-between align-items-center'>
				<div className='d-flex align-items-center'>
					<BiCoinStack className='fs-4' />
					<span className='fw-bold text-purple mx-2'>
						5-Year Normalized Spend
					</span>
				</div>
			</CardHeader>
			<CardBody className='rounded-bottom py-2 '>
				{loading ? (
					<Loader small={true} />
				) : (
					<Line
						id='normalized-chart'
						options={lineOptions}
						data={chartData}
					/>
				)}
			</CardBody>
		</Card>
	);
};

export { NormalizedSpendCard };
