import { getLocation } from '@services/location-service';
import { updateDoc } from 'firebase/firestore';
import { useState } from 'react';
import {
	Button,
	Modal,
	ModalHeader,
	ModalBody,
	ModalFooter,
	Input,
	Label,
} from 'reactstrap';

function VirtualModal({ virtualType, locationId, organization, setVirtualChange }) {
	const [modal, setModal] = useState(false);
	const [inputVal, setInputVal] = useState('');

	const toggle = () => setModal(!modal);

	const handleSubmit = async () => {
		const propToUpdate = virtualType.toLowerCase();
		const locSnap = await getLocation(organization, locationId);
		await updateDoc(locSnap.ref, {
			[propToUpdate]: inputVal,
		});
        setVirtualChange(true);
		toggle();
	};

	return (
		<div className='mb-2'>
			<Button color='success' onClick={toggle} style={{width: '165px'}}>
				Connect {virtualType}
			</Button>
			<Modal isOpen={modal} toggle={toggle} centered>
				<ModalHeader toggle={toggle}>Connect {virtualType}</ModalHeader>
				<ModalBody>
					<Label>{virtualType} ID</Label>
					<Input onChange={(e) => setInputVal(e.target.value)} />
				</ModalBody>
				<ModalFooter>
					<Button color='success' onClick={handleSubmit}>
						Connect
					</Button>{' '}
					<Button color='secondary' onClick={toggle}>
						Cancel
					</Button>
				</ModalFooter>
			</Modal>
		</div>
	);
}

export { VirtualModal };
