import Iframe from 'react-iframe';
import { useRef, useState, useEffect } from 'react';
import { Row, Col, Table } from 'reactstrap';

const MatterportTab = (props) => {
	const { matterport } = props;
	const myRef = useRef();

	const [mattertags, setMattertags] = useState([]);
	const [setMattertagHolder] = useState([]);
	const [model, setModel] = useState();
	const [selectedTag, setSelectedTag] = useState();
	const [sortIndex, setSortIndex] = useState(1);

	const getMPStuff = async () => {
		const iframe = myRef.current.childNodes[0];
		try {
			const mpSdk = await window.MP_SDK.connect(
				iframe, // Obtained earlier
				'y79beusqeecy91wwa4p6gy36d', // Your SDK key
				'' // Unused but needs to be a valid string
			);
			onShowcaseConnect(mpSdk);
		} catch (e) {
			console.error(e);
		}
	};

	const onShowcaseConnect = async (mpSdk) => {
		// start making calls on mpSdk as described in the reference docs
		const data = await mpSdk.Mattertag.getData();
		setMattertags(data);
		setModel(mpSdk);
		setMattertagHolder(data);
	};

	const navigateToTag = (tag) => {
		model.Mattertag.navigateToTag(tag.sid, model.Mattertag.Transition.FLY);
		setSelectedTag(tag);
	};

	const getColor = () => {
		let r = selectedTag.color.r * 255;
		let g = selectedTag.color.g * 255;
		let b = selectedTag.color.b * 255;
		return (
			<div
				className='color-circle'
				style={{ background: 'rgb(' + r + ',' + g + ',' + b + ')' }}
			/>
		);
	};

	const toggleFilters = () => {
		let index;
		if (sortIndex == 1) {
			index = 2;
		} else {
			index = 1;
		}

		if (index === 1) {
			let newMattertags = mattertags;
			newMattertags.sort(function (a, b) {
				a = a.label.toLowerCase();
				b = b.label.toLowerCase();

				return a < b ? -1 : a > b ? 1 : 0;
			});

			setMattertags(newMattertags);
			setSortIndex(index);
		} else {
			let newMattertags = mattertags;
			newMattertags.sort(function (a, b) {
				let calculateLuminosity = function (rgb) {
					return rgb
						.replace(/[^[\d,]/g, '')
						.split(',')
						.map(Number)
						.reduce(function (pv, cv) {
							return pv + cv;
						}, 0);
				};

				let luminosity = {
					a: calculateLuminosity(
						'rgb(' +
							a.color.r * 255 +
							',' +
							a.color.g * 255 +
							',' +
							a.color.b * 255 +
							')'
					),
					b: calculateLuminosity(
						'rgb(' +
							b.color.r * 255 +
							',' +
							b.color.g * 255 +
							',' +
							b.color.b * 255 +
							')'
					),
				};

				if (luminosity.a === luminosity.b) {
					return 0;
				} else {
					return luminosity.a > luminosity.b ? -1 : 1;
				}
			});

			setMattertags(newMattertags);
			setSortIndex(index);
		}
	};

	useEffect(() => {
		getMPStuff();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<div className='fullSize pt-3'>
			<Row className='row-container-mattertags'>
				{mattertags.length > 0 ? (
					<Col xs='3' style={{ paddingRight: '10px' }}>
						<div className='mattertags-container'>
							<ul>
								<div className='toolbar-mattertags d-flex flex-row justify-content-between align-items-center'>
									<h4>Executive Summary</h4>
									<div
										className='assets-filter-button me-3 d-flex justify-content-center align-items-center'
										onClick={toggleFilters}
									>
										<i className='bi bi-filter fs-3 p-1' />
									</div>
								</div>

								{mattertags.map((val, index) => {
									return (
										<div key={val.sid}>
											<Row
												style={{
													alignItems: 'center',
												}}
											>
												<Col>
													<li
														key={index}
														className='mattertag'
														onClick={() =>
															navigateToTag(val)
														}
													>
														{val.label}
													</li>
												</Col>
												<Col>
													<div
														className='color-circle'
														style={{
															background:
																'rgb(' +
																val.color.r *
																	255 +
																',' +
																val.color.g *
																	255 +
																',' +
																val.color.b *
																	255 +
																')',
														}}
													></div>
												</Col>
											</Row>
										</div>
									);
								})}
							</ul>
						</div>
					</Col>
				) : (
					<></>
				)}
				<Col>
					<div
						className='model-view-container position-relative'
						ref={myRef}
					>
						<Iframe
							url={
								'https://my.matterport.com/show?m=' +
								matterport +
								'&play=1&applicationKey=y79beusqeecy91wwa4p6gy36d'
							}
							width='100%'
							height='100%'
							id='showcase_iframe'
						/>
					</div>
				</Col>
			</Row>
			{selectedTag ? (
				<div className='bottom-footer-mattertag'>
					<Row>
						<Col>
							<Table>
								<thead>
									<tr>
										<th>Name</th>
										<th>Description</th>
										<th>Color</th>
										<th>ID</th>
									</tr>
								</thead>
								<tbody>
									<tr className='no-overflow'>
										<th>{selectedTag.label}</th>
										<th>{selectedTag.description}</th>
										<th>{getColor()}</th>
										<th>{selectedTag.sid}</th>
									</tr>
								</tbody>
							</Table>
						</Col>
					</Row>
				</div>
			) : (
				<></>
			)}
		</div>
	);
};

export { MatterportTab };
