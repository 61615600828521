import { LocationsTable } from '@SignedIn/views/Locations/LocationsTable';
import { useEffect, useState } from 'react';
import { Loader } from '@SignedIn/Loader/Loader';
import { DB_ORG } from '@constants/db';
import { getOrganizations } from '@services/organization-service';
import {
	getOrganizationLocations,
	getVerizonLocations,
} from '@services/location-service';
import AuthContext from '@assets/services/Auth';
import { useContext } from 'react';
import { isVerizonUser } from '@assets/services/user-role-service';

const Locations = ({ setIsToastShowing }) => {
	const { authInfo } = useContext(AuthContext);
	const organization = authInfo.org;

	const [isLoading, setIsLoading] = useState(true);

	// Init locations array state
	const [allLocations, setAllLocations] = useState([]);

	// Adds default healthsCore & propertyType attributes to locations that are missing them.
	const addMissingValues = (locations) => {
		const newLocations = locations.map((location) => {
			let newLocation = location;
			newLocation.data.healthScore = location.data.healthScore || 90;
			newLocation.data.propertyType =
				location.data.propertyType || 'Commercial';
			return newLocation;
		});
		return newLocations;
	};

	// Retrieves organization's locations from database
	const getAllLocations = async () => {
		if (organization === DB_ORG.DATAFLEET) {
			const orgSnap = await getOrganizations();
			let promises = [];
			let locationsFromDB = [];

			orgSnap.forEach((d) => {
				let orgId = d.id;
				promises.push(
					new Promise((resolve) => {
						getOrganizationLocations(orgId).then((docSnap) => {
							docSnap.forEach((doc) => {
								locationsFromDB.push({
									data: doc.data(),
									id: doc.id,
									checked: false,
									org: orgId,
								});
							});
							resolve();
						});
					})
				);
			});
			Promise.all(promises).then(async () => {
				const updatedLocations = await addMissingValues(
					locationsFromDB
				);
				setAllLocations(updatedLocations);
				setIsLoading(false);
			});
		} else {
			let docSnap;
			if (organization == DB_ORG.CUSHMANWAKEFIELD) {
				const isVerizon = await isVerizonUser();
				if (isVerizon) {
					docSnap = await getVerizonLocations(authInfo.id);
				} else {
					docSnap = await getOrganizationLocations(organization);
				}
			} else {
				docSnap = await getOrganizationLocations(organization);
			}
			let locationsFromDB = [];
			docSnap.forEach((doc) => {
				locationsFromDB.push({
					data: doc.data(),
					id: doc.id,
					checked: false,
					org: organization,
				});
			});
			const updatedLocations = await addMissingValues(locationsFromDB);
			await setAllLocations(updatedLocations);
			setIsLoading(false);
		}
	};

	// Updates locations array & loading states upon render
	useEffect(() => {
		getAllLocations();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return isLoading ? (
		<Loader />
	) : (
		<div>
			<LocationsTable
				title='Locations'
				allLocations={allLocations}
				setAllLocations={setAllLocations}
				organization={organization}
				getAllLocations={getAllLocations}
				setIsToastShowing={setIsToastShowing}
			/>
		</div>
	);
};

export { Locations };
