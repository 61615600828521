import { memo } from 'react';

const Loader = () => {
	const greetingMessages = [
		"Welcome back, it's great to see you again!",
		'Hey there! Thanks for stopping by again!',
		"Nice to see you again! I hope you've been well!",
		"It's great to have you back!",
		'Hey, good to see you again!',
		'Welcome back, friend!',
		"Hi there! Glad you're back!",
		'Nice to see you again!',
		"Hello again! I hope you're having a great day so far!",
		'Hey there! Thanks for stopping by again!',
		'Welcome back!',
		'Good to see you again!',
		"Hey, it's great to see you again!",
		'Welcome back!',
	];

	const message =
		greetingMessages[Math.floor(Math.random() * greetingMessages.length)];

	return (
		<div className='alfreds-notes-loader'>
			<div>{message}</div>
			<div>
				Stand by as I gather information about your organization...
			</div>
			<svg
				id='dots'
				width='40px'
				height='21px'
				viewBox='0 0 132 58'
				version='1.1'
				xmlns='http://www.w3.org/2000/svg'
				className='m-3'
			>
				<g stroke='none' fill='none'>
					<g id='chatbot-loader' fill='#0c0c0c'>
						<circle
							id='chatbot-loader-dot1'
							cx='25'
							cy='20'
							r='8'
						></circle>
						<circle
							id='chatbot-loader-dot2'
							cx='65'
							cy='20'
							r='8'
						></circle>
						<circle
							id='chatbot-loader-dot3'
							cx='105'
							cy='20'
							r='8'
						></circle>
					</g>
				</g>
			</svg>
		</div>
	);
};

export default memo(Loader);
