import { initializeApp } from '@firebase/app';
import {
	getAuth,
	EmailAuthProvider,
	GoogleAuthProvider,
	signInWithEmailAndPassword,
	createUserWithEmailAndPassword,
	updateProfile,
	sendEmailVerification,
	sendPasswordResetEmail,
} from '@firebase/auth';
import { getFirestore } from '@firebase/firestore';
import { getStorage } from '@firebase/storage';
import { getAnalytics } from 'firebase/analytics';

const firebaseConfig = JSON.parse(process.env.REACT_APP_FIREBASE_CONFIG);

const firebaseApp = initializeApp(firebaseConfig);
export const analytics = getAnalytics(firebaseApp);
export const auth = getAuth(firebaseApp);
export const db = getFirestore(firebaseApp);
export const storage = getStorage(firebaseApp);
const logout = () => {
	auth.signOut();
};

export const AuthProviders = {
	google: new GoogleAuthProvider(),
	email: new EmailAuthProvider(),
	sendPasswordResetEmail,
	signInWithEmailAndPassword,
	createUserWithEmailAndPassword,
	updateProfile,
	sendEmailVerification,
	logout,
};

export default firebaseApp;
