import { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
	Row,
	Col,
	Table,
	Card,
	CardHeader,
	Form,
	CardBody,
} from 'reactstrap';
import { canDelete } from '@assets/services/user-role-service';
import { MdAdd, MdDownload } from 'react-icons/md';
import { AiOutlineClose } from 'react-icons/ai';
import { BsThreeDotsVertical } from 'react-icons/bs';
import { IoMdTrash } from 'react-icons/io';
import { ConfirmDialog } from './children/ConfirmDialog';
import { searchLocations } from './children/Search';
import { Filter } from './children/Filter';
import { ProgressBar } from 'react-bootstrap';
import { auth } from '@assets/services/auth-service';
import { getUser, updateUserFilter } from '@services/user-service';
import { DB_ORG } from '@constants/db';
import { ExportModal } from '@SignedIn/views/Admin/SubmissionsTable/children/ExportModal';
import { AddLocationModal } from './children/AddLocationModal';
import { canAdd } from '@assets/services/user-role-service';

const LocationsTable = (props) => {
	const {
		allLocations,
		setAllLocations,
		organization,
		setIsToastShowing,
		getAllLocations,
	} = props;
	const userId = auth.currentUser.uid;

	const navigate = useNavigate();

	const location = useLocation();

	// Search value state
	const [searchVal, setSearchVal] = useState(
		location?.state?.searchVal || ''
	);

	// Pagination tracking number state
	const [pageNum, setPageNum] = useState(1);

	// Sort by (which column) state
	const [sortBy, setSortBy] = useState();

	// Sort ascending (vs descending) state
	const [sortAsc, setSortAsc] = useState(true);

	// Locations key for address (for sorting)
	const [addressKey, setAddressKey] = useState();

	// Locations array state
	const [locations, setLocations] = useState();

	const [totalLocationsNum, setTotalLocationsNum] = useState(0);

	const [showingLocationsNum, setShowingLocationsNum] = useState(0);

	// Helper state to trigger re-rendering when locations changes
	const [locationsChange, setLocationsChange] = useState(false);

	// Locations table component state
	const [locationsTable, setLocationsTable] = useState();

	// Tracks whether all checkboxes are selected or not
	const [allChecked, setAllChecked] = useState(false);

	// Checked submissions state
	const [checked, setChecked] = useState([]);

	// Determines whether the delete confirmation is shown
	const [showDeleteModal, setShowDeleteModal] = useState(false);

	// Determines whether the export confirmation is shown
	const [showExportModal, setShowExportModal] = useState(false);

	// Filter component state
	const [filter, setFilter] = useState();

	// Determines wheter the kebab filter menu is shown
	const [showFilter, setShowFilter] = useState(false);

	// Determines which columnsShown are shown
	const [columnsShown, setColumnsShown] = useState();

	const [addLocationModal, setAddLocationModal] = useState(false);

	useEffect(() => {
		if (locations) {
			setTotalLocationsNum(locations.length);
			if (locations.length < 10) {
				setShowingLocationsNum(locations.length);
			} else {
				setShowingLocationsNum(10);
			}
		}
	}, [locations]);

	// Tracks whether columnsShown state changes (for useEffect's sake)
	const [columnsShownChanges, setColumnsShownChanges] = useState(false);

	// Delete location from db
	// const deleteLocationFromDb = (id) => {
	// 	//
	// 	// Insert firebase methods and replace return statement
	// 	//
	// 	return;
	// };

	// // Delete single location from locations state
	// const deleteLocationFromState = (index) => {
	// 	const newLocations = [
	// 		...locations.slice(0, Number(index)),
	// 		...locations.slice(Number(index) + 1),
	// 	];
	// 	setLocations(newLocations);
	// };

	// Delete multiple location from locations state
	const deleteLocationsFromState = async () => {
		const newLocations = locations.filter((location) => !location.checked);
		await setLocations(newLocations);
		await toggleDeleteModal();
		await setAllChecked(false);
		setLocationsChange(true);
	};

	// Update checked attribute for given location
	const updateCheck = async (index, check) => {
		const newChecked = [];
		const newLocations = locations;
		const newAllLocations = allLocations;
		if (check) {
			newLocations[index].checked = true;
			newChecked.push(...checked, newLocations[index]);
			for (const location of newAllLocations) {
				if (location.id == newLocations[index].id) {
					location.checked = true;
					break;
				}
			}
		} else {
			newLocations[index].checked = false;
			for (const item of checked) {
				if (newLocations[index].id != item.id) {
					newChecked.push(item);
				}
			}
			for (const location of newAllLocations) {
				if (location.id == newLocations[index].id) {
					location.checked = false;
					break;
				}
			}
		}
		if (newChecked.length == allLocations.length) await setAllChecked(true);
		else await setAllChecked(false);
		await setAllLocations(newAllLocations);
		await setLocations(newLocations);
		await setChecked(newChecked);
		setLocationsChange(true);
	};

	// Checks or unchecks boxes for all locations
	const checkAll = async () => {
		const newAllLocations = allLocations.map((location) => {
			location.checked = !allChecked;
			return location;
		});
		const newLocations = locations.map((location) => {
			location.checked = !allChecked;
			return location;
		});
		if (!allChecked) await setChecked(newAllLocations);
		else await setChecked([]);
		await setAllLocations(newAllLocations);
		await setLocations(newLocations);
		await setAllChecked(!allChecked);
		await setLocationsChange(true);
	};

	// Toggles showDeleteModal state
	const toggleDeleteModal = () => setShowDeleteModal(!showDeleteModal);

	// Toggles showExportModal state
	const toggleExportModal = () => setShowExportModal(!showExportModal);

	const toggleAddLocationModal = () => setAddLocationModal(!addLocationModal);

	// Toggles showFilter state
	const toggleFilter = () => setShowFilter(!showFilter);

	// Handles search
	let sorted = false;
	const handleSearch = async (e = null) => {
		if (e != null) {
			e.preventDefault();
		}
		const results = await searchLocations(
			searchVal,
			allLocations,
			organization
		);
		let newLocations;
		if (results.length == 0 && searchVal == '') {
			newLocations = allLocations;
		} else if (results.length == 0) {
			newLocations = [];
		} else {
			newLocations = results;
		}
		if (location.state != undefined && !sorted) {
			sortLocations(null, location.state, newLocations);
			sorted = true;
		} else {
			setLocations(newLocations);
			setLocationsChange(true);
		}
		setPageNum(1);
	};

	const goToLocation = (e, item) => {
		if (e.target instanceof HTMLTableCellElement) {
			// Clicked the table row not the buttons or input fields.
			// Navigate
			item.tab = 'Overview';
			item.searchVal = searchVal;
			item.sortBy = sortBy;
			item.sortAsc = sortAsc;
			navigate('/locations/' + item.id, { state: item });
		}
	};

	// Handles when pagination buttons are clicked
	const pageBackward = () => {
		if (pageNum > 1) {
			const newPageNum = pageNum - 1;
			setPageNum(newPageNum);
			if (Math.ceil(totalLocationsNum / 10) == newPageNum) {
				setShowingLocationsNum(totalLocationsNum);
			} else {
				setShowingLocationsNum(newPageNum * 10);
			}
			setLocationsChange(true);
		}
	};

	const pageForward = () => {
		if (pageNum < Math.ceil(locations.length / 10)) {
			const newPageNum = pageNum + 1;
			if (Math.ceil(totalLocationsNum / 10) == newPageNum) {
				setShowingLocationsNum(totalLocationsNum);
			} else {
				setShowingLocationsNum(newPageNum * 10);
			}
			setPageNum(newPageNum);
			setLocationsChange(true);
		}
	};

	const sortNumbers = (array, key, asc) => {
		if (asc) {
			return array.sort((a, b) => {
				const number_a = parseFloat(a.data[key]) || 0;
				const number_b = parseFloat(b.data[key]) || 0;
				return number_a - number_b;
			});
		} else {
			return array.sort((a, b) => {
				const number_a = parseFloat(a.data[key]) || 0;
				const number_b = parseFloat(b.data[key]) || 0;
				return number_b - number_a;
			});
		}
	};

	const sortNames = (array, key, asc) => {
		const newArr = array.sort((a, b) => {
			let valA;
			let valB;
			if (a.data[key] != undefined) valA = a.data[key].toUpperCase();
			else valA = '';
			if (b.data[key] != undefined) valB = b.data[key].toUpperCase();
			else valB = '';

			if (asc) {
				if (valA < valB) {
					return -1;
				} else if (valA > valB) {
					return 1;
				} else {
					return 0;
				}
			} else {
				if (valA > valB) {
					return -1;
				} else if (valA < valB) {
					return 1;
				} else {
					return 0;
				}
			}
		});
		return newArr;
	};

	// Sort locations upon column click
	const sortLocations = async (column, state, newLocations = null) => {
		setLocationsChange(false);
		// Init variables
		let list;
		if (newLocations != null) {
			list = newLocations;
		} else {
			list = locations;
		}
		let newList;
		let key;
		let isAscending =
			location?.state?.sortAsc != undefined
				? !location?.state?.sortAsc
				: true;
		// Assign correct key to sort by
		if (column == 'address') {
			key = addressKey;
		} else {
			key = column;
		}
		// Change direction if same key
		if (column == sortBy) {
			isAscending = !sortAsc;
		}
		if (state) {
			key = state.sortBy;
		}
		// Sort either by name or number
		if (key == 'healthscore' || key == 'submissionCount') {
			newList = sortNumbers(
				list,
				key,
				state ? state.sortAsc : isAscending
			);
		} else {
			newList = sortNames(list, key, state ? state.sortAsc : isAscending);
		}
		if (state) {
			await setSortBy(state.sortBy);
			await setSortAsc(state.sortAsc);
		} else {
			await setSortBy(column);
			await setSortAsc(isAscending);
		}
		await setLocations(newList);
		setLocationsChange(true);
	};

	const getColsShown = async () => {
		const data = await getUser(userId);
		let dataExists = true;
		if (!data.savedFilters) dataExists = false;
		else if (!data.savedFilters.locationsTable) dataExists = false;
		if (dataExists) setColumnsShown(data.savedFilters.locationsTable);
		else {
			setColumnsShown({
				address: true,
				city: true,
				state: true,
				zip: true,
				health: true,
				submissionCount: true,
				manager: true,
			});
		}
		setColumnsShownChanges(true);
	};

	const updateFilters = async () => {
		const data = await getUser(userId);
		let savedFilters = data.savedFilters;
		if (!savedFilters) savedFilters = {};
		savedFilters.locationsTable = columnsShown;
		await updateUserFilter(userId, savedFilters);
	};

	useEffect(() => {
		getColsShown();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (columnsShownChanges) {
			updateFilters();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [columnsShownChanges]);

	useEffect(() => {
		if (columnsShown) {
			setFilter(
				<Filter
					visible={showFilter}
					columns={columnsShown}
					setColumns={setColumnsShown}
					colsChange={columnsShownChanges}
					setColsChange={setColumnsShownChanges}
					org={organization}
				/>
			);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [showFilter, columnsShownChanges]);

	// Update locations state when allLocations prop changes
	useEffect(() => {
		if (allLocations[0] != undefined) {
			if (allLocations[0].address != undefined) {
				setAddressKey('address');
			} else {
				setAddressKey('address1');
			}
		}
		setLocations(allLocations);
		setLocationsChange(true);
	}, [allLocations]);

	// Run search function when searchVal changes
	useEffect(() => {
		handleSearch();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [searchVal]);

	// Update locations table state & isLoading state when locationsChange & pageNum states change
	useEffect(() => {
		if (
			(locationsChange || columnsShownChanges) &&
			locations != undefined &&
			columnsShown != undefined
		) {
			if (locations.length > 0) {
				let endIndex;
				let startIndex;
				if (pageNum * 10 > locations.length) {
					endIndex = locations.length;
					startIndex = (pageNum - 1) * 10;
				} else {
					endIndex = pageNum * 10;
					startIndex = endIndex - 10;
				}
				let locationRows = [];
				for (let i = startIndex; i < endIndex; i++) {
					const location = locations[i];
					let healthColor;
					if (location.data.healthscore >= 75) {
						healthColor = 'greenHealth';
					} else if (location.data.healthscore >= 25) {
						healthColor = 'yellowHealth';
					} else {
						healthColor = 'redHealth';
					}
					const newRow = (
						<tr
							key={location.data.id}
							onClick={(e) => goToLocation(e, location)}
						>
							<td>
								<input
									type='checkbox'
									checked={location.checked}
									onChange={() => {
										updateCheck(i, !location.checked);
									}}
								/>
							</td>
							{(() =>
								columnsShown.address &&
								organization == DB_ORG.CUSHMANWAKEFIELD ? (
									<td className='text-start'>
										{location.data.name}
									</td>
								) : columnsShown.address ? (
									<td className='text-start'>
										{location.data.address1}
									</td>
								) : null)()}
							{(() =>
								columnsShown.city ? (
									<td className='text-start'>
										{location.data.city}
									</td>
								) : null)()}
							{(() =>
								columnsShown.state ? (
									<td>{location.data.state}</td>
								) : null)()}
							{(() =>
								columnsShown.zip ? (
									<td>{location.data.zip}</td>
								) : null)()}
							{(() =>
								columnsShown.submissionCount ? (
									<td>{location.data.submissionCount}</td>
								) : null)()}
							{(() =>
								columnsShown.manager ? (
									<td>
										{(organization !==
										DB_ORG.CUSHMANWAKEFIELD
											? location.data.manager
											: location.data
													.cwFacilityAnalyst) ||
											'Not Assigned'}
									</td>
								) : null)()}
							{(() =>
								columnsShown.health ? (
									<td>
										<ProgressBar
											now={location.data.healthscore}
											max={100}
											min={0}
											variant={healthColor}
											className={'locationHealth'}
										/>
									</td>
								) : null)()}
						</tr>
					);
					locationRows = [...locationRows, newRow];
				}
				if (Math.ceil(totalLocationsNum / 10) == pageNum) {
					setShowingLocationsNum(totalLocationsNum);
				} else {
					setShowingLocationsNum(pageNum * locationRows.length);
				}
				setLocationsTable(
					<CardBody className='locations-table-body'>
						<Table
							responsive
							hover
							className='clickable locations-table'
						>
							<thead>
								<tr>
									<th style={{ width: '3%' }}>
										<input
											type='checkbox'
											checked={allChecked}
											onClick={checkAll}
										/>
									</th>
									{(() =>
										columnsShown.address ? (
											organization ==
											DB_ORG.CUSHMANWAKEFIELD ? (
												<th
													onClick={() =>
														sortLocations('name')
													}
													className={
														'is-bold-true text-start'
													}
													style={{ width: '30%' }}
												>
													Site Name
													{(() => {
														if (
															sortBy == 'name' &&
															sortAsc
														) {
															return '↑';
														} else if (
															sortBy == 'name'
														) {
															return '↓';
														}
													})()}
												</th>
											) : (
												<th
													onClick={() =>
														sortLocations('address')
													}
													className={
														'is-bold-true text-start'
													}
													style={{ width: '30%' }}
												>
													Address
													{(() => {
														if (
															sortBy ==
																'address' &&
															sortAsc
														) {
															return ' ↑';
														} else if (
															sortBy == 'address'
														) {
															return ' ↓';
														}
													})()}
												</th>
											)
										) : null)()}
									{(() =>
										columnsShown.city ? (
											<th
												onClick={() =>
													sortLocations('city')
												}
												className={
													'is-bold-true text-start'
												}
											>
												City
												{(() => {
													if (
														sortBy == 'city' &&
														sortAsc
													) {
														return ' ↑';
													} else if (
														sortBy == 'city'
													) {
														return ' ↓';
													}
												})()}
											</th>
										) : null)()}
									{(() =>
										columnsShown.state ? (
											<th
												onClick={() =>
													sortLocations('state')
												}
												className={'is-bold-true'}
												style={{ width: '7%' }}
											>
												State
												{(() => {
													if (
														sortBy == 'state' &&
														sortAsc
													) {
														return ' ↑';
													} else if (
														sortBy == 'state'
													) {
														return ' ↓';
													}
												})()}
											</th>
										) : null)()}
									{(() =>
										columnsShown.zip ? (
											<th
												onClick={() =>
													sortLocations('zip')
												}
												className={'is-bold-true'}
												style={{ width: '7%' }}
											>
												Zip
												{(() => {
													if (
														sortBy == 'zip' &&
														sortAsc
													) {
														return ' ↑';
													} else if (
														sortBy == 'zip'
													) {
														return ' ↓';
													}
												})()}
											</th>
										) : null)()}
									{(() =>
										columnsShown.submissionCount ? (
											<th
												onClick={() =>
													sortLocations(
														'submissionCount'
													)
												}
												className={'is-bold-true'}
											>
												Submissions
												{(() => {
													if (
														sortBy ==
															'submissionCount' &&
														sortAsc
													) {
														return ' ↑';
													} else if (
														sortBy ==
														'submissionCount'
													) {
														return ' ↓';
													}
												})()}
											</th>
										) : null)()}
									{(() =>
										columnsShown.manager ? (
											<th
												onClick={() =>
													sortLocations(
														organization !==
															DB_ORG.CUSHMANWAKEFIELD
															? 'manager'
															: 'cwFacilityAnalyst'
													)
												}
												className={'is-bold-true'}
												style={{ width: '15%' }}
											>
												{organization ===
												DB_ORG.CUSHMANWAKEFIELD
													? 'Site/Facility Analyst'
													: 'Manager'}
												{(() => {
													if (
														sortBy ==
															(organization !==
															DB_ORG.CUSHMANWAKEFIELD
																? 'manager'
																: 'cwFacilityAnalyst') &&
														sortAsc
													) {
														return ' ↑';
													} else if (
														sortBy ==
														(organization !==
														DB_ORG.CUSHMANWAKEFIELD
															? 'manager'
															: 'cwFacilityAnalyst')
													) {
														return ' ↓';
													}
												})()}
											</th>
										) : null)()}
									{(() =>
										columnsShown.health ? (
											<th
												onClick={() =>
													sortLocations('healthscore')
												}
												className={'is-bold-true'}
											>
												Location Health
												{(() => {
													if (
														sortBy ==
															'healthscore' &&
														sortAsc
													) {
														return ' ↑';
													} else if (
														sortBy == 'healthscore'
													) {
														return ' ↓';
													}
												})()}
											</th>
										) : null)()}
								</tr>
							</thead>
							<tbody>{locationRows}</tbody>
						</Table>
						<Row className='activities-page-buttons mt-2'>
							<Col
								style={{
									display: 'flex',
									flexDirection: 'row',
									alignItems: 'center',
									justifyContent: 'flex-start',
									fontSize: '15px',
								}}
							>
								Showing {showingLocationsNum} of{' '}
								{totalLocationsNum}
							</Col>
							<Col
								style={{
									display: 'flex',
									flexDirection: 'row',
									alignItems: 'center',
									justifyContent: 'flex-end',
								}}
							>
								<button
									className='px-2 border rounded-start fs-5'
									onClick={pageBackward}
								>
									<i className='bi bi-arrow-left-short' />
								</button>
								<div
									className='border-top border-bottom p-2 h-100'
									style={{ fontSize: '12px' }}
								>{`${pageNum} of ${Math.ceil(
									locations.length / 10
								)}`}</div>
								<button
									className='px-2 border rounded-end fs-5'
									onClick={pageForward}
								>
									<i className='bi bi-arrow-right-short' />
								</button>
							</Col>
						</Row>
					</CardBody>
				);
			} else if (searchVal.length > 0) {
				setLocationsTable(
					<CardBody>
						<div
							className='d-flex flex-column justify-content-center align-items-center w-100'
							style={{ minHeight: '400px' }}
						>
							<div>No search results. Try again.</div>
						</div>
					</CardBody>
				);
			} else {
				setLocationsTable(
					<CardBody>
						<div
							className='d-flex flex-column justify-content-center align-items-center w-100'
							style={{ minHeight: '400px' }}
						>
							<div>No locations to dipslay.</div>
						</div>
					</CardBody>
				);
			}
		}
		if (locationsChange) setLocationsChange(false);
		if (columnsShownChanges) setColumnsShownChanges(false);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		locationsChange,
		columnsShownChanges,
		allChecked,
		totalLocationsNum,
		checked,
	]);

	return (
		<div>
			<Card className='locations-card set-table-height mt-4'>
				<CardHeader className='gray-background'>
					<Row className='align-items-center'>
						<Col className='align-start'>
							<Form onSubmit={handleSearch}>
								<input
									id='search'
									className='searchbar rounded-pill'
									type='text'
									placeholder='Search'
									value={searchVal}
									onChange={(e) => {
										setSearchVal(e.target.value);
									}}
								/>
							</Form>
						</Col>
						<Col className='d-flex flex-row-reverse flex-md-row justify-content-between justify-content-md-end align-end'>
							<button className='filter-btn table-btn rounded-circle no-border'>
								<AiOutlineClose
									className={`filter-close ${
										showFilter && 'filter-close-expand'
									}`}
									onClick={toggleFilter}
								/>

								{!showFilter && (
									<BsThreeDotsVertical
										className='filter-dots'
										onClick={toggleFilter}
									/>
								)}

								{filter}
							</button>
							<button
								onClick={toggleDeleteModal}
								className='table-btn rounded-circle no-border'
								hidden={!canDelete()}
							>
								<IoMdTrash className='larger-font-icon larger-font' />
							</button>
							<button className='filter-btn table-btn rounded-circle no-border'>
								<MdDownload
									onClick={toggleExportModal}
									className='larger-font-icon larger-font'
								/>
							</button>
							<button
								className='table-btn rounded-circle no-border'
								hidden={!canAdd()}
								onClick={toggleAddLocationModal}
							>
								<MdAdd className='larger-font-icon larger-font' />
							</button>
							{/* <Button className='table-btn rounded-circle no-border'>
								<MdRefresh className='larger-font-icon larger-font' />
							</Button> */}
						</Col>
					</Row>
				</CardHeader>
				{locationsTable}
			</Card>
			<ConfirmDialog
				showConfirm={showDeleteModal}
				toggle={toggleDeleteModal}
				title='Confirm Deletion'
				body='Would you like to delete these rows?'
				functionality={deleteLocationsFromState}
			/>
			<ExportModal
				organization={organization}
				showExport={showExportModal}
				toggle={toggleExportModal}
				locations={allChecked ? undefined : checked}
			/>
			<AddLocationModal
				modalOpen={addLocationModal}
				toggle={toggleAddLocationModal}
				organization={organization}
				setIsToastShowing={setIsToastShowing}
				getAllLocations={getAllLocations}
			/>
		</div>
	);
};

export { LocationsTable };
