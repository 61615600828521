import { Card, CardHeader, Input, Label, FormGroup, Button } from 'reactstrap';
import { useEffect, useState } from 'react';
import { storage } from '@assets/services/auth-service';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
// import ExifReader from 'exifreader';
import { canEdit } from '@assets/services/user-role-service';
import { v4 as uuid } from 'uuid';
import { ConfirmDialog } from '@SignedIn/views/Locations/children/ConfirmDialog';
import Select from 'react-select';
import { CropperModal } from '@SignedIn/views/SingleForm/CropperModal';
import { isValidUrl } from '@utils/validation';
import { getForm, getFormDropdowns } from '@services/form-service';
import {
	addManufacturer,
	deleteWithRef,
	getManufacturers,
	updateWithRef,
} from '@services/organization-service';
import { addAsset, getFormAssetTypes } from '@services/asset-service';
import { addSubmission } from '@services/submission-service';

const SubmissionQAEdit = (props) => {
	const { parsedResponse, chosenRef, toggleEdit, updateParents } = props;

	// Show update modal state
	const [showUpdate, setShowUpdate] = useState(false);
	const toggleUpdate = () => setShowUpdate(!showUpdate);

	// Show revert modal state
	const [showReset, setShowReset] = useState(false);
	const toggleReset = () => setShowReset(!showReset);

	// Updated response data state (for rendering & also updating the DB record)
	const [updatedResponse, setUpdatedResponse] = useState();

	// Helper state that tracks changes for updatedResponse
	const [updatedResponseChanges, setUpdatedResponseChanges] = useState(false);

	// Form inputs data state
	const [inputs, setInputs] = useState();

	// Helper state that tracks changes for inputs state
	const [inputsChange, setInputsChange] = useState();

	// Dropdown array of object
	const [dropdown, setDropdown] = useState();

	// Helper state that tracks changes for inputs state
	const [dropdownChange, setDropdownChange] = useState(false);

	// Bool state for determining if form has conditional inputs
	const [isConditional, setIsConditional] = useState();

	// Rendered data (ie, child component) state
	const [submission, setSubmission] = useState();

	// Parent component state for submission child component state
	const [submissionParent, setSubmissionParent] = useState();

	const [isCropShowing, setIsCropShowing] = useState(false);

	const [photoToBeCropped, setPhotoToBeCropped] = useState(null);

	const [selectedItem, setSelectedItem] = useState(null);

	// Converts date field between formats
	const convertDateToISOString = (date) => {
		if (date) {
			const tempDate = new Date(date).toISOString().split('T');
			return tempDate[0];
		}
	};

	// Takes linear data from form & updates as needed, plus adds conditional inputs
	const filterLinearData = async (oldInputs, organization, formId) => {
		// First, add in missing conditional inputs to end of array, before image inputs
		const condInputs = await getConditionalInputs(
			organization,
			formId,
			parsedResponse.assetType
		);
		let condIndex;
		for (let i = 0; i < oldInputs.length; i++) {
			const input = oldInputs[i];
			if (input.length == undefined) {
				if (input.type == 'image') {
					condIndex = i;
					break;
				}
			}
		}
		const oldInputsWithCond = [
			...oldInputs.slice(0, condIndex),
			...condInputs,
			...oldInputs.slice(condIndex),
		];
		let newInputs = [];
		const newDropdown = {};
		for (let input of oldInputsWithCond) {
			if (input.length == undefined) {
				let responseTag = input.responseTag;
				// let response = parsedResponse[responseTag];
				// if (input.type == 'image' && isValidUrl(response)) {
				// 	const res = await fetch(response);
				// 	const blob = await res.blob();
				// 	const arrBuff = await blob.arrayBuffer();
				// 	const tags = ExifReader.load(arrBuff);
				// 	input.exif = tags;
				// }
				newInputs = [...newInputs, input];
				if (input.type == 'select') {
					let key = responseTag;
					let valueArr = [];
					let collectionName = input.collection;
					if (input.options == undefined) {
						let dropdownValSnap = await getFormDropdowns(
							organization,
							formId,
							collectionName
						);
						let objArr = [];
						dropdownValSnap.forEach((option) => {
							objArr.push(option.data());
						});
						let sortedObjArr = sortNumbers(objArr, 'order');
						for (let obj of sortedObjArr) {
							valueArr.push(obj.name);
						}
						newDropdown[key] = valueArr;
					}
				}
			} else {
				for (const subInput of input) {
					let responseTag = subInput.responseTag;
					if (subInput.type == 'select') {
						let key = responseTag;
						let valueArr = [];
						let collectionName = subInput.collection;
						if (subInput.options == undefined) {
							let dropdownValSnap;
							if (collectionName == 'manufacturers') {
								dropdownValSnap = await getManufacturers();
							} else {
								dropdownValSnap = await getFormDropdowns(
									organization,
									formId,
									collectionName
								);
							}
							let objArr = [];
							dropdownValSnap.forEach((option) => {
								objArr.push(option.data());
							});
							let sortedObjArr = sortNumbers(objArr, 'order');
							for (let obj of sortedObjArr) {
								valueArr.push(obj.name);
							}
							newDropdown[key] = valueArr;
						}
					}
				}
				newInputs = [...newInputs, ...input];
			}
		}
		await setDropdown(newDropdown);
		return newInputs;
	};

	const updateLinearData = async (assetType, oldResponse) => {
		// First, remove old conditional inputs from inputs and updatedResponse
		const oldInputs = [];
		for (const input of inputs) {
			if (input.conditional) delete oldResponse[input.responseTag];
			else oldInputs.push(input);
		}
		// Second, get new conditional inputs and add to oldInputs arr
		const condInputs = await getConditionalInputs(
			updatedResponse.organization,
			updatedResponse.formId,
			assetType
		);
		let condIndex;
		for (let i = 0; i < oldInputs.length; i++) {
			const input = oldInputs[i];
			if (input.length == undefined) {
				if (input.type == 'image') {
					condIndex = i;
					break;
				}
			}
		}
		const updatedCondInputs = [];
		let newDropdown = await JSON.parse(JSON.stringify(dropdown));
		for (let input of condInputs) {
			if (input.length == undefined) {
				let responseTag = input.responseTag;
				// let response = updatedResponse[responseTag];
				// if (input.type == 'image' && isValidUrl(response)) {
				// 	const res = await fetch(response);
				// 	const blob = await res.blob();
				// 	const arrBuff = await blob.arrayBuffer();
				// 	const tags = ExifReader.load(arrBuff);
				// 	input.exif = tags;
				// }
				updatedCondInputs.push(input);
				if (input.type == 'select') {
					let key = responseTag;
					let valueArr = [];
					let collectionName = input.collection;
					if (input.options == undefined) {
						let dropdownValSnap = await getFormDropdowns(
							updatedResponse.organization,
							updatedResponse.formId,
							collectionName
						);
						let objArr = [];
						dropdownValSnap.forEach((option) => {
							objArr.push(option.data());
						});
						let sortedObjArr = sortNumbers(objArr, 'order');
						for (let obj of sortedObjArr) {
							valueArr.push(obj.name);
						}
						newDropdown[key] = valueArr;
					}
				}
			} else {
				updatedCondInputs.push(input);
			}
		}
		const newInputs = [
			...oldInputs.slice(0, condIndex),
			...updatedCondInputs,
			...oldInputs.slice(condIndex),
		];
		return {
			inputs: newInputs,
			response: oldResponse,
			dropdown: newDropdown,
		};
	};

	// Retrieves conditional inputs from form's asset types
	const getConditionalInputs = async (organization, formId, assetType) => {
		let condInputs = [];
		const assetTypesQuery = await getFormAssetTypes(
			organization,
			formId,
			assetType
		);

		if (!assetTypesQuery.empty) {
			const JSONtypes = assetTypesQuery.docs[0].data().inputs || [];
			for (const JSONtype of JSONtypes) {
				const type = JSON.parse(JSONtype);
				if (type.length == undefined) {
					type.conditional = true;
					condInputs.push(type);
				} else {
					for (const item of type) {
						item.conditional = true;
						condInputs.push(item);
					}
				}
			}
		}
		return condInputs;
	};

	// Sorts numbers from least to greatest
	const sortNumbers = (array, key) => {
		return array.sort((a, b) => Number(a[key]) - Number(b[key]));
	};

	// Updates updatedResponse state for linear submission response values
	// EG, key:val pairs; IE, non-Cushman
	const updateNewResponse = async (key, value) => {
		setUpdatedResponseChanges(false);
		const updRes = await JSON.parse(JSON.stringify(updatedResponse));
		if (value === '' && parsedResponse[key] == undefined) {
			delete updRes[key];
		} else {
			updRes[key] = value;
		}
		if (key == 'assetType' && isConditional) {
			const data = await updateLinearData(value, updRes);
			await setInputs(data.inputs);
			await setUpdatedResponse(data.response);
			await setDropdown(data.dropdown);
			await setInputsChange(true);
			await setDropdownChange(true);
		} else {
			await setUpdatedResponse(updRes);
		}
		setUpdatedResponseChanges(true);
	};

	// Updates image in updatedResponse state for linear submission response values
	// EG, key:val pairs; IE, non-cushman
	const uploadNewImage = async (key, file) => {
		setUpdatedResponseChanges(false);
		let reference = ref(
			storage,
			'FieldCaptures/' + new Date().toISOString() + '.png'
		);
		try {
			await uploadBytes(reference, file);
			const url = await getDownloadURL(reference);
			await updateNewResponse(key, url);
			setUpdatedResponseChanges(true);
		} catch (error) {
			return;
		}
	};

	// Updates response data in the db
	const updateDb = async () => {
		try {
			const newResponse = updatedResponse;
			// First, handle "other" manufacturer, if needed
			if (newResponse.ifOtherManufacturer) {
				newResponse.manufacturer =
					newResponse.ifOtherManufacturer.toUpperCase();
				delete newResponse.ifOtherManufacturer;
				const manufacturersSnap = await getManufacturers(
					newResponse.manufacturer
				);
				if (manufacturersSnap.empty) {
					await addManufacturer(newResponse.manufacturer);
				}
			}
			// Second, add submission & store ref
			const submissionRef = await addSubmission(
				newResponse.organization,
				newResponse.formId,
				newResponse
			);
			// Third, add asset & store ref
			const asset = {
				responseRef: submissionRef,
				assetType: newResponse.assetType,
				make: newResponse.manufacturer || null,
				model: newResponse.modelNumber || null,
				serial: newResponse.serialNumber || null,
				dateOfBirth: newResponse.manufacturerDate || null,
				submittingUser: newResponse.submittingUser,
				locationId: newResponse.location.id,
				other: newResponse,
				deleted: false,
			};
			const assetRef = await addAsset(parsedResponse.organization, asset);
			// Fourth, update submission with asset ref
			await updateWithRef(submissionRef, { assetRef: assetRef });
			// Fifth, delete submission from QA collection
			await deleteWithRef(chosenRef);
			// Sixth, update the front-end
			await toggleUpdate();
			updateParents();
		} catch (error) {
			return;
		}
	};

	// Resets updatedResponse data in the db
	const resetSubmission = async () => {
		toggleReset();
		setUpdatedResponse(parsedResponse);
		setUpdatedResponseChanges(true);
		toggleEdit();
	};

	const getCroppedPhoto = async (data) => {
		setIsCropShowing(false);
		const res = await fetch(data);
		const blob = await res.blob();
		const file = new File([blob], 'New Image', { type: 'image/png' });
		uploadNewImage(selectedItem, file);

		setPhotoToBeCropped(null);
	};

	const getData = async () => {
		// First, set updatedResponse to parsedResponse
		let newObj = await JSON.parse(JSON.stringify(parsedResponse));
		await setUpdatedResponse(newObj);
		let newInputs;
		// DB calls to get form data
		const formId = parsedResponse.formId;
		const organization = parsedResponse.organization;
		const formSnap = await getForm(organization, formId);
		// Declare if form contains conditional inputs
		setIsConditional(formSnap.data().conditionalInputs);
		// Iterate through & modify the data a specific way before assigning to the inputs state
		let linearInputs = formSnap.data().inputs.pages[0].Details;
		for (let x = 0; x < linearInputs.length; x++) {
			let input = JSON.parse(linearInputs[x]);
			linearInputs[x] = input;
		}
		newInputs = await filterLinearData(linearInputs, organization, formId);
		await setInputs(newInputs);
		setInputsChange(true);
	};

	const renderSubmission = async () => {
		let lineItems = [];
		let index = 0;
		let firstLine;
		const secondLine = (
			<FormGroup key={index} className='p-3'>
				<Label className='fw-bolder pt-0'>Date of Visit</Label>
				<Input
					disabled
					type='date'
					value={convertDateToISOString(
						updatedResponse.dateOfVisit ||
							updatedResponse.submittedDate
					)}
				/>
			</FormGroup>
		);
		index++;

		let address = '';
		if (updatedResponse.location.address) {
			address = updatedResponse.location.address;
		}
		if (updatedResponse.location.address1) {
			address = address + ' ' + updatedResponse.location.address1;
		}
		if (updatedResponse.location.address2) {
			address = address + ' ' + updatedResponse.location.address2;
		}
		const thirdLine = (
			<FormGroup key={index} className='p-3'>
				<Label className='fw-bolder pt-0'>Location</Label>
				<Input
					disabled
					type='text'
					value={
						address +
						', ' +
						updatedResponse.location.city +
						', ' +
						updatedResponse.location.state +
						' ' +
						updatedResponse.location.zip
					}
				/>
			</FormGroup>
		);
		index++;

		for (let input of inputs) {
			if (input.responseTag == 'location') {
				continue;
			}
			let formKey = input.responseTag;
			if (formKey == 'assetQuality') {
				formKey = formKey + input[0].responseTag;
			}
			let label = input.label;
			let response = updatedResponse[formKey];

			let newRow;
			if (isValidUrl(response)) {
				newRow = (
					<FormGroup key={index} className='p-3 d-flex flex-column'>
						<Label className='submission-label'>{label}</Label>
						<div className='position-relative submitted-image-parent'>
							<img
								className='submitted-image'
								alt=''
								src={response}
							/>
							<Input
								className='position-absolute top-0 h-100 opacity-0'
								type='file'
								id={index + 'image'}
								accept='image/*'
								onChange={(e) => {
									uploadNewImage(formKey, e.target.files[0]);
								}}
							/>
							<span className='button-array'>
								<Button
									onClick={() => {
										setSelectedItem(formKey);
										setPhotoToBeCropped(response);
									}}
								>
									Edit
								</Button>
								<label
									className='image-btn'
									htmlFor={index + 'image'}
								>
									<span className='btn'>Replace</span>
								</label>
								<Button
									color='danger'
									onClick={async () => {
										await updateNewResponse(formKey, '');
									}}
								>
									Remove
								</Button>
							</span>
						</div>
					</FormGroup>
				);
			} else if (input.type == 'image') {
				newRow = (
					<FormGroup key={index} className='p-3 d-flex flex-column'>
						<Label className='submission-label'>{label}</Label>
						<div className='position-relative'>
							<Input
								disabled
								className='bg-white'
								type='text'
								value={response}
							/>
							<Input
								className='position-absolute top-0 h-100 opacity-0'
								type='file'
								accept='image/*'
								onChange={(e) =>
									uploadNewImage(formKey, e.target.files[0])
								}
							/>
						</div>
					</FormGroup>
				);
			} else if (input.type == 'checkbox') {
				const checked = response || false;
				newRow = (
					<FormGroup key={index} className='p-3'>
						<Label className='submission-label'>{label}</Label>
						<Input
							className=''
							type='checkbox'
							defaultChecked={checked}
							onChange={(e) => {
								updateNewResponse(formKey, e.target.checked);
							}}
						/>
					</FormGroup>
				);
			} else if (input.responseTag == 'manufacturer') {
				let options;
				if (input.collection != undefined) {
					options = dropdown[input.responseTag]
						.sort()
						.map((option) => {
							return { label: option, value: option };
						});
				} else {
					options = input.options.sort().map((option) => {
						return { label: option, value: option };
					});
				}
				newRow = (
					<FormGroup key={index} className='p-3'>
						<Label className='submission-label'>{label}</Label>
						<Select
							options={options}
							defaultValue={{
								label: response,
								value: response,
							}}
							onChange={(e) => {
								updateNewResponse(formKey, e.value);
							}}
							isDisabled={
								input.tiedTo
									? updatedResponse[input.tiedTo] === true
									: false
							}
						/>
					</FormGroup>
				);
			} else if (input.type == 'select') {
				newRow = (
					<FormGroup key={index} className='p-3'>
						<Label className='submission-label'>{label}</Label>
						<Input
							className='bg-white'
							type='select'
							onChange={(e) => {
								updateNewResponse(formKey, e.target.value);
							}}
						>
							<option
								value=''
								key={uuid()}
								selected={response == ''}
								disabled
							/>
							{input.collection != undefined
								? dropdown[input.responseTag]
										.sort()
										.map((option) => {
											return (
												<option
													value={option}
													key={uuid()}
													selected={
														response == option
													}
												>
													{option}
												</option>
											);
										})
								: input.options.sort().map((option) => {
										return (
											<option
												value={option}
												key={uuid()}
												selected={response == option}
											>
												{option}
											</option>
										);
								  })}
						</Input>
					</FormGroup>
				);
			} else {
				newRow = (
					<FormGroup
						key={index}
						className='p-3'
						hidden={
							(input.responseTag == 'ifOther' &&
								updatedResponse['assetType'] !== 'Other') ||
							(input.responseTag == 'ifOtherManufacturer' &&
								(updatedResponse['manufacturer'] !==
									'(other)' ||
									updatedResponse['makeNotAvailable'] ==
										true))
						}
					>
						<Label className='submission-label'>{label}</Label>
						<Input
							className='bg-white'
							type='text'
							defaultValue={response}
							onChange={(e) => {
								updateNewResponse(formKey, e.target.value);
							}}
						/>
					</FormGroup>
				);
			}
			lineItems = [...lineItems, newRow];
			index++;
		}

		lineItems = await Promise.all(lineItems);
		const component = (
			<div id='activities-tab-form-submission'>
				{firstLine}
				{secondLine}
				{thirdLine}
				{lineItems}
			</div>
		);
		setSubmission(component);
	};

	useEffect(() => {
		if (photoToBeCropped) setIsCropShowing(true);
	}, [photoToBeCropped]);

	// Update inputs and updatedResponse states when parsedResponse state changes
	useEffect(() => {
		if (parsedResponse != null) {
			getData();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [parsedResponse]);

	// Update submission state when inputs or dropdown state is initialized
	useEffect(() => {
		if (inputs != null && updatedResponse != null && inputsChange) {
			renderSubmission();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [inputs, dropdown]);

	// Update submission state when inputs or dropdown state changes
	useEffect(() => {
		if (
			inputs != null &&
			updatedResponse != null &&
			(inputsChange || dropdownChange)
		) {
			renderSubmission();
			if (inputsChange) setInputsChange(false);
			if (dropdownChange) setDropdownChange(false);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [inputsChange, dropdownChange]);

	// Update submission state when updatedResponseChanges state changes
	useEffect(() => {
		if (
			inputs != null &&
			updatedResponse != null &&
			updatedResponseChanges
		) {
			renderSubmission();
			setUpdatedResponseChanges(false);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [updatedResponseChanges]);

	// Update submissionParent state when submission state changes
	useEffect(() => {
		if (submission != null) {
			setSubmissionParent(
				<Card className='p-3' id='submission'>
					<div className='submission-header d-flex flex-row align-items-center justify-content-between'>
						<div
							className='activities-page-header-buttons d-flex p-1 toggleSubmissionwidth-fit-content'
							onClick={toggleReset}
						>
							<i className='bi bi-arrow-left me-3'></i>
							<div>Undo Changes & Leave in QA</div>
						</div>
						<div className='d-flex flex-row'>
							<Button
								color='success'
								disabled={!canEdit()}
								onClick={toggleUpdate}
							>
								Submit
							</Button>
						</div>
					</div>
					<img
						src={parsedResponse.logo}
						className='submission-logo'
						alt='logo'
					/>
					<CardHeader
						className='d-flex flex-row align-items-center justify-content-between'
						style={{ backgroundColor: '#ffffff', border: 'none' }}
					>
						<div className='submission-title'>
							{`QA'ing ${parsedResponse.formName}`}
						</div>
					</CardHeader>
					{submission}
					<ConfirmDialog
						showConfirm={showReset}
						toggle={toggleReset}
						title='Confirm Reset'
						body='Would you like to reset the changes made to this submission and leave it in QA?'
						functionality={resetSubmission}
					/>
					<ConfirmDialog
						showConfirm={showUpdate}
						toggle={toggleUpdate}
						title='Confirm Update'
						body='Would you like to take this submission out of QA and officially add it to the database?'
						functionality={updateDb}
					/>
					<CropperModal
						showing={isCropShowing}
						photoUrl={photoToBeCropped}
						onCrop={(data) => getCroppedPhoto(data)}
						cancel={() => {
							setIsCropShowing(false);
							setPhotoToBeCropped(null);
						}}
					/>
				</Card>
			);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [submission, showReset, showUpdate, parsedResponse, isCropShowing]);

	return submissionParent;
};

export { SubmissionQAEdit };
