const searchAssets = async (
	val,
	list,
	displayAddress,
	org,
	pageNum,
	pageSize,
	sort,
	location,
	startDate,
	endDate,
	decommissioned
) => {
	let firebaseConfig = JSON.parse(process.env.REACT_APP_FIREBASE_CONFIG);
	const response = await fetch(
		firebaseConfig.projectId.includes('staging')
			? 'https://us-central1-property-echo-staging.cloudfunctions.net/search'
			: 'https://us-central1-property-echo-4d747.cloudfunctions.net/search',
		{
			method: 'POST',
			body: JSON.stringify({
				type: 'assets',
				query: val,
				org,
				displayAddress,
				isProd: !firebaseConfig.projectId.includes('staging'),
				pageSize,
				pageNum,
				sort,
				location,
				startDate,
				endDate,
				decommissioned,
			}),
		}
	);
	const responsejson = await response.json();
	if (responsejson.hits) {
		return { results: responsejson.hits, count: responsejson.nbHits };
	}
};

export { searchAssets };
