import { addLocation } from '@services/location-service';
import { useEffect, useRef, useState } from 'react';
import {
	Button,
	Label,
	Modal,
	ModalBody,
	ModalFooter,
	ModalHeader,
} from 'reactstrap';
import { ADDRESS_VALIDATION_API_URL } from '@constants/api';
import { getOrganization } from '@services/organization-service';
import { collection, getDocs, query, where } from 'firebase/firestore';
import { db } from '@assets/services/auth-service';

const AddLocationModal = ({
	modalOpen,
	toggle,
	organization,
	setIsToastShowing,
	getAllLocations,
}) => {
	const [isInputLoaded, setIsInputLoaded] = useState(false);
	const autocompleteInput = useRef(null);
	const [autocomplete, setAutocomplete] = useState(null);
	const [inputs, setInputs] = useState([
		{
			label: 'Location Name',
			responseTag: 'name',
			required: false,
		},
		{
			label: 'Address 1',
			responseTag: 'address1',
			required: true,
		},
		{
			label: 'Address 2',
			responseTag: 'address2',
			id: 'address2',
			required: false,
		},
		{ label: 'State', responseTag: 'state', id: 'state', required: true },
		{ label: 'City', responseTag: 'city', id: 'city', required: true },
		{ label: 'Zip Code', responseTag: 'zip', id: 'zip', required: true },
	]);
	const [responseData, setResponseData] = useState({});
	const [error, setError] = useState('');

	const toggleModal = () => {
		toggle();
		setResponseData({});
		setError('');
	};

	const getIsLocationNameRequired = async () => {
		const orgSnap = await getOrganization(organization);
		const isLocationNameRequired = orgSnap.data().isLocationNameRequired
			? true
			: false;
		setInputs([
			{
				label: 'Location Name',
				responseTag: 'name',
				required: isLocationNameRequired,
			},
			{
				label: 'Address 1',
				responseTag: 'address1',
				required: true,
			},
			{
				label: 'Address 2',
				responseTag: 'address2',
				id: 'address2',
				required: false,
			},
			{
				label: 'State',
				responseTag: 'state',
				id: 'state',
				required: true,
			},
			{ label: 'City', responseTag: 'city', id: 'city', required: true },
			{
				label: 'Zip Code',
				responseTag: 'zip',
				id: 'zip',
				required: true,
			},
		]);
	};

	const handleChange = (responseTag, val) => {
		let response = responseData;
		response[responseTag] = val;
		setResponseData(response);
	};
	const handlePlaceChange = () => {
		if (modalOpen && autocompleteInput.current && !autocomplete) {
			const newAutocomplete = new window.google.maps.places.Autocomplete(
				autocompleteInput.current,
				{
					types: ['address'],
					componentRestrictions: { country: 'us' },
					fields: ['address_components', 'geometry'],
				}
			);

			const onPlaceChanged = () => {
				const place = newAutocomplete.getPlace();

				if (!place.geometry) {
					window.alert(
						"No details available for input: '" + place.name + "'"
					);
					return;
				}
				const address1Field = document.querySelector('#address1');
				const address2Field = document.querySelector('#address2');
				const city = document.querySelector('#city');
				const state = document.querySelector('#state');
				const zipField = document.querySelector('#zip');

				const addressObj = {};
				let address1 = '';
				let zip = '';
				for (const component of place.address_components) {
					const componentType = component.types[0];
					switch (componentType) {
						case 'street_number': {
							address1 = `${component.long_name} ${address1}`;
							break;
						}
						case 'subpremise': {
							addressObj.address2 = `${component.long_name}`;
							address2Field.value = `${component.long_name}`;
							break;
						}
						case 'route': {
							address1 += component.long_name;
							addressObj.address1 = address1;
							break;
						}
						case 'postal_code': {
							zip = `${component.long_name}${zip}`;
							addressObj.zip = zip;
							break;
						}
						case 'locality':
							city.value = component.long_name;
							addressObj.city = component.long_name;
							break;
						case 'administrative_area_level_1': {
							state.value = component.short_name;
							addressObj.state = component.short_name;
							break;
						}
					}
				}
				if (responseData.name) {
					addressObj.name = responseData.name;
				}
				setResponseData(addressObj);
				address1Field.value = address1;
				zipField.value = zip;
			};

			newAutocomplete.addListener('place_changed', onPlaceChanged);
			setAutocomplete(newAutocomplete);
		} else if (autocomplete) {
			window.google.maps.event.clearInstanceListeners(autocomplete);
			setAutocomplete(null);
			return;
		}
	};

	const checkForEmptyFields = () => {
		const missingInputs = [];
		for (const input of inputs) {
			if (!responseData[input.responseTag] && input.required) {
				missingInputs.push(input.label);
			}
		}

		if (missingInputs.length > 0) {
			setError(
				<>
					<span className='error-text fw-bold'>
						Missing required fields:
					</span>
					<div className='d-flex flex-column error-text'>
						{missingInputs.map((input) => (
							<span key={input}>{input}</span>
						))}
					</div>
				</>
			);
			return true;
		}
		setError(null);
		return false;
	};

	async function getValidationResponse() {
		const url =
			ADDRESS_VALIDATION_API_URL + process.env.ADDRESS_VALIDATION_API_KEY;
		let addressLines = responseData.address1;
		if (responseData.address2) addressLines += ' ' + responseData.address2;

		const body = JSON.stringify({
			address: {
				regionCode: 'US',
				locality: responseData.city,
				administrativeArea: responseData.state,
				postalCode: responseData.zip,
				addressLines: [addressLines],
			},
			previousResponseId: '',
			enableUspsCass: false,
		});

		const options = {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: body,
		};

		try {
			const response = await fetch(url, options);
			if (!response.ok) {
				throw new Error('HTTP error ' + response.status);
			}
			const data = await response.json();
			return data;
		} catch (error) {
			console.log('Fetch error: ' + error.message);
			return false;
		}
	}

	const isDuplicate = async (googleAddress) => {
		const locationsRef = collection(
			db,
			'organizations',
			organization,
			'locations'
		);
		const q = query(
			locationsRef,
			where('googleAddress', '==', googleAddress)
		);
		const snap = await getDocs(q);
		return !snap.empty;
	};

	const addLocationToDbAndCloseModal = async (address) => {
		if (responseData.name) address.name = responseData.name;
		await addLocation(organization, {
			...address,
			submissionCount: 0,
			deleted: false,
		});
		setIsToastShowing({
			showing: true,
			title: 'Success',
			message: 'Location has been added',
			type: 'success',
			position: 'bottom',
		});
		setTimeout(() => {
			setIsToastShowing({
				showing: false,
			});
		}, 2500);
		getAllLocations();
		toggleModal();
	};

	const validateAddress = async () => {
		const emptyFields = checkForEmptyFields();
		if (emptyFields) {
			return false;
		}

		const validationResponse = await getValidationResponse(responseData);
		console.log(validationResponse);

		const { formattedAddress, addressComponents, missingComponentTypes } =
			validationResponse.result.address;
		const {
			addressComplete,
			hasReplacedComponents,
			hasInferredComponents,
			hasUnconfirmedComponents,
		} = validationResponse.result.verdict;

		if (missingComponentTypes) {
			setError(
				<div className='d-flex flex-column'>
					<span className='error-text fw-bold'>
						Address could not be validated. Please enter additional
						information for:
					</span>
					{missingComponentTypes.map((component) => (
						<span key={component} className='error-text fw-bold'>
							{convertComponentTypeToLabel(component)}
						</span>
					))}
				</div>
			);
			return false;
		} else if (hasUnconfirmedComponents) {
			let invalidComponents = [];
			for (const component of addressComponents) {
				const confirmationLevel = component.confirmationLevel;
				if (confirmationLevel == 'UNCONFIRMED_AND_SUSPICIOUS') {
					invalidComponents.push(component.componentType);
				}
			}

			if (invalidComponents.length > 0) {
				setError(
					<div className='d-flex flex-column'>
						<span className='error-text fw-bold'>
							The following fields could not be validated:
						</span>
						{invalidComponents.map((component) => (
							<span
								key={component}
								className='error-text fw-bold'
							>
								{convertComponentTypeToLabel(component)}
							</span>
						))}
					</div>
				);
				return false;
			} else if (addressComplete) {
				const duplicate = await isDuplicate(formattedAddress);
				if (duplicate) {
					setError(
						<span className='error-text fw-bold'>
							Duplicate address detected. Please enter a unique
							address.
						</span>
					);
					return false;
				}
				await addLocationToDbAndCloseModal({
					...responseData,
					googleAddress: formattedAddress,
				});
			}
		} else if (hasInferredComponents || hasReplacedComponents) {
			setError(
				<div className='d-flex flex-column'>
					<h6 className='fw-bold mb-2'>Did you mean:</h6>
					<span>{formattedAddress}?</span>
					<Button
						color='success'
						className='mb-2'
						onClick={async () => {
							const duplicate = await isDuplicate(
								formattedAddress
							);
							if (duplicate) {
								setError(
									<span className='error-text fw-bold'>
										Duplicate address detected. Please enter
										a unique address.
									</span>
								);
								return false;
							}
							const addressComponents =
								validationResponse.result.address
									.addressComponents;
							const address = {};
							let address1 = '';
							for (const component of addressComponents) {
								const componentType = component.componentType;
								switch (componentType) {
									case 'street_number': {
										address1 = `${component.componentName.text} ${address1}`;
										break;
									}
									case 'subpremise': {
										address.address2 = `${component.componentName.text}`;
										break;
									}
									case 'route': {
										address1 +=
											component.componentName.text;
										address.address1 = address1;
										break;
									}
									case 'postal_code': {
										address.zip =
											component.componentName.text;
										break;
									}
									case 'postal_code_suffix': {
										address.zipSuffix = `${component.componentName.text}`;
										break;
									}
									case 'locality':
										address.city =
											component.componentName.text;
										break;
									case 'administrative_area_level_1': {
										address.state =
											component.componentName.text;
										break;
									}
								}
							}
							address.googleAddress = formattedAddress;
							await addLocationToDbAndCloseModal(address);
						}}
					>
						Yes
					</Button>
					<Button
						onClick={() => {
							setError(
								<span className='error-text fw-bold'>
									Please enter a valid address.
								</span>
							);
						}}
						color='secondary'
					>
						No
					</Button>
				</div>
			);
			return false;
		} else if (addressComplete) {
			const duplicate = await isDuplicate(formattedAddress);
			if (duplicate) {
				setError(
					<span className='error-text fw-bold'>
						Duplicate address detected. Please enter a unique
						address.
					</span>
				);
				return false;
			}
			await addLocationToDbAndCloseModal({
				...responseData,
				googleAddress: formattedAddress,
			});
		} else {
			setError(
				<span className='error-text fw-bold'>
					Address could not be validated. Please check for any errors
					try again.
				</span>
			);
			return false;
		}
	};

	const convertComponentTypeToLabel = (type) => {
		switch (type) {
			case 'street_number': {
				return 'Street Number';
			}
			case 'subpremise': {
				return 'Address 2';
			}
			case 'route': {
				return 'Address 1';
			}
			case 'postal_code': {
				return 'Zip Code';
			}
			case 'locality':
				return 'City';
			case 'administrative_area_level_1': {
				return 'State';
			}
		}
	};

	const submit = async () => {
		const addressValidated = await validateAddress();
		if (addressValidated) {
			await addLocationToDbAndCloseModal();
		}
	};

	useEffect(() => {
		getIsLocationNameRequired();
	}, []);

	useEffect(() => {
		handlePlaceChange();
	}, [isInputLoaded]);

	useEffect(() => {
		setIsInputLoaded(modalOpen ? true : false);
	}, [modalOpen]);

	return (
		<Modal isOpen={modalOpen} toggle={toggle} centered>
			<ModalHeader toggle={toggleModal}>Add Location</ModalHeader>
			<ModalBody>
				{inputs.map((input) => {
					if (input.responseTag == 'address1') {
						return (
							<div className='my-2' key={input.responseTag}>
								<Label>Address</Label>
								<span style={{ color: 'red' }}> *</span>
								<input
									className='input-styling'
									onChange={(e) =>
										handleChange('address1', e.target.value)
									}
									ref={autocompleteInput}
									id='address1'
								/>
							</div>
						);
					} else {
						return (
							<div
								key={input.responseTag}
								className='d-flex flex-column my-2'
							>
								<div>
									<Label>{input.label}</Label>
									{input.required && (
										<span style={{ color: 'red' }}> *</span>
									)}
								</div>
								<input
									id={input.id}
									onChange={(e) =>
										handleChange(
											input.responseTag,
											e.target.value
										)
									}
									className='input-styling'
								/>
							</div>
						);
					}
				})}
				{error}
			</ModalBody>
			<ModalFooter>
				<Button color='success' onClick={submit}>
					Add
				</Button>{' '}
				<Button color='secondary' onClick={toggleModal}>
					Cancel
				</Button>
			</ModalFooter>
		</Modal>
	);
};

export { AddLocationModal };
