import { auth, storage } from '@assets/services/auth-service';
import { useEffect, useState } from 'react';
import { getDownloadURL, ref } from '@firebase/storage';
import { getUser } from '@services/user-service';

const Avatar = (props) => {
	const { alt, className } = props;

	// Image source state
	const [img, setImg] = useState();

	// Avatar component state
	const [avatar, setAvatar] = useState();

	// Updates the img state with an image link from firestore
	const updateImg = async () => {
		const id = auth.currentUser.uid;
		const userDoc = await getUser(id);
		const imgPath = userDoc.image || 'ProfilePics/default-profile-pic.png';
		const imgRef = ref(storage, imgPath);
		const url = await getDownloadURL(imgRef);
		setImg(url);
	};

	// Update img state upon init render
	useEffect(() => {
		updateImg();
	}, []);

	// Update avatar state when img state changes
	useEffect(() => {
		if (img != null) {
			setAvatar(<img src={img} alt={alt} className={className} />);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [img]);

	return avatar;
};

export { Avatar };
