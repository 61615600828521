import { Collapse } from 'reactstrap';
import { NavLink } from 'react-router-dom';
import { IoMenu, IoPerson } from 'react-icons/io5';
import { AiOutlineBank, AiOutlineForm } from 'react-icons/ai';
import { Component } from 'react';
import logo from '@assets/img/PE-logo-clear-one-line.png';
import { AuthProviders } from '@assets/services/auth-service';
import { GoLocation } from 'react-icons/go';
import { RiAdminLine } from 'react-icons/ri';
import { Alerts } from './children/Alerts';
import { GiHamburgerMenu } from 'react-icons/gi';

class Navbar extends Component {
	constructor(props) {
		super(props);
		this.firebaseConfig = JSON.parse(process.env.REACT_APP_FIREBASE_CONFIG);
		this.setIsToastShowing = props.setIsToastShowing;
		this.isStaging = this.firebaseConfig.projectId.includes('staging');
		this.toggle = this.toggle.bind(this);
		this.toggleMenu = this.toggleMenu.bind(this);
		this.state = {
			dropdownOpen: false,
			sidemenuOpen: false,
			chatbotOpen: false,
		};
		this.toggleChatbot = props.toggleChatbot;
		this.setToggleChatbot = props.setToggleChatbot;
		this.toggleAlfred = this.toggleAlfred.bind(this);
		this.toggleSidebar = this.toggleSidebar.bind(this);
	}

	toggle() {
		this.setState((prevState) => ({
			dropdownOpen: !prevState.dropdownOpen,
		}));
	}

	toggleMenu() {
		this.setState({ sidemenuOpen: !this.state.sidemenuOpen });
	}

	toggleAlfred() {
		const { toggleChatbot, setToggleChatbot } = this.props;
		setToggleChatbot(!toggleChatbot);
	}

	toggleSidebar() {
		const { isSidebarOpen, setIsSidebarOpen } = this.props;
		setIsSidebarOpen(!isSidebarOpen);
	}

	///////////////////////////////////////////////////////////////////////
	render() {
		let motto;
		if (window.location.pathname == '/') {
			motto = (
				<div className='navbar-motto mb-4'>
					Where Your Assets Live.{' '}
				</div>
			);
		} else {
			motto = null;
		}

		return (
			<>
				<div className='topbar navbar-wrapper d-flex flex-column flex-md-row w-100 justify-content-between align-items-center mb-3'>
					<div className='navbar-left position-relative d-flex flex-column align-items-end justify-content-md-start'>
						<div
							className='toggle-button'
							onClick={this.toggleSidebar}
						>
							<GiHamburgerMenu />
						</div>
						<NavLink to='/' className='logo-anchor'>
							<img className='title p-0' src={logo} alt='logo' />
							<div>{motto}</div>
						</NavLink>
						<div className='motto px-2'>
							Where Your Assets Live.
						</div>
					</div>
					<div className='navbar-right d-flex justify-content-center align-items-center justify-content-md-start'>
						<Alerts />
						<button
							className='toggle-alfred rounded-xl'
							onClick={this.toggleAlfred}
						>
							Ask FacilityAI
						</button>
						<button onClick={this.toggleMenu} className='nav-menu'>
							<span className='navbar-mini-icon'>
								<span className='no-attributes'>
									<IoMenu />
								</span>
							</span>
						</button>
					</div>
					<Collapse
						isOpen={this.state.sidemenuOpen}
						className='nav-menu'
					>
						<div className='nav-menu'>
							<ol className='no-padding'>
								<li
									className='navbar-normal'
									onClick={this.toggleMenu}
								>
									<NavLink
										to='/'
										className={(isActive) =>
											isActive.isActive
												? 'active'
												: 'inactive'
										}
									>
										<span className='navbar-mini-icon'>
											<AiOutlineBank />
										</span>
										<span>Dashboard</span>
									</NavLink>
								</li>
								<li
									className='navbar-normal'
									onClick={this.toggleMenu}
								>
									<NavLink
										to='/profile'
										className={(isActive) =>
											isActive.isActive
												? 'active'
												: 'inactive'
										}
									>
										<span className='navbar-mini-icon'>
											<IoPerson />
										</span>
										<span>My Profile</span>
									</NavLink>
								</li>
								<li
									className='navbar-normal'
									onClick={this.toggleMenu}
								>
									<NavLink
										to='/locations'
										className={(isActive) =>
											isActive.isActive
												? 'active'
												: 'inactive'
										}
									>
										<span className='navbar-mini-icon'>
											<GoLocation />
										</span>
										<span>Locations</span>
									</NavLink>
								</li>
								<li
									className='navbar-normal'
									onClick={this.toggleMenu}
								>
									<NavLink
										to='/forms'
										className={(isActive) =>
											isActive.isActive
												? 'active'
												: 'inactive'
										}
									>
										<span className='navbar-mini-icon'>
											<AiOutlineForm />
										</span>
										<span>Property Reports</span>
									</NavLink>
								</li>
								<li className='navbar-normal'>
									<NavLink
										to='/admin'
										className={(isActive) =>
											isActive.isActive
												? 'active'
												: 'inactive'
										}
									>
										<span className='navbar-mini-icon'>
											<RiAdminLine />
										</span>
										<span>Administration</span>
									</NavLink>
								</li>
								<li className='navbar-normal'>
									<NavLink
										to='/'
										className='inactive'
										onClick={AuthProviders.logout}
									>
										<span className='sidebar-normal'>
											Log Out
										</span>
									</NavLink>
								</li>
							</ol>
						</div>
					</Collapse>
				</div>
				<div
					hidden={!this.isStaging}
					style={{
						width: '100%',
						background: 'red',
						color: 'white',
						textAlign: 'center',
						marginBottom: '20px',
					}}
				>
					STAGING
				</div>
			</>
		);
	}
	///////////////////////////////////////////////////////////////////////
}
export { Navbar };
