import { useEffect, useState } from 'react';
import { Table } from 'reactstrap';
import {
	Card,
	CardBody,
	CardHeader,
	Col,
	Row,
	Toast,
	ToastBody,
} from 'reactstrap';
import { IoMdTrash } from 'react-icons/io';
import { MdAdd } from 'react-icons/md';
import {
	canEditOtherUsers,
	canDeleteOtherUsers,
	canAddOtherUsers,
	setUserRole,
} from '@assets/services/user-role-service';
import { auth } from '@assets/services/auth-service';
import { AddUserModal } from './children/AddUserModal';
import { EditUserModal } from './children/EditUserModal';
import { ConfirmDialog } from '@SignedIn/views/Locations/children/ConfirmDialog';
import { Loader } from '@SignedIn/Loader/Loader';
import {
	addTempUser,
	deleteUser,
	getOrganizationUsers,
	getUser,
	updateUser,
} from '@services/user-service';

const UsersTable = (props) => {
	const { isFocused } = props;
	const [rows, setTableRows] = useState([]);
	const [isLoading, setIsLoading] = useState(true);
	// const [userRole, setUserRoleState] = useState('');
	const [userOrganization, setUserOrganization] = useState('');
	// const [organizations, setOrganizations] = useState([]);
	const [isToastShowing, setIsToastShowing] = useState(false);
	const [users, setUsersTable] = useState([]);
	const [isAddUserModalShowing, setIsAddUserModalShowing] = useState(false);
	const [isEditUserModalShowing, setIsEditUserModalShowing] = useState(false);
	const [userBeingEdited, setUserBeingEdited] = useState(null);
	const [checkedUsers, setCheckedUsers] = useState([]);
	const [showDeleteModal, setShowDeleteModal] = useState(false);
	const toggleDeleteModal = () => setShowDeleteModal(!showDeleteModal);

	const closeModal = () => {
		setIsAddUserModalShowing(false);
		setIsEditUserModalShowing(false);
		setUserBeingEdited(null);
	};

	const getData = async () => {
		let user = await getUser(auth.currentUser.uid);
		setUserRole(user.role);
		// setUserRoleState(getUserRole());
		setUserOrganization(user.organization);
		// setOrganizations(orgs);
		setIsLoading(false);
	};

	const getOrgUsers = async () => {
		if (userOrganization.length > 0) {
			let docs = await getOrganizationUsers(userOrganization);
			let users = [];
			docs.forEach((d) => {
				users.push(d.data());
			});
			setUsersTable(users);
		}
	};

	useEffect(() => {
		if (isFocused) getData();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (isFocused) getData();
	}, [isFocused]);

	useEffect(() => {
		getOrgUsers();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		userOrganization,
		showDeleteModal,
		isEditUserModalShowing,
		isAddUserModalShowing,
	]);

	const addUser = async (data) => {
		let user = { ...data };
		user.organization = userOrganization;
		await addTempUser(user);
		setUsersTable([...users, user]);
		// time to add a user to db
		setIsAddUserModalShowing(false);
	};

	const editUser = async (data) => {
		await updateUser(data.uid, {
			name: data.name,
			role: data.role,
		});

		setIsToastShowing(true);
		setIsEditUserModalShowing(false);
		getData();
		setTimeout(() => {
			setIsToastShowing(false);
		}, 5000);
	};

	const openEditModal = (user) => {
		if (!canEditOtherUsers()) return;
		setUserBeingEdited(user);
		setIsEditUserModalShowing(true);
	};

	const setUserAsChecked = (user, event) => {
		let users = checkedUsers;
		if (event.target.checked) {
			users.push(user.uid);
			setCheckedUsers(users);
		} else {
			for (let i = 0; i < users.length; i++) {
				if (users[i] === user.uid) {
					users.splice(i, 1);
					break;
				}
			}
			setCheckedUsers(users);
		}
	};

	const deleteUsers = async () => {
		for (let i = 0; i < checkedUsers.length; i++) {
			await deleteUser(checkedUsers[i]);
		}
		toggleDeleteModal();
	};

	useEffect(() => {
		let newRows = [];
		let index = 0;
		for (let user of users) {
			const obj = (
				<tr className='users-table-row' id={index}>
					<td>
						<input
							type='checkbox'
							onChange={(e) => setUserAsChecked(user, e)}
						/>
					</td>
					<td onClick={() => openEditModal(user)}>{user.name}</td>
					<td onClick={() => openEditModal(user)}>{user.email}</td>
					<td onClick={() => openEditModal(user)}>{user.role}</td>
					<td></td>
				</tr>
			);
			newRows = [...newRows, obj];
			index++;
		}
		setTableRows(newRows);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [users]);

	return isLoading ? (
		<Loader />
	) : (
		<Card className='mt-4 submissions-table'>
			<CardHeader>
				<Row className='align-items-center'>
					<Col className='align-start'>
						<span className='title'>Organization Users</span>
					</Col>
					<Col className='align-end'>
						<button
							hidden={!canDeleteOtherUsers()}
							onClick={toggleDeleteModal}
							color='primary'
							className='rounded-circle no-border table-btn'
						>
							<IoMdTrash className='larger-font-icon larger-font' />
						</button>
						<button
							hidden={!canAddOtherUsers()}
							color='primary'
							className='rounded-circle no-border table-btn'
							onClick={() =>
								setIsAddUserModalShowing(!isAddUserModalShowing)
							}
						>
							<MdAdd className='larger-font-icon larger-font' />
						</button>
					</Col>
				</Row>
			</CardHeader>
			<CardBody>
				<Table className='users-table' responsive>
					<thead>
						<tr>
							<th></th>
							<th>Name</th>
							<th>Email</th>
							<th>Role</th>
							<th></th>
						</tr>
					</thead>
					<tbody>{rows}</tbody>
				</Table>
			</CardBody>
			<AddUserModal
				showing={isAddUserModalShowing}
				cancel={closeModal}
				submit={addUser}
			/>
			<EditUserModal
				showing={isEditUserModalShowing}
				cancel={closeModal}
				submit={editUser}
				user={userBeingEdited}
			/>
			<Toast isOpen={isToastShowing} className='toast'>
				<ToastBody>Changes have been saved</ToastBody>
			</Toast>
			<ConfirmDialog
				showConfirm={showDeleteModal}
				toggle={toggleDeleteModal}
				title='Confirm Deletion'
				body='Would you like to delete these users?'
				functionality={deleteUsers}
			/>
		</Card>
	);
};

export { UsersTable };
