import {
	useEffect,
	useState,
	// useCallback
} from 'react';
// import { CreateAssetTypeModal } from './children/CreateAssetTypeModal';
// import { DeleteAssetTypeModal } from './children/DeleteAssetTypeModal';
// import { UpdateAssetTypeModal } from './children/UpdateAssetTypeModal';
import { AiFillEdit, AiOutlinePlusSquare } from 'react-icons/ai';
import { IoMdTrash } from 'react-icons/io';
import {
	Card,
	CardHeader,
	// Form,
	Button,
	CardBody,
	Table,
	// Input,
	CardFooter,
} from 'reactstrap';
import { Loader } from '@SignedIn/Loader/Loader';
import { camelize } from '@utils/camelize';
// import {
// 	deleteWithRef,
// 	getDocsFromPath,
// 	updateWithRef,
// 	getDocFromPath,
// } from '@services/organization-service';
// import { searchResponses } from './children/Search';

const MasterAssetsOrgList = (props) => {
	const { org } = props;
	const fields = [
		'Asset Type',
		'Sub Type',
		'Make',
		'Model',
		'Replacement Cost',
		'Useful Life (Years)',
	];
	const limitRows = 100;

	// Loading state
	const [isLoading, setIsLoading] = useState(true);

	// State for all assets
	const [assets, setAssets] = useState([]);

	// State for assets count
	const [assetsCount, setAssetsCount] = useState();

	// States for search, sorting, paginating, etc.
	const [pageNum, setPageNum] = useState(1);
	// const [sortBy, setSortBy] = useState('assetType');
	// const [sortAsc, setSortAsc] = useState(true);
	// const [searchVal, setSearchVal] = useState('');
	const [pageCount, setPageCount] = useState(0);

	// Intermediate search input value
	// const [searchText, setSearchText] = useState('');

	// State for selected asset (ie, to edit or delete)
	const [selected, setSelected] = useState();

	// States for showing modals
	const [showCreate, setShowCreate] = useState(false);
	const [showDelete, setShowDelete] = useState(false);
	const [showUpdate, setShowUpdate] = useState(false);

	let initialized = false;
	// const [rendered, setRendered] = useState(false);
	// Triggers getAssets function on init render
	useEffect(() => {
		// if (!rendered) {
		if (!initialized) {
			console.log(
				org,
				assetsCount,
				selected,
				showCreate,
				showDelete,
				showUpdate
			);
			getAssets();
			initialized = true;
		}
		// }
	}, []);

	// Triggers getAssets function when pageNum or searchVal change
	// useEffect(() => {
	// 	getAssets();
	// }, [searchVal, sortBy, sortAsc, pageNum]);

	// useEffect(() => {
	// 	optimizedFn(searchText);
	// 	// eslint-disable-next-line react-hooks/exhaustive-deps
	// }, [searchText]);

	// Searches master assets via Algolia and updates assets state
	const getAssets = async () => {
		// setIsLoading(true);
		// const { results, count } = await searchResponses(
		// 	searchVal,
		// 	pageNum,
		// 	limitRows,
		// 	sortAsc ? `asc(${sortBy})` : `desc(${sortBy})`
		// );
		// setAssetsCount(count);
		// setPageCount(Math.ceil(count / limitRows));
		// const promises = [];
		// results.forEach((result) => {
		// 	const docSnap = getDocFromPath(result.path);
		// 	promises.push(docSnap);
		// });
		// const newAssets = [];
		// const data = await Promise.all(promises);
		// for (const asset of data) {
		// 	newAssets.push({
		// 		ref: asset.ref,
		// 		data: asset.data(),
		// 		id: asset.id,
		// 	});
		// }
		let firebaseConfig = JSON.parse(process.env.REACT_APP_FIREBASE_CONFIG);
		const res = await fetch(
			firebaseConfig.projectId.includes('staging')
				? 'https://us-central1-property-echo-staging.cloudfunctions.net/getOrgMasterAssets'
				: 'https://us-central1-property-echo-4d747.cloudfunctions.net/getOrgMasterAssets',
			//   'http://localhost:5001/staging/us-central1/getOrgMasterAssets'
			// : 'http://localhost:5001/property-echo-4d747/us-central1/getOrgMasterAssets',
			{
				method: 'POST',
				body: JSON.stringify({
					org: org,
				}),
			}
		);
		const resJson = await res.json();
		const newAssets = resJson.assets;
		await setAssetsCount(newAssets.length);
		await setPageCount(Math.ceil(newAssets.length / limitRows));
		await setAssets(newAssets);
		// setRendered(true);
		setIsLoading(false);
	};

	// Sort assets upon column click
	// const sortAssets = async (column) => {
	// 	let isAscending = true;
	// 	// Change direction if same key
	// 	if (column == sortBy) isAscending = !sortAsc;
	// 	setPageNum(1);
	// 	setSortBy(column);
	// 	setSortAsc(isAscending);
	// };

	// Handles when pagination buttons are clicked
	const pageBackward = async () => {
		if (pageNum > 1) {
			setPageNum(pageNum - 1);
		}
	};

	const pageForward = () => {
		if (pageNum < pageCount) {
			setPageNum(pageNum + 1);
		}
	};

	// const debounce = (func) => {
	// 	let timer;
	// 	return function (...args) {
	// 		const context = this;
	// 		if (timer) clearTimeout(timer);
	// 		timer = setTimeout(() => {
	// 			timer = null;
	// 			func.apply(context, args);
	// 		}, 1000);
	// 	};
	// };

	// // eslint-disable-next-line
	// const optimizedFn = useCallback(debounce(setSearchVal), []);

	// // Create individual asset type on front-end and back-end
	// const createType = async (data) => {
	// 	const isAll = formsArr[formIndex] === 'all';
	// 	const doc = await addAssetType(
	// 		isAll ? org : formsArr[formIndex].ref.path + '/asset-types',
	// 		isAll,
	// 		data
	// 	);
	// 	const newTypes = [
	// 		...types,
	// 		{ id: doc.id, ref: doc.ref, data: doc.data() },
	// 	];
	// 	setTypes(newTypes);
	// };

	// // Delete individual asset type on front-end and back-end
	// const deleteType = async () => {
	// 	await deleteWithRef(selected.ref);
	// 	const newTypes = [
	// 		...types.slice(0, selected.index),
	// 		...types.slice(selected.index + 1),
	// 	];
	// 	setTypes(newTypes);
	// 	closeDelete();
	// };

	// // Update individual asset type on front-end and back-end
	// const updateType = async (data) => {
	// 	await updateWithRef(selected.ref, data);
	// 	const newType = { ref: selected.ref, data: data, id: selected.id };
	// 	const newTypes = [
	// 		...types.slice(0, selected.index),
	// 		newType,
	// 		...types.slice(selected.index + 1),
	// 	];
	// 	setTypes(newTypes);
	// 	closeUpdate();
	// };

	// Opens & closes delete modal
	const openDelete = (type, i) => {
		const selObj = {};
		Object.keys(type).forEach((key) => (selObj[key] = type[key]));
		selObj.index = i;
		setSelected(selObj);
		setShowDelete(true);
	};
	// const closeDelete = () => {
	// 	setSelected();
	// 	setShowDelete(false);
	// };

	// Opens & closes update modal
	const openUpdate = (type, i) => {
		const selObj = {};
		Object.keys(type).forEach((key) => (selObj[key] = type[key]));
		selObj.index = i;
		setSelected(selObj);
		setShowUpdate(true);
	};
	// const closeUpdate = () => {
	// 	setSelected();
	// 	setShowUpdate(false);
	// };

	// return isLoading && !rendered ? (
	return isLoading ? (
		<Loader />
	) : (
		<Card className='mt-4 master-list-table-card'>
			<CardHeader className='p-3 d-flex flex-column flex-md-row justify-content-between'>
				{/* <Form
					onSubmit={(e) => e.preventDefault()}
					className='d-flex flex-column justify-content-center'
				>
					<input
						id='search'
						className='searchbar rounded-pill'
						type='text'
						disabled={isLoading}
						placeholder='Search'
						value={searchText}
						onChange={async (e) => {
							setSearchText(e.target.value);
						}}
					/>
				</Form> */}
				<div></div>
				<div className='d-flex flex-row-reverse flex-md-row justify-content-between align-end'>
					<button
						onClick={() => setShowCreate(true)}
						color='primary'
						className='rounded-circle no-border table-btn'
						disabled={isLoading}
					>
						<AiOutlinePlusSquare className='larger-font-icon larger-font' />
					</button>
				</div>
			</CardHeader>
			{
				// isLoading ? (
				// 	<CardBody>
				// 		<div
				// 			className='d-flex flex-column justify-content-center align-items-center w-100'
				// 			style={{
				// 				minHeight: '200px',
				// 				width: '100%'
				// 			}}
				// 		>
				// 			<Loader />
				// 		</div>
				// 	</CardBody>
				// ) :
				assets.length < 1 ? (
					<CardBody>
						<div
							className='d-flex flex-column justify-content-center align-items-center w-100'
							style={{ minHeight: '200px' }}
						>
							<div>No master assets to display.</div>
						</div>
					</CardBody>
				) : (
					<CardBody className='mt-4 master-list-table-card-body'>
						<Table
							responsive
							size='sm'
							className='master-list-table'
						>
							<thead>
								<tr>
									{fields.map((field) => {
										const cField = camelize(field);
										return (
											<th
												key={'header-' + cField}
												// onClick={() => sortAssets(cField)}
												// className={`is-bold-${
												// 	sortBy == cField
												// }`}
											>
												{field}
												{/* {(() => {
												if (
													sortBy == cField &&
													sortAsc
												) {
													return '↑';
												} else if (sortBy == cField) {
													return '↓';
												}
											})()} */}
											</th>
										);
									})}
									<th
										key='header-buttons'
										style={{ 'text-align': 'center' }}
									>
										Actions
									</th>
								</tr>
							</thead>
							<tbody>
								{assets
									.slice((pageNum - 1) * 100, pageNum * 100)
									.map((asset, i) => {
										return (
											<tr key={asset.id} className=''>
												{fields.map((field) => {
													const data =
														asset.data[
															camelize(field)
														];
													return (
														<td
															key={
																asset.id +
																'-' +
																field
															}
															style={{
																whiteSpace:
																	field ==
																	'Name'
																		? 'nowrap'
																		: 'normal',
																minWidth:
																	field ==
																	'Sub Type'
																		? '300px'
																		: '',
															}}
														>
															{Array.isArray(data)
																? data.join(
																		', '
																  )
																: field ==
																  'Replacement Cost'
																? `$${data}`
																: data}
														</td>
													);
												})}
												<td
													key={asset.id + '-buttons'}
													className=''
													style={{
														whiteSpace: 'nowrap',
														textAlign: 'center',
													}}
												>
													<Button
														color='purple'
														className='mx-1 btn-sm'
														onClick={() =>
															openUpdate(asset, i)
														}
													>
														<AiFillEdit />
													</Button>
													<Button
														color='secondary'
														className='mx-1 btn-sm'
														onClick={() =>
															openDelete(asset, i)
														}
													>
														<IoMdTrash />
													</Button>
												</td>
											</tr>
										);
									})}
							</tbody>
						</Table>
					</CardBody>
				)
			}
			{/* {showCreate && (
				<CreateAssetTypeModal
					isOpen={showCreate}
					toggle={() => setShowCreate(!showCreate)}
					createType={createType}
					title={
						formsArr[formIndex] !== 'all'
							? `Add New Asset Type to ${
									formsArr[formIndex].data().formName
							  }`
							: 'Add New Asset Type to Org'
					}
					isAll={formsArr[formIndex] === 'all' ? true : false}
					org={org}
					crossRefTypes={types}
				/>
			)} */}
			{/* {showDelete && (
				<DeleteAssetTypeModal
					isOpen={showDelete}
					close={closeDelete}
					deleteType={deleteType}
					title='Delete Asset Type'
					body={
						formsArr[formIndex] !== 'all'
							? `Are you sure you want to delete "${
									selected.data.name
							  }" from the ${
									formsArr[formIndex].data().formName
							  }?`
							: `Are you sure you want to delete "${selected.data.name}" from this org's asset types lists?`
					}
				/>
			)} */}
			{/* {showUpdate && (
				<UpdateAssetTypeModal
					isOpen={showUpdate}
					close={closeUpdate}
					updateType={updateType}
					title='Update Asset Type'
					selected={selected.data}
					fields={fields}
				/>
			)} */}
			<CardFooter>
				<div className='activities-page-buttons d-flex flex-row justify-content-end mt-2'>
					<button
						className='px-2 border rounded-start fs-5'
						onClick={pageBackward}
					>
						<i className='bi bi-arrow-left-short' />
					</button>
					<div
						className='border-top border-bottom p-2 h-100'
						style={{ fontSize: '12px' }}
					>{`${pageNum} of ${pageCount}`}</div>
					<button
						className='px-2 border rounded-end fs-5'
						onClick={pageForward}
					>
						<i className='bi bi-arrow-right-short' />
					</button>
				</div>
			</CardFooter>
		</Card>
	);
};

export { MasterAssetsOrgList };
