import {
	collection,
	doc,
	getDoc,
	getDocs,
	query,
	where,
} from 'firebase/firestore';
import { db } from '@assets/services/auth-service';
import { DB_FILTER, DB_PATH } from '@constants/db';

export const getForm = async (organization, form) => {
	const formSnap = await getDoc(
		doc(db, DB_PATH.ORGANIZATIONS, organization, DB_PATH.FORMS, form)
	);
	return formSnap;
};

export const getForms = async (organization, formName) => {
	const colRef = collection(
		db,
		DB_PATH.ORGANIZATIONS,
		organization,
		DB_PATH.FORMS
	);
	const formsSnap = formName
		? await getDocs(
				query(colRef, where(DB_FILTER.FORM_NAME, '==', formName))
		  )
		: await getDocs(colRef);
	return formsSnap;
};

export const getFormId = async (organization, formName) => {
	let formId;
	const snap = await getForms(organization, formName);
	snap.forEach((form) => {
		formId = form.id;
	});
	return formId;
};

export const getFormDropdowns = async (
	organization,
	formId,
	collectionName
) => {
	const snap = await getDocs(
		collection(
			db,
			DB_PATH.ORGANIZATIONS,
			organization,
			DB_PATH.FORMS,
			formId,
			collectionName
		)
	);
	return snap;
};
