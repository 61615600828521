import {
	Card,
	CardBody,
	Row,
	Col,
	CardTitle,
	CardHeader,
	Badge,
} from 'reactstrap';
import {
	Chart as ChartJS,
	CategoryScale,
	LinearScale,
	PointElement,
	LineElement,
	BarElement,
	Title,
	Tooltip,
	Legend,
} from 'chart.js';
import { Line, Bar } from 'react-chartjs-2';
import { useEffect, useState } from 'react';
import { useGraph } from '../graphContext';

const STYLES = [
	{ id: 'maintenance', color: '#fbc658' },
	{ id: 'replace', color: '#228B22' },
	{ id: 'downtime', color: '#00ccff' },
	{ id: 'downtime', color: '#5e79ff' },
];

const getStyle = (index) => STYLES[index > 3 ? 0 : index];

const MediumCard = ({ index }) => {
	const { graphs } = useGraph();
	const { data, labels, type, colorIndex, title, pillText } = graphs[index];

	const { id, color } = getStyle(colorIndex);
	const chartData = {
		labels,
		datasets: [
			{
				data,
				borderColor: color,
				borderRadius: 10,
				backgroundColor: color,
				pointRadius: 0,
			},
		],
	};

	ChartJS.register(
		CategoryScale,
		LinearScale,
		PointElement,
		LineElement,
		BarElement,
		Title,
		Tooltip,
		Legend
	);
	const lineOptions = {
		responsive: true,
		maintainAspectRatio: false,
		plugins: {
			legend: {
				display: false,
				position: 'top',
			},
		},
		scales: {
			y: {
				ticks: {
					color: '#9f9f9f',
					beginAtZero: false,
					maxTicksLimit: 5,
					//padding: 20
				},
				grid: {
					drawBorder: false,
					display: false,
				},
			},
			x: {
				grid: {
					drawBorder: false,
					display: false,
				},
				ticks: {
					padding: 20,
					color: '#9f9f9f',
				},
			},
		},
	};
	const barOptions = {
		responsive: true,
		maintainAspectRatio: false,
		plugins: {
			legend: {
				display: false,
			},
			tooltips: {
				tooltipFillColor: 'rgba(0,0,0,0.5)',
				tooltipFontFamily:
					"'Helvetica Neue', 'Helvetica', 'Arial', sans-serif",
				tooltipFontSize: 14,
				tooltipFontStyle: 'normal',
				tooltipFontColor: '#fff',
				tooltipTitleFontFamily:
					"'Helvetica Neue', 'Helvetica', 'Arial', sans-serif",
				tooltipTitleFontSize: 14,
				tooltipTitleFontStyle: 'bold',
				tooltipTitleFontColor: '#fff',
				tooltipYPadding: 6,
				tooltipXPadding: 6,
				tooltipCaretSize: 8,
				tooltipCornerRadius: 6,
				tooltipXOffset: 10,
			},
		},
		scales: {
			y: {
				ticks: {
					color: '#9f9f9f',
					beginAtZero: true,
					maxTicksLimit: 5,
					padding: 20,
				},
				grid: {
					zeroLineColor: 'transparent',
					display: false,
					drawBorder: false,
					color: '#9f9f9f',
				},
			},
			x: {
				grid: {
					display: false,
					drawBorder: false,
				},
				ticks: {
					padding: 20,
					color: '#9f9f9f',
				},
			},
		},
	};

	// animation state
	const [isAnimated, setIsAnimated] = useState(false);

	// animate the card on mount
	useEffect(() => {
		setIsAnimated(true);
	}, []);

	return (
		<Card
			className={`${isAnimated ? 'show' : 'hidden'} medium-card my-3`}
			id={id}
		>
			<CardHeader className='bg-light pt-2 bg-transition'>
				<Row>
					<Col className='col-2'></Col>
					<Col className='col-8 my-auto text-center'>
						<CardTitle
							className='m-0 fs-4 text-color text-transition'
							style={{ color: color }}
							tag='h6'
						>
							{title}
						</CardTitle>
					</Col>
					<Col className='col-2'>
						{pillText ? (
							<Badge
								pill
								color={color}
								style={{
									'background-color': color,
									float: 'right',
								}}
							>
								{pillText}
							</Badge>
						) : (
							<></>
						)}
					</Col>
				</Row>
			</CardHeader>
			<CardBody className='py-0 bg-light bg-transition'>
				{type === 'line' ? (
					<Line
						options={lineOptions}
						data={chartData}
						height='100%'
						width='100%'
					/>
				) : (
					<Bar
						options={barOptions}
						data={chartData}
						height='100%'
						width='100%'
					/>
				)}
			</CardBody>
		</Card>
	);
};

export { MediumCard };
