import { createChatBotMessage } from 'react-chatbot-kit';
import { Avatar } from '@components/layouts/SignedIn/Avatar/Avatar';
import { RiRobotLine } from 'react-icons/ri';
import BotChatMessage from './BotChatMessage';
import InitialMessageOptions from './InitialMessageOptions';

const config = {
	initialMessages: [
		createChatBotMessage(
			"Hi, I'm FacilityAI, your personalized Facility Manager Assistant!\n\nAsk me anything related to your facilities and assets!",
			{ widget: 'InitialMessageOptions' }
		),
	],
	botName: 'FacilityAI',
	customStyles: {
		botMessageBox: {
			backgroundColor: '#f2f2f2',
		},
		userMessageBox: {
			backgroundColor: '#f2f2f2',
		},
		chatButton: {
			backgroundColor: '#250245',
		},
	},
	customComponents: {
		userAvatar: (props) => (
			<div
				style={{
					width: '50px',
					height: '50px',
					borderRadius: '50%',
					overflow: 'hidden',
					marginLeft: '12px',
					marginTop: '12px',
				}}
			>
				<Avatar {...props} alt='Avatar' className='avatar' />
			</div>
		),
		header: () => (
			<div
				style={{
					backgroundColor: '#250245',
					padding: '6px 8px',
					color: 'white',
					textAlign: 'center',
					borderRadius: '10px 10px 0 0',
					overflow: 'hidden',
					fontSize: '20px',
					fontWeight: 'bold',
				}}
			>
				FacilityAI
			</div>
		),
		botChatMessage: (props) => <BotChatMessage {...props} />,
		botAvatar: () => (
			<div
				className='chatbot-avatar'
				style={{
					width: '40px',
					height: '40px',
					borderRadius: '50%',
					backgroundColor: 'grey',
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
				}}
			>
				<RiRobotLine
					style={{
						width: '27px',
						height: '27px',
						color: 'white',
						transform: 'translatey(-2px)',
					}}
				/>
			</div>
		),
	},
	state: {
		showInitialOptions: true,
	},
	widgets: [
		{
			// defines the name you will use to reference to this widget in "createChatBotMessage".
			widgetName: 'InitialMessageOptions',
			// Function that will be called internally to resolve the widget
			widgetFunc: (props) => <InitialMessageOptions {...props} />,
			// Any props you want the widget to receive on render
			props: {},
			// Any piece of state defined in the state object that you want to pass down to this widget
			mapStateToProps: ['showInitialOptions'],
		},
	],
};

export default config;
