import { useState } from 'react';
import {
	Button,
	Modal,
	ModalHeader,
	ModalBody,
	ModalFooter,
	Input,
	Label,
	FormGroup,
} from 'reactstrap';
import { camelize } from '@utils/camelize';

const UpdateAssetTypeModal = (props) => {
	const { isOpen, title, updateType, close, selected, fields } = props;

	const [type, setType] = useState(() => {
		const selObj = {};
		Object.keys(selected).forEach((key) => (selObj[key] = selected[key]));
		return selObj;
	});

	const confirm = async () => {
		await updateType(type);
		close();
	};

	const handleChange = (e, key) => {
		const obj = {};
		Object.keys(type).forEach((key) => (obj[key] = type[key]));
		obj[key] = e.target.value;
		setType(obj);
	};

	return (
		<div>
			<Modal isOpen={isOpen} toggle={close} centered>
				<ModalHeader toggle={close}>{title}</ModalHeader>
				<ModalBody>
					{fields.map((field) => {
						const key = camelize(field);
						const val = type[key] || '';
						return (
							<FormGroup key={field}>
								<Label>{field}</Label>
								<Input
									type='text'
									value={val}
									onChange={(e) => handleChange(e, key)}
								/>
							</FormGroup>
						);
					})}
				</ModalBody>
				<ModalFooter>
					<Button className='cancel-btn' onClick={close}>
						Cancel
					</Button>
					<Button className='confirm-btn' onClick={confirm}>
						Confirm
					</Button>
				</ModalFooter>
			</Modal>
		</div>
	);
};

export { UpdateAssetTypeModal };
