import { FormGroup, Input, Label } from 'reactstrap';
import { useThemeMode } from '../../../../hooks/themeMode';

const ThemeModeToggle = () => {
	const { thememode, setThemeMode } = useThemeMode();

	const ThemeToggler = () => {
		if (thememode === 'dark') {
			return (
				<FormGroup switch>
					<Label>Dark Mode</Label>
					<Input
						type='switch'
						role='switch'
						className='toggle-label-admin'
						checked={true}
						onChange={() => setThemeMode('light')}
					/>
				</FormGroup>
			);
		} else {
			return (
				<FormGroup switch>
					<Label>Light mode</Label>
					<Input
						type='switch'
						role='switch'
						className='toggle-label-admin'
						checked={false}
						onChange={() => setThemeMode('dark')}
					/>
				</FormGroup>
			);
		}
	};

	return <ThemeToggler />;
};

export { ThemeModeToggle };
