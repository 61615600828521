import { useEffect, useState } from 'react';
import { CreateAssetTypeModal } from './children/CreateAssetTypeModal';
// import { DeleteAssetTypeModal } from './children/DeleteAssetTypeModal';
import { UpdateAssetTypeModal } from './children/UpdateAssetTypeModal';
import { AiFillEdit, AiOutlinePlusSquare } from 'react-icons/ai';
// import { IoMdTrash } from 'react-icons/io';
import { Card, CardHeader, Form, Button, CardBody, Table } from 'reactstrap';
import { Loader } from '@SignedIn/Loader/Loader';
import { camelize } from '@utils/camelize';
import {
	addMasterAssetTypes,
	getMasterAssetsTypes,
} from '@services/asset-service';
import { updateWithRef } from '@services/organization-service';

const AssetTypesMasterList = () => {
	const fields = ['Name', 'Sub Types', 'Family', 'SC Template Name'];

	// Loading state
	const [isLoading, setIsLoading] = useState(true);

	// State for all asset types
	const [types, setTypes] = useState([]);

	// State for selected type (ie, to edit or delete)
	const [selected, setSelected] = useState();

	// States for showing modals
	const [showCreate, setShowCreate] = useState(false);
	// const [showDelete, setShowDelete] = useState(false);
	const [showUpdate, setShowUpdate] = useState(false);

	let rendered = false;
	// Trigger getAssetTypes function on init render
	useEffect(() => {
		if (!rendered) {
			getAssetTypes();
			rendered = true;
		}
	}, []);

	// Grabs asset types master list from Firebase and updates types state
	const getAssetTypes = async () => {
		const assetTypesSnap = await getMasterAssetsTypes();
		if (assetTypesSnap.empty) {
			setIsLoading(false);
			return;
		}
		const newTypes = [];
		for (const type of assetTypesSnap.docs) {
			newTypes.push({ ref: type.ref, data: type.data(), id: type.id });
		}
		await setTypes(newTypes);
		setIsLoading(false);
	};

	// Create individual asset type on front-end and back-end
	const createType = async (data) => {
		const doc = await addMasterAssetTypes(data);
		const newTypes = [
			...types,
			{ id: doc.id, ref: doc.ref, data: doc.data() },
		];
		setTypes(newTypes);
	};

	// // Delete individual asset type on front-end and back-end
	// const deleteType = async () => {
	// 	await deleteWithRef(selected.ref);
	// 	const newTypes = [
	// 		...types.slice(0, selected.index),
	// 		...types.slice(selected.index + 1),
	// 	];
	// 	setTypes(newTypes);
	// 	closeDelete();
	// };

	// Update individual asset type on front-end and back-end
	const updateType = async (data) => {
		await updateWithRef(selected.ref, data);
		const newType = { ref: selected.ref, data: data, id: selected.id };
		const newTypes = [
			...types.slice(0, selected.index),
			newType,
			...types.slice(selected.index + 1),
		];
		setTypes(newTypes);
		closeUpdate();
	};

	// // Opens & closes delete modal
	// const openDelete = (type, i) => {
	// 	const selObj = {};
	// 	Object.keys(type).forEach((key) => (selObj[key] = type[key]));
	// 	selObj.index = i;
	// 	setSelected(selObj);
	// 	setShowDelete(true);
	// };
	// const closeDelete = () => {
	// 	setSelected();
	// 	setShowDelete(false);
	// };

	// Opens & closes update modal
	const openUpdate = (type, i) => {
		const selObj = {};
		Object.keys(type).forEach((key) => (selObj[key] = type[key]));
		selObj.index = i;
		setSelected(selObj);
		setShowUpdate(true);
	};
	const closeUpdate = () => {
		setSelected();
		setShowUpdate(false);
	};

	return isLoading ? (
		<Loader />
	) : (
		<Card className='mt-4 submissions-table'>
			<CardHeader className='p-3 d-flex flex-column flex-md-row justify-content-between'>
				<Form
					// onSubmit={(e) => e.preventDefault()}
					className='d-flex flex-column justify-content-center'
				>
					<input
						id='search'
						className='searchbar rounded-pill'
						type='text'
						disabled={isLoading}
						placeholder='Search'
						// value={searchVal}
						// onChange={(e) => {
						// 	setSearchVal(e.target.value);
						// }}
					/>
				</Form>
				<div className='d-flex flex-row-reverse flex-md-row justify-content-between align-end'>
					<button
						onClick={() => setShowCreate(true)}
						color='primary'
						className='rounded-circle no-border table-btn'
						disabled={isLoading}
					>
						<AiOutlinePlusSquare className='larger-font-icon larger-font' />
					</button>
				</div>
			</CardHeader>
			{isLoading ? (
				<Loader />
			) : types.length < 1 ? (
				<CardBody>
					<div
						className='d-flex flex-column justify-content-center align-items-center w-100'
						style={{ minHeight: '200px' }}
					>
						<div>No asset types to display.</div>
					</div>
				</CardBody>
			) : (
				<CardBody>
					<Table responsive size='sm' className='asset-types-table'>
						<thead>
							<tr>
								{fields.map((field) => {
									return (
										<th key={'header-' + field}>{field}</th>
									);
								})}
								<th
									key='header-buttons'
									style={{
										textAlign: 'center',
										width: '53px',
									}}
								>
									Edit
								</th>
								{/* <th key='header-buttons'
									style={{
										textAlign: 'center',
										width: '53px',
									}}
								>
									Delete
								</th> */}
							</tr>
						</thead>
						<tbody>
							{types.map((type, i) => {
								return (
									<tr key={type.id} className=''>
										{fields.map((field) => {
											const data =
												type.data[camelize(field)];
											return (
												<td
													key={type.id + '-' + field}
													style={
														field == 'Name'
															? {
																	'white-space':
																		'nowrap',
															  }
															: {}
													}
												>
													{Array.isArray(data)
														? data.join(', ')
														: data}
												</td>
											);
										})}
										<td
											key={type.id + '-buttons'}
											className=''
											style={{ textAlign: 'center' }}
										>
											<Button
												color='purple'
												className='mx-1 btn-sm'
												onClick={() =>
													openUpdate(type, i)
												}
											>
												<AiFillEdit />
											</Button>
										</td>
										{/* <td
											key={type.id + '-buttons2'}
											className=''
											style={{ textAlign: 'center' }}
										>
											<Button
												color='secondary'
												className='mx-1 btn-sm'
												onClick={() =>
													openDelete(type, i)
												}
											>
												<IoMdTrash />
											</Button>
 										</td> */}
									</tr>
								);
							})}
						</tbody>
					</Table>
				</CardBody>
			)}
			{showCreate && (
				<CreateAssetTypeModal
					isOpen={showCreate}
					toggle={() => setShowCreate(!showCreate)}
					createType={createType}
					title='Create New Asset Type'
					fields={fields}
				/>
			)}
			{/* {showDelete && (
				<DeleteAssetTypeModal
					isOpen={showDelete}
					close={closeDelete}
					deleteType={deleteType}
					title='Delete Asset Type'
					body={`Are you sure you want to delete "${selected.data.name}" from the asset types master list?`}
				/>
			)} */}
			{showUpdate && (
				<UpdateAssetTypeModal
					isOpen={showUpdate}
					close={closeUpdate}
					updateType={updateType}
					title='Update Asset Type'
					selected={selected.data}
					fields={fields}
				/>
			)}
		</Card>
	);
};

export { AssetTypesMasterList };
