import {
	collection,
	endBefore,
	getCountFromServer,
	getDocs,
	limit,
	query,
	startAfter,
	startAt,
	where,
	orderBy,
	addDoc,
} from 'firebase/firestore';
import { db } from '@assets/services/auth-service';
import { DB_FILTER, DB_PATH } from '@constants/db';

export const getQAsWithPagenation = async (
	organization,
	locationId,
	sortColumn,
	sortDir,
	limitRows,
	after,
	at,
	before
) => {
	const params = [
		collection(db, DB_PATH.ORGANIZATIONS, organization, DB_PATH.QA),
	];
	if (locationId) {
		params.push(where(DB_FILTER.LOCATION_ID, '==', locationId));
	}
	if (sortColumn && sortDir) {
		params.push(orderBy(sortColumn, sortDir));
	}
	if (after) {
		params.push(startAfter(after));
	}
	if (at && before) {
		params.push(startAt(at));
		params.push(endBefore(before));
	}
	if (limitRows) {
		params.push(limit(limitRows));
	}
	const qasSnap = await getDocs(query(...params));
	return qasSnap;
};

export const getQAs = async (organization, locationId) => {
	const params = [
		collection(db, DB_PATH.ORGANIZATIONS, organization, DB_PATH.QA),
	];
	if (locationId) {
		params.push(where(DB_FILTER.LOCATION_ID, '==', locationId));
	}
	const qasSnap = await getDocs(query(...params));
	return qasSnap;
};

export const getQACount = async (organization, locationId) => {
	const params = [
		collection(db, DB_PATH.ORGANIZATIONS, organization, DB_PATH.QA),
	];
	if (locationId) {
		params.push(where(DB_FILTER.LOCATION_ID, '==', locationId));
	}
	const qasSnap = await getCountFromServer(query(...params));
	return qasSnap.data().count;
};

export const addQA = async (organization, data) => {
	await addDoc(
		collection(db, DB_PATH.ORGANIZATIONS, organization, DB_PATH.QA),
		data
	);
};
