import React, { useRef, useState, useEffect } from 'react';
import styles from '../views/Dashboard/ChatbotMainCard/BotChatMessage.module.css';
import { useThemeMode } from '../../../../hooks/themeMode';

const Loader = ({ dashboard }) => {
	const { thememode } = useThemeMode();
	const messageTextStyleClasses =
		thememode !== 'dark' ? styles.messageText : styles.messageText_dark;
	const [generateMessage, setGenerateMessage] = useState(false);

	const loadingMessages = [
		"Hold on, I'm digging deep for the answer! 🤔",
		"Hang on, I'm doing some mental gymnastics here! 🧠",
		"Just a sec, I'm pondering the possibilities! 🤔",
		"Give me a moment, I'm getting the gears turning!",
		'Hmm, let me see what I can conjure up!',
		"I'm sifting through the data! 📊",
		'Let me check that for you!',
		'Searching for an answer... 🔍😜',
		'Working out the best solution! 💡😆',
		'Calculating the response!',
		'Generating a response!',
		'Let me see what I can find to make you smile! 😊',
		'Coming up with a good reply! 💬😜',
		"Just a sec, I'm consulting with my team of expert robots! 🤖🤔",
		"I'm tapping into my vast knowledge bank to give you the best answer! 💡💰",
		"I'm channeling my inner Einstein to solve this problem! 🧐🧠",
		"Let me think on it for a second, I'm a professional brainstormer! 💭💼",
		"Hold your horses, I'm analyzing the problem like Sherlock Holmes! 🕵️‍♀️🔍",
		"I'm brewing up a storm of ideas in my mind! ☁️🌩️",
		"Hold on, I'm diving deep into my brain's database to retrieve the answer! 🤯💾",
	];

	const message = useRef(
		loadingMessages[Math.floor(Math.random() * loadingMessages.length)]
	);

	useEffect(() => {
		setTimeout(() => {
			message.current =
				loadingMessages[
					Math.floor(Math.random() * loadingMessages.length)
				];
			setGenerateMessage(!generateMessage);
		}, 4000);
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [generateMessage]);

	return (
		<span className={`loader ${dashboard ? messageTextStyleClasses : ''}`}>
			{message.current}
		</span>
	);
};

export default React.memo(Loader, (props, nextProps) =>
	props == nextProps ? true : false
);
