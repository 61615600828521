// Formats submission date to "YYYY-MM-DD HH:MM:SS" format
export const formatDate = (oldDate) => {
	let date = new Date(oldDate);

	let month = String(date.getMonth() + 1).padStart(2, '0');
	let day = String(date.getDate()).padStart(2, '0');
	let hours = String(date.getHours()).padStart(2, '0');
	let minutes = String(date.getMinutes()).padStart(2, '0');
	let seconds = String(date.getSeconds()).padStart(2, '0');
	let year = String(date.getFullYear());

	return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
};
