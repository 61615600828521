import { useEffect, useState } from 'react';
import { AiOutlineClose, AiOutlineBell } from 'react-icons/ai';
import { deleteDoc, updateDoc } from 'firebase/firestore';
import { auth } from '@assets/services/auth-service';
import { useNavigate } from 'react-router-dom';
import { useRef } from 'react';
import { getNotifications } from '@services/user-service';

const Alerts = () => {
	const navigate = useNavigate();
	const btnRef = useRef(null);

	// State for kebab visibility
	const [visible, setVisible] = useState(false);

	// State for array of alerts
	const [alerts, setAlerts] = useState([]);
	const [alertsChange, setAlertsChange] = useState(false);

	// State for unreads alerts count
	const [unreadCount, setUnreadCount] = useState(0);

	// State for class of certain kebab components
	const [kebabClass, setKebabClass] = useState('');

	// State for array of kebab items
	const [kebabArr, setKebabArr] = useState();

	// Opens (or closes) the kebab and marks the alerts as read
	const clickOnBell = async () => {
		if (!visible) {
			for (const alert of alerts) {
				if (alert.data().unread == true) {
					await updateDoc(alert.ref, { unread: false });
				}
			}
			setUnreadCount(0);
			setVisible(true);
		} else {
			setVisible(false);
		}
	};

	// Navigates user when clicking on alert
	const clickOnAlert = async (ref, type, submissionId = null) => {
		try {
			await deleteDoc(ref);
		} catch (error) {
			null;
		}
		setVisible(false);
		updateAlerts();
		if (type == 'drafts') {
			navigate('/admin', {
				state: { viewDrafts: true, tab: 'Submissions' },
			});
		} else if (type == 'releases') navigate('/releases');
		else if (type == 'submissionEdit') {
			navigate('/admin', {
				state: { tab: 'Submissions', submissionId: submissionId },
			});
		}
	};

	// Grabs alerts for signed-in user from Firebase
	const updateAlerts = async () => {
		const alertsSnap = await getNotifications(auth.currentUser.uid);
		if (alertsSnap.empty) await setAlerts([]);
		else await setAlerts(alertsSnap.docs);
		setAlertsChange(true);
	};

	// Updates alerts state on init render
	useEffect(() => {
		updateAlerts();
	}, []);

	// Updates kebabArr state when alerts state changes
	useEffect(() => {
		if ((alerts != undefined) & alertsChange) {
			const rows = [];
			let count = 0;
			if (alerts.length == 0) {
				rows.push(<div>You have no alerts at this time.</div>);
			} else {
				for (const alert of alerts) {
					if (alert.data().unread == true) count++;
					const row = (
						<div
							className='alert-item'
							onClick={() =>
								clickOnAlert(
									alert.ref,
									alert.data().type,
									alert.data().submissionId
								)
							}
						>
							{alert.data().message}
						</div>
					);
					rows.push(row);
				}
			}
			setUnreadCount(count);
			setKebabArr(rows);
			setAlertsChange(false);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [alertsChange]);

	// Updates kebab class state when visible prop changes
	useEffect(() => {
		if (visible) {
			setKebabClass('active');
		} else {
			setKebabClass('');
		}
	}, [visible]);

	const handleClickOutside = (e) => {
		if (btnRef.current && !btnRef.current.contains(e.target)) {
			setVisible(false);
		}
	};

	useEffect(() => {
		document.addEventListener('mousedown', handleClickOutside);
		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, []);

	return (
		<button
			ref={btnRef}
			className='alert-btn rounded-circle no-border fs-6 mb-1'
		>
			<AiOutlineClose
				className={`alert-close ${visible && 'alert-close-expand'} w-100 h-100`}
				onClick={clickOnBell}
			/>
			{!visible && (
				<>
					<AiOutlineBell
						className='alert-dots w-100 h-100'
						onClick={clickOnBell}
					/>
					{unreadCount > 0 && (
						<div className='alert-badge'>{unreadCount}</div>
					)}
				</>
			)}
			<div className='alert-kebab-wrapper'>
				<div className={`alert-kebab ${kebabClass} d-flex flex-column`}>
					<div className='pb-2 fs-6 alert-title'>Alerts</div>
					{kebabArr}
				</div>
			</div>
		</button>
	);
};

export { Alerts };
