import { AiOutlineClose } from 'react-icons/ai';
import { BsExclamation } from 'react-icons/bs';
import { BiCheck } from 'react-icons/bi';

const Toast = ({ isShowing, title, subtitle, position, type }) => {
	return (
		<div
			className={`toast-wrapper ${type} ${
				isShowing ? 'toast-show' : 'toast-unshow'
			} ${position}`}
		>
			{type === 'success' && <BiCheck className='toast-icon' />}

			{type === 'danger' && <BsExclamation className='toast-icon' />}

			<div className='toast-text-wrapper'>
				<h6 className='toast-title'>{title}</h6>
				<h6 className='toast-subtitle'>{subtitle}</h6>
			</div>
			<AiOutlineClose className='toast-close' />
		</div>
	);
};

export { Toast };
