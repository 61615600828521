import { useEffect, useState } from 'react';
import {
	Button,
	Modal,
	ModalHeader,
	ModalBody,
	ModalFooter,
	Input,
} from 'reactstrap';

const SCExportDialog = (props) => {
	const { showConfirm, toggle, functionality, categories } = props;
	const [options, setOptions] = useState();
	const [chosenCategory, setChosenCategory] = useState();

	useEffect(() => {
		if (categories) {
			setChosenCategory(categories[0]);
			const opts = [];
			for (const cat of categories) opts.push(<option>{cat}</option>);
			setOptions(opts);
		}
	}, [categories]);

	return (
		<div>
			<Modal isOpen={showConfirm} toggle={toggle} centered>
				<ModalHeader toggle={toggle}>
					Which type would you like to export?
				</ModalHeader>
				<ModalBody>
					<div>
						<Input
							type='select'
							onChange={(ev) => {
								setChosenCategory(ev.target.value);
							}}
						>
							{options}
						</Input>
					</div>
				</ModalBody>
				<ModalFooter>
					<Button className='cancel-btn' onClick={toggle}>
						Cancel
					</Button>
					<Button
						className='confirm-btn'
						onClick={() => functionality(chosenCategory)}
					>
						Export
					</Button>
				</ModalFooter>
			</Modal>
		</div>
	);
};

export { SCExportDialog };
