import { useState } from 'react';
import {
	Dropdown,
	DropdownToggle,
	DropdownMenu,
	DropdownItem,
} from 'reactstrap';
import { HealthScore } from '@SignedIn/views/Dashboard/HealthScore/HealthScore';

function WeekDropdown({
	week1Percent,
	week2Percent,
	week3Percent,
	week4Percent,
}) {
	const [dropdownOpen, setDropdownOpen] = useState(false);
	const [activeWeek, setActiveWeek] = useState(1);

	const toggle = () => setDropdownOpen((prevState) => !prevState);

	return (
		<div className='d-flex flex-column week-dropdown'>
			<Dropdown className='z-100' isOpen={dropdownOpen} toggle={toggle}>
				<DropdownToggle className='border-none bg-green mt-3' caret>
					Week {activeWeek}
				</DropdownToggle>
				<DropdownMenu className='z-100'>
					<DropdownItem onClick={() => setActiveWeek(1)}>
						Week 1
					</DropdownItem>
					<DropdownItem onClick={() => setActiveWeek(2)}>
						Week 2
					</DropdownItem>
					<DropdownItem onClick={() => setActiveWeek(3)}>
						Week 3
					</DropdownItem>
					<DropdownItem onClick={() => setActiveWeek(4)}>
						Week 4
					</DropdownItem>
				</DropdownMenu>
			</Dropdown>
			<div className='w-100 d-flex justify-content-center mt-5 mb-6'>
				{activeWeek == 1 && (
					<div className='current-month-single-health-wrapper'>
						<HealthScore
							healthScore={week1Percent}
							label={`Week ${activeWeek} PM Reports`}
						/>
					</div>
				)}
				{activeWeek == 2 && (
					<div className='current-month-single-health-wrapper'>
						<HealthScore
							healthScore={week2Percent}
							label={`Week ${activeWeek} PM Reports`}
						/>
					</div>
				)}
				{activeWeek == 3 && (
					<div className='current-month-single-health-wrapper'>
						<HealthScore
							healthScore={week3Percent}
							label={`Week ${activeWeek} PM Reports`}
						/>
					</div>
				)}
				{activeWeek == 4 && (
					<div className='current-month-single-health-wrapper'>
						<HealthScore
							healthScore={week4Percent}
							label={`Week ${activeWeek} PM Reports`}
						/>
					</div>
				)}
			</div>
		</div>
	);
}

export { WeekDropdown };
