import {
	Page,
	Text,
	View,
	Document,
	Image,
	StyleSheet,
} from '@react-pdf/renderer';
import { useState, useEffect } from 'react';
import { auth } from '@assets/services/auth-service';
import QRCode from 'qrcode';
import { getForms, getForm } from '@services/form-service';
import { getAssetTypes } from '@services/asset-service';
import { getAssets as getOrgAssets } from '@services/asset-service';
import { DB_ORDER } from '@constants/db';

const LeaveBehindReportPdf = (props) => {
	const { address1, address2, org, locationId, setToast } = props;
	const [isLoading, setIsLoading] = useState(false);
	const [pdf, setPdf] = useState();
	const [orgLogo, setOrgLogo] = useState();
	const [pages, setPages] = useState();
	const [assets, setAssets] = useState();
	const [categories, setCategories] = useState();

	const getFormId = async () => {
		let formId;
		const snap = await getForms(org);
		snap.forEach((form) => {
			formId = form.id;
		});
		return formId;
	};

	const getLogo = async () => {
		const formId = await getFormId();
		const formSnap = await getForm(org, formId);
		setOrgLogo(formSnap.data().logo);
	};

	const getAssets = async () => {
		let assetRows = [];
		const assetsSnap = await getOrgAssets(
			org,
			locationId,
			null,
			null,
			DB_ORDER.ASSET_TYPES
		);
		const assetTypesSnap = await getAssetTypes(org);
		assetsSnap.forEach(async (asset) => {
			const tagId = asset.data().other.assetTag;
			const qrLink = await QRCode.toDataURL(tagId);
			const newAsset = {
				id: asset.data().other.assetTag,
				type: asset.data().assetType,
				make: asset.data().make,
				model: asset.data().model,
				serial: asset.data().serial,
				img: asset.data().other.equipmentAssetImage,
				img2: qrLink,
				location: asset.data().other.assetLocation,
			};
			const assetType = assetTypesSnap.docs.find(
				(aType) => aType.data().name == newAsset.type
			);
			if (assetType) newAsset.subType = assetType.data().subType;
			assetRows.push(newAsset);
		});
		setAssets(assetRows);
	};

	const styles = StyleSheet.create({
		page: {
			flexDirection: 'row',
			fontFamily: 'Helvetica',
		},
		titleSection: {
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
			margin: 10,
			padding: 10,
			flexGrow: 1,
		},
		section: {
			flexGrow: 1,
		},
		title: {
			fontSize: 40,
			flexDirection: 'column',
			textAlign: 'center',
			marginBottom: 40,
		},
		address: {
			textAlign: 'center',
			margin: '8px 0',
		},
		date: {
			textAlign: 'end',
			position: 'absolute',
			right: 10,
			bottom: 12,
			fontSize: 16,
		},
		logo: {
			position: 'absolute',
			left: 10,
			bottom: 0,
			width: 100,
		},
		header: {
			height: 100,
			backgroundColor: '#000',
			position: 'relative',
			top: 0,
			left: 0,
			right: 0,
			display: 'flex',
			alignItems: 'center',
		},
		headerText: {
			color: '#fff',
			textAlign: 'center',
			fontSize: 40,
			marginTop: 25,
		},
		pageNumber: {
			position: 'absolute',
			color: '#000',
			bottom: 20,
			right: 20,
			fontSize: 14,
		},
		th: {
			backgroundColor: '#530000',
			height: 25,
			width: '20%',
			fontWeight: 'bold',
		},
		tr: {
			height: 25,
			width: '20%',
			fontWeight: 'bold',
		},
		thText: {
			color: '#fff',
			fontSize: 10,
			marginTop: 6,
			fontWeight: 900,
			marginLeft: 5,
			fontFamily: 'Helvetica-Bold',
		},
		tdText: {
			fontSize: 8,
			marginTop: 5,
			marginLeft: 5,
		},
		break: {
			height: 60,
			position: 'relative',
			width: '100%',
			bottom: 0,
			backgroundColor: 'transparent',
		},
		asset: {
			border: '2px solid gray',
			marginTop: 20,
			marginRight: 20,
			marginLeft: 10,
			marginBottom: 0,
			padding: 10,
			height: 240,
			display: 'flex',
			flexDirection: 'row',
			justifyContent: 'space-apart',
			alignItems: 'center',
		},
		img: {
			objectFit: 'cover',
			width: '220px',
			height: '220px',
		},
		img2: {
			objectFit: 'cover',
			width: '150px',
			height: '150px',
		},
		assetDetails: {
			display: 'flex',
			flexDirection: 'column',
		},
		labelKey: {
			fontFamily: 'Helvetica-Bold',
			fontSize: 12,
			marginBottom: 6,
			marginLeft: 'auto',
			width: 150,
		},
		labelValue: {
			fontSize: 12,
			color: '#000',
			fontFamily: 'Helvetica',
		},
	});

	const getPdf = async () => {
		if (categories && pages) {
			await setPdf(
				<Document>
					<Page size='A4' style={styles.page}>
						<View style={styles.titleSection}>
							<Text style={styles.title}>
								Leave Behind Report
							</Text>
							<Text style={styles.address}>{address1}</Text>
							<Text style={styles.address}>{address2}</Text>
							<View style={styles.logo}>
								<Image src={orgLogo}></Image>
							</View>
							<Text style={styles.date}>{`Created: ${
								new Date().getMonth() + 1
							}/${new Date().getDate()}/${new Date().getFullYear()}`}</Text>
						</View>
					</Page>
					<Page size='A4' style={styles.page} wrap>
						<View style={styles.section}>
							<View style={styles.header}>
								<Text style={styles.headerText}>
									Table of Contents
								</Text>
							</View>

							<View
								style={{
									display: 'flex',
									flexDirection: 'row',
									backgroundColor: '#f5f5f3',
									marginTop: 20,
									marginRight: 20,
									marginLeft: 20,
								}}
								fixed
							>
								<View
									style={{
										...styles.tr,
										backgroundColor: '#530000',
									}}
								>
									<Text style={styles.thText}>ID</Text>
								</View>
								<View
									style={{
										...styles.tr,
										backgroundColor: '#530000',
									}}
								>
									<Text style={styles.thText}>
										ASSET TYPE
									</Text>
								</View>
								<View
									style={{
										...styles.tr,
										backgroundColor: '#530000',
									}}
								>
									<Text break style={styles.thText}>
										MAKE
									</Text>
								</View>
								<View
									style={{
										...styles.tr,
										backgroundColor: '#530000',
									}}
								>
									<Text style={styles.thText}>MODEL</Text>
								</View>
								<View
									style={{
										...styles.tr,
										backgroundColor: '#530000',
									}}
								>
									<Text style={styles.thText}>SERIAL</Text>
								</View>
							</View>
							{assets.map((row) => {
								return (
									<View
										style={{
											display: 'flex',
											flexDirection: 'row',
											backgroundColor: '#f5f5f3',
											marginLeft: 20,
											marginRight: 20,
										}}
										wrap={false}
										key={row.id}
									>
										<View style={styles.tr}>
											<Text style={styles.tdText}>
												{row.id || 'N/A'}
											</Text>
										</View>
										<View style={styles.tr}>
											<Text style={styles.tdText}>
												{row.type || 'N/A'}
											</Text>
										</View>
										<View style={styles.tr}>
											<Text style={styles.tdText}>
												{row.make || 'N/A'}
											</Text>
										</View>
										<View style={styles.tr}>
											<Text style={styles.tdText}>
												{row.model || 'N/A'}
											</Text>
										</View>
										<View style={styles.tr}>
											<Text style={styles.tdText}>
												{row.serial || 'N/A'}
											</Text>
										</View>
									</View>
								);
							})}

							<View style={styles.logo} fixed>
								<Image src={orgLogo}></Image>
							</View>

							<View fixed style={styles.break}></View>
							<Text
								style={styles.pageNumber}
								render={({ pageNumber }) =>
									`Page ${pageNumber}`
								}
								fixed
							/>
						</View>
					</Page>
					{pages}
				</Document>
			);
		}
	};

	const getPages = async () => {
		if (categories) {
			const sortedCategories = Object.keys(categories).sort();
			let pages = sortedCategories.map((category) => {
				return (
					<Page key={category} size='A4' style={styles.page} wrap>
						<View style={styles.section}>
							<View style={styles.header}>
								<Text style={styles.headerText}>
									{category}
								</Text>
							</View>

							{categories[category].map((asset) => {
								return (
									<View
										key={asset.id}
										style={styles.asset}
										wrap={false}
									>
										{asset.img != undefined ? (
											<Image
												style={styles.img}
												src={asset.img}
											/>
										) : null}

										{asset.img2 != undefined ? (
											<Image
												style={styles.img2}
												src={asset.img2}
											/>
										) : null}

										<View
											key={`${asset.id} - details`}
											style={styles.assetDetails}
										>
											<Text style={styles.labelKey}>
												ID:{' '}
												<Text style={styles.labelValue}>
													{asset.id || 'N/A'}
												</Text>
											</Text>
											<Text style={styles.labelKey}>
												Asset Type:{' '}
												<Text style={styles.labelValue}>
													{asset.type || 'N/A'}
												</Text>
											</Text>
											<Text style={styles.labelKey}>
												Asset Subtype:{' '}
												<Text style={styles.labelValue}>
													{asset.subType || 'N/A'}
												</Text>
											</Text>
											<Text style={styles.labelKey}>
												Make:{' '}
												<Text style={styles.labelValue}>
													{asset.make || 'N/A'}
												</Text>
											</Text>
											<Text style={styles.labelKey}>
												Model:{' '}
												<Text style={styles.labelValue}>
													{asset.model || 'N/A'}
												</Text>
											</Text>
											<Text style={styles.labelKey}>
												Serial:{' '}
												<Text style={styles.labelValue}>
													{asset.serial || 'N/A'}
												</Text>
											</Text>
											{/* <Text style={styles.labelKey}>
												Location:{' '}
												<Text style={styles.labelValue}>
													{asset.location || 'N/A'}
												</Text>
											</Text> */}
										</View>
									</View>
								);
							})}

							<View style={styles.logo} fixed>
								<Image src={orgLogo}></Image>
							</View>
							<View fixed style={styles.break}></View>
							<Text
								style={styles.pageNumber}
								render={({ pageNumber }) =>
									`Page ${pageNumber}`
								}
								fixed
							/>
						</View>
					</Page>
				);
			});
			setPages(pages);
		}
	};

	const getCategories = async () => {
		if (assets) {
			const newCategories = {};
			// const assetTypesSnap = await getDocs(
			// 	collection(db, 'organizations', org, 'asset-types')
			// );
			// for (const asset of assets) {
			// 	const assetType = asset.type;
			// 	const match = assetTypesSnap.docs.find(
			// 		(aType) => aType.data().name == assetType
			// 	);
			// 	if (match) {
			// 		if (newCategories.hasOwnProperty(match.data().subType)) {
			// 			newCategories[match.data().subType].push(asset);
			// 		} else {
			// 			newCategories[match.data().subType] = [];
			// 			newCategories[match.data().subType].push(asset);
			// 		}
			// 	}
			// }
			for (const asset of assets) {
				// eslint-disable-next-line no-prototype-builtins
				if (newCategories.hasOwnProperty(asset.location)) {
					newCategories[asset.location].push(asset);
				} else {
					newCategories[asset.location] = [];
					newCategories[asset.location].push(asset);
				}
			}
			setCategories(newCategories);
		}
	};

	const downloadPdf = async () => {
		const asString = JSON.stringify(pdf, (k, v) =>
			typeof v === 'symbol' ? `$$Symbol:${Symbol.keyFor(v)}` : v
		);

		// On localhost, open the following link and request temp access to the demo server
		// to get around the CORS issue: https://cors-anywhere.herokuapp.com/corsdemo ...
		// Before deploying to prod, switch the URLs passed to fetch, as CORS is not an issue
		// when using the app on propertyecho.com or staging.propertyecho.com
		const controller = new AbortController();
		const signal = controller.signal;
		const date = new Date().toISOString().split('T')[0];
		fetch(
			// 'https://micro-backend-kn7dm7lqsa-ue.a.run.app/leaveBehindExport',
			'https://us-central1-property-echo-4d747.cloudfunctions.net/downloadLeaveBehind',
			{
				method: 'POST',
				signal: signal,
				headers: {
					'Content-Type': 'application/json',
				},
				body: JSON.stringify({
					data: asString,
					email: auth?.currentUser?.email,
					location: `${address1}, ${address2}`,
					date: date,
					org: org,
				}),
			}
		);
		setToast({
			showing: true,
			title: 'Generating PDF',
			message:
				'You will receive an email when your download link is ready.',
			type: 'success',
			position: 'bottom',
		});
		setTimeout(() => {
			setToast({
				showing: false,
			});
			setIsLoading(false);
		}, 4500);
		setTimeout(() => {
			controller.abort();
		}, 5000);
	};

	useEffect(() => {
		if (isLoading) {
			getAssets();
			getLogo();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isLoading]);

	useEffect(() => {
		if (assets && orgLogo) {
			getCategories();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [assets, orgLogo]);

	useEffect(() => {
		if (categories) {
			getPages();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [categories]);

	useEffect(() => {
		if (pages) {
			getPdf();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [pages]);

	useEffect(() => {
		if (pdf) {
			downloadPdf();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [pdf]);

	return !isLoading ? (
		<button
			style={{ marginTop: '5px', marginRight: '10px' }}
			className='no-border table-btn'
			onClick={() => setIsLoading(true)}
		>
			Leave Behind Report
		</button>
	) : (
		<button
			style={{ marginTop: '5px', marginRight: '10px' }}
			disabled
			className='no-border table-btn'
		>
			Generating PDF...
		</button>
	);
};

export { LeaveBehindReportPdf };
