import { useState } from 'react';
import { BsShieldFillCheck } from 'react-icons/bs';

const WarrantyIcon = ({ isWarrantyExpired, percentWarrantyLeft }) => {
	const [tooltipOpen, setTooltipOpen] = useState(false);
	const warranty = {};
	const getWarrantyData = () => {
		const percent = Number(percentWarrantyLeft);
		if (isWarrantyExpired === false) {
			if (percent <= 50 && percent > 40) {
				warranty.text = 'Warranty Expiring';
				warranty.style = '#fab832';
			} else if (percent <= 50) {
				warranty.text = 'Warranty Expiring';
				warranty.style = '#df3053';
			} else if (percent > 50) {
				warranty.text = 'Asset Under Warranty';
				warranty.style = '#228b22';
			}
		} else {
			warranty.text = 'Not Under Warranty';
			warranty.style = null;
		}
	};
	getWarrantyData();

	return (
		<div className='tooltip-wrapper'>
			<BsShieldFillCheck
				onMouseEnter={() => setTooltipOpen(true)}
				onMouseLeave={() => setTooltipOpen(false)}
				className='warranty-icon'
				style={{ color: warranty.style }}
			/>

			<div
				className={`${
					tooltipOpen ? 'tooltip-active' : 'tooltip-inactive'
				}`}
				style={{ backgroundColor: warranty.style }}
			>
				{warranty.text}
			</div>
		</div>
	);
};

export { WarrantyIcon };
