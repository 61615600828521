import {
	addDoc,
	collection,
	deleteDoc,
	doc,
	getDoc,
	getDocs,
	query,
	setDoc,
	updateDoc,
	where,
} from 'firebase/firestore';
import { db } from '@assets/services/auth-service';
import { DB_FILTER, DB_PATH } from '@constants/db';

export const getUser = async (id) => {
	const userSnap = await getDoc(doc(db, DB_PATH.USERS, id));
	return userSnap.data();
};

export const getOrganizationUsers = async (organization) => {
	const userRef = collection(db, DB_PATH.USERS);
	const docs = await getDocs(
		query(userRef, where(DB_FILTER.ORGANIZATION, '==', organization))
	);
	return docs;
};

export const getNotifications = async (userId) => {
	const notifications = await getDocs(
		collection(db, DB_PATH.USERS, userId, DB_PATH.NOTIFICATIONS)
	);
	return notifications;
};

export const addTempUser = async (user) => {
	await addDoc(collection(db, DB_PATH.TEMP_USERS), { ...user });
};

export const setuser = async (id, data) => {
	await setDoc(doc(db, DB_PATH.USERS, id), data);
};

export const updateUser = async (userId, data) => {
	await updateDoc(doc(db, DB_PATH.USERS, userId), data);
};

export const updateUserFilter = async (userId, filters) => {
	await updateUser(userId, { savedFilters: filters });
};

export const updateUserImage = async (userId, path) => {
	await updateUser(userId, { image: path });
};

export const deleteUser = async (userId) => {
	await deleteDoc(doc(db, DB_PATH.USERS, userId));
};
