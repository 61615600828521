import { useState } from 'react';
import {
	Button,
	Modal,
	ModalHeader,
	ModalBody,
	ModalFooter,
	Input,
	Label,
	FormGroup,
} from 'reactstrap';
import { camelize } from '@utils/camelize';

const CreateAssetTypeModal = (props) => {
	const { isOpen, toggle, title, createType, fields } = props;

	const initType = () => {
		const obj = {};
		fields.forEach((field) => {
			obj[camelize(field)] = '';
		});
		return obj;
	};

	const [type, setType] = useState(initType());

	const cancel = () => {
		toggle();
		setType(initType());
	};

	const confirm = async () => {
		await createType(type);
		cancel();
	};

	const handleChange = (e, key) => {
		const obj = {};
		Object.keys(type).forEach((key) => (obj[key] = type[key]));
		obj[key] = e.target.value;
		setType(obj);
	};

	return (
		<div>
			<Modal isOpen={isOpen} toggle={toggle} centered>
				<ModalHeader toggle={toggle}>{title}</ModalHeader>
				<ModalBody>
					{fields.map((field) => {
						const key = camelize(field);
						const val = type[key] || '';
						return (
							<FormGroup key={field}>
								<Label>{field}</Label>
								<Input
									type='text'
									value={val}
									onChange={(e) => handleChange(e, key)}
								/>
							</FormGroup>
						);
					})}
				</ModalBody>
				<ModalFooter>
					<Button className='cancel-btn' onClick={cancel}>
						Cancel
					</Button>
					<Button className='confirm-btn' onClick={confirm}>
						Confirm
					</Button>
				</ModalFooter>
			</Modal>
		</div>
	);
};

export { CreateAssetTypeModal };
