import { Card, CardHeader, Input, Label, FormGroup } from 'reactstrap';
// import { DownloadCsv } from './DownloadCsv';
// import { DownloadPdf } from './DownloadPdf';
import { useEffect, useState } from 'react';
// import ExifReader from 'exifreader';
import { canEdit } from '@assets/services/user-role-service';
import { SubmissionQAEdit } from './SubmissionQAEdit';
import { isValidUrl } from '@utils/validation';
import { AiOutlineEdit } from 'react-icons/ai';
import { MdDeleteOutline } from 'react-icons/md';
import { ConfirmDialog } from '@SignedIn/views/Locations/children/ConfirmDialog';
import { getFormAssetTypes } from '@services/asset-service';
import { getForm } from '@services/form-service';
import { deleteWithRef } from '@services/organization-service';

const SubmissionQA = (props) => {
	const { chosenResponse, chosenRef, toggleSubmission, updateResData } =
		props;

	// Parsed response data state
	const [parsedResponse, setParsedResponse] = useState([]);

	// Helper state that tracks changes for parsedResponse
	const [parsedResponseChanges, setParsedResponseChanges] = useState(false);

	// Form inputs data state
	const [inputs, setInputs] = useState();

	// Rendered data (ie, child component) state
	const [submission, setSubmission] = useState();

	// Parent component state for submission child component state
	const [submissionParent, setSubmissionParent] = useState();

	// Determines if editing is turned on
	const [showEdit, setShowEdit] = useState(false);

	const toggleEdit = () => setShowEdit(!showEdit);

	const [showDelete, setShowDelete] = useState(false);

	const toggleDelete = () => setShowDelete(!showDelete);

	const deleteSubmission = async () => {
		await deleteWithRef(chosenRef);
		toggleSubmission();
		updateResData();
	};

	const resetPostSubmission = async () => {
		await setParsedResponse([]);
		await setParsedResponseChanges(false);
		await setInputs();
		await setSubmission();
		await setSubmissionParent();
		await setShowEdit(false);
		await toggleSubmission();
		updateResData();
	};

	// Converts date field between formats
	const convertDateToISOString = (date) => {
		if (date) {
			const tempDate = new Date(date).toISOString().split('T');
			return tempDate[0];
		}
	};

	// // Takes nested data from form & further nests the inputs under the labels, leaving out blank sections
	// const nestAndFilterData = async (data) => {
	// 	let newData = [];
	// 	for (let page of data) {
	// 		let key = Object.keys(page)[0];
	// 		let newPage = {
	// 			page: key,
	// 			sections: [],
	// 		};
	// 		let pageInputs = page[key];
	// 		// Parent object for form section
	// 		let section;
	// 		// Child array for section inputs
	// 		let sectionInputs = [];
	// 		for (let input of pageInputs) {
	// 			// Assign first section
	// 			if (section == null) {
	// 				section = input;
	// 			}
	// 			// Add input values to section inputs IF same inputs exist in parsedResponse
	// 			// Also check if data is old (before commit 69e0450) and parse differently
	// 			else if (
	// 				input.length != undefined &&
	// 				parsedResponse.inputs == undefined
	// 			) {
	// 				let responseTag = input[0].responseTag;
	// 				let response = parsedResponse[responseTag];
	// 				if (response != undefined) {
	// 					input[1].responseTag =
	// 						input[1].responseTag + input[0].responseTag;
	// 					input[2].responseTag =
	// 						input[0].responseTag + input[2].responseTag;
	// 					// Also, check if any of the subinputs are images, then check if they need to be rotated
	// 					// for (let i = 0; i < input.length; i++) {
	// 					// 	let subResponse =
	// 					// 		parsedResponse[input[i].responseTag];
	// 					// 	if (
	// 					// 		input[i].type == 'image' &&
	// 					// 		subResponse != undefined
	// 					// 	) {
	// 					// 		const res = await fetch(subResponse);
	// 					// 		const blob = await res.blob();
	// 					// 		const arrBuff = await blob.arrayBuffer();
	// 					// 		const tags = ExifReader.load(arrBuff);
	// 					// 		input[i].exif = tags;
	// 					// 	}
	// 					// }
	// 					sectionInputs = [...sectionInputs, input];
	// 				}
	// 			} else if (
	// 				input.length != undefined &&
	// 				parsedResponse.inputs != undefined
	// 			) {
	// 				let responseTag = input[0].responseTag;
	// 				let response;
	// 				if (parsedResponse.inputs)
	// 					response = parsedResponse.inputs[responseTag];
	// 				else response = parsedResponse[responseTag];

	// 				if (response != undefined) {
	// 					// Also, check if any of the subinputs are images, then check if they need to be rotated
	// 					// for (let i = 0; i < response.length; i++) {
	// 					// 	let imageUrl = response[i].image;
	// 					// 	if (imageUrl != undefined) {
	// 					// 		const res = await fetch(imageUrl);
	// 					// 		const blob = await res.blob();
	// 					// 		const arrBuff = await blob.arrayBuffer();
	// 					// 		const tags = ExifReader.load(arrBuff);
	// 					// 		if (input[2].exif == undefined) {
	// 					// 			input[2].exif = { [i]: tags };
	// 					// 		} else {
	// 					// 			input[2].exif[i] = tags;
	// 					// 		}
	// 					// 	}
	// 					// }
	// 					sectionInputs = [...sectionInputs, input];
	// 				}
	// 			}
	// 			// Add sectionInputs to section as property + add section to page.sections if sectionInputs is not blank
	// 			// Then, reset section & sectionInputs for next section
	// 			else {
	// 				if (sectionInputs.length > 0) {
	// 					section.inputs = sectionInputs;
	// 					newPage.sections = [...newPage.sections, section];
	// 				}
	// 				section = input;
	// 				sectionInputs = [];
	// 			}
	// 		}
	// 		// Check final section & add to page.sections if sectionInputs is not blank
	// 		if (sectionInputs.length > 0) {
	// 			section.inputs = sectionInputs;
	// 			newPage.sections = [...newPage.sections, section];
	// 		}
	// 		// Add update page to newData array
	// 		newData = [...newData, newPage];
	// 	}
	// 	return newData;
	// };

	// Takes linear data and filters out blank sections, plus adds conditional inputs
	const filterLinearData = async (data, organization, formId) => {
		// First, add in missing conditional inputs to end of array, before image inputs
		const condInputs = await getConditionalInputs(organization, formId);
		let condIndex;
		for (let i = 0; i < data.length; i++) {
			const input = data[i];
			if (input.length == undefined) {
				if (input.type == 'image') {
					condIndex = i;
					break;
				}
			}
		}
		const updatedData = [
			...data.slice(0, condIndex),
			...condInputs,
			...data.slice(condIndex),
		];
		let newData = [];
		for (let input of updatedData) {
			// If input is not an array, check if it's in the response then add it to the new data (or not)
			if (input.length == undefined) {
				let responseTag = input.responseTag;
				let response = parsedResponse[responseTag];
				if (response != undefined) {
					// if (input.type == 'image') {
					// 	const res = await fetch(response);
					// 	const blob = await res.blob();
					// 	const arrBuff = await blob.arrayBuffer();
					// 	const tags = ExifReader.load(arrBuff);
					// 	input.exif = tags;
					// }
					newData = [...newData, input];
				}
			}
			// Otherwise, check whether the first array item's field has a response & decide on which array item to add to the new data
			else {
				if (parsedResponse[input[0].responseTag] != undefined) {
					newData = [...newData, input[0]];
				} else {
					const newLabel = input[0].label + ' ' + input[1].label;
					input[1].label = newLabel;
					parsedResponse[input[1].responseTag] = 'true';
					newData = [...newData, input[1]];
				}
			}
		}
		return newData;
	};

	// Retrieves conditional inputs from form's asset types
	const getConditionalInputs = async (organization, formId) => {
		let condInputs = [];
		const assetTypesQuery = await getFormAssetTypes(
			organization,
			formId,
			parsedResponse.assetType
		);

		if (!assetTypesQuery.empty) {
			const JSONtypes = assetTypesQuery.docs[0].data().inputs || [];
			for (const JSONtype of JSONtypes) {
				const type = JSON.parse(JSONtype);
				type.conditional = true;
				condInputs.push(type);
			}
		}
		return condInputs;
	};

	// // Sorts numbers from least to greatest
	// const sortNumbers = (array, key) => {
	// 	return array.sort((a, b) => Number(a[key]) - Number(b[key]));
	// };

	const getData = async () => {
		let newInputs;
		// DB calls to get form data
		const formId = parsedResponse.formId;
		const organization = parsedResponse.organization || props.organization;
		if (organization && formId) {
			const formSnap = await getForm(organization, formId);
			// Iterate through & modify the data a specific way before assigning to the inputs state
			let linearInputs = formSnap.data().inputs.pages[0].Details;
			for (let x = 0; x < linearInputs.length; x++) {
				let input = JSON.parse(linearInputs[x]);
				linearInputs[x] = input;
			}
			newInputs = await filterLinearData(
				linearInputs,
				organization,
				formId
			);

			setInputs(newInputs);
		}
	};

	const renderSubmission = async () => {
		let lineItems = [];
		let index = 0;
		const firstLine = (
			<FormGroup key={index} className='p-3'>
				<Label className='fw-bolder pt-0'>Date of Visit</Label>
				<Input
					disabled
					className='bg-white'
					type='date'
					value={convertDateToISOString(
						parsedResponse.dateOfVisit ||
							parsedResponse.submittedDate
					)}
				/>
			</FormGroup>
		);
		index++;

		let address = '';
		if (parsedResponse.location.address) {
			address = parsedResponse.location.address;
		}
		if (parsedResponse.location.address1) {
			address = address + ' ' + parsedResponse.location.address1;
		}
		if (parsedResponse.location.address2) {
			address = address + ' ' + parsedResponse.location.address2;
		}
		const secondLine = (
			<FormGroup key={index} className='p-3'>
				<Label className='fw-bolder pt-0'>Location</Label>
				<Input
					disabled
					className='bg-white'
					type='text'
					value={
						address +
						', ' +
						parsedResponse.location.city +
						', ' +
						parsedResponse.location.state +
						' ' +
						parsedResponse.location.zip
					}
				/>
			</FormGroup>
		);
		index++;

		// Render data
		for (let input of inputs) {
			if (input.responseTag == 'location') {
				continue;
			}
			let formKey = input.responseTag;
			if (formKey == 'assetQuality') {
				formKey = formKey + input[0].responseTag;
			}
			let label = input.label;
			let response = parsedResponse[formKey];

			let newRow;
			if (isValidUrl(response)) {
				newRow = (
					<FormGroup key={index} className='p-3 d-flex flex-column'>
						<Label className='submission-label'>{label}</Label>
						<div className='position-relative submitted-image-parent'>
							<img
								className='submitted-image'
								alt=''
								src={response}
							/>
						</div>
					</FormGroup>
				);
			} else if (input.type == 'image') {
				newRow = (
					<FormGroup key={index} className='p-3 d-flex flex-column'>
						<Label className='submission-label'>{label}</Label>
						<div className='position-relative'>
							<Input
								disabled
								className='bg-white'
								type='text'
								value={response}
							/>
						</div>
					</FormGroup>
				);
			} else if (input.type == 'checkbox') {
				newRow = (
					<FormGroup key={index} className='p-3'>
						<Label className='submission-label'>{label}</Label>
						<Input
							disabled
							className='bg-white'
							type='text'
							value={(() => {
								if (response === 'true') {
									return 'true';
								} else {
									return 'false';
								}
							})()}
						/>
					</FormGroup>
				);
			} else if (input.type == 'select') {
				newRow = (
					<FormGroup key={index} className='p-3'>
						<Label className='submission-label'>{label}</Label>
						<Input
							disabled
							className='bg-white'
							type='text'
							value={response}
						/>
					</FormGroup>
				);
			} else {
				newRow = (
					<FormGroup key={index} className='p-3'>
						<Label className='submission-label'>{label}</Label>
						<Input
							disabled
							className='bg-white'
							type='text'
							value={response}
						/>
					</FormGroup>
				);
			}
			lineItems = [...lineItems, newRow];
			index++;
		}

		lineItems = await Promise.all(lineItems);
		const component = (
			<div id='activities-tab-form-submission'>
				{firstLine}
				{secondLine}
				{lineItems}
			</div>
		);
		setSubmission(component);
	};

	// Update parsedResponse state when chosenResponse prop changes
	useEffect(() => {
		if (chosenResponse != null && chosenRef != null) {
			let newResponse = JSON.parse(JSON.stringify(chosenResponse));
			// (AMC specific code)
			// Check if submission contains 'images' key and add new inputs
			if (newResponse.images != undefined) {
				newResponse.equipmentAssetImage = newResponse.images[0];
				if (newResponse.images[1] != undefined) {
					newResponse.manufacturersPlateAssetImage =
						newResponse.images[1];
				}
				newResponse.images = undefined;
			}
			setParsedResponse(newResponse);
			setParsedResponseChanges(true);
		}
	}, [chosenResponse, chosenRef]);

	// Update inputs and parsedResponse states when parsedResponse state changes
	useEffect(() => {
		if (parsedResponse != null && parsedResponseChanges) {
			getData();
			setParsedResponseChanges(false);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [parsedResponseChanges]);

	// Update submission state when inputs
	useEffect(() => {
		if (inputs != null) {
			renderSubmission();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [inputs]);

	// Update submissionParent state when submission state changes
	useEffect(() => {
		if (submission != null && !showEdit) {
			setSubmissionParent(
				<Card className='p-3' id='submission'>
					<div className='submission-header d-flex flex-row align-items-center justify-content-between'>
						<div
							className='activities-page-header-buttons d-flex p-2 width-fit-content'
							onClick={toggleSubmission}
						>
							<i className='bi bi-arrow-left me-3'></i>
							<div>Return to Submissions in QA</div>
						</div>

						<div className='d-flex align-content-start'>
							<div className='d-flex flex-row'>
								{/* <DownloadPdf
												form={parsedResponse}
												inputs={inputs}
												isMultiPage={isNested}
											/>
											<DownloadCsv
												form={parsedResponse}
											/> */}
							</div>
							<button
								className='edit-submission-btn p-2'
								color='success'
								disabled={!canEdit()}
								onClick={toggleEdit}
							>
								Edit & Submit
								<span>
									<AiOutlineEdit className='fs-5 mx-1' />
								</span>
							</button>
							<button
								className='edit-submission-btn d-flex align-items-center p-2'
								color='success'
								disabled={!canEdit()}
								onClick={toggleDelete}
							>
								Delete
								<span>
									<MdDeleteOutline className='fs-5 mx-1' />
								</span>
							</button>
						</div>
					</div>
					<img
						src={parsedResponse.logo}
						className='submission-logo'
						alt='logo'
					/>
					<CardHeader
						className='d-flex flex-row align-items-center justify-content-between'
						style={{ backgroundColor: '#ffffff', border: 'none' }}
					>
						<div className='submission-title'>
							{parsedResponse.formName}
						</div>
						<ConfirmDialog
							showConfirm={showDelete}
							toggle={toggleDelete}
							title='Confirm Delete'
							body='Are you sure you want to delete this submission?'
							functionality={deleteSubmission}
						/>
					</CardHeader>
					{submission}
				</Card>
			);
		} else if (submission != null && showEdit) {
			setSubmissionParent(
				<SubmissionQAEdit
					toggleEdit={toggleEdit}
					chosenRef={chosenRef}
					parsedResponse={parsedResponse}
					updateParents={resetPostSubmission}
				/>
			);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		submission,
		parsedResponse,
		parsedResponseChanges,
		showEdit,
		showDelete,
	]);

	return submissionParent;
};

export { SubmissionQA };
