import {
	Badge,
	Card,
	CardBody,
	Row,
	Col,
	CardTitle,
	Popover,
	PopoverHeader,
	PopoverBody,
} from 'reactstrap';
import { BiTrendingUp } from 'react-icons/bi';
import { useState } from 'react';
import { useEffect } from 'react';

const StatsCard = (props) => {
	const [isAnimated, setIsAnimated] = useState(false);
	const { name, data, color, icon, lineBg, id } = props.stats;
	const [modalTimer, setModalTimer] = useState(null);
	const [showPopover, setShowPopover] = useState(false);

	const startModalTimer = () => {
		const timer = setTimeout(() => {
			setShowPopover(!showPopover);
		}, 600);
		setModalTimer(timer);
	};
	const clearModalTimer = () => {
		setShowPopover(false);
		clearTimeout(modalTimer);
		setModalTimer(null);
	};

	const toggle = () => {
		setShowPopover(!showPopover);
	};

	useEffect(() => {
		setIsAnimated(true);
	}, []);

	return (
		<div>
			<Card
				className={`${
					isAnimated ? 'show' : 'hidden'
				} stats-card my-3 me-0 card-bg ${lineBg}`}
				onMouseEnter={() => startModalTimer()}
				onMouseLeave={() => clearModalTimer()}
				id={id}
			>
				<CardBody className='px-4 small-card-body'>
					<div className='small-card-badge-wrapper'>
						<BiTrendingUp
							style={{
								color: color,
							}}
							className='small-card-arrow-up fs-3 mx-3'
						/>
						<Badge
							pill
							color={color}
							style={{
								float: 'right',
								'background-color': color,
							}}
							className='stats-card-badge'
						>
							+18%
						</Badge>
					</div>
					<Row className='h-100'>
						<Col className='col-12'>
							<div className='d-flex align-items-center justify-content-between h-100'>
								<div className='d-flex flex-column justify-content-center h-100 w-50'>
									<span
										style={{
											color: color,
										}}
										className='text-nowrap fs-2'
									>
										{data}
									</span>
									<CardTitle
										tag='p'
										className='fs-6 text-color'
									>
										<p className='m-0 small-card-title text-color text-nowrap'>
											{name}
										</p>
									</CardTitle>
								</div>
								<div className='d-flex align-items-center justify-content-end w-50 mt-3'>
									<i
										style={{
											color: color,
										}}
										className={`bi-${icon} card-icon text-color`}
									/>
								</div>
							</div>
						</Col>
					</Row>
				</CardBody>
			</Card>
			{id ? (
				<Popover flip target={id} toggle={toggle} isOpen={showPopover}>
					<PopoverHeader>{name}</PopoverHeader>
					<PopoverBody>
						Sed posuere consectetur est at lobortis. Aenean eu leo
						quam. Pellentesque ornare sem lacinia quam venenatis
						vestibulum.
					</PopoverBody>
				</Popover>
			) : null}
		</div>
	);
};

export { StatsCard };
