import Chatbot from 'react-chatbot-kit';
import 'react-chatbot-kit/build/main.css';
import config from './config.js';
import { ActionProvider } from './ActionProvider.jsx';
import { MessageParser } from './MessageParser.jsx';
import { AiOutlineClose } from 'react-icons/ai';

const PEChatbot = ({ toggleChatbot, setToggleChatbot }) => {
	return (
		toggleChatbot && (
			<div className='chatbot-wrapper position-absolute'>
				<>
					<button
						className='chatbot-close'
						onClick={() => setToggleChatbot(false)}
					>
						<AiOutlineClose />
					</button>
					<Chatbot
						config={config}
						messageParser={MessageParser}
						actionProvider={ActionProvider}
					/>
				</>
			</div>
		)
	);
};

export { PEChatbot };
