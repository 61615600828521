const cushmanExport = async (items, title) => {
	// Returns CSV header array
	const getHeader = (sampleItem) => {
		const headers = [];
		// Iterate through formsData and add input labels to header variable
		for (const header of Object.keys(sampleItem)) headers.push(header);
		return headers;
	};

	// Returns CSV rows array
	const getRows = (items) => {
		const rows = [];
		for (const item of items) {
			const row = [];
			for (const val of Object.values(item)) row.push(val);
			rows.push(row);
		}
		return rows;
	};

	// Converts a 2D array into a CSV string
	const getCsvString = (data) => {
		return data
			.map(
				(row) =>
					row
						.map(String) // convert every value to String
						.map((v) => v.replaceAll('"', '""')) // escape double colons
						.map((v) => `"${v}"`) // quote it
						.join(',') // comma-separated
			)
			.join('\r\n'); // rows starting on new lines
	};

	// Downloads CSV string as a CSV file
	const downloadFile = (csvString, filename, contentType) => {
		// Create a blob
		var blob = new Blob([csvString], { type: contentType });
		var url = URL.createObjectURL(blob);

		// Create a link to download it
		var pom = document.createElement('a');
		pom.href = url;
		pom.setAttribute('download', filename);
		pom.click();
	};

	// Call all the functions to get the CSV file download
	const header = getHeader(items[0]);
	const rows = getRows(items);
	const csvString = getCsvString([header, ...rows]);
	const filename = title;
	const contentType = 'text/csv;charset=utf-8;';
	return downloadFile(csvString, filename, contentType);
};

export { cushmanExport };
