// ** Store Imports
import { handleThemeMode } from '../redux/themeMode'
import { useDispatch, useSelector } from 'react-redux'
import AuthContext from '@assets/services/Auth'
import { useContext, useEffect } from 'react'


export const useThemeMode = () => {
  // ** Hooks
  const dispatch = useDispatch()
  const store = useSelector(state => state.handleThemeMode)
  const { authInfo } = useContext(AuthContext);

  const setThemeMode = type => {
    dispatch(handleThemeMode(type))
  }

  useEffect(() => {
    const htmlElement = document.querySelector('html');
    htmlElement.classList.remove('dark-mode');
    
    if (authInfo.loggedIn === false)
      return;
    if (store.thememode === 'dark')
      htmlElement.classList.add('dark-mode');
  }, [store.thememode, authInfo]);

  return { thememode: store.thememode, setThemeMode }
}
