import { canViewVendors } from '@assets/services/user-role-service.js';
import { DB_FORM, DB_ORG, DB_FILTER } from '@constants/db';
import { getForms } from '@services/form-service';
import { getOrganization } from '@services/organization-service';
import { getSubmissionWithFilter } from '@services/submission-service';

const exportCsvCushman = async (
	organization,
	field,
	option,
	sortOption,
	direction,
	setLoading,
	browserLocationId,
	locations,
	toggle,
	showApproved
) => {
	// Retrieves form snapshot docs
	const getFormDocs = async (organization) => {
		const formsSnapshot = await getForms(organization, DB_FORM.HANDYMAN);
		return formsSnapshot.docs;
	};

	// Retrieves form(s) and its inputs from the database
	const getFormsData = async (formDocs) => {
		let formsData = [];
		if (formDocs.length > 1) {
			// Update later on when we decide how to handle multiple-form CSV exports
		} else {
			const formSnap = formDocs[0];
			// Declare pages array from formSnap data
			const pages = formSnap.data().inputs.pages;
			// Iterate through the pages
			for (let x = 0; x < pages.length; x++) {
				let page = pages[x];
				let pageKeys = Object.keys(page);
				// Iterate through page keys
				for (let pageKey of pageKeys) {
					// Iterate through any value (ie, the inputs array) that doesn't have the key 'page'
					if (pageKey == 'page') {
						delete pages[x][pageKey];
					} else {
						// Remove location and date input values on first page
						if (x == 0) {
							const canViewLocations = await canViewVendors();
							if (!canViewLocations)
								pages[x][pageKey].splice(0, 2);
							else pages[x][pageKey].splice(1, 1);
						}
						const pageInputs = page[pageKey];
						for (let y = 0; y < pageInputs.length; y++) {
							// Parse the JSON input with an object or array
							let input = JSON.parse(pageInputs[y]);
							// Replace the old JSON input with the parsed & modified input
							pages[x][pageKey][y] = input;
						}
					}
				}
			}
			// Further update & filter the data
			for (let page of pages) {
				let key = Object.keys(page)[0];
				let pageInputs = page[key];
				for (let input of pageInputs) {
					if (input.length != undefined) {
						input[1].responseTag =
							input[1].responseTag + input[0].responseTag;
						// Add parent responseTag to each image responseTag
						input
							.filter((_input) => _input.type === 'image')
							.forEach(
								(imageInput) =>
									(imageInput.responseTag =
										input[0].responseTag +
										imageInput.responseTag)
							);
						for (let subinput of input) {
							formsData = [...formsData, subinput];
						}
					} else if (input.type != 'label') {
						formsData = [...formsData, input];
					}
				}
			}
			return formsData;
		}
	};

	// Returns all form(s) submissions from database
	const getSubmissionsData = async (formDocs) => {
		const formId = formDocs[0].id;
		const submissions = [];
		if (formDocs.length > 1) {
			// Update later on when we decide how to handle multiple-form CSV exports
		}
		// If formDocs only contain 1 form, then return one array of submissions
		else {
			// Iterate over locations if dealing with locations
			if (locations || browserLocationId) {
				const locs = [];
				if (locations && !browserLocationId) {
					locations.forEach((loc) => locs.push(loc.id));
				} else if (!locations && browserLocationId) {
					locs.push(browserLocationId);
				}
				const batch = 100;
				const length = Math.ceil(locs.length / batch);
				for (let i = 0; i < length; i++) {
					const promises = [];
					const start = i * batch;
					let end;
					if (i == length) end = locs.length;
					else end = start + batch;
					await locs.slice(start, end).forEach(async (loc) => {
						promises.push(
							new Promise((resolve, reject) => {
								try {
									const queryParams = [];
									if (field && option) {
										queryParams.push({
											name: field,
											value: option.value,
										});
									}
									queryParams.push({
										name: DB_FILTER.LOCATION_ID,
										value: loc,
									});
									if (showApproved !== null) {
										queryParams.push({
											name: DB_FILTER.APPROVED,
											value: showApproved,
										});
									}
									getSubmissionWithFilter(
										organization,
										formId,
										queryParams
									)
										.then((snap) => {
											if (!snap.empty) {
												submissions.push(...snap.docs);
											}
											resolve();
										})
										.catch(reject);
								} catch (e) {
									reject();
								}
							})
						);
					});
					await Promise.all(promises);
				}
				// for (const loc of locs) {
				// 	const queryParams = [];
				// 	if (field && option) {
				// 		queryParams.push({ name: field, value: option.value });
				// 	}
				// 	queryParams.push({
				// 		name: DB_FILTER.LOCATION_ID,
				// 		value: loc,
				// 	});
				// 	const submissionsSnap = await getSubmissionWithFilter(
				// 		organization,
				// 		formId,
				// 		queryParams
				// 	);
				// 	if (!submissionsSnap.empty) {
				// 		submissions.push(...submissionsSnap.docs);
				// 	}
				// }
			}
			// Otherwise, pull submissions from all locations
			else {
				const queryParams = [];
				if (field && option) {
					queryParams.push({ name: field, value: option.value });
				}
				if (showApproved !== null) {
					queryParams.push({
						name: DB_FILTER.APPROVED,
						value: showApproved,
					});
				}
				const submissionsSnap = await getSubmissionWithFilter(
					organization,
					formId,
					queryParams
				);
				if (!submissionsSnap.empty) {
					submissions.push(...submissionsSnap.docs);
				}
			}
		}
		return await formatSubmissionsData(submissions);
	};

	const formatSubmissionsData = async (submissions) => {
		const formattedSubmissions = [];
		for (const item of submissions) {
			const submission = item.data();
			submission.id = item.id;
			// Update submitted date field
			submission.submittedDate = formatDate(
				submission.submittedDate || submission.dateOfVisit
			);
			formattedSubmissions.push(submission);
		}
		return sortSubmissionsData(formattedSubmissions);
	};

	const sortSubmissionsData = async (submissions) => {
		const sortedSubmissions = submissions.sort((a, b) => {
			if (sortOption == 'submittedDate') {
				if (direction == 'asc') {
					return (
						new Date(a.submittedDate) - new Date(b.submittedDate)
					);
				} else {
					return (
						new Date(b.submittedDate) - new Date(a.submittedDate)
					);
				}
			} else {
				let valA, valB;
				if (sortOption.includes('.')) {
					const index = sortOption.indexOf('.');
					const key1 = sortOption.slice(0, index);
					const key2 = sortOption.slice(index + 1);
					valA = a[key1][key2];
					valB = a[key1][key2];
				} else {
					if (sortOption == 'submittingUserId')
						sortOption = 'submittingUser';
					valA = a[sortOption];
					valB = b[sortOption];
				}
				if (direction == 'asc') {
					if (valA < valB) return -1;
					else if (valA > valB) return 1;
					else return 0;
				} else {
					if (valA > valB) return -1;
					else if (valA < valB) return 1;
					else return 0;
				}
			}
		});
		return sortedSubmissions;
	};

	// Returns CSV rows array
	const getRows = async (submissionsData, inputs, formDocs, header) => {
		if (formDocs.length > 1) {
			// Update later on when we decide how to handle multiple-form CSV exports
		}
		// If formDocs only contain 1 form, then following code will return an array of rows for the CSV rows
		else {
			const orgSnap = await getOrganization(DB_ORG.CUSHMANWAKEFIELD);
			const categories = orgSnap.data().exportItemCategories;
			const rows = [];
			for (const submission of submissionsData) {
				// Init row with submission ID, date, and user
				const rowTemplate = [
					submission.id,
					submission.submittedDate,
					submission.submittingUser,
				];
				// Add location to row
				let location = '';
				if (submission.location) {
					if (submission.location.tririgaBuildingSystemId) {
						location = submission.location.tririgaBuildingSystemId;
					}
				}
				rowTemplate.push(location);

				// Define row variable and iterate over inputs of form
				// Skipping index 0, every three questions (1: text, 2: select, 3: image)
				// ... is associated with its own row, assuming the "text" question is filled out
				// Otherwise, skip to the next trio of questions and keep going
				let row = [...rowTemplate];
				addAdditionalInputs(submission, inputs);
				const isSingleImage = submissionHasSingleImages(submission);
				for (let i = 1; i < inputs.length; i++) {
					const input = inputs[i];
					try {
						const responseTag = getResponseTag(
							isSingleImage,
							input
						);
						const response = submission[responseTag];
						if (
							(response == undefined ||
								response == null ||
								response == '') &&
							input.type == 'text'
						) {
							i = i + 3;
							continue;
						} else if (
							response != undefined &&
							input.type == 'text'
						) {
							row.push(
								input.label,
								categories[input.label],
								response
							);
						} else if (
							response != undefined &&
							!imageAlreadyParsed(isSingleImage, input)
						) {
							row.push(response);
						}
						if (
							input.type == 'image' &&
							input.responseTag.includes('ImageAfter')
						) {
							rows.push(row);
							row = [...rowTemplate];
						}
					} catch (e) {
						return;
					}
				}
			}
			getCsvString([header, ...rows]);
		}
	};

	// Submissions before the multiple image form will only contain
	// submission fields with 'AssetImage'
	const submissionHasSingleImages = (submission) => {
		return (
			Object.keys(submission).find(
				(key) =>
					key.includes('AssetImageBefore') ||
					key.includes('AssetImageAfter')
			) === undefined
		);
	};

	// Prevent a single image response from being parsed for multiple
	// image inputs
	const imageAlreadyParsed = (isSingleImage, input) => {
		return isSingleImage && input.responseTag.includes('ImageAfter');
	};

	// Input response tags will not match with past single image forms
	// Replace responseTag to match with form submission
	const getResponseTag = (isSingleImage, input) => {
		return input.responseTag?.includes('AssetImage') && isSingleImage
			? input.responseTag.replace('Before', '').replace('After', '')
			: input.responseTag;
	};

	// A submission may have added additional inputs which will not be present
	// in the default form inputs array. These additional inputs have a number
	// in their response key. Identify these additional inputs by their number
	// and add additional inputs to the default form inputs array so they will
	// be parsed into the CSV file
	const addAdditionalInputs = (submission, inputs) => {
		const { additionalInputKeys, additionalResponseTags } =
			findAdditionalResponses(submission);

		for (const tag of additionalResponseTags) {
			// Find form inputs to be copied
			const index = inputs.findIndex((input) => {
				return input.responseTag === tag;
			});

			const repeatedInputs = inputs.slice(index, index + 4);

			const parentKey = additionalInputKeys.find(
				(k) =>
					k.includes(tag) &&
					!k.includes('AssetImage') &&
					!k.includes('assetQuality')
			);

			const qualityKey = additionalInputKeys.find(
				(k) => k.includes(tag) && k.includes('assetQuality')
			);

			const imageBeforeKey = parentKey + 'AssetImageBefore';
			const imageAfterKey = parentKey + 'AssetImageAfter';
			const responseKeys = [
				parentKey,
				qualityKey,
				imageBeforeKey,
				imageAfterKey,
			];

			const inputsToAdd = repeatedInputs.map((input, index) => ({
				...input,
				responseTag: responseKeys[index],
			}));

			inputs.splice(index + 4, 0, ...inputsToAdd);
		}
	};

	const findAdditionalResponses = (submission) => {
		// Find additional inputs from number included in the name
		const additionalInputKeys = Object.keys(submission).filter((key) =>
			/\d/.test(key)
		);

		// Find the first (or parent) response tag (the text input)
		const additionalResponseTags = additionalInputKeys
			.map((key) => key.replace(/[0-9]/g, ''))
			.filter(
				(key) =>
					!(
						key.includes('AssetImage') ||
						key.includes('assetQuality')
					)
			);

		return { additionalInputKeys, additionalResponseTags };
	};

	// Formats submission date to "YYYY-MM-DD HH:MM:SS" format
	const formatDate = (oldDate) => {
		let date = new Date(oldDate);

		let month = String(date.getMonth() + 1).padStart(2, '0');
		let day = String(date.getDate()).padStart(2, '0');
		let hours = String(date.getHours()).padStart(2, '0');
		let minutes = String(date.getMinutes()).padStart(2, '0');
		let seconds = String(date.getSeconds()).padStart(2, '0');
		let year = String(date.getFullYear());

		return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
	};

	// Converts a 2D array into a CSV string
	const getCsvString = (data) => {
		const csvString = data
			.map(
				(row) =>
					row
						.map(String) // convert every value to String
						.map((v) => v.replaceAll('"', '""')) // escape double colons
						.map((v) => `"${v}"`) // quote it
						.join(',') // comma-separated
			)
			.join('\r\n'); // rows starting on new lines
		const filename = convertDateToISOString(new Date());
		const contentType = 'text/csv;charset=utf-8;';
		return downloadFile(csvString, filename, contentType);
	};

	// Downloads CSV string as a CSV file
	// And sets "loading" state in parent component to false
	const downloadFile = (csvString, filename, contentType) => {
		setLoading(false);
		// Create a blob
		var blob = new Blob([csvString], { type: contentType });
		var url = URL.createObjectURL(blob);

		// Create a link to download it
		var pom = document.createElement('a');
		pom.href = url;
		pom.setAttribute('download', filename);
		pom.click();
	};

	// Converts any date to ISO string
	const convertDateToISOString = (date) => {
		if (date) {
			const tempDate = new Date(date).toISOString().split('T');
			return tempDate[0];
		}
	};

	// If we didn't pass locations in, we want a straight data -> csv experience

	const formDocs = await getFormDocs(organization);
	if (formDocs != null) {
		await setLoading(true);
		const header = [
			'id',
			'Date',
			'Vendor',
			'Store',
			'Item',
			'Category',
			'Description',
			'Action',
			'BeforeImage',
			'AfterImage',
		];
		const submissions = await getSubmissionsData(formDocs);
		const formsData = await getFormsData(formDocs, submissions);
		await getRows(submissions, formsData, formDocs, header);
		toggle();
	}
};

export { exportCsvCushman };
