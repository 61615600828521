import React, { useState } from 'react';
import {
	Button,
	Col,
	Modal,
	ModalBody,
	ModalFooter,
	ModalHeader,
	Row,
} from 'reactstrap';
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';
import { FiMinus, FiPlus } from 'react-icons/fi';

const SubmittedImageModal = (props) => {
	const [clickZoomIn, setClickZoomIn] = useState(false);
	const [clickZoomOut, setClickZoomOut] = useState(false);
	const cancel = () => props.cancel();

	///////////////////////////////////////////////////////////////////////
	return (
		<div>
			<Modal isOpen={props.showing} toggle={cancel} centered>
				<ModalHeader toggle={cancel}></ModalHeader>
				<ModalBody>
					<TransformWrapper initialScale={1}>
						{({ zoomIn, zoomOut }) => (
							<React.Fragment>
								<div className='submitted-image-container'>
									<TransformComponent>
										<img
											src={props.photoUrl}
											alt='img'
											className='image-submitted'
										/>
									</TransformComponent>
									<div className='tools'>
										<button
											className={`btn-zoom ${
												clickZoomOut
													? 'btn-zoom-pop'
													: ''
											}`}
											onClick={() => {
												setClickZoomOut(true);
												zoomOut();
											}}
											onAnimationEnd={() =>
												setClickZoomOut(false)
											}
										>
											<FiMinus />
										</button>
										<button
											className={`btn-zoom ${
												clickZoomIn
													? 'btn-zoom-pop'
													: ''
											}`}
											onClick={() => {
												setClickZoomIn(true);
												zoomIn();
											}}
											onAnimationEnd={() =>
												setClickZoomIn(false)
											}
										>
											<FiPlus />
										</button>
									</div>
								</div>
							</React.Fragment>
						)}
					</TransformWrapper>
				</ModalBody>
				<ModalFooter>
					<Row>
						<Col>
							<Button
								color='cancel'
								hidden={!props.switchToCrop}
								onClick={() => props.cancel()}
							>
								Cancel
							</Button>
						</Col>
						<Col>
							<Button
								onClick={() => props.switchToCrop()}
								hidden={!props.switchToCrop}
							>
								Crop
							</Button>
						</Col>
						<Col>
							<Button
								color='dark'
								onClick={() => props.removeImage()}
								hidden={!props.removeImage}
							>
								Remove
							</Button>
						</Col>
					</Row>
				</ModalFooter>
			</Modal>
		</div>
	);
	///////////////////////////////////////////////////////////////////////
};
export { SubmittedImageModal };
