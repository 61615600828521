import { QATable } from './children/QATable.js';

const QATab = (props) => {
	const { browserLocationId, organization, isFocused } = props;

	return (
		<QATable
			organization={organization}
			browserLocationId={browserLocationId}
			isFocused={isFocused}
		/>
	);
};
export { QATab };
