import { useEffect, useState } from 'react';
import { Card, CardHeader, Table, CardBody, Button } from 'reactstrap';
import { Loader } from '@SignedIn/Loader/Loader';
import { MdDownload } from 'react-icons/md';
import { cushmanExport } from '@SignedIn/views/Admin/cushmanExport/cushmanExport';
import { getSubmissions } from '@services/submission-service';
import { getFormId } from '@services/form-service';
import { getEvenOddLocations } from '@services/location-service';
import { DB_FORM } from '@constants/db';

const MonthlyPMTab = (props) => {
	const { org, isFocused } = props;
	const [tableRows, setTableRows] = useState();
	const [noSubmissionRows, setNoSubmissionRows] = useState();
	const [submissionsTable, setSubmissionsTable] = useState();
	const [noSubmissionsTable, setNoSubmissionsTable] = useState();
	const [isLoading, setIsLoading] = useState(true);
	const [tabChange, setTabChange] = useState(false);

	const getLocations = async () => {
		let locationsSnap = await getEvenOddLocations(org, false);
		console.log(locationsSnap);
		let locationsWithSubmission = [];
		let locationsWithoutSubmission = [];
		const formId = await getFormId(org, DB_FORM.HANDYMAN);
		const end = locationsSnap.docs.length;

		let i = 1;
		await locationsSnap.forEach(async (location) => {
			let row = {
				sitename: location.data().name,
				address: location.data().address1,
				city: location.data().city,
				zip: location.data().zip,
				state: location.data().state,
			};
			const querySnap = await getSubmissions(
				org,
				formId,
				null,
				location.data().tririgaBuildingSystemId
			);
			if (!querySnap.empty) {
				let dateArr = [];
				querySnap.forEach((item) => {
					dateArr.push(item.data().submittedDate);
				});

				dateArr = dateArr.sort((a, b) => new Date(b) - new Date(a));

				let mostRecentSubmissionDate = new Date(dateArr[0]);

				const lastMonthDate = new Date();
				lastMonthDate.setDate(1);
				lastMonthDate.setMonth(lastMonthDate.getMonth() - 1);

				if (mostRecentSubmissionDate >= lastMonthDate) {
					locationsWithSubmission.push(row);
				} else {
					locationsWithoutSubmission.push(row);
				}
			} else {
				locationsWithoutSubmission.push(row);
			}
			if (i == end) {
				setTableRows(locationsWithSubmission);
				setNoSubmissionRows(locationsWithoutSubmission);
			}
			i++;
		});
	};

	const createSubmissionsTable = () => {
		let rowArr = [];
		for (const item of tableRows) {
			let row = (
				<tr className='pmreport-table-row'>
					<td>{item.sitename}</td>
					<td>{item.address}</td>
					<td>{item.city}</td>
					<td>{item.state}</td>
					<td>{item.zip}</td>
				</tr>
			);
			rowArr.push(row);
		}
		setSubmissionsTable(<tbody>{rowArr}</tbody>);
	};

	const createNoSubmissionsTable = () => {
		let data = [];
		for (const item of noSubmissionRows) {
			let row = (
				<tr className='pmreport-table-row'>
					<td>{item.sitename}</td>
					<td>{item.address}</td>
					<td>{item.city}</td>
					<td>{item.state}</td>
					<td>{item.zip}</td>
				</tr>
			);
			data.push(row);
		}
		setNoSubmissionsTable(<tbody>{data}</tbody>);
	};

	const getLastMonthNameAndYear = () => {
		const date = new Date();
		date.setMonth(date.getMonth() - 1);
		const lastMonth = date.toLocaleString('default', { month: 'long' });
		return `${lastMonth} ${date.getFullYear()}`;
	};

	useEffect(() => {
		if (isFocused) {
			setTabChange(true);
		}
	}, [isFocused]);

	useEffect(() => {
		if (isFocused) {
			const func = async () => await getLocations();
			func();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [tabChange]);

	useEffect(() => {
		if (tableRows && noSubmissionRows) {
			createSubmissionsTable();
			createNoSubmissionsTable();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [tableRows, noSubmissionRows]);

	useEffect(() => {
		if (noSubmissionsTable && submissionsTable) {
			setIsLoading(false);
		}
	}, [submissionsTable, noSubmissionsTable]);

	return (
		<div
			style={{
				gap: '20px',
			}}
			className='d-flex flex-column flex-lg-row'
		>
			<Card className='mt-4 previous-audit-table'>
				<CardHeader className='p-3 d-flex flex-row justify-content-between'>
					<div className='table-title m-0 opacity-75'>
						Locations With PM Report{' '}
						{`for ${getLastMonthNameAndYear()}`}
					</div>
					<Button
						color='primary'
						className='rounded-circle no-border table-btn'
						outline
						onClick={() =>
							cushmanExport(
								tableRows,
								`Locations With PM Report ${getLastMonthNameAndYear()}`
							)
						}
					>
						<MdDownload className='larger-font-icon larger-font' />
					</Button>
				</CardHeader>
				<CardBody>
					{isLoading ? (
						<Loader />
					) : (
						<Table>
							<thead>
								<tr>
									<th>Site Name</th>
									<th>Address</th>
									<th>City</th>
									<th>State</th>
									<th>Zip</th>
								</tr>
							</thead>
							{submissionsTable}
						</Table>
					)}
				</CardBody>
			</Card>
			<Card className='mt-4 previous-audit-table'>
				<CardHeader className='p-3 d-flex flex-column flex-md-row justify-content-between'>
					<div className='table-title m-0 opacity-75'>
						Locations Without PM Report{' '}
						{`for ${getLastMonthNameAndYear()}`}
					</div>
					<Button
						color='primary'
						className='rounded-circle no-border table-btn'
						outline
						onClick={() =>
							cushmanExport(
								noSubmissionRows,
								`Locations Without PM Report ${getLastMonthNameAndYear()}`
							)
						}
					>
						<MdDownload className='larger-font-icon larger-font' />
					</Button>
				</CardHeader>
				<CardBody>
					{isLoading ? (
						<Loader />
					) : (
						<Table>
							<thead>
								<tr>
									<th>Site Name</th>
									<th>Address</th>
									<th>City</th>
									<th>State</th>
									<th>Zip</th>
								</tr>
							</thead>
							{noSubmissionsTable}
						</Table>
					)}
				</CardBody>
			</Card>
		</div>
	);
};

export { MonthlyPMTab };
