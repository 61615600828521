// import { CapitalPlanningCard } from './children/CapitalPlanningCard';
import { useState, useEffect } from 'react';
import { Row, Col } from 'reactstrap';
import { NormalizedSpendCard } from './children/NormalizedSpendCard';
import { AssetsTable } from './children/AssetsTable';

const PredictiveSpendTab = (props) => {
	const { locationId, organization, isFocused } = props;

	// Location assets data extracted from db
	const [assets, setAssets] = useState([]);

	// Determines year-range for pred spend data
	const years = 5;

	// Get all assets from db for single location
	const getAssets = async () => {
		let firebaseConfig = JSON.parse(process.env.REACT_APP_FIREBASE_CONFIG);
		const res = await fetch(
			firebaseConfig.projectId.includes('staging')
				? 'https://us-central1-property-echo-staging.cloudfunctions.net/getAssetsLocationWithPredSpendData'
				: 'https://us-central1-property-echo-4d747.cloudfunctions.net/getAssetsLocationWithPredSpendData',
			//   'http://localhost:5001/staging/us-central1/getAssetsLocationWithPredSpendData'
			// : 'http://localhost:5001/property-echo-4d747/us-central1/getAssetsLocationWithPredSpendData',
			{
				method: 'POST',
				body: JSON.stringify({
					org: organization,
					locationId: locationId,
					years: years,
				}),
			}
		);
		const resJson = await res.json();
		const newAssets = resJson.assets;
		setAssets(newAssets);
	};

	// Update assets state on initial render
	useEffect(() => {
		if (isFocused == true) {
			getAssets();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isFocused]);

	// const isAssetHealthy = (asset) => {
	// 	if (asset.data.healthscore > 20) return true;
	// 	else return false;
	// };
	// Update assets table state when assets state changes
	// Note: only works when the entire array is being replaced or when an item is deleted
	// useEffect(() => {
	// 	let assetRows = [];
	// 	let unhealthyAssets = [];

	// 	assets.sort((a, b) => r_sort(a, b, 'healthscore', false));
	// 	if (assets.length > 0) {
	// 		for (let i = 0; i < assets.length; i++) {
	// 			const newRow = (
	// 				<CapitalPlanningAssetRow
	// 					org={organization}
	// 					location={locationId}
	// 					index={i}
	// 					key={assets[i].id}
	// 					asset={assets[i]}
	// 					type={'all'}
	// 				/>
	// 			);
	// 			if (!isAssetHealthy(assets[i])) {
	// 				const newUnhealthRow = (
	// 					<CapitalPlanningAssetRow
	// 						org={organization}
	// 						location={locationId}
	// 						index={i}
	// 						key={assets[i].id}
	// 						asset={assets[i]}
	// 						type={'unhealthy'}
	// 					/>
	// 				);
	// 				unhealthyAssets = [...unhealthyAssets, newUnhealthRow];
	// 			}
	// 			assetRows = [...assetRows, newRow];
	// 		}
	// 	}
	// 	setUnhealthyAssetsTable(<tbody>{unhealthyAssets}</tbody>);
	// 	// eslint-disable-next-line react-hooks/exhaustive-deps
	// }, [assets]);

	return (
		<div className='predictive-spend-tab'>
			<Row color='light' className='cap-tab tab-content pt-3 mb-4'>
				<Col className='col-12 mb-4 mb-md-0'>
					<NormalizedSpendCard assets={assets} />
				</Col>
				{/* <Col className='col-12 col-md-6 mb-4 mb-md-0'>
					<CapitalPlanningCard
						assets={assets}
						location={locationId}
						org={organization}
					/>
				</Col> */}
			</Row>
			<Row>
				<Col className='col-12'>
					<AssetsTable assets={assets} />
				</Col>
				{/* <Col className='col-12 col-md-6 mb-4 mb-md-0'>
					<Card className='predictive-spend-card predictive-table-card'>
						<CardHeader className='d-flex justify-content-between align-items-center py-2'>
							<div className='d-flex align-items-center'>
								<TiWarningOutline className='fs-3' />
								<span className='fw-bold text-purple mx-2'>
									Poor Condition Assets
								</span>
							</div>
							<button className='predictive-btn'>
								{' '}
								<TbArrowsSort />
							</button>
						</CardHeader>
						<Table responsive>
							<thead>
								<tr className='predictive-table-row text-center'>
									<th width='30%'>
										<span className='mx-1'>Asset</span>
									</th>
									<th>
										<span className='mx-1'>
											Health Score
										</span>
									</th>
									<th>
										<span className='mx-1'>Cost</span>
									</th>
								</tr>
							</thead>
							{unhealthyAssetsTable}
						</Table>
					</Card>
				</Col> */}
			</Row>
		</div>
	);
};

export { PredictiveSpendTab };
