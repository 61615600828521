import { Input } from 'reactstrap';

const PredSpendMenu = (props) => {
	const { org, optionOne, setOptionOne, optionTwo, setOptionTwo } = props;

	return (
		<div className='pred-spend-menu'>
			<div>You are viewing </div>
			<Input type='select' onChange={(e) => setOptionOne(e.target.value)}>
				<option
					value={'types'}
					key={'pred-spend-option-one-1'}
					selected={optionOne == 'types'}
				>
					Asset Types
				</option>
				<option
					value={'assets'}
					key={'pred-spend-option-one-2'}
					selected={optionOne == 'assets'}
				>
					Master Assets
				</option>
			</Input>
			<div> within </div>
			<Input type='select' onChange={(e) => setOptionTwo(e.target.value)}>
				<option
					value={'one'}
					key={'pred-spend-option-two-1'}
					selected={optionTwo == 'one'}
				>
					{org}
				</option>
				<option
					value={'all'}
					key={'pred-spend-option-two-2'}
					selected={optionTwo == 'all'}
				>
					All Orgs
				</option>
			</Input>
			<div>.</div>
		</div>
	);
};

export { PredSpendMenu };
