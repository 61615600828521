import { useEffect, useState } from 'react';
import { AiOutlineHome, AiOutlineNumber } from 'react-icons/ai';
import { TbMap2, TbReport } from 'react-icons/tb';
import { GiHealthNormal } from 'react-icons/gi';
import { FaRegBuilding } from 'react-icons/fa';
import { BsGlobe } from 'react-icons/bs';
import { DB_ORG } from '@constants/db';
const Filter = (props) => {
	const {
		visible,
		columns,
		setColumns,
		colsChange,
		setColsChange,
		org,
		displayAddress,
	} = props;

	// State for class of certain kebab components
	const [kebabClass, setKebabClass] = useState('');

	// State for list of toggle items within kebab
	const [kebabArr, setKebabArr] = useState(() => {
		const array = [];
		const keys = Object.keys(columns);
		for (let key of keys) {
			let title;
			if (
				key == 'address' &&
				org == DB_ORG.CUSHMANWAKEFIELD &&
				displayAddress == true
			) {
				title = 'Site Name';
			} else if (key == 'manager' && org == DB_ORG.CUSHMANWAKEFIELD) {
				title = 'Site Analyst';
			} else if (key == 'address' && displayAddress == false) {
				continue;
			} else {
				const text = key;
				const result = text.replace(/([A-Z])/g, ' $1');
				const finalResult =
					result.charAt(0).toUpperCase() + result.slice(1);
				title = finalResult;
			}
			const row = (
				<div
					className='filter-row d-flex flex-row align-items-center justify-content-between p-1 px-2 mb-1'
					key={key}
				>
					<div
						className={`filter-title ${
							columns[key] && 'text-pe-green'
						}`}
					>
						{title === 'Address' ||
							(title === 'Site Name' && (
								<AiOutlineHome className='fs-5 mobile-none' />
							))}
						{title === 'City' && (
							<BsGlobe className='fs-5 mobile-none' />
						)}
						{title === 'State' && (
							<TbMap2 className='fs-5 mobile-none' />
						)}
						{title === 'Zip' && (
							<AiOutlineNumber className='fs-5 mobile-none' />
						)}
						{title === 'Property Type' && (
							<FaRegBuilding className='fs-5 mobile-none' />
						)}
						{title === 'Health' && (
							<GiHealthNormal className='fs-5 mobile-none' />
						)}
						{title === 'Submission Count' && (
							<TbReport className='fs-5 mobile-none' />
						)}
						<span className='px-0 px-md-3'>{title}</span>
					</div>
					<div className='filter-toggle d-flex flex-row'>
						<input
							id={`filter-button-${key}`}
							type='checkbox'
							className='filter-input'
							checked={columns[key]}
							onChange={() => updateColumns(key)}
						/>
						<label
							className='filter-button filter-label mx-3'
							htmlFor={`filter-button-${key}`}
						></label>
						<span
							className={`filter-title ${
								columns[key] && 'text-pe-green'
							}`}
						>
							{columns[key] ? 'On' : 'Off'}
						</span>
					</div>
				</div>
			);
			array.push(row);
		}
		return array;
	});

	// Updates kebab class state when visible prop changes
	useEffect(() => {
		if (visible) {
			setKebabClass('active');
		} else {
			setKebabClass('');
		}
	}, [visible]);

	// Updates kebabArr state when colsChange props change
	useEffect(() => {
		if (colsChange) {
			const newArr = [];
			const keys = Object.keys(columns);
			for (let key of keys) {
				let title;
				if (
					key == 'address' &&
					org == DB_ORG.CUSHMANWAKEFIELD &&
					displayAddress == true
				) {
					title = 'Site Name';
				} else if (key == 'manager' && org == DB_ORG.CUSHMANWAKEFIELD) {
					title = 'Site Analyst';
				} else if (key == 'address' && displayAddress == false) {
					continue;
				} else {
					const text = key;
					const result = text.replace(/([A-Z])/g, ' $1');
					const finalResult =
						result.charAt(0).toUpperCase() + result.slice(1);
					title = finalResult;
				}
				const row = (
					<div
						className='filter-row d-flex flex-row align-items-center justify-content-between p-1 px-2 mb-1'
						key={key}
					>
						<div
							className={`filter-title d-flex align-items-center ${
								columns[key] && 'text-pe-green'
							}`}
						>
							{title === 'Address' ||
								(title === 'Site Name' && (
									<AiOutlineHome className='fs-5 mobile-none' />
								))}
							{title === 'City' && (
								<BsGlobe className='fs-5 mobile-none' />
							)}
							{title === 'State' && (
								<TbMap2 className='fs-5 mobile-none' />
							)}
							{title === 'Zip' && (
								<AiOutlineNumber className='fs-5 mobile-none' />
							)}
							{title === 'Property Type' && (
								<FaRegBuilding className='fs-5 mobile-none' />
							)}
							{title === 'Health' && (
								<GiHealthNormal className='fs-5 mobile-none' />
							)}
							{title === 'Submission Count' && (
								<TbReport className='fs-5 mobile-none' />
							)}
							<span className='px-0 px-md-3'>{title}</span>
						</div>
						<div className='filter-toggle d-flex flex-row'>
							<input
								id={`filter-button-${key}`}
								type='checkbox'
								className='filter-input'
								checked={columns[key]}
								onChange={() => updateColumns(key)}
							/>
							<label
								className='filter-button filter-label mx-3'
								htmlFor={`filter-button-${key}`}
							></label>
							<span
								className={`filter-title ${
									columns[key] && 'text-pe-green'
								}`}
							>
								{columns[key] ? 'On' : 'Off'}
							</span>
						</div>
					</div>
				);
				newArr.push(row);
			}
			setKebabArr(newArr);
			setColsChange(false);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [colsChange]);

	// Updates respective property within columns
	const updateColumns = async (updatedKey) => {
		const keys = Object.keys(columns);
		const newColumns = await JSON.parse(JSON.stringify(columns));
		for (let key of keys) {
			if (key == updatedKey) {
				newColumns[key] = !newColumns[key];
				break;
			}
		}
		await setColumns(newColumns);
		setColsChange(true);
	};

	return (
		<div className='kebab-wrapper'>
			<div className={`kebab ${kebabClass} d-flex flex-column`}>
				<div className='mb-2'>Filter by Column</div>
				{kebabArr}
			</div>
		</div>
	);
};

export { Filter };
